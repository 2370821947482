import { Substrate } from './types';
import defaults from './defaults';
import { CompositeMode } from '../common';

export function getFrostedPlasticSubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 75,
        reflectivity: 0.1,
        reflectionMapOverrideUrls: [
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
        ],
        supportsTransparency: true,
        baseTexture: {
            dpi: 200,
            sourceUrl: 'https://uploads.documents.cimpress.io/v1/uploads/370fdfdd-1c30-4ccd-a98b-1bbebfb8d9ba~110/original?tenant=doc-platform',
        },
        baseColorMode: CompositeMode.multiply
    };
}
