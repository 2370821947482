import { FlatGeometry } from './flatGeometry';
import { Mesh, Shape, ExtrudeGeometry, ShapeGeometry } from 'three';
import { Size2D, Size3D, UVMapShape } from '@rendering/vortex-core/common';
import { convertToShape, preserve, mirror } from '../common';

const MIN_CURVE_SEGMENTS: number = 8;
const MAX_CURVE_SEGMENTS: number = 35;

// Centers a mesh according to its physical size
function centerMesh(mesh: Mesh, physicalSize: Size3D) {
    mesh.position.x = physicalSize.width * -0.5;
    mesh.position.y = physicalSize.height * -0.5;
    mesh.position.z = physicalSize.depth * -0.5;
}

// Finds the maximum size of the path by scanning all path operations
function findMaxSize(pathOperations: PathOperation[]): Size2D {
    let maxX = 0;
    let maxY = 0;

    for (let i = 0; i < pathOperations.length; i++) {
        if (pathOperations[i].x > maxX) {
            maxX = pathOperations[i].x;
        }

        if (pathOperations[i].y > maxY) {
            maxY = pathOperations[i].y;
        }
    }

    return { width: maxX, height: maxY };
}

// Determines the curve count of the custom path based on its complexity
function getCurveSegmentCount(customPath: CustomPath): number {
    let complexOperationCount: number = 0;

    // Sum up all the complex path operations in the primary path
    customPath.primary.forEach(operation => {
        if (operation.type === PathOperationType.quadraticCurveTo ||
            operation.type === PathOperationType.bezierCurveTo ||
            operation.type === PathOperationType.arcTo) {
            complexOperationCount++;
        }
    });

    // Add all cutouts if they exist
    if (customPath.cutouts && customPath.cutouts.length > 0) {
        customPath.cutouts.forEach(cutout => {
            cutout.forEach(operation => {
                if (operation.type === PathOperationType.quadraticCurveTo ||
                    operation.type === PathOperationType.bezierCurveTo ||
                    operation.type === PathOperationType.arcTo) {
                    complexOperationCount++;
                }
            });
        });
    }

    // If there is nothing complex return the default
    if (complexOperationCount === 0) {
        return MAX_CURVE_SEGMENTS;
    }

    // Otherwise limit the curve count to be between the min and max and then a function of the operation count
    return Math.max(MIN_CURVE_SEGMENTS, Math.min(MAX_CURVE_SEGMENTS, Math.round(180 / complexOperationCount)));
}

export enum PathOperationType {
    moveTo,
    lineTo,
    quadraticCurveTo,
    bezierCurveTo,
    arcTo
}

export interface PathOperation {
    type: PathOperationType;

    x: number;
    y: number;

    aCP1x?: number;
    aCP1y?: number;

    aCP2x?: number;
    aCP2y?: number;

    radius?: number;
    startAngle?: number;
    endAngle?: number;
}

export interface CustomPath {
    primary: PathOperation[];

    cutouts?: Array<PathOperation[]>;
    fills?: Array<PathOperation[]>;
}

export class CustomPathGeometry implements FlatGeometry {
    get key(): string {
        return 'custom path';
    }

    private maxSize: Size2D;
    private customPath: CustomPath;

    private curveSegments: number;

    constructor(customPath: CustomPath) {
        this.maxSize = findMaxSize(customPath.primary);

        this.customPath = customPath;
        this.curveSegments = getCurveSegmentCount(customPath);
    }

    buildFront(physicalSize: Size3D): Mesh {
        const frontShape: Shape = convertToShape(this.customPath, this.maxSize, { x: preserve, y: mirror }, { x: preserve, y: preserve });

        const geometry = new ShapeGeometry(frontShape, this.curveSegments);
        UVMapShape(geometry, physicalSize.width, physicalSize.height);

        const mesh = new Mesh(geometry);
        centerMesh(mesh, physicalSize);

        return mesh;
    }

    buildBack(physicalSize: Size3D): Mesh {
        const backShape: Shape = convertToShape(this.customPath, this.maxSize, { x: mirror, y: mirror }, { x: mirror, y: preserve });

        const geometry = new ShapeGeometry(backShape, this.curveSegments);
        UVMapShape(geometry, physicalSize.width, physicalSize.height);

        const mesh = new Mesh(geometry);
        centerMesh(mesh, physicalSize);

        // Account for the reverse centering
        mesh.position.x += physicalSize.width;

        return mesh;
    }

    buildBody(physicalSize: Size3D): Mesh {
        const extrudeSettings = { bevelEnabled: false, depth: physicalSize.depth, curveSegments: this.curveSegments };

        const bodyShape: Shape = convertToShape(this.customPath, this.maxSize, { x: preserve, y: mirror }, { x: preserve, y: preserve });

        const mesh = new Mesh(new ExtrudeGeometry(bodyShape, extrudeSettings));
        centerMesh(mesh, physicalSize);

        return mesh;
    }
}