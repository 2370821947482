import { Substrate } from './types';
import { CompositeMode } from '../common';

export function getKraftSubstrate(): Substrate {
    return {
        color: 0Xba966b,
        shininess: 20,
        baseCompositeMode: CompositeMode.multiply,
        baseTexture: {
            dpi: 200,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/kraft/v1/base',
            overlayColor: '#a2763d',
        },
    };
}
