/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Preview, PreviewSize } from 'Preview';
import { Fragment } from 'react';
import ShapeOptions from 'ShapeOptions';
import { Layout } from './constants';
import { useTranslation } from 'translation/i18n';
import { Theme } from './component-library/theme';
import { OptionValueType, Shape } from './shapes';
import { ReactComponent as BackIcon } from './icons/back.svg';
import { FlipOptions } from 'types';

const getLayoutPreviewArea = (theme: Theme, isDraggable: boolean, layout?: Layout) => {
    let width = '55%';
    if (layout !== Layout.PreviewOnSide) {
        width = '100%';
    }
    return css`
      width: ${width};
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid ${theme.secondaryBackgroundColor};
      background-color: ${theme.secondaryBackgroundColor};
      border-radius: 4px;
      padding: 10px;
      ${isDraggable
        ? '&:hover { border: 2px dashed #00b5e2; }'
        : ''}`;
};

const getDetailContainerStyle = (layout?: string) => {
    let flexDirection = 'row';
    if (layout === Layout.PreviewOnBottom) {
        flexDirection = 'column-reverse';
    } else if (layout === Layout.PreviewOnTop) {
        flexDirection = 'column';
    }
    return css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: ${flexDirection};
`;
};

const getBackButtonStyle = (theme: Theme) => css`
    display: flex;
    align-items: center;
    border: none;
    padding: 5px;
    background-color: transparent;
    margin-bottom: 10px;
    color: ${theme.primaryActiveColor};
    :hover {
      cursor: pointer;
    }
    svg {
        margin-right: 5px;
        width: 12px;
        height: 14px;
        fill: ${theme.primaryActiveColor};
    }
`;

const getBackButtonTextStyle = css`
  font-size: 12px;
`;

interface Props {
    shape: Shape;
    theme: Theme;
    isDraggable: boolean;
    layout: Layout;
    onEditCancel(): void;
    setOptionValue(optionId: string, value: OptionValueType): void;
    flip(flipArg: FlipOptions): void
}

export function EditorView({ shape,
    isDraggable,
    theme,
    layout,
    onEditCancel,
    setOptionValue,
    flip }: Props): JSX.Element {
    const { t } = useTranslation();

    const getOptionComponent = (): JSX.Element | undefined => {
        let childComponent = undefined;
        if (shape.options && shape.options.filter((option) => option.input).length > 0) {
            childComponent = (<ShapeOptions
                shapeOptions={shape.options}
                onChange={setOptionValue}
                flip={flip}
            />);
        }
        return childComponent;
    };

    return (
        <Fragment>
            <button css={getBackButtonStyle(theme)} onClick={onEditCancel}>
                <BackIcon />
                <span css={getBackButtonTextStyle}>{t('back')}</span>
            </button>
            <div css={getDetailContainerStyle(layout)}>
                <div className='smart-shapes--preview-panel' css={getLayoutPreviewArea(theme, isDraggable, layout)}>
                    <Preview
                        shape={shape}
                        previewSize={PreviewSize.Large}
                        isDraggable={isDraggable}
                    />
                </div>
                {getOptionComponent()}
            </div>
        </Fragment>
    );
}
