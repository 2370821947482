import Axios from 'axios';
import { VortexProduct } from '@rendering/vortex-core/products';
import { getAssetContentUrl, LinkInfo, queryLinksBySkuAndVariables } from './assetLoader';
import { ModelData, ProductSurface, VortexProductConfig } from '../configs';
import { loadModelFromConfig, ModelConfig, ModelSurface } from '@rendering/vortex-modeled-products/common';
import { loadSubstrateMap } from './materialLoader';
import { Substrate } from '@rendering/vortex-core/substrates';
import { Rotation3D } from '@rendering/vortex-core/common';

export async function getModelProductFromMcpSku(bearerToken: string, referenceId: string, attributes: { [key: string]: string | number }, tenant: { id: string, type: string }): Promise<VortexProduct | undefined> {
    const links: LinkInfo[] = await queryLinksBySkuAndVariables(bearerToken, referenceId, attributes, tenant);

    const tenantLink: LinkInfo | undefined = filterByTenant(links, tenant);
    const fallbackLink = filterByTenant(links, { id: 'cimpressVortex', type: 'fulfillers' });

    const assetId = tenantLink?.assetId || fallbackLink?.assetId; 

    // An asset was found, pull the information and load it for vortex
    if (assetId) {
        const modelResponse = await Axios.get(getAssetContentUrl(assetId));

        if (modelResponse.status >= 400) {
            throw `Failed to fetch content link from asset '${assetId}'`;
        }

        return getModelProduct(modelResponse.data as VortexProductConfig);
    }

    return undefined;
}

// Filter the assets by a specific tenant
function filterByTenant(links: LinkInfo[], tenant: { id: string, type: string }): LinkInfo | undefined {
    return links.find(link => { return link.tenant.id === tenant.id && link.tenant.type === tenant.type});
}

export async function getModelProduct(productConfig: VortexProductConfig): Promise<VortexProduct> {
    const dataResponse = await Axios.get<ModelData>(productConfig.productDataUrl);

    if (dataResponse.status >= 400) {
        throw new Error(`Failed to fetch ModelData from '${productConfig.productDataUrl}'!`)
    }

    const modelData: ModelData = dataResponse.data;

    const surfaceIds: string[] = Object.keys(productConfig.surfaceMapping);
    const surfaceMapping: { [key: string]: ModelSurface; } = {};

    const substrateMap: { [key: string]: Substrate } = await loadSubstrateMap(productConfig);

    // Build the surface mapping
    for (let i = 0; i < surfaceIds.length; i++) {
        const surfaceId: string = surfaceIds[i];
        const productSurface: ProductSurface = productConfig.surfaceMapping[surfaceId];

        if (!modelData.surfaceData[surfaceId]) {
            throw new Error(`The model data does not contain surface '${surfaceId}'!`)
        }

        surfaceMapping[surfaceId] = {
            page: productSurface.page,
            substrate: substrateMap[productSurface.substrateId],
            fullBleed: modelData.surfaceData[surfaceId].fullBleed,
            trim: modelData.surfaceData[surfaceId].trim,
            focalPoint: productSurface.focalPoint,
        };
    }

    const modelConfig: ModelConfig = {
        type: modelData.type,
        modelUri: modelData.modelUrl,
        productMapping: surfaceMapping,
        fullBleed: { width: 0, height: 0 },
        rotationOffset: convertRotationToRadians(modelData.rotationOffset),
        positionOffset: modelData.positionOffset,
        modelStates: productConfig.modelStates
    };

    return loadModelFromConfig(modelConfig);
}

const DEGREES_TO_RADIANS = Math.PI / 180;

function convertRotationToRadians(configRotationOffset: Rotation3D | undefined): Rotation3D {
    let rotationOffset: Rotation3D = { yaw: 0, pitch: 0, roll: 0 }

    if (configRotationOffset) {
        // convert degrees to radians for rotation
        rotationOffset = {
            yaw: configRotationOffset.yaw * DEGREES_TO_RADIANS,
            pitch: configRotationOffset.pitch * DEGREES_TO_RADIANS,
            roll: configRotationOffset.roll * DEGREES_TO_RADIANS
        }
    }

    return rotationOffset
}