/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'translation/i18n';
import {
    SvgDefaults
} from './constants';
import { Shape } from './shapes';
import { ShapeSvg } from './SvgShapes';

export enum PreviewSize {
    Auto = 'Auto',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large'
}

interface Props {
    shape: Shape | undefined;
    previewSize?: PreviewSize;
    isDraggable: boolean;
}

function getPreviewContainerCss(previewSize: PreviewSize, isDraggable: boolean) {
    let width;
    let cursor = 'default';
    if (isDraggable) {
        cursor = 'grab';
    }
    switch (previewSize) {
    case PreviewSize.Small:
        width = '120px';
        break;
    case PreviewSize.Medium:
        width = '170px';
        break;
    case PreviewSize.Large:
        width = '240px';
        break;
    default:
        width = '100%';
        break;
    }
    return css`
    width: ${width};
    height: ${width};
    display: flex;
    cursor: ${cursor};
    justify-content: center;
    ${isDraggable ? `&:active { cursor: grabbing}` : ''}`;
}

export function Preview({ shape, previewSize=PreviewSize.Auto, isDraggable }: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <div
            {...(isDraggable && { title: t('dragShape')})}
            className='shape-preview'
            css={getPreviewContainerCss(previewSize, isDraggable)}
        >
            {shape && (
                <ShapeSvg
                    shape={shape}
                    fillColor={SvgDefaults.fillColor}
                    stroke={SvgDefaults.stroke}
                    strokeWidth={SvgDefaults.strokeWidth}
                    width={SvgDefaults.viewBoxWidth}
                    height={SvgDefaults.viewBoxWidth}
                    isDraggable={isDraggable}
                />
            )}
        </div>
    );
}
