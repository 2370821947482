import { Animation } from './animation';
import { AnimationState } from './types';

export class AnimationManager {
    private animations: Animation[] = [];
    private animationState: AnimationState = AnimationState.playing;

    get requiresUpdate(): boolean {
        return this.animations.some((animation) => animation.isActive);
    }

    pauseAnimations(): void {
        this.animationState = AnimationState.paused;
    }

    playAnimations(): void {
        this.animationState = AnimationState.playing;
    }

    resetAnimations(): void {
        this.animations.forEach((animation) => animation.resetMeshState());
    }

    update(delta: number): void {
        if (this.animationState === AnimationState.paused) return;

        this.animations.forEach((animation) => animation.update(delta));
    }

    pushAnimation(animation: Animation): Animation {
        this.animations.push(animation);
        return animation;
    }

    cleanAnimations(): void {
        const keepAnimations: Animation[] = [];
        this.animations.forEach((animation) => {
            if (!animation.isComplete) {
                keepAnimations.push(animation);
            }
        });
        this.animations = keepAnimations;
    }

    // Cancel animation that have a specific tag.
    cancelAnimations(tag?: string): void {
        this.stopAnimations(tag);
        const filteredAnimations = tag === undefined ? this.animations
            : this.animations.filter((animation) => animation.tags.includes(tag));
        filteredAnimations.forEach((animation) => animation.cancel());
    }

    // If tag present stop animations with that tag, else stop all animations
    stopAnimations(tag?: string): void {
        if (tag) {
            this.animations.filter((animation) => animation.tags.includes(tag))
                .forEach((animation) => animation.stop());
        } else {
            this.animations.forEach((animation) => animation.stop());
        }
    }

    // Do not resume animations that contain tag ignoreTag
    resumeAnimations(ignoreTag?: string): void {
        if (ignoreTag) {
            this.animations.filter((animation) => !animation.tags.includes(ignoreTag))
                .forEach((animation) => animation.start());
        } else {
            this.animations.forEach((animation) => animation.resume());
        }
    }
}
