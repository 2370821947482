import Axios from 'axios';
import { FinishType } from '@rendering/vortex-core/finishes';
import { Instruction, RenderingInstructions } from '../externalModels';

const RAISED_INK = 'raisedink';
const METALLIC = 'metallic';
const RAISED_GOLD = 'raisedfoilgold';
const RAISED_SILVER = 'raisedfoilsilver';

const POINTS_TO_CM = 28.346;

export async function fetchInstructionData(instructionUrl: string): Promise<InstructionData> {
    const renderingInstructions = await fetchRenderingInstructions(instructionUrl);
    return getInstructionData(renderingInstructions);
}

export async function fetchRenderingInstructions(instructionsUrl: string): Promise<RenderingInstructions> {
    const result = await Axios.get<RenderingInstructions>(instructionsUrl);

    if (result.status >= 400) {
        throw `Failed to fetch instructions at ${instructionsUrl} with status code ${result.status}`;
    }

    return result.data;
}

export function getInstructionData(instructions: RenderingInstructions): InstructionData {
    const result: InstructionData = {
        pages: []
    }

    instructions.Slots.forEach( slot => {
        const pageData: InstructionPageData = {
            width: slot.WidthInPoints / POINTS_TO_CM,
            height: slot.HeightInPoints / POINTS_TO_CM,
            finish: getPageFinish(slot.Instructions)
        };
        result.pages.push(pageData);
    });

    return result;
}

// At the moment will only return one finish type
export function getPageFinish(slotInstructions: Instruction[]): FinishType | undefined {
    for (let i = 0; i < slotInstructions.length; i++) {
        let instruction = slotInstructions[i];
        // Check for spot color from image
        if (instruction.Variations && instruction.Variations.length > 0) {
            for (let j = 0; j < instruction.Variations.length; j++) {
                let variation = instruction.Variations[j];
                if (variation.SpotColor && variation.SpotColor.Name && finishFromString(variation.SpotColor.Name)) {
                    return finishFromString(variation.SpotColor.Name)
                }
            }
        }
        // Check Colors array
        if (instruction.Colors && instruction.Colors.length > 0) {
            for (let j = 0; j < instruction.Colors.length; j++) {
                let color = instruction.Colors[j];
                if (color.FillColor && color.FillColor.Overprint && color.FillColor.ManufacturerColorName && finishFromString(color.FillColor.ManufacturerColorName)) {
                    return finishFromString(color.FillColor.ManufacturerColorName);
                }
                if (color.StrokeColor && color.StrokeColor.Overprint && color.StrokeColor.ManufacturerColorName && finishFromString(color.StrokeColor.ManufacturerColorName)) {
                    return finishFromString(color.StrokeColor.ManufacturerColorName);
                }
            }
        }
    }
    return undefined;
}

export function finishFromString(colorName: string): FinishType | undefined {
    const normalizedName = colorName.toLowerCase();
    if (normalizedName.includes(RAISED_INK)) {
        return FinishType.raisedInk;
    }
    if (normalizedName.includes(METALLIC)) {
        return FinishType.metallic;
    }
    if (normalizedName.includes(RAISED_SILVER)) {
        return FinishType.raisedFoilSilver;
    }
    return normalizedName.includes(RAISED_GOLD) ? FinishType.raisedFoilGold : undefined;
}

export interface InstructionData {
    pages: InstructionPageData[];
}

export interface InstructionPageData {
    width: number;
    height: number;
    finish?: FinishType;
}