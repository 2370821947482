import { isSafari } from '../common';
import { OrbitalCamera } from '../cameras';

export interface ShadowOptions {
    canvasShadows?: CanvasShadowOptions;
}

export interface CanvasShadowOptions {
    enabled: boolean;

    softSpread?: number;
    softColor?: string;

    hardSpread?: number;
    hardOffset?: { x: number, y: number };
    hardColor?: string;
}

const SHADOW_DPI = 0.0071;

export function updateCanvasShadows(canvas: HTMLCanvasElement, canvasShadows: CanvasShadowOptions, camera: OrbitalCamera) {
    let styleFilter = '';

    const softSpread: number = canvasShadows.softSpread ?? 2.5;
    const hardSpread: number = canvasShadows.hardSpread ?? 0.5;
    const hardOffset: { x: number, y: number } = canvasShadows.hardOffset ?? { x: 0, y: 0.5 };

    // Add soft spread shadows
    if (softSpread > 0) {
        const spreadAmount: number = canvas.offsetWidth * SHADOW_DPI * softSpread * camera.zoomFactor;

        // Intensify and shrink the single default drop shadow for safari since it can only render one
        if (isSafari) {
            styleFilter += ` drop-shadow(0px 0px ${(spreadAmount * 0.5).toFixed(2)}px ${canvasShadows.softColor ?? 'rgba(0,0,0,0.08)'})`;
        } else {
            styleFilter += ` drop-shadow(0px 0px ${spreadAmount.toFixed(2)}px ${canvasShadows.softColor ?? 'rgba(0,0,0,0.05)'})`;
        }
    }

    // Add hard spread shadows when browser support is available
    if (hardSpread > 0 && !isSafari) {
        const spreadAmount = (canvas.offsetWidth * SHADOW_DPI * hardSpread * camera.zoomFactor).toFixed(2);
        const offsetX = (canvas.offsetWidth * SHADOW_DPI * hardOffset.x * camera.zoomFactor).toFixed(2);
        const offsetY = (canvas.offsetWidth * SHADOW_DPI * hardOffset.y * camera.zoomFactor).toFixed(2);

        styleFilter += ` drop-shadow(${offsetX}px ${offsetY}px ${spreadAmount}px ${canvasShadows.hardColor ?? 'rgba(0,0,0,0.04)'})`;
    }

    // eslint-disable-next-line no-param-reassign
    canvas.style.filter = styleFilter;
}
