import { Fold, NFoldConfig, Section, SectionSet } from "./types2";
import { buildPointLightGrid, disposeGeometryRecursively, RenderSize, Size2D, Size3D, UVMapShape } from '@rendering/vortex-core/common';
import { ProductState, ProductSurface, VortexProduct } from "@rendering/vortex-core/products";
import { Group, Light, Mesh, ExtrudeGeometry, Vector3, ShapeGeometry, Box3 } from 'three';
import { convertToShape, preserve, mirror, convertPathToShape } from '../common';
import { FoldAnimationOptions2, FoldingAnimation } from "./animations2";
import { getSection } from "./helpers";
import { instantlyPlayAnimation } from '@rendering/vortex-core/common';
import { Animation, AnimationDirection, AnimationOptions } from '@rendering/vortex-core/animations';
import { PathOperation } from "flatGeometries";

const CURVE_SEGMENTS = 15;
const EPSILON: number = 0.0001;

// Separate content and body layers by 20 micrometers
const LAYER_OFFSET = 0.02;

export class NFoldProduct2 implements VortexProduct {
    get name() {
        return 'package';
    }

    private config: NFoldConfig;
    private primarySectionSet: SectionSet;
    private surfaceMapping: { [key: string]: ProductSurface; };
    private sectionGroupMap: { [key: string]: Group };

    requiresGeometryUpdate: boolean = false;
    private initializedGeometry: Group | undefined;
    private physicalSize: Size3D;

    private currentState: ProductState;
    private productStates: ProductState[];

    constructor(config: NFoldConfig) {
        this.config = config;
        this.primarySectionSet = config.sectionSets[0];

        this.surfaceMapping = {};
        this.sectionGroupMap = {};

        this.physicalSize = {
            width: this.primarySectionSet.trim.bottomRightX - this.primarySectionSet.trim.topLeftX,
            height: this.primarySectionSet.trim.bottomRightY - this.primarySectionSet.trim.topLeftY,
            depth: this.primarySectionSet.thickness
        };

        this.productStates = [{ id: 'Unfolded' }];

        // Build the product states for fold sequences
        for (let foldSequence of this.primarySectionSet.foldSequences) {
            // Ensure dynamic focal points can be generated
            const state: ProductState = { id: foldSequence.id, allowGeneratedFocalPoints: true };

            if (foldSequence.dependencyId) {
                state.dependency = {
                    stateId: foldSequence.dependencyId,
                    animationId: foldSequence.id,
                    animationDirection: AnimationDirection.forward
                }
            } else {
                state.dependency = {
                    stateId: 'Unfolded',
                    animationId: foldSequence.id,
                    animationDirection: AnimationDirection.forward
                }
            }

            this.productStates.push(state);
        }

        const foldedState = this.productStates.find(state => state.id === 'Folded');

        if (!foldedState) {
            throw new Error(`Must have 'Folded' as a fold sequence`);
        }

        this.currentState = foldedState;
    }

    // Builds and textures a section front/body/back
    private buildSectionMeshes(section: Section): Mesh[] {
        const maxSize: Size2D = { width: this.physicalSize.width, height: this.physicalSize.height };

        const sectionMeshes: Mesh[] = [];

        sectionMeshes.push(...this.buildFrontSection(section, maxSize));
        sectionMeshes.push(...this.buildSectionBody(section, maxSize));
        sectionMeshes.push(...this.buildSectionBack(section, maxSize));

        return sectionMeshes;
    }

    private buildFrontSection(section: Section, maxSize: Size2D): Mesh[] {
        const frontGeometry = new ShapeGeometry(convertToShape(section.path, maxSize, { x: preserve, y: mirror }, { x: preserve, y: mirror }), CURVE_SEGMENTS);
        UVMapShape(frontGeometry, this.physicalSize.width, this.physicalSize.height);

        const meshes: Mesh[] = [];

        // Create the front geometry slightly in front of the body
        const frontMesh = new Mesh(frontGeometry);
        frontMesh.uuid = section.id + ':Front';
        frontMesh.position.z = this.primarySectionSet.thickness + LAYER_OFFSET;
        meshes.push(frontMesh);

        // Map the front surface
        this.surfaceMapping[frontMesh.uuid] = {
            substrate: section.frontSide.substrate,
            fullBleed: this.primarySectionSet.fullBleed,
            trim: this.primarySectionSet.trim,
            page: section.frontSide.page,
            focalPoint: section.frontSide.focalPoint,
            finishes: section.frontSide.finishes
        };

        // Create premium finishes if they exist
        if (section.frontSide.finishes) {
            let finishOffset: number = LAYER_OFFSET * 2;

            for (let finish of section.frontSide.finishes) {
                // Create the finish geometry slightly in front of the front geometry
                const finishMesh = new Mesh(frontGeometry);
                finishMesh.uuid = section.id + ':Front-' + finish.name;
                finishMesh.position.z = this.primarySectionSet.thickness + finishOffset;
                meshes.push(finishMesh);

                // Keep incrementing the layer offset for multiple finishes
                finishOffset += LAYER_OFFSET;
            }
        }

        return meshes;
    }

    private buildSectionBody(section: Section, maxSize: Size2D): Mesh[] {
        const bodyMeshes: Mesh[] = [];

        // Ignore rendering the body geometry folded product has a low thickness
        if (this.primarySectionSet.thickness > EPSILON) {
            // Build extrude settings based on thickness for the body mesh
            const extrudeSettings = { bevelEnabled: false, depth: this.primarySectionSet.thickness, curveSegments: CURVE_SEGMENTS };

            const bodyGeometry = new ExtrudeGeometry(convertToShape(section.path, maxSize, { x: preserve, y: mirror }, { x: preserve, y: mirror }), extrudeSettings);
            UVMapShape(bodyGeometry, this.physicalSize.width, this.physicalSize.height);
    
            const bodyMesh = new Mesh(bodyGeometry);
            bodyMesh.uuid = section.id + ':Body';
    
            // Map the body surface
            this.surfaceMapping[bodyMesh.uuid] = {
                substrate: section.bodySubstrate,
                fullBleed: this.primarySectionSet.fullBleed,
            };
    
            bodyMeshes.push(bodyMesh);
        }

        // If the section has any fills include them as meshes
        if (section.path.fills && section.fillSubstrates) {
            // Build extrude settings based on thickness for the body mesh
            const extrudeSettings = { bevelEnabled: false, depth: LAYER_OFFSET, curveSegments: CURVE_SEGMENTS };

            for (let i = 0; i < section.path.fills.length; i++) {
                const fillPath: PathOperation[] = section.path.fills[i];

                const fillGeometry = new ExtrudeGeometry(convertPathToShape(fillPath, maxSize, preserve, mirror), extrudeSettings);
                UVMapShape(fillGeometry, this.physicalSize.width, this.physicalSize.height);

                const fillMesh = new Mesh(fillGeometry);
                fillMesh.uuid = section.id + ':Body Fill:' + i;

                // Map the fill surface
                this.surfaceMapping[fillMesh.uuid] = {
                    substrate: section.fillSubstrates[i],
                    fullBleed: this.primarySectionSet.fullBleed,
                };

                bodyMeshes.push(fillMesh);
            }
        }

        return bodyMeshes;
    }

    private buildSectionBack(section: Section, maxSize: Size2D): Mesh[] {
        const backGeometry = new ShapeGeometry(convertToShape(section.path, maxSize, { x: mirror, y: mirror }, { x: mirror, y: mirror }), CURVE_SEGMENTS);
        UVMapShape(backGeometry, this.physicalSize.width, this.physicalSize.height);

        const meshes: Mesh[] = [];

        const backMesh = new Mesh(backGeometry);
        backMesh.uuid = section.id + ':Back';
        meshes.push(backMesh);

        // Move the back mesh behind the body and rotate/mirror it
        backMesh.position.z = -LAYER_OFFSET;
        backMesh.position.x += this.physicalSize.width;
        backMesh.rotation.y = Math.PI;

        // Map the back surface
        this.surfaceMapping[backMesh.uuid] = {
            substrate: section.backSide.substrate,
            fullBleed: this.primarySectionSet.fullBleed,
            trim: this.primarySectionSet.trim,
            page: section.backSide.page,
            focalPoint: section.backSide.focalPoint,
            finishes: section.backSide.finishes
        };

        // Create premium finishes if they exist
        if (section.backSide.finishes) {
            let finishOffset: number = LAYER_OFFSET * 2;

            for (let finish of section.backSide.finishes) {
                // Create the finish geometry slightly in behind of the back geometry
                const finishMesh = new Mesh(backGeometry);
                finishMesh.uuid = section.id + ':Back-' + finish.name;
                meshes.push(finishMesh);

                // Move the back mesh behind the body and rotate/mirror it
                finishMesh.position.z = -finishOffset;
                finishMesh.position.x += this.physicalSize.width;
                finishMesh.rotation.y = Math.PI;

                // Keep incrementing the layer offset for multiple finishes
                finishOffset += LAYER_OFFSET;
            }
        }

        return meshes;
    }

    // Builds a section group starting with a source section
    private buildSectionGroup(section: Section): Group {
        // Initialize and index a new section group
        const group: Group = new Group();
        this.sectionGroupMap[section.id] = group;

        // Add the section mesh to its group
        const sectionMeshes: Mesh[] = this.buildSectionMeshes(section);
        group.add(...sectionMeshes);

        const connectedFolds: Fold[] = this.getConnectedFolds(section.id);

        // Recursively add a group for each child section
        for (let i = 0; i < connectedFolds.length; i++) {
            const fold: Fold = connectedFolds[i];

            const childSection: Section = getSection(fold.childSectionId, this.primarySectionSet.sections);
            group.add(this.buildSectionGroup(childSection));
        }

        return group;
    }

    private getConnectedFolds(parentId: string): Fold[] {
        const folds: Fold[] = [];

        for (let i = 0; i < this.primarySectionSet.folds.length; i++) {
            const fold: Fold = this.primarySectionSet.folds[i];

            if (fold.parentSectionId === parentId) {
                folds.push(fold);
            }
        }

        return folds;
    }

    async getGeometry(): Promise<Group> {
        // If existing geometry already exists dispose of it
        if (this.initializedGeometry) {
            disposeGeometryRecursively(this.initializedGeometry);
        }

        const baseSection: Section = getSection(this.primarySectionSet.baseSectionId, this.primarySectionSet.sections);

        // Recursively build all section groups starting with the source
        const sectionGeometry = this.buildSectionGroup(baseSection);

        // Rotate the geometry when a rotation offsets exist
        if (this.primarySectionSet.rotationOffset) {
            const rotationOffset = this.primarySectionSet.rotationOffset;

            sectionGeometry.rotation.set(rotationOffset.yaw, rotationOffset.pitch, rotationOffset.roll);
        }

        // Start the product in it's folded state
        await instantlyPlayAnimation('Folded', this);

        // Find the center of the geometry
        const bounds = new Box3().setFromObject(sectionGeometry);
        const center: Vector3 = bounds.getCenter(new Vector3());

        // Translate the center of the model to the origin
        sectionGeometry.position.set(-center.x, -center.y, -center.z);
        sectionGeometry.updateMatrix();

        this.requiresGeometryUpdate = false;

        // Add the section geometry to a new group to ensure it remains
        // centered during any external animations
        this.initializedGeometry = new Group();
        this.initializedGeometry.add(sectionGeometry);

        return this.initializedGeometry;
    }

    getSurfaceMapping(): { [key: string]: ProductSurface; } {
        return this.surfaceMapping;
    }

    setProductSurface(surfaceId: string, productSurface: ProductSurface) {
        if (this.surfaceMapping[surfaceId]) {
            this.surfaceMapping[surfaceId] = productSurface;
        } else {
            throw new Error(`'${surfaceId}' is not a surface on this N-fold product`);
        }
    };

    getSectionGroupMapping(): { [key: string]: Group; } {
        return this.sectionGroupMap;
    }

    getPhysicalSize(): Size3D {
        return this.physicalSize;
    }

    getCurrentState(): ProductState {
        return this.currentState;
    }

    setCurrentState(stateId: string) {
        const newState = this.getStates().find(state => state.id === stateId);
        if (newState) {
            this.currentState = newState;
        } else {
            throw new Error(`'${stateId} is not a state defined in this product!`);
        }
    }

    getStates(): ProductState[] {
        return this.productStates;
    }

    setStates(productState: ProductState[]): void { }

    getAnimationIds(): string[] {
        const animationIds: string[] = [];
        this.primarySectionSet.foldSequences.forEach(sequence => animationIds.push(sequence.id));
        return animationIds;
    }

    buildAnimation(animationOptions: AnimationOptions): Animation {
        //check if Fold sequence exist
        const foldSequence = this.primarySectionSet.foldSequences.find(fold => fold.id === animationOptions.animationId);

        if (this.primarySectionSet.foldSequences === undefined || !foldSequence) {
            throw new Error(`Fold sequence '${animationOptions.animationId}' is not an available in the NFold Config!`);
        }

        const options: FoldAnimationOptions2 = {
            duration: animationOptions.duration,
            direction: animationOptions.direction,
            sequenceId: animationOptions.animationId
        };

        return new FoldingAnimation(this, this.config, options);
    }

    getAlphaMap(renderSize: RenderSize, physicalSize: Size2D, context: CanvasRenderingContext2D): void {
        // Clear the bg with black
        context.fillRect(0, 0, renderSize.productSize.width, renderSize.productSize.height);

        // Set the fill to white for any content drawn
        context.fillStyle = '#FFFFFF';

        // Close any paths
        context.closePath();
        context.fill();
    }

    getAreaLights(pointLightIntensity: number, directionalLightIntensity: number): Light[] {
        const pointLightIntensities: number[] = [
            0.4 * pointLightIntensity, 0.4 * pointLightIntensity,
            0.37 * pointLightIntensity, 0.37 * pointLightIntensity,
            0.43 * pointLightIntensity, 0.40 * pointLightIntensity
        ];

        return buildPointLightGrid(pointLightIntensities);
    }

    preLoad(): Promise<void> {
        return Promise.resolve();
    }

    dispose(): void {
        // Nothing to do
        if (!this.initializedGeometry) return;

        disposeGeometryRecursively(this.initializedGeometry);

        this.initializedGeometry = undefined;
    }
}
