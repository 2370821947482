import { convertSvgToCimdoc, RenderingFormat } from '@cimpress-technology/svg-to-cimdoc';
import { SvgDefaults } from '../constants';
import { Shape, ShapeTypes, getSvgFromShape } from '../shapes';
import { addNewShape } from './shapesUsages';
import { AuthConfig } from '../types';

interface AppUserData {
    tenantId: string;
    hostApp: string;
    authConfig: AuthConfig;
}

let currentShape: Shape;
let appUserData: AppUserData;

export function getCurrentSvg(
    width?: number,
    height?: number): Promise<string> {
    addNewShape({ ...appUserData, shape: currentShape });
    const { fillColor, stroke, strokeWidth, viewBoxWidth } = SvgDefaults;
    return getSvgFromShape(currentShape,
        width || viewBoxWidth,
        height || viewBoxWidth,
        stroke,
        strokeWidth,
        fillColor);
}

function getModuleFromShape(
    shapeType: ShapeTypes,
    mmWidth: number,
    mmHeight: number
): Promise<RenderingFormat> {
    return new Promise(function (resolve) {
        resolve(({
            type: shapeType,
            position: {
                x: `0mm`,
                y: `0mm`,
                height: `${mmHeight}mm`,
                width: `${mmWidth}mm`,
            },
            curves: [],
            viewBox: { x: '0mm', y: '0mm', height: `${mmHeight}mm`, width: `${mmWidth}mm` },
            stroke: {
                color: `rgb(${SvgDefaults.stroke})`,
                thickness: `${SvgDefaults.strokeWidth}pt`,
            },
            color: `rgb(${SvgDefaults.fillColor})`,
        }))
    });
}

export function getCurrentShapeInRenderingFormat(
    mmWidth?: number,
    mmHeight?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    addNewShape({ ...appUserData, shape: currentShape });
    const newWidth = mmWidth || SvgDefaults.viewBoxWidth;
    let newHeight = mmHeight || SvgDefaults.viewBoxWidth;
    const shapeType = currentShape.type;
    if (
        shapeType === ShapeTypes.Rectangle ||
        shapeType === ShapeTypes.Ellipse ||
        shapeType === ShapeTypes.Line
    ) {
        newHeight = shapeType === ShapeTypes.Line ? 1 : newHeight;
        return getModuleFromShape(
            shapeType,
            newWidth,
            newHeight
        ).then(data => [data]); //returning an array
    } else {
        return getCurrentSvg(newWidth, newHeight)
            .then(svg => convertSvgToCimdoc({
                svgString:svg,
                widthInMM: newWidth,
                shouldMergePath: true
            }).then((data: RenderingFormat[]) => data));
    }
}

export function setCurrentShape(shape: Shape): void {
    currentShape = shape;
}

export function getCurrentShape(): Shape {
    return currentShape;
}

export function setAppUser(appUser: AppUserData): void {
    appUserData = appUser;
}

export function getAppUser(): AppUserData {
    return appUserData;
}
