import { TextureLoader, Texture, WebGLRenderer, Vector2, RepeatWrapping } from 'three';

export function createRenderer(width: number, height: number): WebGLRenderer {
    const renderer = new WebGLRenderer({ antialias: true, alpha: true });

    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0, 0);

    return renderer;
}

export async function loadTextureFromUri(textureUrl: string, repeatCount?: number): Promise<Texture> {
    const loader = new TextureLoader();
    const texture: Texture = await loader.loadAsync(textureUrl);
    texture.anisotropy = 4;

    if (repeatCount) {
        texture.repeat = new Vector2(repeatCount, repeatCount);
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
    }

    return texture;
}

export function createTextureFromCanvas(canvas: HTMLCanvasElement | HTMLImageElement, repeatCount?: number): Texture {
    const texture = new Texture(canvas);

    texture.needsUpdate = true;
    texture.anisotropy = 4;

    if (repeatCount) {
        texture.repeat = new Vector2(repeatCount, repeatCount);
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
    }

    return texture;
}

export function WebGlSupported(canvas: HTMLCanvasElement): boolean {
    const webGlSupported = !!(WebGLRenderingContext
        && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
    return webGlSupported;
}
