import { Size2D, RenderSize } from '../common';
import { RenderingOptions } from '../config';
import { ProductSurface, ProductTrim, VortexProduct } from '../products';

const BASE_URL_DEFAULT = 'https://rendering.documents.cimpress.io/v2';

const MIN_TEXTURE_DIM = 512;
const MAX_TEXTURE_DIM = 1024;

export class RenderingClient {
    private instructionSourceUrl?: string;
    private instructionSourceOverrides: { [key: number]: string } = {};
    private instructionsChanged = false;

    private tenant: string;
    private category: string;

    private containerDimensions?: Size2D;

    private baseUrl: string;
    private cacheBust: boolean;

    private minimumRenderSize: number;
    private maximumRenderSize: number;

    private webpEnabled: boolean;
    private webpSupported = false;

    constructor(options: RenderingOptions) {
        this.tenant = options.tenant;
        this.category = options.category;

        this.baseUrl = options.baseUrl ?? BASE_URL_DEFAULT;
        this.cacheBust = options.cacheBust ?? false;

        this.minimumRenderSize = options.minimumRenderSize ?? MIN_TEXTURE_DIM;
        this.maximumRenderSize = options.maximumRenderSize ?? MAX_TEXTURE_DIM;

        this.webpEnabled = options.enableWebp ?? false;

        if (!this.isPow2(this.minimumRenderSize) || !this.isPow2(this.maximumRenderSize)) {
            throw new Error('Minium and maximum render sizes must be powers of two!');
        }
    }

    // Initializes the rendering client by checking for webp image support
    initialize(): Promise<void> {
        if (!this.webpEnabled) {
            return Promise.resolve();
        }

        const testImg = new Image();

        testImg.onload = () => { this.webpSupported = true; };
        testImg.onerror = () => { this.webpSupported = false; };

        testImg.src = 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==';

        return testImg.decode();
    }

    computeRenderSize(geometryAspectRatio: number): RenderSize {
        if (this.containerDimensions === undefined) {
            return {
                textureSize: { width: this.minimumRenderSize, height: this.minimumRenderSize },
                productSize: {
                    width: Math.round(geometryAspectRatio > 1 ? this.maximumRenderSize : this.maximumRenderSize * geometryAspectRatio),
                    height: Math.round(geometryAspectRatio > 1 ? this.maximumRenderSize / geometryAspectRatio : this.maximumRenderSize),
                },
            };
        }
        const containerAspectRatio = this.containerDimensions.width / this.containerDimensions.height;

        let productWidth: number;
        let productHeight: number;

        // Figure out the projected product dimensions based on the container size
        if (containerAspectRatio > 1) {
            productHeight = this.containerDimensions.height;
            productWidth = productHeight * geometryAspectRatio;
        } else {
            productWidth = this.containerDimensions.width;
            productHeight = productWidth / geometryAspectRatio;
        }

        return {
            textureSize: {
                width: productWidth < this.minimumRenderSize ? this.minimumRenderSize : this.maximumRenderSize,
                height: productHeight < this.minimumRenderSize ? this.minimumRenderSize : this.maximumRenderSize,
            },
            productSize: {
                width: Math.round(geometryAspectRatio > 1 ? this.maximumRenderSize : this.maximumRenderSize * geometryAspectRatio),
                height: Math.round(geometryAspectRatio > 1 ? this.maximumRenderSize / geometryAspectRatio : this.maximumRenderSize),
            },
        };
    }

    isInitialized(): boolean {
        return this.instructionSourceUrl !== undefined;
    }

    requiresUpdate(): boolean {
        return this.instructionsChanged;
    }

    updateContainerDimensions(dimensions: Size2D) {
        this.containerDimensions = dimensions;
    }

    setInstructionSourceUrl(instructionSourceUrl: string, page?: number): void {
        // If a custom page is provided only update that specific instruction source. Only
        // apply this logic when the base instruction source exists, otherwise some pages may have
        // empty instruction sources and fail rasterization
        if (page && this.instructionSourceUrl) {
            this.instructionSourceOverrides[page] = `${instructionSourceUrl}`;
        } else {
            // Otherwise flush all existing instructions overrides and use the provided source for all pages
            this.instructionSourceOverrides = {};
            this.instructionSourceUrl = `${instructionSourceUrl}`;
        }

        this.instructionsChanged = true;
    }

    buildDocumentRasterUrls(product: VortexProduct): { [key: string]: string } {
        const rasterMap: { [key: string]: string } = {};
        const surfaceMap: { [key: string]: ProductSurface } = product.getSurfaceMapping();

        // some surfaces need to preserve transparency some do not, the key only takes into account the page number
        // and not transparency. If any surface needs transparency set preserve alpha to be true
        const preserveAlpha = Object.keys(surfaceMap).some(
            (surfaceId: string) => surfaceMap[surfaceId].page
            && surfaceMap[surfaceId].substrate
            && (surfaceMap[surfaceId].substrate.supportsTransparency || surfaceMap[surfaceId].substrate.requiresDocumentTransparency),
        );

        Object.keys(surfaceMap).forEach((surfaceId) => {
            const surface: ProductSurface = surfaceMap[surfaceId];

            // If the surface is designable configure its pages and finishes if they don't exist
            if (surface.page) {
                const documentKey = `${surface.page} none`;

                // Get the instruction source (check for overrides)
                const instructions: string = this.instructionSourceOverrides[surface.page] ?? this.instructionSourceUrl;

                // Get the raster size
                const geometryAspect = surface.fullBleed.width / surface.fullBleed.height;
                const renderSize: RenderSize = this.computeRenderSize(geometryAspect);

                // Add the document raster if it doesn't exist
                if (!rasterMap[documentKey]) {
                    rasterMap[documentKey] = this.getDocumentRaster(renderSize.textureSize, surface, instructions, undefined, preserveAlpha);
                }

                // Add any finishes that don't exist
                if (surface.finishes) {
                    for (const finish of surface.finishes) {
                        const finishKey = `${surface.page} ${finish.channel}`;

                        if (!rasterMap[finishKey]) {
                            rasterMap[finishKey] = this.getDocumentRaster(renderSize.textureSize, surface, instructions, finish.channel, true);
                        }
                    }
                }
            }
        });

        // New map built, reset instruction change
        this.instructionsChanged = false;

        return rasterMap;
    }

    private getDocumentRaster(dimensions: Size2D, surface: ProductSurface, instructions: string,
        channel: string | undefined, preserveAlpha: boolean): string {
        if (!instructions) {
            throw new Error('Cannot render a document without instructions defined!');
        }

        if (!surface.page) {
            throw new Error('Cannot build rendering instructions for a surface without a defined page number!');
        }

        const renderingParameters = new URLSearchParams();

        renderingParameters.append('instructions_uri', instructions);
        renderingParameters.append('scene', this.buildTransientScene(dimensions, surface.page, surface.fullBleed, surface.trim, channel));

        if (this.cacheBust) {
            renderingParameters.append('vortexBust', `vortex${Math.random()}`);
        }

        // Start with webp if its supported, otherwise use to jpg/png
        if (this.webpEnabled && this.webpSupported) {
            renderingParameters.append('format', 'webp');
        } else if (preserveAlpha) {
            renderingParameters.append('format', 'png');
        } else {
            renderingParameters.append('format', 'jpg');
        }

        // Request white as the bg color when alpha is not required
        if (!preserveAlpha) {
            renderingParameters.append('bgColor', 'FFFFFF');
        }

        if (this.category) {
            renderingParameters.append('category', this.category);
        }

        return `${this.baseUrl}/${this.tenant}/preview?width=${dimensions.width}&${renderingParameters.toString()}`;
    }
    /**
     * @param  {Size2D} dimensions
     * @param  {number} page
     * @param  {Size2D} bleed
     * @param  {ProductTrim} trim?
     * @param  {string} channel?
     * @returns string
     * TODO: This function should use local variables (this), if not, should be moved to a utils
     */
    // eslint-disable-next-line class-methods-use-this
    private buildTransientScene(dimensions: Size2D, page: number, bleed: Size2D, trim?: ProductTrim, channel?: string): string {
        const TRANSIENT_V3_URL = 'https://cdn.scenes.documents.cimpress.io/v3/transient';

        let sanitizedTrim = trim;
        // If no trim is specified, update the trim to the full bleed
        if (!sanitizedTrim) {
            sanitizedTrim = {
                topLeftX: 0,
                topLeftY: 0,
                bottomRightX: bleed.width,
                bottomRightY: bleed.height,
            };
        }

        // Build out the input data object for transient V3
        const inputData = {
            width: dimensions.width,
            height: dimensions.height,
            page,
            channel,
            product: {
                bleed,
                trim: sanitizedTrim,
            },
        };

        return `${TRANSIENT_V3_URL}?data=${encodeURIComponent(JSON.stringify(inputData))}`;
    }

    // TODO: This should be move to a utils
    // eslint-disable-next-line class-methods-use-this
    private isPow2(x: number): boolean {
        const log = Math.log2(x);

        return Math.floor(log) === Math.ceil(log);
    }
}
