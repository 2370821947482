import { Object3D } from 'three';
import { Preview } from '../preview';
import { SwivelAnimation, SwivelAnimationOptions } from './swivelAnimation';

// Generic swivel animation initializer that any product can use
export function swivel(preview: Preview, range: number, damping?: number | boolean, tags?: string[]): Promise<void> {
    const swivelOptions: SwivelAnimationOptions = {
        range,
        damping: damping ?? false,
        tags: tags ?? [],
    };
    const animation = new SwivelAnimation(preview.getProductGeometry as Object3D, swivelOptions);
    return preview.pushAnimation(animation).start();
}
