/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Theme, useTheme } from '../theme';
// import Tooltip from './Tooltip';

// import './slider.css';

export interface SliderProps {
    label: string;
    disabled?: boolean;
    min: number;
    max: number;
    value: number;
    onSelect(value: number): void;
    increment?: number;
    selectOnDrag?: boolean;
    className?: string;
}

/**
 * Determines the number of digits after the decimal place. Returns 0 if there are none.
 * @param {number} num any real number
 * @returns {number} - an integer denoting the number of decimal digits
 */
export function countDecimals(num: number) {
    if (Math.floor(num) === num) return 0;
    return num.toString().split('.')[1].length;
}

/**
 * Rounds the given number to the closest increment. The increment can be any real number.
 * @param {number} num any real number
 * @param {number} inc any real number
 * @returns {number} - num rounded to the closest increment.
 */
export function roundToIncrement(num: number, inc: number) {
    return Math.round(Math.round(num / (inc / 10)) / 10) * inc; // https://gordonlesti.com/inaccurate-rounding-with-decimal-digits/
}

/**
 * Set the number a string, truncate to the given decimal point, and cut off any extraneous decimal zeroes.
 * @param {number} num any real number
 * @param {number} decimals any integer
 * @returns {number} - num truncated to the decimals, plus any extra zeroes removed.
 */
export function truncate(num: number, decimals: number) {
    // toFixed() solves the problem of floats (such as 1.0000000000000000012) being returned from increment.
    // the replace() will then determine if the result is a decimal with trailing zeroes, and remove the zeroes/decimal if applicable.
    return num
        .toFixed(decimals)
        .replace(/\.\d*0+$/, (match) => match.replace(/\.?0+$/, ''));
}

let barWidth = 0;
let barLeft = 0;
let touchIdentifier = 0;

function getPosition(element: any) {
    const box = element.getBoundingClientRect();
    return {
        left: box.left + window.scrollX,
        top: box.top + window.scrollY,
        width: box.width,
        height: box.height,
    };
}

function getMouseX(e: any) {
    // Regular mouse events.
    // eslint-disable-next-line no-self-compare
    if (typeof e.pageX === 'number' && e.pageX === e.pageX) {
        return e.pageX;
    }

    // Touch events.
    for (let i = 0; i < e.changedTouches.length; i += 1) {
        if (e.changedTouches[i].identifier === touchIdentifier) {
            return e.changedTouches[i].pageX;
        }
    }

    return 0; // It should never get here.
}

const slider = css`
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;
    font-size: 10px;
`;

const sliderTitle = css`
    height: 23px;
    font-weight: 600;
`;

const sliderBar = css`
    position: absolute;
    width: 100%;
    top: calc(23px + ((20px - 10px) / 2));
    height: 10px;
    background-color: #DFE0E2;
    border-radius: calc(10px / 2);
    cursor: pointer;
`;

function getSliderProgressBar(theme: Theme, width: string) {
    return css`
        position: relative;
        background-color: ${theme.primaryColor};
        height: 100%;
        border-radius: calc(10px / 2);
        width: ${width};
    `;
}

function getSliderHandle(theme: Theme, left: string) {
    return css`
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 3px solid ${theme.primaryBackgroundColor};
        background-color: ${theme.primaryColor};
        cursor: pointer;
        cursor: -webkit-grab;
        transform: translateX(-50%);
        transition: box-shadow 0.3s;
        box-shadow: 0 0 7px 0 #DFE0E2;
        left: ${left};
        &:hover .slider--tooltip {
            display: block;
        }
        .slider--tooltip {
            margin-bottom: 3px;
            padding: 7px 0;
            pointer-events: auto;
            position: relative !important;
            z-index: 1070;
            display: none;
            font-size: 12px;
            margin-left: -8px;
            /* opacity: 0; */
            .slider--tooltip__content {
                width: 28px;
                padding: 3px 8px;
                color: #fff;
                text-align: center;
                background-color: #3a414c;
                border-radius: 2px;
                padding: 4px;
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
            }

            .slider--tooltip__arrow {
                top: 0;
                border-width: 0 5px 7px;
                position: absolute;
                left: 12px;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                border-bottom-color: #3a414c;
                border-bottom-color: #3a414c;
            }
        }
    `;
}

const sliderVirtualHandle = css`
    margin-top: -5px;
    width: 20px;
    height: calc(20px + 5px);
`;

const sliderValues = css`
    display: flex;
    justify-content: space-between;
    color: #697582;
`;

export function Slider({
    value,
    label,
    min,
    max,
    disabled = false,
    increment = 1,
    selectOnDrag,
    onSelect,
    className,
    ...rest
}: SliderProps) {
    // State setup
    const [internalNumber, setInternalNumber] = useState<number>(value);
    const [displayString, setDisplayString] = useState(value.toString());
    const [roundedNumber, setRoundedNumber] = useState(value);
    const [decimals, setDecimals] = useState(increment);
    const [dragging, setDragging] = useState(false);
    const [hover, setHover] = useState(false);

    const theme = useTheme();

    // Ref to the slider bar
    const sliderBarRef = useRef(null);

    const left = `${100 * ((internalNumber - min) / (max - min))}%`;
    const shouldFocus = hover || dragging;

    useEffect(() => {
        if (value !== roundedNumber) {
            setInternalNumber(value);
            setDisplayString(value.toString());
            setRoundedNumber(value);
        }
        setDecimals(countDecimals(increment));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, increment]);

    useEffect(() => {
        if (!dragging) {
            onSelect(roundedNumber);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragging]);

    const onDragMove = (e: any) => {
        const mouseX = getMouseX(e);
        let nextInternalNumber;

        if (mouseX <= barLeft) {
            nextInternalNumber = min;
        } else if (mouseX >= barLeft + barWidth) {
            nextInternalNumber = max;
        } else {
            const range = max - min;
            const percentage = (mouseX - barLeft) / barWidth;
            nextInternalNumber = min + percentage * range;
        }

        const nextRoundedNumber = roundToIncrement(
            nextInternalNumber,
            increment
        );
        const nextDisplayString = truncate(nextRoundedNumber, decimals);

        setInternalNumber(nextInternalNumber);
        setRoundedNumber(nextRoundedNumber);
        setDisplayString(nextDisplayString);

        if (selectOnDrag && nextRoundedNumber !== value) {
            onSelect(nextRoundedNumber);
        }
    };

    const onDragEnd = () => {
        setDragging(false);
        document.removeEventListener('mousemove', onDragMove);
        document.removeEventListener('touchmove', onDragMove);
        document.removeEventListener('mouseup', onDragEnd);
        document.removeEventListener('touchend', onDragEnd);
    };

    const onDragStart = (e: any) => {
        if (!disabled) {
            const barPosition = getPosition(sliderBarRef.current);

            barLeft = barPosition.left;
            barWidth = barPosition.width;

            if (e.targetTouches) {
                touchIdentifier = e.targetTouches[0].identifier;
            }

            setDragging(true);
            document.addEventListener('mousemove', onDragMove);
            document.addEventListener('touchmove', onDragMove);
            document.addEventListener('mouseup', onDragEnd);
            document.addEventListener('touchend', onDragEnd);
            onDragMove(e);
        }
    };

    const onMouseOver = () => {
        setHover(true);
    };

    const onMouseOut = () => {
        setHover(false);
    };

    return (
        <div
            className={cx(shouldFocus? 'slider-focus': 'slider', className)}
            // disabled={disabled}
            css={slider}
            onMouseOver={onMouseOver}
            onFocus={onMouseOver}
            onMouseOut={onMouseOut}
            onBlur={onMouseOut}
            {...rest}
        >
            <div css={sliderTitle}>{label}</div>
            <div
                className='slider--progress-bar'
                ref={sliderBarRef}
                css={sliderBar}
                onTouchStart={onDragStart}
                onMouseDown={onDragStart}
            >
                <div css={getSliderProgressBar(theme, left)} />
            </div>
            <div
                className='slider--progress-head'
                css={getSliderHandle(theme, left)}
            >
                <div
                    css={sliderVirtualHandle}
                    onMouseDown={onDragStart}
                    onTouchStart={onDragStart}
                />
                <div className='slider--tooltip'>
                    <div className='slider--tooltip__arrow' />
                    <div className='slider--tooltip__content'>{displayString}</div>
                </div>
            </div>
            <div css={sliderValues}>
                <div>{min}</div>
                <div>{max}</div>
            </div>
        </div>
    );
}
