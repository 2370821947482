import { TiledTexture, CompositeMode } from '../common';

export enum RenderSide {
    front = 'front',
    back = 'back',
    double = 'double',
}
export interface Substrate {
    color: string | number;
    side?: RenderSide;

    baseCompositeMode?: CompositeMode;
    baseTexture?: TiledTexture;
    baseColorMode?: CompositeMode;

    shininess: number;
    specularTexture?: TiledTexture;

    bumpScale?: number;
    bumpTexture?: TiledTexture;

    fullyTransparent?: boolean;
    supportsTransparency?: boolean;
    requiresDocumentTransparency?: boolean;

    reflectivity?: number;
    reflectionMapOverrideUrls?: string[];

    emissive?: number;
    ambientIntensity?: number;
}

export enum SubstrateType {
    natural,
    matte,
    kraft,
    linen,
    pearl,
    fabric,
    glossy,

    canvas,
    wood,
    darkWood,

    premiumGlossy,
    premiumMatte,

    recycledMatte,

    clearPlasticFilm,
    transparent,

    extraSoftRed,
    metallicGold,
    metallicGrey,
    metallicIceSilver,
    metallicIceGold,

    clearPlastic,
    frostedPlastic,
    cotton,
}
