import { Substrate } from './types';
import { CompositeMode } from '../common';
import defaults from './defaults';

export function getClearPlasticFilmSubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 100,
        bumpScale: 0.1,
        ambientIntensity: 14,
        reflectivity: 0.2,
        reflectionMapOverrideUrls: [
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
        ],
        supportsTransparency: true,
        baseCompositeMode: CompositeMode.normal,
        baseTexture: {
            dpi: 200,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/plasticFilm/v1/base',
        },
        baseColorMode: CompositeMode.multiply
    };
}
