import { Substrate } from './types';

export function getPearlSubstrate(): Substrate {
    return {
        color: 0xFFFFFF,
        shininess: 650,
        specularTexture: {
            dpi: 300,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/pearl/v1/specular',
        },
    };
}
