import { MapMode, CompositeMode, TiledTexture } from '../common';

export interface Finish {
    name: string;
    channel: string;

    shininess: number;
    specularTexture?: TiledTexture;

    bumpScale: number;
    bumpMode: MapMode;

    baseCompositeMode: CompositeMode;
    baseTexture?: TiledTexture;

    reflectivity?: number;
    reflectionMapOverrideUrls?: string[];
}

export enum FinishType {
    metallic,
    raisedInk,
    raisedFoilGold,
    raisedFoilSilver,
    glitter
}
