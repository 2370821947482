import defaults from './defaults';
import { Substrate } from './types';

export function getPremiumMatteSubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 10,
        bumpScale: 0.7,
        bumpTexture: {
            dpi: 300,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/matte/v1/bump',
        },
    };
}
