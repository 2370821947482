// https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
// https://easings.net/
export function easeInOutQuart(time: number, startVal: number, deltaVal: number, duration: number) {
    let delta = time / (duration / 2);
    if (delta < 1) {
        // eslint-disable-next-line no-restricted-properties
        return (deltaVal / 2) * Math.pow(delta, 4) + startVal;
    }
    delta -= 2;
    // eslint-disable-next-line no-restricted-properties
    return (-deltaVal / 2) * (Math.pow(delta, 4) - 2) + startVal;
}
