export interface MemoizeFlush {
    flush(): void;
}

export function memoize<R, T extends(...args: any[]) => R>(fn: T, keyBuilder: (...args: any[]) => string): T & MemoizeFlush {
    let cache: {[key: string]: R} = {};

    const memoizedFn = (...args: any[]) => {
        const key = keyBuilder(...args);

        if (cache[key] === undefined) {
            cache[key] = fn(...args);
        }

        return cache[key];
    };
    return Object.assign(memoizedFn as T,
        {
            flush: () => { cache = {}; },
        });
}
