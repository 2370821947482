import Axios from 'axios';
import { Finish, getFinish } from '@rendering/vortex-core/finishes';
import { getSubstrate, Substrate, SubstrateType } from '@rendering/vortex-core/substrates';
import { getTrimPath, getTrimBounds, SurfaceSpecification } from '../externalModels';
import { FlatSheetProduct, FlatSheetProductConfig } from '@rendering/vortex-paper-products/flatSheet';
import { fetchInstructionData } from './instructionsLoader';
import { getSurfaceSpecification } from './surfaceSpecificationLoader';
import { FlatSheetData, ProductSurface, VortexProductConfig } from '../configs';
import { loadFinishMap, loadSubstrateMap } from './materialLoader';

const CM_TO_MM = 10;

export function getFlatSheetConfigFromMcpSku(bearerToken: string, instructionsUrl: string, mcpSku: string, selectedOptions?: { [key: string]: string | number }): Promise<FlatSheetProductConfig> {
    const surfaceSpecTask = getSurfaceSpecification(bearerToken, mcpSku, selectedOptions);

    return getFlatSheetConfigFromSurfaceSpecification(surfaceSpecTask, instructionsUrl);
}

export async function getFlatSheetConfigFromSurfaceSpecification(surfaceSpecPromise: Promise<SurfaceSpecification>, instructionsUrl: string): Promise<FlatSheetProductConfig> {
    const instructionsTask = fetchInstructionData(instructionsUrl);

    const [surfaceData, instructionData] = await Promise.all([surfaceSpecPromise, instructionsTask]);

    const frontSurface = surfaceData.surfaceGroups[0].surfaces[0];
    const config: FlatSheetProductConfig = {
        fullBleed: {
            width: frontSurface.widthCm * CM_TO_MM,
            height: frontSurface.heightCm * CM_TO_MM,
        },
        trim: getTrimBounds(frontSurface.docAdditionalData),
        finishes: instructionData.pages[0].finish ? [getFinish(instructionData.pages[0].finish)] : undefined,
        substrate: getSubstrate(SubstrateType.matte),
        depth: .2, // default to same as demo,
        customPath: getTrimPath(frontSurface.docAdditionalData)
    };
    return config;
}

export async function getFlatSheetProductFromMcpSku(bearerToken: string, instructionsUrl: string, mcpSku: string, selectedOptions?: { [key: string]: string | number }): Promise<FlatSheetProduct> {
    const config = await getFlatSheetConfigFromMcpSku(bearerToken, instructionsUrl, mcpSku, selectedOptions);
    return new FlatSheetProduct(config);
}

export async function getFlatSheetProduct(productConfig: VortexProductConfig): Promise<FlatSheetProduct> {
    const surfaceMapping: { [key: string]: ProductSurface } = productConfig.surfaceMapping;

    // Validate the surface mapping
    if (!surfaceMapping['front'] || !surfaceMapping['body'] || !surfaceMapping['back']) {
        throw new Error('The FlatSheetProductConfig must contain three surfaces for front, body, and back!');
    }

    // Load substrate and finish maps
    const substrateMap: { [key: string]: Substrate } = await loadSubstrateMap(productConfig);
    const finishMap: { [key: string]: Finish} = await loadFinishMap(productConfig);

    // Get substrateIds for each surface
    const frontSubstrateId: string = surfaceMapping['front'].substrateId;
    const bodySubstrateId: string = surfaceMapping['body'].substrateId;
    const backSubstrateId: string = surfaceMapping['back'].substrateId;

    // Get finishes if they exist
    const finishes: Finish[] = [];

    // Finishes can only exist on the front surface
    if (productConfig.finishUrlMap && surfaceMapping['front'].finishIds) {
        for (let i = 0; i < surfaceMapping['front'].finishIds.length; i++) {
            const finishId: string = surfaceMapping['front'].finishIds[i];

            finishes.push(finishMap[finishId]);
        }
    }

    const dataResponse = await Axios.get<FlatSheetData>(productConfig.productDataUrl);

    if (dataResponse.status >= 400) {
        throw new Error(`Failed to fetch FlatSheetData from '${productConfig.productDataUrl}'!`)
    }

    const flatSheetData: FlatSheetData = dataResponse.data;

    const flatSheetConfig: FlatSheetProductConfig = {
        substrates: [substrateMap[frontSubstrateId], substrateMap[bodySubstrateId], substrateMap[backSubstrateId]],
        finishes,
        fullBleed: flatSheetData.fullBleed,
        trim: flatSheetData.trim,
        depth: flatSheetData.thickness,
        orientation: flatSheetData.orientation,
        customPath: flatSheetData.customPath,
    };

    return new FlatSheetProduct(flatSheetConfig);
}