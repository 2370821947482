export enum AnimationType {
    forwardImmediateBack,
    forwardWaitForBack
}

export interface ModeledAnimationState {
    forwardAction: Promise<void>,
    backwardsAction: Promise<void>,
    resume: () => void
}
