/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useState, useEffect } from 'react';
import { Input } from 'component-library/input';
import { useTranslation } from 'translation/i18n';
import { getAppUser } from 'commands';
import { handleEnterKeyPress } from 'utility.keyEvent';
import { Spinner } from 'component-library/spinner';

import { Shape } from './shapes';
import ShapeType from './ShapeType';
import { Theme, useTheme } from './component-library/theme';
import { ReactComponent as EmptySearchIcon } from './icons/emptySearchIcon.svg';
import { getAssetList } from './services/assert.service';
import { devIconFetchAppId, iconFetchAppId, IsDevDeployment } from './constants';

const getShapeListStyle = css`
    width: 100%;
    display: flex;
    overflow-x: auto;
    flex-wrap: wrap;
    margin-bottom: 10px;
`;

const getShapeTypeTagStyle = css`
    margin: 0 0 4px 4px;
    color: #697582;
    font-size: 14px;
`;

const getSearchInputStyle = css`
    margin: 0 4px 15px 4px;
`;

const getCancelButtonStyle = (theme: Theme, searchValue: string) => {
    return css`
        border: none;
        background-color: transparent;
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
        }
        color: ${theme.primaryColor};
        display: ${searchValue !== '' ? 'block' : 'none'};
    `;
};
const getSpinnerDivStyle = (isFetching: boolean) => {
    return css`
        height: ${isFetching ? '' : 'inherit'};
        padding-top: ${isFetching ? '10%' : ''};
    `;
};

interface ShapeTypeListProps {
    shapeList: { collectionName: string; items: Shape[] }[];
    isDraggable: boolean;
    onSelect(shape: Shape): void;
    onDrag?(shape: Shape): void;
    isFetchingShapes: boolean;
}

export default function ShapeTypeList({ shapeList, isFetchingShapes, onSelect, onDrag, isDraggable }: ShapeTypeListProps): JSX.Element {
    const { t } = useTranslation();
    const theme = useTheme();
    const appUserData = getAppUser();
    const [isFetchingSearch, setIsFetchingSearch] = useState<boolean>(false);
    const [currentShapes, setCurrentShapes] = useState(shapeList);
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        setCurrentShapes(shapeList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shapeList.length]);

    const onChangeSearchValue = (value: string) => {
        setSearchValue(value);
        if (value === '') {
            setCurrentShapes(shapeList);
        }
    };

    const onSearch = async () => {
        const assetObj = {
            metadata: {
                [searchValue.toLowerCase()]: 'tags',
            },
            app: IsDevDeployment ? devIconFetchAppId : iconFetchAppId ,
        };
        setIsFetchingSearch(true);
        const collections = await getAssetList(appUserData.authConfig, assetObj, { limit: 400 });
        if (collections.length) {
            setCurrentShapes(collections);
        } else {
            setCurrentShapes([]);
        }
        setIsFetchingSearch(false);
    };

    const onClickCancel = () => {
        setSearchValue('');
        setCurrentShapes(shapeList);
    };
    const isFetching = isFetchingShapes || isFetchingSearch;
    return (
        <Fragment>
            <div className='shape-type-search' css={getSearchInputStyle}>
                <Input
                    suffixLabel={
                        <button css={getCancelButtonStyle(theme, searchValue)} disabled={isFetchingSearch} onClick={onClickCancel}>
                            X
                        </button>
                    }
                    value={searchValue}
                    onChange={onChangeSearchValue}
                    onKeyPress={handleEnterKeyPress(onSearch)}
                    placeholder={t('searchPlaceholder')}
                />
            </div>
            {!isFetching && currentShapes.length === 0 ? (
                <EmptySearchIcon />
            ) : (
                <div className='shape-type-list'>
                    {!isFetchingSearch &&
                        currentShapes &&
                        currentShapes.map((currentItem) => {
                            return (
                                <div key={`${currentItem.collectionName}`}>
                                    <div css={getShapeTypeTagStyle}>{currentItem.collectionName}</div>
                                    <div css={getShapeListStyle}>
                                        {currentItem.items.map((shape) => (
                                            <ShapeType
                                                key={`${shape.id}`}
                                                shape={shape}
                                                isDraggable={isDraggable}
                                                onSelect={onSelect}
                                                onDrag={onDrag}
                                            />
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    {isFetching && (
                        <div css={getSpinnerDivStyle(isFetchingShapes)}>
                            <Spinner size='58px' thickness={6} />{' '}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
}
