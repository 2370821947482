import defaults from './defaults';
import { Substrate } from './types';

export function getRecycledMatteSubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 15,
        bumpScale: 1.2,
        bumpTexture: {
            dpi: 300,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/matte/v1/bump',
        },
    };
}
