import Axios from 'axios';
import { Substrate } from '@rendering/vortex-core/substrates';
import { NFoldConfig, NFoldProduct2, Section, SectionSet } from '@rendering/vortex-paper-products/nFold';
import { NFoldData, ProductSurface, VortexProductConfig } from '../configs';
import { loadFinishMap, loadSubstrateMap } from './materialLoader';
import { Finish } from '@rendering/vortex-core/finishes';

export async function getNfoldProduct(productConfig: VortexProductConfig): Promise<NFoldProduct2> {
    const dataResponse = await Axios.get<NFoldData>(productConfig.productDataUrl);

    if (dataResponse.status >= 400) {
        throw new Error(`Failed to fetch NFoldData from '${productConfig.productDataUrl}'!`)
    }

    const nFoldData: NFoldData = dataResponse.data;

    const sectionSets: SectionSet[] = [];

    // Load all the substrates and finishes
    const substrateMap: { [key: string]: Substrate } = await loadSubstrateMap(productConfig);
    const finishMap: { [key: string]: Finish} = await loadFinishMap(productConfig);

    // Create each section set in the nFoldData
    for (let sectionSet of nFoldData.sectionSets) {
        const sections: Section[] = [];

        // Create each section in the nFold data
        for (let sectionData of sectionSet.sections) {
            // Build the product surface ids for the front, back, and body
            const frontId: string = sectionData.id + ':Front';
            const bodyId: string = sectionData.id + ':Body';
            const backId: string = sectionData.id + ':Back';

            // Ensure all those surfaces exist
            if (!productConfig.surfaceMapping[frontId] || !productConfig.surfaceMapping[bodyId] || !productConfig.surfaceMapping[backId]) {
                throw new Error('The surface mapping must contain three surfaces (front, body, and back) for section!');
            }

            // Get the all the surfaces
            const frontSurface: ProductSurface = productConfig.surfaceMapping[frontId];
            const bodySurface: ProductSurface = productConfig.surfaceMapping[bodyId];
            const backSurface: ProductSurface = productConfig.surfaceMapping[backId];

            // Build the section
            const section: Section = {
                id: sectionData.id,
                path: sectionData.path,
                frontSide: { page: frontSurface.page, substrate: substrateMap[frontSurface.substrateId], focalPoint: frontSurface.focalPoint },
                backSide: { page: backSurface.page, substrate: substrateMap[backSurface.substrateId], focalPoint: backSurface.focalPoint },
                bodySubstrate: substrateMap[bodySurface.substrateId]
            };

            // Add finishes to the front surface if they exist
            if (frontSurface.finishIds) {
                section.frontSide.finishes = [];

                for (let finishId of frontSurface.finishIds) {
                    section.frontSide.finishes.push(finishMap[finishId]);
                }
            }

            // Add finishes to the back surface if they exist
            if (backSurface.finishIds) {
                section.backSide.finishes = [];

                for (let finishId of backSurface.finishIds) {
                    section.backSide.finishes.push(finishMap[finishId]);
                }
            }

            // Add any filled in sections
            if (sectionData.path.fills) {
                section.fillSubstrates = [];

                for (let i = 0; i < sectionData.path.fills.length; i++) {
                    const bodyFillId: string = sectionData.id + ':Body Fill:' + i;

                    if (!productConfig.surfaceMapping[bodyFillId]) {
                        throw new Error(`The surface mapping must include '${bodyFillId}' because the path has fill sections!`);
                    }

                    const fillSurface: ProductSurface = productConfig.surfaceMapping[bodyFillId];

                    section.fillSubstrates.push(substrateMap[fillSurface.substrateId]);
                }
            }

            sections.push(section);
        }

        // Add a new section set
        sectionSets.push({
            id: sectionSet.id,
            name: sectionSet.name,
            fullBleed: sectionSet.fullBleed,
            trim: sectionSet.trim,
            thickness: sectionSet.thickness,
            baseSectionId: sectionSet.baseSectionId,
            sections,
            folds: sectionSet.folds,
            foldSequences: sectionSet.foldSequences,
            rotationOffset: sectionSet.rotationOffset,
            positionOffset: sectionSet.positionOffset
        });
    }

    // Generate the final nfold config
    const nFoldConfig: NFoldConfig = {
        sectionSets
    };

    return new NFoldProduct2(nFoldConfig);
}