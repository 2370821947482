import { RenderSize, Size2D, Size3D, UVMapPlane, UVMapBox, disposeGeometryRecursively, buildPointLightGrid } from '@rendering/vortex-core/common';
import { ProductState, ProductSurface, ProductTrim, VortexProduct } from '@rendering/vortex-core/products';
import { Animation, AnimationOptions } from '@rendering/vortex-core/animations';
import { Substrate } from '@rendering/vortex-core/substrates';
import { Group, Mesh, Object3D, Vector3, PlaneGeometry, BoxGeometry, Light } from 'three';

export interface CanvasPrintFrameOptions {
    substrate: Substrate,
    inner: {
        depth: number,
        thickness: number
    },
    outer: {
        depth: number,
        thickness: number
    }
}

export interface CanvasPrintProductConfig {
    fullBleed: Size2D,
    trim?: ProductTrim,
    depth: number,
    fabricSubstrate: Substrate,
    interiorOptions: {
        frameOptions: {
            substrate: Substrate,
            thickness: number
        }
    },
    exteriorOptions?: {
        cover?: {
            substrate: Substrate,
            depth: number
        },
        frameOptions?: CanvasPrintFrameOptions
    }
}

export class CanvasPrintProduct implements VortexProduct {
    name: string = 'canvas print';

    requiresGeometryUpdate: boolean = false;

    private config: CanvasPrintProductConfig;
    private initializedGeometry?: Group;

    private surfaceMapping: { [key: string]: ProductSurface; };

    constructor(config: CanvasPrintProductConfig) {
        this.config = config;

        const fabricSubstrate: Substrate = this.config.fabricSubstrate;
        const interiorFrameSubstrate: Substrate = this.config.interiorOptions.frameOptions.substrate;

        // Designable portions of the canvas print
        this.surfaceMapping = {
            'front': {
                page: 1,
                substrate: fabricSubstrate,
                fullBleed: this.config.fullBleed,
                focalPoint: { cameraView: new Vector3(0, 0, -1) }
            },
            'left': {
                page: 1,
                substrate: fabricSubstrate,
                fullBleed: this.config.fullBleed,
                focalPoint: { cameraView: new Vector3(1, 0, -0.2).normalize() }
            },
            'right': {
                page: 1,
                substrate: fabricSubstrate,
                fullBleed: this.config.fullBleed,
                focalPoint: { cameraView: new Vector3(-1, 0, -0.2).normalize() }
            },
            'top': {
                page: 1,
                substrate: fabricSubstrate,
                fullBleed: this.config.fullBleed,
                focalPoint: { cameraView: new Vector3(0, -1, -0.2).normalize() }
            },
            'bottom': {
                page: 1,
                substrate: fabricSubstrate,
                fullBleed: this.config.fullBleed,
                focalPoint: { cameraView: new Vector3(0, 1, -0.2).normalize() }
            }
        };

        // Add 5 fabric components for the back of the canvas
        this.surfaceMapping['back-center'] = { substrate: fabricSubstrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['back-left'] = { substrate: fabricSubstrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['back-right'] = { substrate: fabricSubstrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['back-top'] = { substrate: fabricSubstrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['back-bottom'] = { substrate: fabricSubstrate, fullBleed: this.config.fullBleed };

        // Add four components interior frame segments
        this.surfaceMapping['interior-frame-left'] = { substrate: interiorFrameSubstrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['interior-frame-right'] = { substrate: interiorFrameSubstrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['interior-frame-top'] = { substrate: interiorFrameSubstrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['interior-frame-bottom'] = { substrate: interiorFrameSubstrate, fullBleed: this.config.fullBleed };

        // Add optional cover
        if (this.config.exteriorOptions?.cover) {
            const coverOptions = this.config.exteriorOptions.cover;

            this.addCover(coverOptions.substrate, coverOptions.depth);
        }

        // Add optional exterior frame
        if (this.config.exteriorOptions?.frameOptions) {
            this.addFrame(this.config.exteriorOptions.frameOptions);
        }
    }

    async getGeometry(): Promise<Group> {
        // If existing geometry already exists dispose of it
        if (this.initializedGeometry) {
            disposeGeometryRecursively(this.initializedGeometry);
        }

        const dimensions: Size3D = {
            width: this.config.fullBleed.width,
            height: this.config.fullBleed.height,
            depth: this.config.depth
        };

        const outerUVCoords: number[] = this.computeOuterUVCoords();
        const innerUVCoords: number[] = this.computeInnerUVCoords(outerUVCoords);

        this.initializedGeometry = new Group();

        // Add all the core canvas print geometry
        this.initializedGeometry.add(...this.buildFrontDocumentMeshes(dimensions, innerUVCoords, outerUVCoords));
        this.initializedGeometry.add(...this.buildBackDocumentMeshes(dimensions, innerUVCoords, outerUVCoords));
        this.initializedGeometry.add(...this.buildInteriorFrame(dimensions));

        // Add optional cover
        if (this.config.exteriorOptions?.cover) {
            this.initializedGeometry.add(...this.buildExteriorCover(dimensions, this.config.exteriorOptions.cover.depth));
        }

        // Add optional frame
        if (this.config.exteriorOptions?.frameOptions) {
            this.initializedGeometry.add(...this.buildExteriorFrameInner(dimensions, this.config.exteriorOptions.frameOptions));
            this.initializedGeometry.add(...this.buildExteriorFrameOuter(dimensions, this.config.exteriorOptions.frameOptions));
        }

        this.requiresGeometryUpdate = false;

        return this.initializedGeometry;
    }

    getSurfaceMapping(): { [key: string]: ProductSurface; } {
        return this.surfaceMapping;
    }

    setProductSurface(surfaceId: string, productSurface: ProductSurface) {
        if (this.surfaceMapping[surfaceId]) {
            this.surfaceMapping[surfaceId] = productSurface;
        } else {
            throw new Error(`'${surfaceId}' is not a surface on this canvas product`);
        }
    };

    getStates(): ProductState[] {
        return [{ id: 'default' }];
    }

    setStates(productState: ProductState[]): void { }

    getCurrentState(): ProductState {
        return { id: 'default' };
    }

    setCurrentState(stateId: string) { }

    getAnimationIds(): string[] {
        return [];
    }

    buildAnimation(animationOptions: AnimationOptions): Animation {
        throw 'Method not implemented for Canvas products.'
    }

    getAreaLights(pointLightIntensity: number, directionalLightIntensity: number): Light[] {
        const pointLightIntensities: number[] = [0.4 * pointLightIntensity, 0.4 * pointLightIntensity,
        0.4 * pointLightIntensity, 0.4 * pointLightIntensity,
        0.35 * pointLightIntensity, 0.35 * pointLightIntensity];

        return buildPointLightGrid(pointLightIntensities);
    }

    addCover(substrate: Substrate, depth: number): void {
        this.requiresGeometryUpdate = true;

        if (this.config.exteriorOptions) {
            this.config.exteriorOptions.cover = { substrate, depth }
        } else {
            this.config.exteriorOptions = { cover: { substrate, depth } }
        }

        this.surfaceMapping['cover'] = {
            substrate: substrate,
            fullBleed: this.config.fullBleed
        };
    }

    removeCover(): void {
        if (this.config.exteriorOptions?.cover) {
            this.config.exteriorOptions.cover = undefined;

            this.requiresGeometryUpdate = true;
        }

        delete this.surfaceMapping['cover'];
    }

    addFrame(frameOptions: CanvasPrintFrameOptions): void {
        this.requiresGeometryUpdate = true;

        if (this.config.exteriorOptions) {
            this.config.exteriorOptions.frameOptions = frameOptions;
        } else {
            this.config.exteriorOptions = { frameOptions }
        }

        // Add four inner segments for the external frame
        this.surfaceMapping['exterior-frame-inner-left'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['exterior-frame-inner-right'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['exterior-frame-inner-top'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['exterior-frame-inner-bottom'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };

        // Add four outer segments for the external frame
        this.surfaceMapping['exterior-frame-outer-left'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['exterior-frame-outer-right'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['exterior-frame-outer-top'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };
        this.surfaceMapping['exterior-frame-outer-bottom'] = { substrate: frameOptions.substrate, fullBleed: this.config.fullBleed };
    }

    removeFrame(): void {
        if (this.config.exteriorOptions?.frameOptions) {
            this.config.exteriorOptions.frameOptions = undefined;

            this.requiresGeometryUpdate = true;
        }

        // Remove four inner segments for the external frame
        delete this.surfaceMapping['exterior-frame-inner-left'];
        delete this.surfaceMapping['exterior-frame-inner-right'];
        delete this.surfaceMapping['exterior-frame-inner-top'];
        delete this.surfaceMapping['exterior-frame-inner-bottom'];

        // Remove four outer segments for the external frame
        delete this.surfaceMapping['exterior-frame-outer-left'];
        delete this.surfaceMapping['exterior-frame-outer-right'];
        delete this.surfaceMapping['exterior-frame-outer-top'];
        delete this.surfaceMapping['exterior-frame-outer-bottom'];
    }

    getAlphaMap(renderSize: RenderSize, physicalSize: Size2D, context: CanvasRenderingContext2D): void { }

    preLoad(): Promise<void> {
        return Promise.resolve();
    }

    dispose(): void {
        // Nothing to do
        if (!this.initializedGeometry) return;

        disposeGeometryRecursively(this.initializedGeometry);

        this.initializedGeometry = undefined;
    }

    // Builds and UV maps the meshes seen on the front and sides of the canvas print
    private buildFrontDocumentMeshes(dimensions: Size3D, innerUVCoords: number[], outerUVCoords: number[]): Object3D[] {
        let meshes: Mesh[] = [];

        const frontGeometry = new PlaneGeometry(dimensions.width, dimensions.height);
        UVMapPlane(frontGeometry, innerUVCoords[0], innerUVCoords[2], innerUVCoords[1], innerUVCoords[3]);

        const frontMesh = new Mesh(frontGeometry);
        frontMesh.uuid = 'front';
        frontMesh.position.setZ(dimensions.depth / 2);
        meshes.push(frontMesh);

        const leftGeometry = new PlaneGeometry(dimensions.depth, dimensions.height);
        UVMapPlane(leftGeometry, outerUVCoords[0], innerUVCoords[0], innerUVCoords[1], innerUVCoords[3]);

        const leftMesh = new Mesh(leftGeometry);
        leftMesh.uuid = 'left';
        leftMesh.rotateY(-Math.PI / 2);
        leftMesh.position.setX(-dimensions.width / 2);
        meshes.push(leftMesh);

        const rightGeometry = new PlaneGeometry(dimensions.depth, dimensions.height);
        UVMapPlane(rightGeometry, innerUVCoords[2], outerUVCoords[2], innerUVCoords[1], innerUVCoords[3]);

        const rightMesh = new Mesh(rightGeometry);
        rightMesh.uuid = 'right';
        rightMesh.rotateY(Math.PI / 2);
        rightMesh.position.setX(dimensions.width / 2);
        meshes.push(rightMesh);

        const topGeometry = new PlaneGeometry(dimensions.width, dimensions.depth);
        UVMapPlane(topGeometry, innerUVCoords[0], innerUVCoords[2], innerUVCoords[3], outerUVCoords[3]);

        const topMesh = new Mesh(topGeometry);
        topMesh.uuid = 'top';
        topMesh.rotateX(-Math.PI / 2);
        topMesh.position.setY(dimensions.height / 2);
        meshes.push(topMesh);

        const bottomGeometry = new PlaneGeometry(dimensions.width, dimensions.depth);
        UVMapPlane(bottomGeometry, innerUVCoords[0], innerUVCoords[2], outerUVCoords[1], innerUVCoords[1]);

        const bottomMesh = new Mesh(bottomGeometry);
        bottomMesh.uuid = 'bottom';
        bottomMesh.rotateX(Math.PI / 2);
        bottomMesh.position.setY(-dimensions.height / 2);
        meshes.push(bottomMesh);

        return meshes;
    }

    // Builds and UV maps the blank meshes seen on the back/interior of the canvas print
    private buildBackDocumentMeshes(dimensions: Size3D, innerUVCoords: number[], outerUVCoords: number[]): Object3D[] {
        const meshes: Mesh[] = [];

        const backGeometry = new PlaneGeometry(dimensions.width, dimensions.height);
        UVMapPlane(backGeometry, innerUVCoords[0], innerUVCoords[2], innerUVCoords[1], innerUVCoords[3]);

        const backMesh = new Mesh(backGeometry);
        backMesh.uuid = 'back-center';
        backMesh.rotateY(Math.PI);
        backMesh.position.setZ(dimensions.depth / 2 - 0.5);
        meshes.push(backMesh);

        const leftGeometry = new PlaneGeometry(dimensions.depth / 3, dimensions.height);
        UVMapPlane(leftGeometry, 0, outerUVCoords[0], innerUVCoords[1], innerUVCoords[3]);

        const leftMesh = new Mesh(leftGeometry);
        leftMesh.uuid = 'back-left';
        leftMesh.rotateY(Math.PI);
        leftMesh.position.setX(-dimensions.width / 2 + dimensions.depth / 6);
        leftMesh.position.setZ(-dimensions.depth / 2 - 0.01);
        meshes.push(leftMesh);

        const rightGeometry = new PlaneGeometry(dimensions.depth / 3, dimensions.height);
        UVMapPlane(rightGeometry, outerUVCoords[2], 1, innerUVCoords[1], innerUVCoords[3]);

        const rightMesh = new Mesh(rightGeometry);
        rightMesh.uuid = 'back-right';
        rightMesh.rotateY(Math.PI);
        rightMesh.position.setX(dimensions.width / 2 - dimensions.depth / 6);
        rightMesh.position.setZ(-dimensions.depth / 2 - 0.01);
        meshes.push(rightMesh);

        const topGeometry = new PlaneGeometry(dimensions.width, dimensions.depth / 3);
        UVMapPlane(topGeometry, innerUVCoords[2], innerUVCoords[0], 1, outerUVCoords[3]);

        const topMesh = new Mesh(topGeometry);
        topMesh.uuid = 'back-top';
        topMesh.rotateY(Math.PI);
        topMesh.position.setY(dimensions.height / 2 - dimensions.depth / 6);
        topMesh.position.setZ(-dimensions.depth / 2);
        meshes.push(topMesh);

        const bottomGeometry = new PlaneGeometry(dimensions.width, dimensions.depth / 3);
        UVMapPlane(bottomGeometry, innerUVCoords[2], innerUVCoords[0], outerUVCoords[1], 0);

        const bottomMesh = new Mesh(bottomGeometry);
        bottomMesh.uuid = 'back-bottom';
        bottomMesh.rotateY(Math.PI);
        bottomMesh.position.setY(-dimensions.height / 2 + dimensions.depth / 6);
        bottomMesh.position.setZ(-dimensions.depth / 2);
        meshes.push(bottomMesh);

        return meshes;
    }

    // Builds and UV maps the interior wooden frame meshes of the canvas print
    private buildInteriorFrame(dimensions: Size3D): Object3D[] {
        const meshes: Mesh[] = [];

        const frameThickness = this.config.interiorOptions.frameOptions.thickness;

        const verticalGeometry = new BoxGeometry(frameThickness, dimensions.height * 0.99, dimensions.depth * 0.99);
        UVMapBox(verticalGeometry);

        const leftVerticalMesh = new Mesh(verticalGeometry);
        leftVerticalMesh.uuid = 'interior-frame-left';
        leftVerticalMesh.position.setX(-dimensions.width * 0.5 + frameThickness * 0.51);

        const rightVerticalMesh = new Mesh(verticalGeometry);
        rightVerticalMesh.uuid = 'interior-frame-right';
        rightVerticalMesh.position.setX(dimensions.width * 0.5 - frameThickness * 0.51);

        meshes.push(leftVerticalMesh);
        meshes.push(rightVerticalMesh);

        const horizontalGeometry = new BoxGeometry(dimensions.width - frameThickness * 2, frameThickness, dimensions.depth * 0.99);
        UVMapBox(horizontalGeometry);

        const topHorizontalMesh = new Mesh(horizontalGeometry);
        topHorizontalMesh.uuid = 'interior-frame-top';
        topHorizontalMesh.position.setY(dimensions.height * 0.5 - frameThickness * 0.51);

        const bottomHorizontalMesh = new Mesh(horizontalGeometry);
        bottomHorizontalMesh.uuid = 'interior-frame-bottom';
        bottomHorizontalMesh.position.setY(-dimensions.height * 0.5 + frameThickness * 0.51);

        meshes.push(topHorizontalMesh);
        meshes.push(bottomHorizontalMesh);

        return meshes;
    }

    // Builds and UV maps the optional external covering mesh on the back of the canvas print
    private buildExteriorCover(canvasDimensions: Size3D, coverDepth: number): Object3D[] {
        const meshes: Mesh[] = [];

        const coverGeometry = new BoxGeometry(canvasDimensions.width * 0.99, canvasDimensions.height * 0.99, coverDepth);
        UVMapBox(coverGeometry);

        const coverMesh = new Mesh(coverGeometry);
        coverMesh.uuid = 'cover';
        coverMesh.position.setZ(-canvasDimensions.depth * 0.5 - coverDepth * 0.5);
        meshes.push(coverMesh);

        return meshes;
    }

    // Builds and UV maps the optional external frame base meshes on the back of the canvas print
    private buildExteriorFrameInner(dimensions: Size3D, frameOptions: CanvasPrintFrameOptions): Object3D[] {
        const meshes: Mesh[] = [];

        const verticalGeometry = new BoxGeometry(frameOptions.inner.thickness,
            dimensions.height + frameOptions.inner.thickness, frameOptions.inner.depth);
        UVMapBox(verticalGeometry);

        const leftVerticalMesh = new Mesh(verticalGeometry);
        leftVerticalMesh.uuid = 'exterior-frame-inner-left';
        leftVerticalMesh.position.setX(-dimensions.width * 0.5);
        leftVerticalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth * 0.5);

        const rightVerticalMesh = new Mesh(verticalGeometry);
        rightVerticalMesh.uuid = 'exterior-frame-inner-right';
        rightVerticalMesh.position.setX(dimensions.width * 0.5);
        rightVerticalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth * 0.5);

        meshes.push(leftVerticalMesh);
        meshes.push(rightVerticalMesh);

        const horizontalGeometry = new BoxGeometry(dimensions.width, frameOptions.inner.thickness, frameOptions.inner.depth);
        UVMapBox(horizontalGeometry);

        const topHorizontalMesh = new Mesh(horizontalGeometry);
        topHorizontalMesh.uuid = 'exterior-frame-inner-top';
        topHorizontalMesh.position.setY(dimensions.height * 0.5);
        topHorizontalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth * 0.5);

        const bottomHorizontalMesh = new Mesh(horizontalGeometry);
        bottomHorizontalMesh.uuid = 'exterior-frame-inner-bottom';
        bottomHorizontalMesh.position.setY(-dimensions.height * 0.5);
        bottomHorizontalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth * 0.5);

        meshes.push(topHorizontalMesh);
        meshes.push(bottomHorizontalMesh);

        return meshes;
    }

    // Builds and UV maps the optional external frame body meshes on the side of the canvas print
    private buildExteriorFrameOuter(dimensions: Size3D, frameOptions: CanvasPrintFrameOptions): Object3D[] {
        const meshes: Mesh[] = [];

        const verticalGeometry = new BoxGeometry(frameOptions.outer.thickness,
            dimensions.height + frameOptions.inner.thickness, frameOptions.outer.depth);
        UVMapBox(verticalGeometry);

        const leftVerticalMesh = new Mesh(verticalGeometry);
        leftVerticalMesh.uuid = 'exterior-frame-outer-left';
        leftVerticalMesh.position.setX(-dimensions.width * 0.5 - frameOptions.inner.thickness * 0.5);
        leftVerticalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth + frameOptions.outer.depth * 0.5);

        const rigtVerticalMesh = new Mesh(verticalGeometry);
        rigtVerticalMesh.uuid = 'exterior-frame-outer-right';
        rigtVerticalMesh.position.setX(dimensions.width * 0.5 + frameOptions.inner.thickness * 0.5);
        rigtVerticalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth + frameOptions.outer.depth * 0.5);

        meshes.push(leftVerticalMesh);
        meshes.push(rigtVerticalMesh);

        const horizontalGeometry = new BoxGeometry(dimensions.width + frameOptions.inner.thickness + frameOptions.outer.thickness,
            frameOptions.outer.thickness, frameOptions.outer.depth);
        UVMapBox(horizontalGeometry);

        const topHorizontalMesh = new Mesh(horizontalGeometry);
        topHorizontalMesh.uuid = 'exterior-frame-outer-top';
        topHorizontalMesh.position.setY(dimensions.height * 0.5 + frameOptions.inner.thickness * 0.5);
        topHorizontalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth + frameOptions.outer.depth * 0.5);

        const bottomHorizontalMesh = new Mesh(horizontalGeometry);
        bottomHorizontalMesh.uuid = 'exterior-frame-outer-bottom';
        bottomHorizontalMesh.position.setY(-dimensions.height * 0.5 - frameOptions.inner.thickness * 0.5);
        bottomHorizontalMesh.position.setZ(-dimensions.depth * 0.5 - frameOptions.inner.depth + frameOptions.outer.depth * 0.5);

        meshes.push(topHorizontalMesh);
        meshes.push(bottomHorizontalMesh);

        return meshes;
    }

    private computeOuterUVCoords(): number[] {
        if (this.config.trim) {
            const trim = this.config.trim;

            return [
                trim.topLeftX / this.config.fullBleed.width,
                trim.topLeftY / this.config.fullBleed.height,
                trim.bottomRightX / this.config.fullBleed.width,
                trim.bottomRightY / this.config.fullBleed.height
            ];
        }

        // No trim return default UV coords
        return [0.0, 0.0, 1.0, 1.0];
    }

    private computeInnerUVCoords(outerCoords: number[]) {
        const flattenedPrintDimensions: Size2D = {
            width: this.config.fullBleed.width + this.config.depth * 2,
            height: this.config.fullBleed.height + this.config.depth * 2
        };

        const sideRatio = {
            x: this.config.depth / flattenedPrintDimensions.width,
            y: this.config.depth / flattenedPrintDimensions.height
        };

        return [
            outerCoords[0] + sideRatio.x,
            outerCoords[1] + sideRatio.y,
            outerCoords[2] - sideRatio.x,
            outerCoords[3] - sideRatio.y
        ];
    }
}