import { NFoldOptions, PanelEdge } from './types';

export enum FoldType {
    TwoPageRoll,
    ThreePanelZ,
    FourPanelZ,
    ThreePanelRoll,
    FourPanelRoll,
    Parallel,
    Gate,
    DoubleGate,
    TwoPageRollH,
}

const TwoPageRoll: NFoldOptions = {
    horizontalRatios: [ 0.498, 0.502],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.right,
            },
            {
                ratioIndex: 1,
                angleOpen: Math.PI * 0.15,
                angleClosed: Math.PI * 0.85,
                edge: PanelEdge.left
            }
        ]
    ],
};

const TwoPageRollH: NFoldOptions = {
    horizontalRatios: [1],
    verticalRatios: [0.498, 0.502],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: Math.PI * 0.15,
                angleClosed: Math.PI * 0.85,
                edge: PanelEdge.bottom,
            },
            {
                ratioIndex: 1,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.top
            }
        ]
    ],
    rotationOffset: {
        yaw: Math.PI * -0.02,
        pitch: 0,
        roll: Math.PI
    },
};

const ThreePanelZ: NFoldOptions = {
    horizontalRatios: [0.333, 0.334, 0.333],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: Math.PI * 0.1,
                angleClosed: Math.PI * 0.95,
                edge: PanelEdge.right,
            },
            {
                ratioIndex: 1,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 2,
                angleOpen: Math.PI * 0.1,
                angleClosed: Math.PI * 0.95,
                edge: PanelEdge.left
            }
        ]
    ],
};

const FourPanelZ: NFoldOptions = {
    horizontalRatios: [0.25, 0.25, 0.25, 0.25],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: Math.PI * -0.1,
                angleClosed: Math.PI * -0.95,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 1,
                angleOpen: Math.PI * 0.1,
                angleClosed: Math.PI * 0.95,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 2,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 3,
                angleOpen: Math.PI * 0.1,
                angleClosed: Math.PI * 0.95,
                edge: PanelEdge.left
            },
        ]
    ],
};

const ThreePanelRoll: NFoldOptions = {
    horizontalRatios: [0.327, 0.338, 0.334],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: -Math.PI * 0.1,
                angleClosed: -Math.PI,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 1,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.right
            },
        ],
        [
            {
                ratioIndex: 2,
                angleOpen: Math.PI * 0.1,
                angleClosed: Math.PI * 0.95,
                edge: PanelEdge.left
            }
        ]
    ],
};

const FourPanelRoll: NFoldOptions = {
    horizontalRatios: [0.245, 0.25, 0.2525, 0.2525],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: Math.PI * -0.05,
                angleClosed: -Math.PI,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 2,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.right
            },
        ],
        [
            {
                ratioIndex: 1,
                angleOpen: Math.PI * -0.05,
                angleClosed: -Math.PI,
                edge: PanelEdge.right
            }
        ],
        [
            {
                ratioIndex: 3,
                angleOpen: Math.PI * 0.05,
                angleClosed: Math.PI * 0.95,
                edge: PanelEdge.left
            }
        ]
    ],
};

const ParallelFold: NFoldOptions = {
    horizontalRatios: [0.25, 0.25, 0.25, 0.25],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 1,
                angleOpen: Math.PI * -0.05,
                angleClosed: -Math.PI,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 2,
                angleOpen: Math.PI * 0,
                angleClosed: Math.PI * 0,
                edge: PanelEdge.left
            },
        ],
        [
            {
                ratioIndex: 0,
                angleOpen: Math.PI * 0.05,
                angleClosed: Math.PI,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 3,
                angleOpen: Math.PI * 0.05,
                angleClosed: Math.PI * 0.9,
                edge: PanelEdge.left
            },
        ]
    ],
};

const GateFold: NFoldOptions = {
    horizontalRatios: [0.25, 0.50, 0.25],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: -Math.PI * 0.1,
                angleClosed: -Math.PI * 1,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 2,
                angleOpen: Math.PI * 0.1,
                angleClosed: Math.PI * 1,
                edge: PanelEdge.left
            },
            {
                ratioIndex: 1,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.right
            },
        ]
    ],
};

const DoubleGate: NFoldOptions = {
    horizontalRatios: [0.2499, 0.2501, 0.2501, 0.2499],
    verticalRatios: [1],
    foldDirections: [
        [
            {
                ratioIndex: 0,
                angleOpen: Math.PI * -0.05,
                angleClosed: Math.PI * -1,
                edge: PanelEdge.right
            },
            {
                ratioIndex: 3,
                angleOpen: Math.PI * 0.05,
                angleClosed: Math.PI,
                edge: PanelEdge.left
            },
            {
                ratioIndex: 1,
                angleOpen: 0,
                angleClosed: 0,
                edge: PanelEdge.right
            },
        ],
        [
            {
                ratioIndex: 2,
                angleOpen: Math.PI * 0.05,
                angleClosed: Math.PI * 0.85,
                edge: PanelEdge.left
            }
        ]
    ],
};

export { FourPanelZ, FourPanelRoll, DoubleGate, ParallelFold, ThreePanelZ, ThreePanelRoll, GateFold, TwoPageRoll, TwoPageRollH };
