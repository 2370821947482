import { Finish } from './types';
import { CompositeMode, MapMode } from '../common';

// TODO update spot color
export function getGlitterFinish(): Finish {
    return {
        name: 'glitter',
        channel: 'RaisedFoilGlitterSilver',
        bumpScale: 0.1,
        bumpMode: MapMode.generate,
        shininess: 3000,
        specularTexture: {
            dpi: 160,
            sourceUrl: 'https://uploads.documents.cimpress.io/v1/uploads/2a97fc86-89ac-4308-9caf-b12f7569b462~110?variant=original&tenant=doc-platform',
        },
        baseCompositeMode: CompositeMode.normal,
        reflectivity: 0.4,
        baseTexture: {
            dpi: 160,
            sourceUrl: 'https://uploads.documents.cimpress.io/v1/uploads/e48803af-8097-4edd-be90-81b038cf7911~110?variant=original&tenant=doc-platform',
        },
    };
}
