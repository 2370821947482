import { getCanvasSubstrate } from './canvas';
import { getKraftSubstrate } from './kraft';
import { getLinenSubstrate } from './linen';
import { getMatteSubstrate } from './matte';
import { getPearlSubstrate } from './pearl';
import { getWoodSubstrate } from './wood';
import { Substrate, SubstrateType } from './types';
import { getNaturalSubstrate } from './natural';
import { getFabricSubstrate } from './fabric';
import { getGlossySubstrate } from './glossy';
import { getDarkWoodSubstrate } from './darkWood';
import { getPremiumGlossySubstrate } from './premiumGlossy';
import { getPremiumMatteSubstrate } from './premiumMatte';
import { getRecycledMatteSubstrate } from './recycledMatte';
import { getClearPlasticFilmSubstrate } from './clearPlasticFilm';
import { getTransparentSubstrate } from './transparent';
import { getExtraSoftRed } from './extraSoftRed';
import { getMetallicGold } from './metallicGold';
import { getMetallicGrey } from './metallicGrey';
import { getMetallicIceSilver } from './metallicIceSilver';
import { getMetallicIceGold } from './metallicIceGold';
import { getClearPlasticSubstrate } from './clearPlastic';
import { getFrostedPlasticSubstrate } from './frostedPlastic';
import { getCottonSubstrate } from './cotton';

export function getSubstrate(type: SubstrateType): Substrate {
    switch (type) {
    case SubstrateType.natural:
        return getNaturalSubstrate();
    case SubstrateType.matte:
        return getMatteSubstrate();
    case SubstrateType.kraft:
        return getKraftSubstrate();
    case SubstrateType.linen:
        return getLinenSubstrate();
    case SubstrateType.pearl:
        return getPearlSubstrate();
    case SubstrateType.fabric:
        return getFabricSubstrate();
    case SubstrateType.glossy:
        return getGlossySubstrate();
    case SubstrateType.canvas:
        return getCanvasSubstrate();
    case SubstrateType.wood:
        return getWoodSubstrate();
    case SubstrateType.darkWood:
        return getDarkWoodSubstrate();
    case SubstrateType.premiumGlossy:
        return getPremiumGlossySubstrate();
    case SubstrateType.premiumMatte:
        return getPremiumMatteSubstrate();
    case SubstrateType.recycledMatte:
        return getRecycledMatteSubstrate();
    case SubstrateType.clearPlasticFilm:
        return getClearPlasticFilmSubstrate();
    case SubstrateType.transparent:
        return getTransparentSubstrate();
    case SubstrateType.extraSoftRed:
        return getExtraSoftRed();
    case SubstrateType.metallicGold:
        return getMetallicGold();
    case SubstrateType.metallicGrey:
        return getMetallicGrey();
    case SubstrateType.metallicIceSilver:
        return getMetallicIceSilver();
    case SubstrateType.metallicIceGold:
        return getMetallicIceGold();
    case SubstrateType.clearPlastic:
        return getClearPlasticSubstrate();
    case SubstrateType.frostedPlastic:
        return getFrostedPlasticSubstrate();
    case SubstrateType.cotton:
        return getCottonSubstrate();
    default:
        throw new Error(`Unknown substrate type requested: ${{ type }}`);
    }
}
