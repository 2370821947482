import { RenderSize, Size2D, buildPointLightGrid, Rotation3D, Position3D } from '@rendering/vortex-core/common';
import { ProductState, ProductSurface, VortexProduct } from '@rendering/vortex-core/products';
import { buildSurfaceMapRecursively, centerModel, getSurfaceMapping, ModelConfig } from '../common';
import { Group, DefaultLoadingManager, Light, AnimationMixer, AnimationClip, Object3D } from 'three';
import { GLTFLoader } from './GLTFLoader';
import { DRACOLoader } from './DRACOLoader';
import { Animation, AnimationDirection, AnimationOptions } from '@rendering/vortex-core/animations';
import { ModelAnimation, ModelAnimationOptions } from '../modelProduct';

export class GltfProduct implements VortexProduct {
    name: string = 'gltf';

    private modelUri: string;
    private meshGroup?: Group;
    private rotationOffset?: Rotation3D;
    private positionOffset?: Position3D;
    private surfaceMapping: { [key: string]: ProductSurface };
    private dracoLoader: DRACOLoader;
    private animationClips: AnimationClip[];
    private mixer?: AnimationMixer;

    private currentState: ProductState;
    private productStates: ProductState[];

    constructor(config: ModelConfig) {
        this.animationClips = [];

        this.modelUri = config.modelUri;

        this.rotationOffset = config.rotationOffset;
        this.positionOffset = config.positionOffset;

        this.surfaceMapping = getSurfaceMapping(config.productMapping, config.fullBleed);

        this.dracoLoader = new DRACOLoader(DefaultLoadingManager);

        this.dracoLoader.setDecoderPath('https://vortex.documents.cimpress.io/draco/v2/');

        const decoderConfig = {
            type: 'js'
        };
        this.dracoLoader.setDecoderConfig(decoderConfig);

        if (config.modelStates && config.modelStates.length > 0) {
            this.productStates = config.modelStates;
        } else {
            this.productStates = [];
        }

        this.currentState = this.productStates[0];
    }

    get requiresGeometryUpdate() {
        return false;
    }

    async getGeometry(): Promise<Group> {
        if (this.meshGroup) {
            return this.meshGroup;
        }

        await this.preLoad();

        if (this.meshGroup === undefined) {
            throw new Error("Failed to load model from uri");
        }

        return this.meshGroup;
    }

    getSurfaceMapping() {
        return this.surfaceMapping;
    }

    setProductSurface(surfaceId: string, productSurface: ProductSurface) {
        if (this.surfaceMapping[surfaceId]) {
            this.surfaceMapping[surfaceId] = productSurface;
        } else {
            throw new Error(`'${surfaceId}' is not a surface on this modeled product`);
        }
    };

    getAlphaMap(_renderSize: RenderSize, _physicalSize: Size2D, _context: CanvasRenderingContext2D) {
        return;
    }

    getAreaLights(pointLightIntensity: number, directionalLightIntensity: number): Light[] {
        const pointLightIntensities: number[] = [
            0.4 * pointLightIntensity, 0.4 * pointLightIntensity,
            0.37 * pointLightIntensity, 0.37 * pointLightIntensity,
            0.43 * pointLightIntensity, 0.40 * pointLightIntensity
        ];

        return buildPointLightGrid(pointLightIntensities);
    }

    getCurrentState(): ProductState {
        return this.currentState;
    }

    setStates(productState: ProductState[]): void {
        this.productStates = productState;
    }

    setCurrentState(stateId: string) {
        const newState = this.getStates().find(state => state.id === stateId);
        if (newState) {
            this.currentState = newState;
        } else {
            throw new Error(`'${stateId} is not a state defined in this product!`);
        }
    }

    getStates(): ProductState[] {
        return this.productStates;
    }

    getAnimationIds(): string[] {
        const animationIds: string[] = [];
        this.animationClips.forEach(clip => animationIds.push(clip.name));
        return animationIds;
    }

    buildAnimation(animationOptions: AnimationOptions): Animation {
        // create mixer for this product if mixer has not been created
        if (this.mixer === undefined) {
            this.mixer = new AnimationMixer(this.meshGroup as Object3D);
        }

        const clip: AnimationClip = this.getAnimationClip(animationOptions.animationId);

        const options: ModelAnimationOptions = {
            direction: animationOptions.direction,
            duration: animationOptions.duration
        }

        return new ModelAnimation(this.mixer, clip, options);
    }

    getAnimationClip(animationId?: string): AnimationClip {
        let clip: AnimationClip;

        if (animationId) {
            const result = this.animationClips.find((entry) => entry.name === animationId);
            if (result) {
                clip = result;
            } else {
                throw `Animation with name ${animationId} was not found`;
            }
        } else if (this.animationClips.length > 0) {
            clip = this.animationClips[0];
        } else {
            throw 'No animation was found';
        }

        return clip;
    }

    preLoad() {
        if (this.meshGroup !== undefined) {
            return Promise.resolve();
        }
        const loader = new GLTFLoader(DefaultLoadingManager);
        loader.setDRACOLoader(this.dracoLoader);

        return new Promise<void>((resolve) => {
            loader.load(this.modelUri, (gltf) => {

                // Apply rotation offsets before the model is re-sized
                if (this.rotationOffset) {
                    gltf.scene.rotation.set(this.rotationOffset.yaw,
                        this.rotationOffset.pitch,
                        this.rotationOffset.roll);
                }

                if (gltf.animations) {
                    gltf.animations.forEach((clip) => {
                        this.animationClips.push(clip);
                    });
                }

                if (this.productStates.length === 0) {
                    this.productStates.push({ id: "Folded" });
                    this.animationClips.forEach(clip => {
                        this.productStates.push({
                            id: `${clip.name}-forward`,
                            dependency: {
                                stateId: 'Folded',
                                animationId: clip.name,
                                animationDirection: AnimationDirection.forward
                            }
                        })
                    });
                    this.currentState = this.productStates[0];
                }

                this.meshGroup = centerModel(this.positionOffset, gltf.scene);

                // model surface is not mapped. Map each surface to specified substrate in surface 'primary'
                if (Object.keys(this.surfaceMapping).length === 1 && this.surfaceMapping.primary) {
                    let meshSurfaces: { [key: string]: ProductSurface } = {};
                    buildSurfaceMapRecursively(meshSurfaces, this.meshGroup, this.surfaceMapping.primary);
                    this.surfaceMapping = meshSurfaces;
                }

                resolve();
            });
        });
    }

    dispose() {
        this.meshGroup = undefined;
        this.dracoLoader.dispose();
    }
}
