export enum ShapeTypes {
    Polygon = 'polygon',
    StarBurst = 'starBurst',
    Ellipse = 'ellipse',
    Rectangle = 'rectangle',
    Line = 'line',
    Arrow = 'arrow',
    DoubleSidedArrow = 'doubleSidedArrow',
    CircularSpeechBubble = 'circularSpeechBubble',
    SpeechBubble = 'speechBubble',
}

export enum InputTypes {
    Number = 'number',
    Color = 'color',
}
export interface Shape {
  id: string;
  type?: ShapeTypes;
  url?: string;
  options?: ShapeOption[];
  horizontalFlip?: boolean;
  verticalFlip?: boolean;
}

export type OptionValueType = string | number;

export interface ShapeOption {
    id: string; // Key
    value?: OptionValueType;
    input?: NumberInput | ColorInput;
    displayLabel?: string;
    displayLabelKey?: string;
}

interface NumberInput {
    type: InputTypes.Number;
    min: number;
    max: number;
    steps: number;
}

interface ColorInput {
    type: InputTypes.Color;
}

export interface Coordinate {
    x: number;
    y: number;
}
