import { Size2D, Position3D, Rotation3D } from "@rendering/vortex-core/common";
import { FocalPoint, ProductState, ProductTrim } from "@rendering/vortex-core/products";
import { Substrate } from "@rendering/vortex-core/substrates";

/**
 * Required configuration for a modeled product
 */
export interface ModelConfig {
    type?: ModelType;
    modelUri: string;
    
    productMapping: { [key: string]: ModelSurface; };
    fullBleed: Size2D;

    rotationOffset?: Rotation3D;
    positionOffset?: Position3D;

    modelStates?: ProductState[];
}

export interface ModelSurface {
    substrate: Substrate;
    page?: number;

    fullBleed?: Size2D;
    trim?: ProductTrim;
    
    focalPoint?: FocalPoint;
}

export enum ModelType {
    dae = 'dae',
    glb ='glb'
}