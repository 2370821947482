import { Finish } from './types';
import { CompositeMode, MapMode } from '../common';

export function getRaisedFoilSilverFinish(): Finish {
    return {
        name: 'raisedFoilSilver',
        channel: 'RaisedFoilSilver',
        bumpScale: 0.03,
        bumpMode: MapMode.generate,
        shininess: 100,
        reflectivity: 0.4,
        baseCompositeMode: CompositeMode.normal,
        baseTexture: {
            dpi: 400,
            sourceUrl: 'https://vortex.documents.cimpress.io/finishes/foil/v1/base',
            overlayColor: '#e4e4e4',
        },
    };
}
