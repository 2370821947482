import { Substrate } from './types';
import { CompositeMode } from '../common';

export function getMetallicIceGold(): Substrate {
    return {
        color: '#f5eabf',
        shininess: 650,
        baseCompositeMode: CompositeMode.multiply,
        specularTexture: {
            dpi: 300,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/pearl/v1/specular',
        },
        reflectivity: 0.05,
        reflectionMapOverrideUrls: [
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
        ],
    };
}
