import { FoldDirection, NFoldOptions, PanelEdge } from './types';
import { Object3D, Vector3 } from 'three';
import { Animation } from '@rendering/vortex-core/animations';
import { applyRotation, getRotationAxis, getZScaleRatios, rotateVector } from './helpers';
import { easeInOutQuart } from '@rendering/vortex-core/common';
import { Mesh } from 'three';

export interface FoldAnimationOptions {
    duration?: number;
    nFoldOptions: NFoldOptions;
    direction: FoldAnimationDirection;
    depth: number;
}

export enum FoldAnimationDirection {
    open,
    close
}

export class NFoldAnimation implements Animation {

    private completionStatus: boolean = false;
    private activeStatus: boolean = false;
    private canceledStatus: boolean = false;

    private myTags: string[];
    private mesh: Object3D;

    private elapsedTime: number = 0;
    private duration: number;
    private startPosition: Vector3;
    private endPosition: Vector3;
    private direction: FoldAnimationDirection;

    private startZScaleRatios: number[];
    private endZScaleRatios: number[];
    private foldZScaleRatios: number[];
    private depth: number;

    private nFoldOptions: NFoldOptions;

    private currentRotations: number[] = [];

    private resolveAnimation?: (value: void | PromiseLike<void>) => void;

    constructor(meshGroup: Object3D, options: FoldAnimationOptions, tags?: string[]) {
        this.myTags = tags ?? [];
        this.mesh = meshGroup;
        this.duration = options.duration ?? 2;
        this.nFoldOptions = { ...options.nFoldOptions };
        this.direction = options.direction;
        this.depth = options.depth;

        // flatten [][] to a single [] to get modifier
        const flatFoldDirections = this.nFoldOptions.foldDirections.reduce((a, b) => { return a.concat(b); }, []).sort((a, b) => (a.ratioIndex > b.ratioIndex) ? 1 : -1);

        for (let i = 0; i < flatFoldDirections.length; i++) {
            const startAngle = meshGroup.userData['state'] === 'open' ? flatFoldDirections[i].angleOpen : flatFoldDirections[i].angleClosed;
            this.currentRotations.push(startAngle);
        }

        this.startPosition = this.mesh.position;
        this.startZScaleRatios = this.mesh.children.map(child => child.scale.z);
        this.foldZScaleRatios = getZScaleRatios(this.nFoldOptions);

        if (this.direction === FoldAnimationDirection.open) {
            this.nFoldOptions.foldDirections = this.nFoldOptions.foldDirections.slice(0, this.nFoldOptions.foldDirections.length).reverse();
            this.endPosition = this.mesh.userData['openPosition'];
            this.endZScaleRatios = new Array(this.nFoldOptions.horizontalRatios.length * this.nFoldOptions.verticalRatios.length).fill(1);
        }
        else {
            this.endPosition = this.mesh.userData['closedPosition'];
            this.endZScaleRatios = this.foldZScaleRatios;
        }
    }
    get isComplete(): boolean {
        return this.completionStatus;
    }
    get isActive(): boolean {
        return this.activeStatus;
    }

    get tags(): string[] {
        return this.myTags;
    }

    get isCanceled(): boolean {
        return this.canceledStatus;
    }

    resetMeshState() {
        this.activeStatus = false;
        this.interpolateValues(this.duration);
        this.resolveAnimation && this.resolveAnimation();
    }

    update(delta: number) {
        if (this.isActive && this.elapsedTime < this.duration) {
            this.interpolateValues(this.elapsedTime);
            this.elapsedTime += delta;
        }
        if (this.elapsedTime >= this.duration && this.isActive) {

            this.interpolateValues(this.duration);
            this.mesh.userData['state'] = this.direction === FoldAnimationDirection.open ? 'open' : 'closed';
            this.resolveAnimation && this.resolveAnimation();
            this.activeStatus = false;
        }
    }

    start() {
        this.activeStatus = true;
        return new Promise<void>((resolve, _reject) => {
            this.resolveAnimation = resolve;
        });
    }

    resume() {
        if (this.resolveAnimation !== undefined) {
            this.activeStatus = true;
        }
    }

    stop(): void {
        this.activeStatus = false;
    }

    cancel() {
        this.activeStatus = false;
        this.canceledStatus = true;
        this.resetMeshState();
        if (this.resolveAnimation) {
            this.resolveAnimation();
        }
    }

    private interpolateValues(time: number) {
        // Animate each panel in the order specified in the options
        // Length of time each panel moving should take up
        const fractionDuration = this.duration / this.nFoldOptions.foldDirections.length;
        const index = Math.trunc(time / fractionDuration);
        // Case when animation is over, safety check to avoid index out of bounds error
        if (index >= this.nFoldOptions.foldDirections.length) {
            return;
        }

        // Apply fold based on the current part of the animation to work on
        for (let i = 0; i < this.nFoldOptions.foldDirections[index].length; i++) {
            this.applyFold(time - fractionDuration * index, this.nFoldOptions.foldDirections[index][i], fractionDuration);
        }

        for (let i = 0; i < this.startZScaleRatios.length; i++) {
            // interpolate the zScale to make things a little smoother
            const zScale = easeInOutQuart(this.elapsedTime, this.startZScaleRatios[i], this.endZScaleRatios[i] - this.startZScaleRatios[i], this.duration);
            this.mesh.children[i].scale.setZ(zScale);
        }

        // Interpolate the x value, when we begin folding over the x axis we can also do this for the y element of position
        const positionX = easeInOutQuart(time, this.startPosition.x, this.endPosition.x - this.startPosition.x, this.duration);
        this.mesh.position.setX(positionX);

        const positionY = easeInOutQuart(time, this.startPosition.y, this.endPosition.y - this.startPosition.y, this.duration);
        this.mesh.position.setY(positionY);

        const positionZ = easeInOutQuart(time, this.startPosition.z, this.endPosition.z - this.startPosition.z, this.duration);
        //this.mesh.position.setZ(positionZ);
    }

    private applyFold(time: number, foldDirection: FoldDirection, duration: number) {
        const panelIndex = foldDirection.ratioIndex;
        // Get start/end angle for interpolation
        const startAngle = this.mesh.userData['state'] === 'open' ? foldDirection.angleOpen : foldDirection.angleClosed;
        const endAngle = this.direction === FoldAnimationDirection.open ? foldDirection.angleOpen : foldDirection.angleClosed;

        // What angle the panel should be at in the animation.
        const angle = easeInOutQuart(time, startAngle, endAngle - startAngle, duration);

        // geometry to anchor the current animate panel on
        const rotationPanelData = this.getRotationPanel(foldDirection);
        const rotationPanel = rotationPanelData.panel;

        const rotationMesh = rotationPanel.children[0] as Mesh;
        const rotationMesh2 = rotationPanel.children[1] as Mesh;

        //  Flag to signify which side of the rotating panel we're rotating about
        const modifier = foldDirection.edge === PanelEdge.right || foldDirection.edge === PanelEdge.top ? -1 : 1;
        const angleModifier = foldDirection.angleClosed > 0 ? 1 : -1

        // Ensure bounding box is generated for the geometry
        if (rotationMesh.geometry.boundingBox === null || rotationMesh.geometry.boundingBox === undefined) {
            rotationMesh.geometry.computeBoundingBox();
            rotationMesh2.geometry.computeBoundingBox();
        }

        // Hopefully this is never hit since we're computing bb above
        if (rotationMesh.geometry.boundingBox === null || rotationMesh2.geometry.boundingBox === null) {
            throw "unable to compute bounding box";
        }

        const panelWidth = Math.max(rotationMesh.geometry.boundingBox.max.x, rotationMesh2.geometry.boundingBox.max.x) - Math.min(rotationMesh.geometry.boundingBox.min.x, rotationMesh2.geometry.boundingBox.min.x);
        const panelHeight = Math.max(rotationMesh.geometry.boundingBox.max.y, rotationMesh2.geometry.boundingBox.max.y) - Math.min(rotationMesh.geometry.boundingBox.min.y, rotationMesh2.geometry.boundingBox.min.y);

        // The edge of a panel we'll be rotating about
        let rotationPoint = new Vector3(
            rotationPanel.position.x + modifier * panelWidth / 2,
            rotationPanel.position.y - modifier * panelHeight / 2,
            rotationPanel.position.z - modifier * this.depth * this.foldZScaleRatios[rotationPanelData.index] * 0.5 * angleModifier
        );

        // Rotate the edge about the center of the panel with the panels rotation
        const rotationAxis = getRotationAxis(foldDirection.edge);
        rotationPoint = rotateVector(rotationPoint, rotationPanel.position, rotationPanel.rotation);

        const foldPanels = this.findRotationPanels(foldDirection);
        foldPanels.forEach(fp => applyRotation(fp, rotationPoint, angle - this.currentRotations[panelIndex], rotationAxis));

        this.currentRotations[panelIndex] = angle;
    }

    private getRotationPanel(foldDirection: FoldDirection): {panel: Object3D, index: number} {
        const edge = foldDirection.edge;
        const geometry = this.mesh;
        if (edge === PanelEdge.left || edge === PanelEdge.right) {
            const rotationPanelIndex: number = foldDirection.edge === PanelEdge.left ? foldDirection.ratioIndex - 1 : foldDirection.ratioIndex + 1;
            return {panel: geometry.children[rotationPanelIndex], index: rotationPanelIndex};
        }
        const rotationPanelIndex: number = foldDirection.edge === PanelEdge.top ? foldDirection.ratioIndex - 1 : foldDirection.ratioIndex + 1;
        const index = rotationPanelIndex * this.nFoldOptions.horizontalRatios.length;
        return {panel: geometry.children[rotationPanelIndex * this.nFoldOptions.horizontalRatios.length], index};
    }

    private findRotationPanels(foldDirection: FoldDirection): Object3D[] {
        const edge = foldDirection.edge;
        const geometry = this.mesh;

        const targetPanels: Object3D[] = [];

        let minHor = 0;
        let maxHor = this.nFoldOptions.horizontalRatios.length;

        let minVert = 0;
        let maxVert = this.nFoldOptions.verticalRatios.length;

        // Move all panels to the right
        if (foldDirection.edge === PanelEdge.left) {
           minHor = foldDirection.ratioIndex;
        } else if (edge === PanelEdge.right) {
            maxHor = foldDirection.ratioIndex + 1;
        }
        else if (edge === PanelEdge.bottom) {
            maxVert = foldDirection.ratioIndex + 1;
        }
        else {
            minVert = foldDirection.ratioIndex;
        }

        for (let i = minVert; i < maxVert; i++) {
            for (let j = minHor; j < maxHor; j++) {
                targetPanels.push(geometry.children[i*this.nFoldOptions.horizontalRatios.length + j]);
            }
        }

        return targetPanels;
    }
}