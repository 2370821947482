import Axios from 'axios';
import { VortexProduct } from '@rendering/vortex-core/products';
import { getCanvasPrintProduct } from './canvasPrintLoader';
import { ProductType, VortexProductConfig } from '../configs/vortexProductConfig';
import { getFlatSheetProduct } from './flatSheetLoader';
import { getModelProduct } from './modelLoader';
import { getNfoldProduct } from './nFoldLoader';

/**
* Loads a vortex product from a vortex product configuration payload. If the product fails to load, this function will throw an error.
*/
export async function loadProduct(vortexProductConfig: VortexProductConfig): Promise<VortexProduct> {
    switch (vortexProductConfig.type) {
        case ProductType.flatSheet: {
            return getFlatSheetProduct(vortexProductConfig);
        }
        case ProductType.model: {
            return getModelProduct(vortexProductConfig);
        }
        case ProductType.nFold: {
            return getNfoldProduct(vortexProductConfig);
        }
        case ProductType.canvasPrint: {
            return getCanvasPrintProduct(vortexProductConfig);
        }
        default:
            throw new Error(`Unsupported product type '${vortexProductConfig.type}'!`);
    }
}

const MODEL_CONFIG_URL = 'https://cdn.previews.cimpress.io/v2/modelConfig';

export interface ProductConfigOptions {
    sku: string,
    attributes: { [key: string]: string | number },
    tenant: { id: string, type: string }
};

/**
* Queries the vortex orchestration service for a vortex product configuration given a sku, variables, and tenant.
* If no vortex product configuration is found, this function will throw an error.
*/
export async function getProductConfig(options: ProductConfigOptions): Promise<VortexProductConfig> {
    const attributes: string = JSON.stringify(options.attributes);

    const queryUrl: string = `${MODEL_CONFIG_URL}?sku=${options.sku}&skuAttributes=${encodeURIComponent(attributes)}&tenantId=${options.tenant.id}&tenantType=${options.tenant.type}`;

    const vortexResponse = await Axios.get<VortexProductConfig>(queryUrl);

    // If an error occurred throw an exception and report the message
    if (vortexResponse.status >= 400) {
        throw `Failed to fetch vortex product config: '${JSON.stringify(vortexResponse.data)}'!`;
    }

    return vortexResponse.data;
}