import { Substrate } from './types';

export function getMatteSubstrate(): Substrate {
    return {
        color: 0xFFFFFF,
        shininess: 20,
        bumpScale: 0.4,
        bumpTexture: {
            dpi: 300,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/matte/v1/bump',
        },
    };
}
