/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';

class IconWithTooltip extends PureComponent {
  onClick = (event) => {
    this.props.onClick(event, this.props.callbackData);
  };

  render() {
    const clickProps = this.props.clickable
      ? { role: 'button', tabIndex: '0', onClick: this.onClick }
      : {};
    return (
      <div className={this.props.className} {...clickProps}>
        <Tooltip direction="top" contents={this.props.tooltipContent}>
          <a>
            {this.props.icon}
          </a>
        </Tooltip>
      </div>
    );
  }
}

IconWithTooltip.propTypes = {
  className: PropTypes.string,
  tooltipContent: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  callbackData: PropTypes.string,
};

IconWithTooltip.defaultProps = {
  className: '',
  onClick: () => null,
  clickable: false,
  callbackData: null,
};

export default IconWithTooltip;
