export enum MapMode {
    generate = 'generated',
    inherit = 'inherit',
}

export enum CompositeMode {
    normal = 'normal',
    multiply = 'multiply',
    overlay = 'overlay',
    mask = 'destination-in',
    sourceIn = 'source-in',
}
