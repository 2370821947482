import { DragEvent } from 'react';
import { Coordinate, ShapeTypes } from 'shapes';
import { getCurrentShape } from './commands/command.currentSvg';

const ghostImageId = 'shape-ghost-image';
const fakeGhostImageId = 'fake-ghost-image';

export interface DragAndDrop {
  dropTargetClassName: string;
  canvasToShapePercentage: number;
  onDrop({ x, y, height, width }: { x: number; y: number; height: number; width: number }): void;
}

const getDropTarget = (className: string) => {
    let dropTarget;
    const elements = document.getElementsByClassName(className);
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        const {width, height} = element.getBoundingClientRect();
        if(width > 0  && height > 0) {
            // get the visible drop target
            dropTarget = element;
            break;
        }
    }
    return dropTarget;
}

const isValidDroppableTarget = (inputPosition: Coordinate,
    droppableAreaPosition: { top: number; left: number; right: number; bottom: number}) => {
    let isValidDroppable = false;
    if (inputPosition.x > droppableAreaPosition.left && inputPosition.x < droppableAreaPosition.right
    && inputPosition.y > droppableAreaPosition.top && inputPosition.y < droppableAreaPosition.bottom) {
        isValidDroppable = true;
    }

    return isValidDroppable;
};

const getShapeDimensions = (targetDimension: {width: number; height: number}, ratio: number) => {
    const dimensions = {
        width: 0,
        height: 0,
    };
    const maxWidth = targetDimension.width;
    const maxHeight = targetDimension.height;
    dimensions.width = dimensions.height = Math.min(maxWidth, maxHeight) * ratio;

    return dimensions;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCoordinates = (event: any): Coordinate => {
    return {
        x: event.pageX,
        y: event.pageY,
    }
};

const onDragOver = (event: Event, dragAndDrop: DragAndDrop) => {
    event.preventDefault();
    const ghostImage = document.getElementById(ghostImageId);
    const dropTarget = getDropTarget(dragAndDrop.dropTargetClassName);
    if(ghostImage && dropTarget) {
        const droppableAreaPosition = dropTarget.getBoundingClientRect();
        const inputPosition = getCoordinates(event);
        const shapeDimensions  = getShapeDimensions({ width: droppableAreaPosition.width, height: droppableAreaPosition.height },
            dragAndDrop.canvasToShapePercentage);
        ghostImage.style.width = `${shapeDimensions.width}px`;

        const ghostImageHeight  = ghostImage.getBoundingClientRect().height;
        const left = (inputPosition.x ) - (shapeDimensions.width / 2);
        const top = (inputPosition.y ) - (ghostImageHeight / 2);
        ghostImage.style.transform = `translate(${left}px, ${top}px)`;
    }

};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onDropShape = (event: any, dragAndDrop: DragAndDrop) => {
    const draggableElementType = event.dataTransfer.getData('text');
    const dropTarget = getDropTarget(dragAndDrop.dropTargetClassName);
    const ghostImage = document.getElementById(ghostImageId);
    if (draggableElementType === 'shape' && dropTarget && ghostImage) {
        const inputPosition = getCoordinates(event);
        const droppableAreaPosition = dropTarget.getBoundingClientRect();
        const isShapeWithinDroppableArea = isValidDroppableTarget(inputPosition, droppableAreaPosition);
        if (isShapeWithinDroppableArea) {
            const isLine = getCurrentShape().type === ShapeTypes.Line;
            const left = inputPosition.x - droppableAreaPosition.left;
            const top = inputPosition.y - droppableAreaPosition.top;
            if(left > 0 && top > 0){
                const shapeDimensions  = getShapeDimensions({width: droppableAreaPosition.width, height: droppableAreaPosition.height},
                    dragAndDrop.canvasToShapePercentage);
                const ghostImageHeight  = ghostImage.getBoundingClientRect().height;
                const newHeight = isLine ? 1 : ghostImageHeight;

                const x = left - (shapeDimensions.width / 2);
                const y = top - (newHeight / 2);
                dragAndDrop.onDrop({ x, y, height: newHeight, width: shapeDimensions.width });
            }
            event.dataTransfer.clearData();
        }
    }
};

export const onDragStart = (event: DragEvent, element: HTMLImageElement | null) => {
    if (element) {
        const ghostImage = document.createElement('div');
        const clonedElement = element.cloneNode(true) as HTMLImageElement;
        ghostImage.appendChild(clonedElement);
        ghostImage.id = ghostImageId;
        ghostImage.style.position = 'absolute';
        ghostImage.style.left = '0';
        ghostImage.style.top = '0';
        ghostImage.style.opacity = '0.5';
        ghostImage.style.zIndex = '9999';
        document.body.appendChild(ghostImage);

        const fakeGhostImageimg = new Image();
        fakeGhostImageimg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
        fakeGhostImageimg.id = fakeGhostImageId;
        document.body.appendChild(fakeGhostImageimg);
        event.dataTransfer.setDragImage(fakeGhostImageimg, 0, 0);

        event.dataTransfer.setData("text/plain", 'shape');
    }
};

export const onDragEnd = () => {
    const ghostImage = document.getElementById(ghostImageId);
    const fakeGhostImage = document.getElementById(fakeGhostImageId);
    ghostImage && ghostImage.remove();
    fakeGhostImage && fakeGhostImage.remove();
};

export const enbaleDragAndDrop = (dragAndDrop: DragAndDrop) => {
    const droptarget = getDropTarget(dragAndDrop.dropTargetClassName);
    if (droptarget) {
        const index = 0;
        const bodyElements = document.getElementsByTagName('body');
        if (index <= bodyElements.length - 1) {
            const body = bodyElements[index];
            body.addEventListener('dragover', (event) => onDragOver(event, dragAndDrop));
            body.addEventListener('drop', (event) => onDropShape(event, dragAndDrop));
        }

    }
};
