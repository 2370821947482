import { Box3, Object3D, Vector3 } from 'three';
import { easeInOutQuart } from '../common';
import { Animation } from './animation';

export interface CenterAnimationOptions {
    duration?: number;

    initialBounds: Box3;
    targetBounds: Box3;
}

export class CenterAnimation implements Animation {
    private duration: number;
    private elapsedTime = 0;

    private geometry: Object3D;
    private initialCenter: Vector3;
    private translationOffset: Vector3;

    private resolveAnimation?: Function;
    private activeStatus = false;
    private completed = false;
    private canceledStatus = false;

    constructor(geometry: Object3D, options: CenterAnimationOptions) {
        this.duration = options.duration ?? 2;
        this.geometry = geometry;

        const initialCenter = options.initialBounds.getCenter(new Vector3());
        const targetCenter = options.targetBounds.getCenter(new Vector3());

        this.translationOffset = new Vector3(
            initialCenter.x - targetCenter.x,
            initialCenter.y - targetCenter.y,
            initialCenter.z - targetCenter.z,
        );

        this.initialCenter = geometry.position.clone();
    }

    get isComplete() {
        return this.completed;
    }

    public tags: string[] = [];

    get isCanceled() {
        return this.canceledStatus;
    }

    resetMeshState() {
        this.activeStatus = false;
        this.interpolateValues(this.duration);
        this.resolveAnimation && this.resolveAnimation();
    }

    cancel() {
        this.activeStatus = false;
        this.canceledStatus = true;
        this.resetMeshState();
        if (this.resolveAnimation) {
            this.resolveAnimation();
        }
    }

    get isActive() {
        return this.activeStatus;
    }

    update(delta: number) {
        if (this.isActive && this.elapsedTime < this.duration) {
            this.interpolateValues(this.elapsedTime);
            this.elapsedTime += delta;
        }

        if (this.elapsedTime >= this.duration && this.isActive) {
            this.interpolateValues(this.duration);
            this.resolveAnimation && this.resolveAnimation();
            this.activeStatus = false;
        }
    }

    start() {
        this.activeStatus = true;
        return new Promise<void>((resolve) => {
            this.resolveAnimation = resolve;
        });
    }

    resume() {
        if (this.resolveAnimation !== undefined) {
            this.activeStatus = true;
        }
    }

    stop() {
        this.activeStatus = false;
    }

    private interpolateValues(time: number) {
        this.geometry.position.x = easeInOutQuart(time, this.initialCenter.x, this.translationOffset.x, this.duration);
        this.geometry.position.y = easeInOutQuart(time, this.initialCenter.y, this.translationOffset.y, this.duration);
        this.geometry.position.z = easeInOutQuart(time, this.initialCenter.z, this.translationOffset.z, this.duration);
    }
}
