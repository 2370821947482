import axios from 'axios';
import { Shape, ShapeTypes } from './types.shapes';
import { Attributes, getStrokeWidthByViewBox, modifySVG } from 'svgs/svgModifier';
import { Shape as ComplexShape, GetSvg, BasicShape, PathProperties } from '@cimpress-technology/svg-path';

const getShapeOptions = (shape: Shape) => {
    const shapeOptions = {};
    shape.options?.forEach((option) => {
        shapeOptions[option.id] = option.value as number;
    });

    return shapeOptions;
}

const getShapeObject = (shape: Shape, width: number, height: number): ComplexShape | BasicShape | undefined => {
    let shapeObj: ComplexShape | BasicShape | undefined;
    const shapeOptions = getShapeOptions(shape);

    switch (shape.type) {
    case ShapeTypes.Polygon: {
        shapeObj = {
            type: shape.type,
            numberOfSides: shapeOptions['numberOfSides'],
            borderRadius: shapeOptions['radius'],
        }
        break;
    }

    case ShapeTypes.StarBurst: {
        shapeObj = {
            type: shape.type,
            numberOfPoints: shapeOptions['numberOfPoints'],
            height: shapeOptions['height'],
        }
        break;
    }

    case ShapeTypes.CircularSpeechBubble: {
        shapeObj = {
            type: shape.type,
            pointerWidth: shapeOptions['pointerWidth'],
            pointerHeight: shapeOptions['pointerHeight'],
        }
        break;
    }

    case ShapeTypes.SpeechBubble: {
        shapeObj = {
            type: shape.type,
            pointerHeight: shapeOptions['pointerHeight'],
            pointerPosition: shapeOptions['pointerPosition'],
            borderRadius: shapeOptions['radius'],
        }
        break;
    }

    case ShapeTypes.Arrow:
    case ShapeTypes.DoubleSidedArrow: {
        shapeObj = {
            type: shape.type,
            tailWidth: shapeOptions['tailWidth'],
            headWidth: shapeOptions['headWidth'],
        }

        break;
    }

    case ShapeTypes.Rectangle:
    case ShapeTypes.Line:
    case ShapeTypes.Ellipse: {
        shapeObj = {
            type: shape.type,
            height,
            width
        };
        break;
    }

    }

    return shapeObj;
}

export async function getSvgFromShape(
    shape: Shape,
    width: number,
    height: number,
    stroke: string,
    strokeWidth: number,
    fillColor: string,
): Promise<string> {
    let svgString = '';
    const scaleX = shape.horizontalFlip ? -1 : 1;
    const scaleY = shape.verticalFlip ? -1 : 1;
    const svgPathProperties: PathProperties = {
        stroke: {
            color: stroke,
            width: strokeWidth,
        },
        fill: fillColor || 'none',
        transform: `scale(${scaleX}, ${scaleY})`
    };

    if (shape.url) {
        const svgUrl = shape.url;
        try {
            const { data } = await axios.get(svgUrl);
            const adjustedStrokeWidth = parseFloat(getStrokeWidthByViewBox(data));
            const attrs: Attributes = {
                'stroke-width': `${strokeWidth * adjustedStrokeWidth}`,
                fill: fillColor || 'none',
                stroke: stroke,
            };

            svgString = modifySVG(data, attrs);
        } catch (error) {
            throw error;
        }
    } else {
        const shapeObject = getShapeObject(shape, width, height);
        svgString = (shapeObject && GetSvg(shapeObject, svgPathProperties)) || '';
    }
    return svgString;
}
