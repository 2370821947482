import {
    Group,
    WebGLRenderer,
    Scene,
    Box3,
    Vector3,
    AmbientLight,
    Mesh,
    Clock,
    Texture,
    Color,
    Spherical,
    Vector2,
    MeshPhongMaterial,
    Light,
    SkinnedMesh,
    BufferGeometry,
    BoxHelper,
    Object3D,
} from 'three';
import ResizeObserver from 'resize-observer-polyfill';
import {
    buildSubstrate,
    buildFinish,
    loadTextureFromUri,
    Size3D,
    normalizePosition,
    TraceIntersection,
    getStateBounds,
    StateOptions,
    BuildMeshMap,
    ComputeFocalPoints,
    buildBoundingBoxGeometry,
} from '../common';
import { PreviewConfig, DocumentReference, Environment, VortexEvent, MAX_GEOMETRY_DIMENSION, WebGlOptions } from '../config';
import { VortexProduct, ProductSurface, ProductState, ProductLoadingOptions } from '../products';
import { RenderingClient } from '../clients';
import { OrbitalCamera, CameraOptions } from '../cameras';
import { Finish } from '../finishes';
import { Animation, AnimationDirection, AnimationManager, AnimationOptions, CenterAnimation, CenterAnimationOptions } from '../animations';
import { Action } from '../actions';
import { updateCanvasShadows } from '../rendering/shadows';

export class Preview {
    // Custom product to display
    private vortexProduct?: VortexProduct;
    private productLoadingOptions?: ProductLoadingOptions;

    // Environment product will be shown in
    private environment: Environment;

    // Rendering from document references
    private renderingClient: RenderingClient;

    // WebGL Rendering
    private webGlOptions: WebGlOptions;
    private renderer: WebGLRenderer;
    private rafId = 0;

    // HTML canvas element and resizing
    private canvas: HTMLCanvasElement;
    private canvasResizeObserver: ResizeObserver;

    // Vortex canvas interactions
    private eventMap: { [key: string]: Array<(event: VortexEvent) => any> } = {};
    private boundClickEvent = this.onClick.bind(this);
    private boundMoveEvent = this.onMouseMove.bind(this);
    private lastIntersectingSurfaceId: string | undefined = undefined;

    private productRequiresUpdate = false;
    private holdRenderForUpdate = false;

    private scene: Scene;
    private camera: OrbitalCamera;
    private cameraOptions: CameraOptions;
    private areaLights: Light[] | undefined;

    private currentGeometry: Group | undefined;
    private maximumGeometrySize: Size3D | undefined;

    private stateBounds: { [key: string]: Box3 } = {};
    private meshMap: { [key: string]: Mesh } | undefined;

    // Store for created materials and highlighted areas
    private materialMap: { [key: string]: MeshPhongMaterial } = {};
    private hightLightMap: { [key: string]: Color } = {};

    // Define the document rasters urls (pages and finishes)
    private documentRasterUrls: { [key: string]: string } = {};

    private normalizationFactor = 1;

    private clock: Clock = new Clock();
    private animationManager: AnimationManager = new AnimationManager();

    private boundingBox: Object3D | undefined;
    private boxHelper: BoxHelper | undefined;

    constructor(config: PreviewConfig) {
        this.environment = config.environment;
        this.cameraOptions = config.cameraOptions;
        this.canvas = config.canvas;

        // Add canvas interactions
        this.canvas.addEventListener('click', this.boundClickEvent, false);
        this.canvas.addEventListener('mousemove', this.boundMoveEvent, false);

        // Define the render client and initialize it with the canvas dimensions and document
        const canvasRect = this.canvas.getBoundingClientRect();
        this.renderingClient = new RenderingClient(config.renderingOptions);
        this.renderingClient.updateContainerDimensions({ width: canvasRect.width, height: canvasRect.height });

        // Load a document reference if its provided
        if (config.documentReference) {
            this.renderingClient.setInstructionSourceUrl(config.documentReference.renderingInstructionsUrl);
        }

        // Build and connect the webGL renderer to the supplied canvas
        this.webGlOptions = config.webGlOptions ?? { preserveDrawingBuffer: false };
        const preserveDrawingBuffer = this.webGlOptions.preserveDrawingBuffer;
        this.renderer = new WebGLRenderer({ antialias: true, alpha: true, canvas: this.canvas, preserveDrawingBuffer });

        // Ensure the pixel ratio is at least 2 for better details on edges
        this.renderer.setPixelRatio(Math.max(2, devicePixelRatio));
        this.renderer.setClearColor(0, 0);

        // Build the orbital camera with the canvas dimensions
        this.camera = new OrbitalCamera(this.cameraOptions, {
            aspectRatio: canvasRect.width / canvasRect.height,
            domElement: this.canvas,
        });

        // Container for the entire preview
        this.scene = new Scene();

        // Add the ambient lighting to the scene
        const ambientIntensity = this.environment.lightingOptions?.ambientIntensity ?? 1.0;
        this.scene.add(new AmbientLight(0x111111, 11.04 * ambientIntensity));

        // Observes when the provided canvas size changes and properly updates the camera and rendering client
        this.canvasResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            if (entries.length === 0) { return; }

            // Get the current width and height of the updated canvas
            const { width, height } = entries[0].contentRect;

            // If a product is loaded with geometry ensure the orbit radius is updated when the canvas size changes
            if (this.currentGeometry && this.maximumGeometrySize) {
                this.camera.computeOrbitRadius(this.maximumGeometrySize);
            }

            this.camera.aspectRatio = width / height;
            this.renderingClient.updateContainerDimensions({ width, height });
            this.renderer.setSize(width, height, false);
        });
    }

    get product(): VortexProduct | undefined {
        return this.vortexProduct;
    }

    get getProductGeometry(): Group | undefined {
        return this.currentGeometry;
    }

    /**
    * Load a new vortex product
    */
    async load(vortexProduct: VortexProduct, productLoadingOptions?: ProductLoadingOptions): Promise<void> {
        this.holdRenderForUpdate = true;

        const initialized = !!this.vortexProduct;
        const backgroundTask = this.initializeBackground();

        if (initialized) {
            // eslint-disable-next-line no-unused-expressions
            this.vortexProduct?.dispose();

            // Flush any cached materials associated to this product
            buildSubstrate.flush();
            buildFinish.flush();

            // remove product area lights from scene if it exists
            if (this.areaLights) {
                this.scene.remove(...this.areaLights);
            }

            // remove geometry from scene if it exists and dispose
            if (this.currentGeometry) {
                this.scene.remove(this.currentGeometry);
            }
        } else {
            // Initialize the rendering client
            await this.renderingClient.initialize();
        }

        this.vortexProduct = vortexProduct;
        this.productLoadingOptions = productLoadingOptions;

        // Load the product's geometry and add it to the scene
        this.currentGeometry = await this.loadGeometry(this.vortexProduct);
        this.scene.add(this.currentGeometry);

        // render bounding box of geometry for debugging or model config setup purposes
        if (this.webGlOptions.renderBoundingBox) {
            this.boxHelper = new BoxHelper(this.currentGeometry, new Color(0x000000));
            this.scene.add(this.boxHelper);
        }

        // Initialize state bounds after re-normalizing geometry
        this.scene.updateMatrixWorld();
        this.stateBounds = await getStateBounds(this, vortexProduct, this.currentGeometry, this.normalizationFactor);

        // If a document reference exists, initialize all document rasters
        if (this.renderingClient.isInitialized()) {
            this.documentRasterUrls = this.renderingClient.buildDocumentRasterUrls(vortexProduct);
        }

        // Add the product's lighting to the scene
        const pointLightIntensity = this.environment?.lightingOptions?.pointLightIntensity ?? 1.0;
        const directionalLightIntensity = this.environment?.lightingOptions?.directionalLightIntensity ?? 1.0;

        this.areaLights = vortexProduct.getAreaLights(pointLightIntensity, directionalLightIntensity);
        this.scene.add(...this.areaLights);

        // Once the geometry is loaded the materials and background can be done in parallel
        await Promise.all([this.textureGeometry(), backgroundTask]);

        this.observeResize();

        if (!initialized) {
            this.render();
        }

        // Generate focal points dynamically for surfaces that don't already contain them
        if (this.vortexProduct && this.meshMap) {
            await ComputeFocalPoints(this.vortexProduct, this.meshMap, this);
        }
        this.holdRenderForUpdate = false;
    }

    /**
    * Update the document reference. An optional page can be provided to only update that docref
    * for that particular page. Otherwise all pages in the model will be updated.
    */
    setDocumentReference(documentReference: DocumentReference, page?: number): void {
        this.renderingClient.setInstructionSourceUrl(documentReference.renderingInstructionsUrl, page);
    }

    /**
    * Update a specific document texture by page number
    */
    setDocumentImage(imageUrl: string, page: number) {
        this.documentRasterUrls[`${page} none`] = imageUrl;
    }

    /**
    * Update a specific document mask by page number and channel
    */
    setFinishMaskImage(imageUrl: string, page: number, channel: string) {
        this.documentRasterUrls[`${page} ${channel}`] = imageUrl;
    }

    /**
     * Update camera zoom factor
     */
    adjustZoom(zoom: number, duration?: number, easingFunction?: (time: number, startVal: number, deltaVal: number, duration: number) => number): Promise<void> {
        return this.camera.adjustZoomFactor(zoom, duration, easingFunction);
    }

    /**
    * Adds a colored highlight to a specific surface. The optional color is defined with css syntax, otherwise
    * the default will be yellow.
    */
    addHighlight(surfaceId: string, color?: string): void {
        // Resolve a race condition where highlights happen during geometry updates
        if (!this.materialMap[surfaceId]) return;

        const material: MeshPhongMaterial = this.materialMap[surfaceId];

        // If a custom color is specified use that, otherwise default to yellow
        material.color = color ? new Color(color) : new Color(1, 1, 0.3);

        material.needsUpdate = true;
        this.productRequiresUpdate = true;
    }

    removeHighlight(surfaceId: string): void {
        // Resolve a race condition where highlights happen during geometry updates
        if (!this.materialMap[surfaceId]) return;

        const material: MeshPhongMaterial = this.materialMap[surfaceId];

        // Restore the material color
        material.color = this.hightLightMap[surfaceId].clone();

        material.needsUpdate = true;
        this.productRequiresUpdate = true;
    }

    /**
     * Re-load a product in the preview. Used if the geometry or textures of a vortex product have changed
     */
    async update(): Promise<void> {
        this.holdRenderForUpdate = true;

        if (this.vortexProduct) {
            // Re-load product geometry if required
            if (this.vortexProduct.requiresGeometryUpdate) {
                if (this.currentGeometry) {
                    this.scene.remove(this.currentGeometry);
                }

                this.currentGeometry = await this.loadGeometry(this.vortexProduct);
                this.scene.add(this.currentGeometry);
            }

            // Flush existing textures
            buildSubstrate.flush();
            buildFinish.flush();

            // Rebuild the document map if the rendering client has instructions changed
            if (this.renderingClient.requiresUpdate()) {
                this.documentRasterUrls = this.renderingClient.buildDocumentRasterUrls(this.vortexProduct);
            }

            await this.textureGeometry();
        }

        // Ensure the new geometry/material gets rendered if no other actions or camera movements take place
        this.productRequiresUpdate = true;
        this.holdRenderForUpdate = false;
    }

    /**
     * Add an event listener for vortex interactions
     */
    addEventListener(type: string, listener: (event: VortexEvent) => any) {
        if (!Object.keys(this.eventMap).includes(type)) {
            this.eventMap[type] = [];
        }

        this.eventMap[type].push(listener);
    }

    /**
     * Remove an event listener for vortex interactions
     */
    removeEventListener(type: string, listener: (event: VortexEvent) => any) {
        if (Object.keys(this.eventMap).includes(type)) {
            const index = this.eventMap[type].indexOf(listener);

            this.eventMap[type].splice(index, 1);
        }
    }

    lookAtFront(duration?: number, easingFunction?: (time: number, startVal: number, deltaVal: number, duration: number) => number): Promise<void> {
        return this.lookAtAngle(0, undefined, duration, easingFunction);
    }

    lookAtRight(duration?: number, easingFunction?: (time: number, startVal: number, deltaVal: number, duration: number) => number): Promise<void> {
        return this.lookAtAngle(Math.PI / 2, undefined, duration, easingFunction);
    }

    lookAtLeft(duration?: number, easingFunction?: (time: number, startVal: number, deltaVal: number, duration: number) => number): Promise<void> {
        return this.lookAtAngle(-Math.PI / 2, undefined, duration, easingFunction);
    }

    lookAtBack(duration?: number, easingFunction?: (time: number, startVal: number, deltaVal: number, duration: number) => number): Promise<void> {
        return this.lookAtAngle(Math.PI, undefined, duration, easingFunction);
    }

    /**
     * Focus the camera on a given surface. This may also trigger model animations to show occluded surfaces
     */
    async focusOnSurface(
        surfaceId: string,
        duration?: number,
        easingFunction?: (time: number, startVal: number, deltaVal: number, duration: number) => number,
    ): Promise<void> {
        if (!this.vortexProduct) {
            throw new Error('Vortex Product must be defined before a surface can be focused on!');
        }

        const { focalPoint } = this.vortexProduct.getSurfaceMapping()[surfaceId];

        if (focalPoint) {
            // Get the camera view vector and reverse it
            const { cameraView } = focalPoint;
            const cameraViewReverse = new Vector3(-cameraView.x, -cameraView.y, -cameraView.z);

            // Map the reversed normal into spherical coordinates
            const spherical = new Spherical().setFromVector3(cameraViewReverse);

            const cameraAction = this.lookAtAngle(spherical.theta, spherical.phi, duration, easingFunction);

            if (focalPoint.allowedStates && focalPoint.allowedStates.length > 0) {
                const currentState = this.vortexProduct.getCurrentState();
                if (!focalPoint.allowedStates.includes(currentState.id)) {
                    await this.changeState(focalPoint.allowedStates[0]);
                }
            }

            await cameraAction;
        }

        return Promise.resolve();
    }

    lookAtAngle(
        azimuthAngle: number,
        polarAngle?: number,
        animationDuration?: number,
        easingFunction?: (time: number, startVal: number, deltaVal: number, duration: number) => number,
    ): Promise<void> {
        return this.camera.lookAt(
            {
                azimuthAngle,
                polarAngle: polarAngle !== undefined ? polarAngle : Math.PI / 2,
            },
            animationDuration,
            easingFunction,
        );
    }

    /**
     * @deprecated Please use transitionState for more functionality related to centering and scaling the model.
     */
    async changeState(stateId: string, duration?: number): Promise<void> {
        await this.transitionState(stateId, { duration });
    }

    async transitionState(stateId: string, stateOptions?: StateOptions): Promise<void> {
        if (this.vortexProduct) {
            const currentState = this.vortexProduct.getCurrentState();

            // product already in the given state, no nothing
            if (stateId === currentState.id) {
                return Promise.resolve();
            }

            const targetState = this.vortexProduct.getStates().find((state) => state.id === stateId);

            // given state is not valid state, throw error
            if (!targetState) {
                throw new Error(`'${stateId}' not found on product!`);
            }

            if (targetState.dependency || currentState.dependency) {
                // Determine auto center/zoom options from the state
                const duration: number = stateOptions?.duration ?? targetState.dependency?.animationDuration ?? currentState.dependency?.animationDuration ?? 1;
                const autoCenter: boolean = stateOptions?.centerGeometry ?? false;
                const autoZoom: boolean = stateOptions?.scaleToFitGeometry ?? false;

                if (targetState.boundingBox) {
                    this.boundingBox = buildBoundingBoxGeometry(targetState.boundingBox, this.normalizationFactor)
                    this.boxHelper?.setFromObject(this.boundingBox);
                } else {
                    this.currentGeometry && this.boxHelper?.setFromObject(this.currentGeometry)
                    this.boundingBox = undefined;
                }

                // play all animations needed to get from current state to target state
                await this.changeStateRecursively(this.vortexProduct, currentState, targetState, duration, autoCenter, autoZoom);

                this.vortexProduct.setCurrentState(stateId);
                return Promise.resolve();
            }
            // neither target nor current state has dependency meaning no associated animation, throw error
            throw new Error(`Cannot change to '${stateId}' because '${currentState.id}' has no dependencies!`);
        }

        throw new Error('Cannot change state, no vortex product loaded!');
    }

    async performAction(action: Action, pauseAnimations = false): Promise<void> {
        const promises: Promise<any>[] = [];
        this.animationManager.cleanAnimations();

        if (pauseAnimations) {
            this.animationManager.stopAnimations();
        }

        // add animation
        if (action.animation !== undefined) {
            promises.push(action.animation.start());
            this.pushAnimation(action.animation);
        }

        // add lookAt
        if (action.cameraOptions && action.cameraOptions.targetOrientation) {
            promises.push(this.lookAtAngle(action.cameraOptions.targetOrientation.azimuthAngle,
                action.cameraOptions.targetOrientation.polarAngle,
                action.cameraOptions.duration,
                action.cameraOptions.easingFunction));
        }

        // add focus
        if (action.cameraOptions && action.cameraOptions.geometrySize) {
            const actualSize: Size3D = {
                width: action.cameraOptions.geometrySize.width * this.normalizationFactor,
                height: action.cameraOptions.geometrySize.height * this.normalizationFactor,
                depth: action.cameraOptions.geometrySize.depth * this.normalizationFactor,
            };

            promises.push(this.camera.adjustFocus(actualSize, action.cameraOptions.duration));
        }

        // Perform model actions (i.e. centering)
        if (action.modelOptions && this.currentGeometry) {
            if (action.modelOptions.center) {
                // Load the bounds from the initial and target states
                const centerOptions: CenterAnimationOptions = {
                    duration: action.modelOptions.duration,
                    initialBounds: action.modelOptions.center.initialBounds,
                    targetBounds: action.modelOptions.center.targetBounds,
                };

                const centerAnimation = new CenterAnimation(this.currentGeometry, centerOptions);
                promises.push(centerAnimation.start());
                this.pushAnimation(centerAnimation);
            }
        }

        await Promise.all(promises);

        if (pauseAnimations) {
            this.animationManager.resumeAnimations();
        }
    }

    pushAnimation(animation: Animation): Animation {
        return this.animationManager.pushAnimation(animation);
    }

    updateAnimations(delta: number) {
        this.animationManager.update(delta);
    }

    updateWebGlOptions(options: Partial<WebGlOptions>) {
        if (!this.webGlOptions.renderBoundingBox && options.renderBoundingBox && this.currentGeometry) {
            // toggling render of bounding box on. false or undefined => true

            if (!this.boxHelper) {
                this.boxHelper = new BoxHelper(this.boundingBox ?? this.currentGeometry, new Color(0x000000));
            }

            // add bounding box object to scene for it to be rendered
            this.scene.add(this.boxHelper);
        } else if (this.webGlOptions.renderBoundingBox && options.renderBoundingBox == false) {
            // toggling render of bounding box off. true => false
            // remove render object from scene
            this.boxHelper && this.scene.remove(this.boxHelper);
        }

        this.webGlOptions = Object.assign(this.webGlOptions, options);
    }

    updateBoundingBox() {
        if (this.vortexProduct) {
            const currentState = this.vortexProduct.getCurrentState();
            if (currentState.boundingBox) {
                this.boundingBox = buildBoundingBoxGeometry(currentState.boundingBox, this.normalizationFactor);
                this.boxHelper?.setFromObject(this.boundingBox);
                this.productRequiresUpdate = true;
            }
        }
    }

    /**
     * Use with caution in production environments as this will cause the canvas to freeze for
     * as long as it takes to screenshot
     * @returns DataUri of the canvas
     */
    screenCapture(outputType = 'image/png'): string {
        this.holdRenderForUpdate = true;
        this.renderer.render(this.scene, this.camera.threeJsCamera);
        const resp = this.canvas.toDataURL(outputType);
        this.holdRenderForUpdate = false;
        return resp;
    }

    /**
     * Dispose the product and flush all textures
     */
    dispose(): void {
        this.vortexProduct && this.vortexProduct.dispose();

        this.renderer.dispose();
        this.canvasResizeObserver.disconnect();

        // Flush any cached materials associated to the product
        buildSubstrate.flush();
        buildFinish.flush();

        // Remove canvas interactions
        this.canvas.removeEventListener('click', this.boundClickEvent, false);
        this.canvas.removeEventListener('mousemove', this.boundMoveEvent, false);
        this.lastIntersectingSurfaceId = undefined;

        this.camera.dispose();
        cancelAnimationFrame(this.rafId);
        this.animationManager.cancelAnimations();
    }

    private render = () => {
        const delta = this.clock.getDelta();

        this.camera.update(delta);

        // used to completely stop rendering new frames
        if (!this.holdRenderForUpdate) {
            // if nothing has changed in the preview then don't render a new frame and waste compute
            if (this.camera.requiresUpdate || this.productRequiresUpdate || this.animationManager.requiresUpdate) {
                // Update animations and ensure they got locked at their final state on the current frame before rendering
                this.updateAnimations(delta);

                // Actually tells the webGlRenderer to re-draw the scene
                this.renderer.render(this.scene, this.camera.threeJsCamera);

                // Update canvas shadows if they are enabled
                if (this.environment?.shadowOptions?.canvasShadows?.enabled) {
                    updateCanvasShadows(this.canvas, this.environment.shadowOptions.canvasShadows, this.camera);
                }

                // Allow optional updates of the vortex product when rendering
                if (this.vortexProduct && this.vortexProduct.sync) {
                    this.vortexProduct.sync({ preview: this });
                }

                // update bounding box if enabled
                if (this.webGlOptions?.renderBoundingBox) {
                    this.boxHelper?.update();
                }

                this.productRequiresUpdate = false;
            }
        }

        // Call back to render the next frame
        this.rafId = requestAnimationFrame(this.render);
    }

    private observeResize() {
        if (this.canvasResizeObserver) {
            this.canvasResizeObserver.disconnect();
        }

        this.canvasResizeObserver.observe(this.canvas);
    }

    private onClick(event: MouseEvent) {
        const cursorPosition: Vector2 = normalizePosition(event.clientX, event.clientY, this.canvas);

        const surfaceId: string | undefined = TraceIntersection(cursorPosition, this.camera.threeJsCamera, this.scene);

        if (surfaceId && Object.keys(this.eventMap).includes('click')) {
            this.triggerEvents('click', surfaceId);
        }
    }

    private onMouseMove(event: MouseEvent) {
        const cursorPosition: Vector2 = normalizePosition(event.clientX, event.clientY, this.canvas);

        const intersectingSurfaceId: string | undefined = TraceIntersection(cursorPosition, this.camera.threeJsCamera, this.scene);

        // If there was an existing hit check that its still the same or trigger actions
        if (this.lastIntersectingSurfaceId) {
            if (intersectingSurfaceId) {
                // The two intersections are for different surfaces
                if (intersectingSurfaceId !== this.lastIntersectingSurfaceId) {
                    if (Object.keys(this.eventMap).includes('mouseout')) {
                        this.triggerEvents('mouseout', this.lastIntersectingSurfaceId);
                    }

                    if (Object.keys(this.eventMap).includes('mouseover')) {
                        this.triggerEvents('mouseover', intersectingSurfaceId);
                    }
                }
            } else if (Object.keys(this.eventMap).includes('mouseout')) {
                // The last surface id is no longer selected
                this.triggerEvents('mouseout', this.lastIntersectingSurfaceId);
            }
        } else if (intersectingSurfaceId && Object.keys(this.eventMap).includes('mouseover')) {
            // The the interaction just started
            this.triggerEvents('mouseover', intersectingSurfaceId);
        }

        this.lastIntersectingSurfaceId = intersectingSurfaceId;
    }

    private triggerEvents(type: string, surfaceId: string) {
        for (let i = 0; i < this.eventMap[type].length; i++) {
            const event: VortexEvent = {
                preview: this,
                type,
                surfaceId,
                srcElement: this.canvas,
            };

            this.eventMap[type][i](event);
        }
    }

    // Loads the geometry and normalizes the scale to a specific max dimension
    private async loadGeometry(vortexProduct: VortexProduct): Promise<Group> {
        const geometry: Group = await vortexProduct.getGeometry();

        // Build a mapping for each mesh to it's uuid
        this.meshMap = BuildMeshMap(geometry);

        const states: ProductState[] = vortexProduct.getStates();
        const initialStateId: string = vortexProduct.getCurrentState().id;

        // Get the bounds of each state after loading
        const stateBounds: { [key: string]: Box3 } = await getStateBounds(this, vortexProduct, geometry);

        let maximumStateVolume = 0;
        let maximumGeometryBounds: Vector3 = new Vector3();

        // Find the state with the maximum volume
        for (const state of states) {
            // Ignore the state if it shouldn't be used for bounds
            // eslint-disable-next-line no-continue
            if (this.productLoadingOptions?.scaleToInitialState && state.id !== initialStateId) continue;

            // Load the current state bounds
            const bounds: Box3 = stateBounds[state.id];

            // Compute the volume of the state bounds
            const stateSize: Vector3 = bounds.getSize(new Vector3());
            const stateVolume: number = stateSize.x * stateSize.y * stateSize.z;

            // If this state's volume is greater mark it as the maximum bounds
            if (stateVolume > maximumStateVolume) {
                maximumStateVolume = stateVolume;
                maximumGeometryBounds = stateSize.clone();
            }
        }

        // Normalize to the maximum geometry size
        const maxDimension = Math.max(maximumGeometryBounds.x, maximumGeometryBounds.y, maximumGeometryBounds.z);
        const normalizationFactor = MAX_GEOMETRY_DIMENSION / maxDimension;
        this.normalizationFactor = normalizationFactor;

        // Scale and position the geometry based on the normalized factor
        geometry.scale.multiplyScalar(normalizationFactor);
        geometry.position.multiplyScalar(normalizationFactor);

        // Compute the maximum geometry size (this can be used later if the canvas size changes)
        this.maximumGeometrySize = {
            width: maximumGeometryBounds.x * normalizationFactor,
            height: maximumGeometryBounds.y * normalizationFactor,
            depth: maximumGeometryBounds.z * normalizationFactor,
        };

        // Set the camera to the new orbit radius based on size
        this.camera.computeOrbitRadius(this.maximumGeometrySize);

        return geometry;
    }

    private async initializeBackground(): Promise<void> {
        if (this.environment.background?.textureUrl) {
            this.scene.background = await loadTextureFromUri(this.environment.background.textureUrl);
        } else if (this.environment.background?.color) {
            this.scene.background = new Color(this.environment.background.color);
        }
    }

    private async textureGeometry(): Promise<void> {
        // First dispose of any existing materials in the map
        Object.keys(this.materialMap).forEach((materialKey) => {
            const material: MeshPhongMaterial = this.materialMap[materialKey];

            Object.keys(material).forEach((key) => {
                const value = material[key];

                if (value instanceof Texture) {
                    value.dispose();
                }
            });
        });

        // Clear the map
        this.materialMap = {};
        this.hightLightMap = {};

        // Add updated materials to the map
        if (this.vortexProduct && this.currentGeometry && this.meshMap) {
            const surfaceMap: { [key: string]: ProductSurface; } = this.vortexProduct.getSurfaceMapping();

            await this.buildMaterialMap(surfaceMap);

            // Texture each mesh defined in the vortex product
            Object.keys(this.meshMap).forEach((meshId) => {
                if (!this.meshMap) return;

                let material: MeshPhongMaterial;

                if (this.materialMap[meshId]) {
                    material = this.materialMap[meshId];
                } else if (this.materialMap.primary) {
                    material = this.materialMap.primary;
                } else {
                    throw new Error(`No product surface was defined for mesh: ${meshId}`);
                }

                // morphTarget meshes need morphTarget flag in material to be true to render animations correctly
                // morphTargetsRelative is a boolean but might also be undefined
                if ((this.meshMap[meshId].geometry as BufferGeometry).morphTargetsRelative) {
                    material.morphTargets = true;
                }

                this.meshMap[meshId].material = material;
            });
        }
    }

    private async buildMaterialMap(surfaceMap: { [key: string]: ProductSurface; }) {
        const materialPromises: Promise<void>[] = [];

        // Build the substrate and/or finish for every product surface
        Object.keys(surfaceMap).forEach((surfaceKey) => {
            const productSurface: ProductSurface = surfaceMap[surfaceKey];

            materialPromises.push(this.loadAndMapSubstrate(surfaceKey, productSurface));

            if (productSurface.finishes) {
                productSurface.finishes.forEach((finish) => {
                    const finishKey = `${surfaceKey}-${finish.name}`;

                    materialPromises.push(this.loadAndMapFinish(finishKey, finish, productSurface));
                });
            }
        });

        await Promise.all(materialPromises);
    }

    private async loadAndMapSubstrate(key: string, surface: ProductSurface) {
        if (this.vortexProduct && this.renderingClient && this.environment) {
            let documentImageUrl: string | undefined;

            if (surface.page) {
                const documentKey = `${surface.page} none`;

                if (!this.documentRasterUrls[documentKey]) {
                    throw new Error(`No document information exists for page ${surface.page}!`);
                }

                documentImageUrl = this.documentRasterUrls[documentKey];
            }

            const material = await buildSubstrate(documentImageUrl, surface, this.vortexProduct, this.renderingClient, this.environment);

            // preserve skin material for SkinnedMesh models with Bones
            if (this.meshMap?.[key] instanceof SkinnedMesh) {
                // TODO: We need to recheck this and use correct types
                // @ts-ignore
                material.skinning = this.meshMap[key].material.skinning ?? false;
            }

            this.materialMap[key] = material.clone();
            this.hightLightMap[key] = material.color.clone();
        }
    }

    private async loadAndMapFinish(key: string, finish: Finish, surface: ProductSurface) {
        if (this.vortexProduct && this.renderingClient && this.environment) {
            if (!surface.page) {
                throw new Error(`A page number must exist in the surface map to show to show finish: '${finish.channel}'!`);
            }

            // Get the document and finish keys
            const documentKey = `${surface.page} none`;
            const finishKey = `${surface.page} ${finish.channel}`;

            if (!this.documentRasterUrls[documentKey]) {
                throw new Error(`No document information exists for page ${surface.page}!`);
            }

            if (!this.documentRasterUrls[finishKey]) {
                throw new Error(`No finish information exists on page ${surface.page} for finish '${finish.channel}'!`);
            }

            const documentImageUrl: string = this.documentRasterUrls[documentKey];
            const finishMaskUrl: string = this.documentRasterUrls[finishKey];

            const material = await buildFinish(documentImageUrl, finishMaskUrl, finish, surface, this.vortexProduct, this.renderingClient, this.environment);

            // preserve skin material for SkinnedMesh models with Bones
            if (this.meshMap?.[key] instanceof SkinnedMesh) {
                // TODO: We need to recheck this and use correct types
                // @ts-ignore
                material.skinning = this.meshMap[key].material.skinning ?? false;
            }

            this.materialMap[key] = material.clone();
            this.hightLightMap[key] = material.color.clone();
        }
    }

    private createActionFromState(
        product: VortexProduct,
        current: ProductState,
        target: ProductState,
        duration: number,
        reverse: boolean,
        autoCenter: boolean,
        autoZoom: boolean,
    ): Action {
        const action: Action = { id: target.id };

        if (target.dependency) {
            const reverseDirection = target.dependency.animationDirection === AnimationDirection.forward ? AnimationDirection.backward
                : AnimationDirection.forward;

            const animationOptions: AnimationOptions = {
                animationId: target.dependency.animationId,
                direction: reverse ? reverseDirection : target.dependency.animationDirection,
                duration,
            };

            action.animation = product.buildAnimation(animationOptions);

            // Auto-center the model if required. Take into account animation direction for determining
            // the target and initial state locations
            if (autoCenter && this.stateBounds[current.id] && this.stateBounds[target.id]) {
                action.modelOptions = {
                    duration,
                    center: {
                        initialBounds: reverse ? this.stateBounds[target.id] : this.stateBounds[current.id],
                        targetBounds: reverse ? this.stateBounds[current.id] : this.stateBounds[target.id],
                    },
                };
            }

            // Auto-center the model if required. Take into account animation direction for determining
            // the target and initial state locations
            if (autoZoom && this.stateBounds[current.id] && this.stateBounds[target.id]) {
                const bounds: Box3 = reverse ? this.stateBounds[current.id] : this.stateBounds[target.id];

                const boundSize: Vector3 = bounds.getSize(new Vector3());

                // Ensure the state bounds are re-normalized to max geometry dimension (200) for zooming in/out
                action.cameraOptions = {
                    duration,
                    geometrySize: {
                        width: boundSize.x / this.normalizationFactor,
                        height: boundSize.y / this.normalizationFactor,
                        depth: boundSize.z / this.normalizationFactor,
                    },
                };
            }
        }

        return action;
    }

    private async changeStateRecursively(
        product: VortexProduct,
        current: ProductState,
        target: ProductState,
        duration: number,
        autoCenter: boolean,
        autoZoom: boolean,
    ): Promise<ProductState> {
        // end condition: one animation get model to target state
        if (target.dependency && target.dependency.stateId === current.id) {
            // play target state animation
            await this.performAction(this.createActionFromState(product, current, target, duration, false, autoCenter, autoZoom));
            return target;
        }
        if (current.dependency && current.dependency.stateId === target.id) {
            // play current state animation in reverse
            await this.performAction(this.createActionFromState(product, target, current, duration, true, autoCenter, autoZoom));
            return target;
        }

        // find second to the last target state
        const states = product.getStates();
        let newTarget = states.find((state) => state.id === target.dependency?.stateId);

        // TODO: It seems we can improve this one with a recursive function maybe?
        if (!newTarget || newTarget.dependency?.stateId !== target.id) {
            let dependencyState = current;
            // eslint-disable-next-line no-constant-condition
            while (true) {
                // eslint-disable-next-line no-loop-func
                const middleState = states.find((state) => state.id === dependencyState.dependency?.stateId);
                if (middleState) {
                    if (middleState.dependency?.stateId === target.id) {
                        newTarget = middleState;
                        break;
                    }
                    dependencyState = middleState;
                } else {
                    break;
                }
            }
        }

        if (!newTarget) {
            throw new Error('Cannot find state stated in dependency');
        }

        // call recursively with new target state to get new current state
        const newCurrent = await this.changeStateRecursively(product, current, newTarget, duration, autoCenter, autoZoom);

        // play animation to reach target state
        if (target.dependency && target.dependency.stateId === newCurrent.id) {
            // play target state animation
            await this.performAction(this.createActionFromState(product, current, target, duration, false, autoCenter, autoZoom));
            return target;
        } if (newCurrent.dependency && newCurrent.dependency.stateId === target.id) {
            // play current state animation in reverse
            await this.performAction(this.createActionFromState(product, current, newCurrent, duration, true, autoCenter, autoZoom));
            return target;
        }

        throw new Error('Did not play animation to move on to next state');
    }
}
