import { Substrate } from './types';
import { CompositeMode } from '../common';

export function getWoodSubstrate(): Substrate {
    return {
        color: 0xFFFFFF,
        shininess: 40,
        baseCompositeMode: CompositeMode.multiply,
        baseTexture: {
            dpi: 40,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/wood/v1/base',
            overlayColor: '#d39c66',
        },
        bumpScale: 0.5,
        bumpTexture: {
            dpi: 40,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/wood/v1/base',
        },
    };
}
