/* eslint-disable no-param-reassign */
import {
    BoxGeometry,
    BufferGeometry,
    Geometry,
    Intersection,
    Mesh,
    Object3D,
    PerspectiveCamera,
    PlaneGeometry,
    Raycaster,
    Scene,
    Vector2,
    Vector3,
} from 'three';

const raycaster = new Raycaster();

export interface Position2D {
    x: number;
    y: number;
}

export interface Position3D {
    x: number;
    y: number;
    z: number;
}

export interface Rotation3D {
    yaw: number;
    pitch: number;
    roll: number;
}

// Traces a canvas position to geometry in the scene
export function TraceIntersection(position: Vector2, camera: PerspectiveCamera, scene: Scene): string | undefined {
    // Update the ray caster to the current position
    raycaster.setFromCamera(position, camera);

    // Recursively find all intersections from the casted ray
    const intersects: Intersection[] = raycaster.intersectObjects(scene.children, true);

    // Take the first result (closest) if any exist
    if (intersects.length > 0) {
        return intersects[0].object.uuid;
    }

    return undefined;
}

// Traces a canvas position to geometry in the scene
export function TraceIntersectionFromPosition(position: Vector3, direction: Vector3, scene: Scene): string | undefined {
    // Update the ray caster to the current position
    raycaster.set(position, direction);

    // Recursively find all intersections from the casted ray
    const intersects: Intersection[] = raycaster.intersectObjects(scene.children, true);

    // Take the first result (closest) if any exist
    if (intersects.length > 0) {
        return intersects[0].object.uuid;
    }

    return undefined;
}

// TODO: This should make a copy and return a new geometry back instead of modifying the parameter
// Maps a specific face of a geometry to provided UV coordinates
export function UVMapFace(geometry: Geometry, faceId: number, minU: number, maxU: number, minV: number, maxV: number): void {
    // Left triangle
    geometry.faceVertexUvs[0][faceId * 2][0] = new Vector2(minU, maxV); // 0 1
    geometry.faceVertexUvs[0][faceId * 2][1] = new Vector2(minU, minV); // 0 0
    geometry.faceVertexUvs[0][faceId * 2][2] = new Vector2(maxU, maxV); // 1 1

    // Right triangle
    geometry.faceVertexUvs[0][faceId * 2 + 1][0] = new Vector2(minU, minV); // 0 0
    geometry.faceVertexUvs[0][faceId * 2 + 1][1] = new Vector2(maxU, minV); // 1 0
    geometry.faceVertexUvs[0][faceId * 2 + 1][2] = new Vector2(maxU, maxV); // 1 1
}

// Maps a plane with provided UV coordinates
export function UVMapPlane(geometry: PlaneGeometry, minU: number, maxU: number, minV: number, maxV: number): void {
    return UVMapFace(geometry, 0, minU, maxU, minV, maxV);
}

// Maps each face of a box normalized to it's largest dimension
export function UVMapBox(geometry: BoxGeometry): void {
    const maxDimension = Math.max(geometry.parameters.width, geometry.parameters.height, geometry.parameters.depth);

    // Left & right faces
    UVMapFace(geometry, 0, 0, geometry.parameters.depth / maxDimension, 0, geometry.parameters.height / maxDimension);
    UVMapFace(geometry, 1, 0, geometry.parameters.depth / maxDimension, 0, geometry.parameters.height / maxDimension);

    // Top & bottom faces
    UVMapFace(geometry, 2, 0, geometry.parameters.width / maxDimension, 0, geometry.parameters.depth / maxDimension);
    UVMapFace(geometry, 3, 0, geometry.parameters.width / maxDimension, 0, geometry.parameters.depth / maxDimension);

    // Front & back faces
    UVMapFace(geometry, 4, 0, geometry.parameters.width / maxDimension, 0, geometry.parameters.height / maxDimension);
    UVMapFace(geometry, 5, 0, geometry.parameters.width / maxDimension, 0, geometry.parameters.height / maxDimension);
}

// UV maps and offsets a shaped geometry defined with all coordinates relative to 0,0 as bottom left
export function UVMapOffsetShape(geometry: Geometry, width: number, height: number, offset: Vector2): void {
    for (let i = 0; i < geometry.faceVertexUvs.length; i++) {
        for (let j = 0; j < geometry.faceVertexUvs[i].length; j++) {
            for (let k = 0; k < geometry.faceVertexUvs[i][j].length; k++) {
                geometry.faceVertexUvs[i][j][k].setX((geometry.faceVertexUvs[i][j][k].x - offset.x) / width);
                geometry.faceVertexUvs[i][j][k].setY((geometry.faceVertexUvs[i][j][k].y - offset.y) / height);
            }
        }
    }
}

// UV maps a shaped geometry defined with all coordinates relative to 0,0 as bottom left
export function UVMapShape(geometry: Geometry, width: number, height: number): void {
    UVMapOffsetShape(geometry, width, height, new Vector2());
}

// Recursively disposes geometry in a provided object
export function disposeGeometryRecursively(object3D: Object3D): void {
    if (!object3D) return;

    // The object has children that need disposable
    if (object3D.children) {
        object3D.children.forEach((child) => disposeGeometryRecursively(child));
    }

    // The object is a mesh and can have two types of disposal based on type
    if (object3D instanceof Mesh) {
        if (object3D.geometry instanceof Geometry) {
            object3D.geometry.dispose();
        } else if (object3D.geometry instanceof BufferGeometry) {
            object3D.geometry.dispose();
        }
    }
}

function buildMeshMapRecursively(meshMap: { [key: string]: Mesh }, object3D: Object3D) {
    if (!object3D) return;

    // The object has children that need to be added recursively
    if (object3D.children) {
        object3D.children.forEach((child) => buildMeshMapRecursively(meshMap, child));
    }

    if (object3D instanceof Mesh) {
        meshMap[object3D.uuid] = object3D;
    }
}

// Builds a mapping of each surface id to mesh object in a scene
export function BuildMeshMap(geometry: Object3D): { [key: string]: Mesh } {
    const meshMap: { [key: string]: Mesh } = {};

    buildMeshMapRecursively(meshMap, geometry);

    return meshMap;
}
