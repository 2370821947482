import { Substrate } from './types';
import { CompositeMode } from '../common';

export function getExtraSoftRed(): Substrate {
    return {
        color: '#a60f30',
        shininess: 20,
        bumpScale: 0.2,
        baseCompositeMode: CompositeMode.multiply,
        bumpTexture: {
            dpi: 300,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/matte/v1/bump',
        },
    };
}
