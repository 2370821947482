/* eslint-disable @typescript-eslint/explicit-function-return-type */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ReactNode, useState, FocusEvent, ChangeEvent } from 'react';
import cx from 'classnames';

import { Theme, useTheme } from '../theme';

export interface InputProps {
    value?: string | number;
    onChange(value: string | number): void;
    onKeyPress?(event: React.KeyboardEvent<HTMLInputElement>): void;
    onFocus?: (event?: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
    prefixLabel?: string | ReactNode;
    suffixLabel?: string | ReactNode;
    labelledBy?: string;
    label?: string;
    placeholder? : string;
    className?: string;
    type?: 'text' | 'number' | 'date' | 'url';
}

function getBaseStyling(focused: boolean, theme: Theme) {
    let borderColor = '#8090A2';
    const backgroundColor = theme.primaryBackgroundColor;

    if (focused) {
        borderColor = theme.primaryColor;
    }

    const isModified = focused;

    return css`
        display: inline-flex;
        height: 26px;
        width: 100%;
        box-sizing: border-box;
        align-items: baseline;
        border: 1px solid ${borderColor};
        border-radius: 2px;
        font-size: 11px;
        background-color: ${backgroundColor};

        &:hover {
            border-color: ${isModified ? borderColor : theme.primaryColor};
        }
    `;
}

const inputStyle = css`
    display: block;
    flex: auto;
    margin: auto;
    position: relative;
    height: 16px;
    line-height: 16px;
    box-sizing: border-box;
    border-radius: 2px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: rgba(255,255,255,0); // rgba used to workaound Safari bug https://bugs.webkit.org/show_bug.cgi?id=115510
    border: 1px solid transparent;
    font-family: inherit;
    font-size: inherit;
    -moz-appearance: textfield;
    -webkit-appearance: none;

    &:hover, &:focus {
        -moz-appearance: number-input;
        outline: none;
    }

    &::placeholder {
        color: #8090A2;
    }
`;

function getLabelStyle() {
    return css`
        align-self: stretch;
        display: flex;
        align-items: center;
        line-height: 15px;
        width: auto;
        color: '#8090A2';
        padding-left: 8px;
    `;
}
/**
 * This is a simple styled Input Component
 */
export function Input({
    value,
    onChange,
    onKeyPress,
    onFocus,
    onBlur,
    className,
    prefixLabel,
    suffixLabel,
    labelledBy,
    label,
    placeholder,
    ...restOfProps
}: InputProps): JSX.Element {
    const classes = cx('ss-input', className);
    const theme = useTheme();
    const [focused, setFocused] = useState(false);

    const wrappedOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.type === 'number') {
            onChange(+e.currentTarget.value as number);
        } else {
            onChange(e.currentTarget.value as string);
        }
    };

    return (
        <div
            data-testid='ss-input'
            className={classes}
            css={getBaseStyling(focused, theme)}
        >
            {prefixLabel && (
                <label
                    data-testid='ss-input__prefix'
                    className='ss-input__prefix'
                    css={getLabelStyle()}
                >
                    {prefixLabel}
                </label>
            )}
            <input
                data-testid='ss-input__input'
                className='ss-input__input'
                css={inputStyle}
                style={{ color: '#000000' }}
                value={value}
                onChange={wrappedOnChange}
                onKeyPress={onKeyPress}
                {...restOfProps}
                onFocus={(event) => {
                    setFocused(true);
                    if (onFocus) {
                        onFocus(event);
                    }
                }}
                onBlur={(event) => {
                    setFocused(false);
                    if (onBlur) {
                        onBlur(event);
                    }
                }}
                placeholder={placeholder}
                aria-label={typeof label === 'string' ? label : undefined}
                aria-labelledby={typeof labelledBy === 'string' ? labelledBy : undefined}
            />
            {suffixLabel && (
                <label
                    data-testid='ss-input__suffix'
                    className='ss-input__suffix'
                    css={css`
                        ${getLabelStyle()}`}
                >
                    {suffixLabel}
                </label>
            )}
        </div>
    );
}
