import translationEN from './locales/en-US/translation.json';
import translationIT from './locales/it-IT/translation.json';
import translationNL from './locales/nl-NL/translation.json';
import translationDE from './locales/de-DE/translation.json';

export const resources = {
    'en-US': {
        translation: translationEN,
    },
    'it-IT': {
        translation: translationIT,
    },
    'nl-NL': {
        translation: translationNL,
    },
    'de-DE': {
        translation: translationDE,
    },
};
