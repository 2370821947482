import { CompositeMode } from '../common';

export default {
    color: 0xFFFFFF,
    emissive: 0x0,
    shininess: 20,
    baseCompositeMode: CompositeMode.normal,
    bumpScale: 0,
    fullyTransparent: false,
    supportsTransparency: false,
};
