/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import { ReactNode, DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import cx from 'classnames';

import { useTheme, Theme } from '../../theme';
import { isNumber } from '../../utility/isNumber';

export interface IconButtonProps
    extends DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    children?: ReactNode | ReactNode[];
    size?: number | string;
}

function getButtonStyle(theme: Theme, size: string): SerializedStyles {
    return css`
        background: transparent;
        padding: 5px 5px;
        font-size: 14px;
        border: 1px solid transparent;
        border-radius: 2px;
        font-weight: 600;
        cursor: pointer;
        vertical-align: middle;
        color: ${theme.primaryColor};

        &[disabled] {
            cursor: default;
            img,
            span {
                color: ${theme.inactiveColor};
            }
        }

        img {
            height: ${size};
            width: ${size};
            color: ${theme.primaryColor};
        }

        &:hover:not([disabled]) {
            img,
            span {
                color: ${theme.primaryHoverColor};
            }
        }

        &:active:not([disabled]) {
            img,
            span {
                color: ${theme.primaryActiveColor};
            }
        }

        &:focus {
            outline: none;
            img,
            span {
                color: ${theme.primaryHoverColor};
            }
        }
    `;
}

export function IconButton({
    children,
    disabled = false,
    size = 24,
    className,
    ...restOfProps
}: IconButtonProps): JSX.Element {
    const theme = useTheme();
    const sizeString = isNumber(size) ? `${size}px` : size.toString();
    return (
        <button
            data-testid='dsc-icon-button'
            className={cx('dsc-icon-button', className)}
            css={getButtonStyle(theme, sizeString)}
            disabled={disabled}
            {...restOfProps}
        >
            {children}
        </button>
    );
}
