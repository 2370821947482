import { Size2D } from '@rendering/vortex-core/common';
import { Finish } from '@rendering/vortex-core/finishes';
import { ProductTrim } from '@rendering/vortex-core/products';
import { Substrate } from '@rendering/vortex-core/substrates';

export interface NFoldOptions {
    foldDirections: FoldDirection[][];
    horizontalRatios: number[];
    verticalRatios: number[];
    rotationOffset?: RotationOffset;
}

export interface RotationOffset {
    yaw: number;
    pitch: number;
    roll: number;
}

export interface FoldDirection {
    ratioIndex: number;
    angleOpen: number;
    angleClosed: number;
    edge: PanelEdge
}

export enum PanelEdge {
    left,
    right,
    top,
    bottom
}

export enum ProductRotation {
    none,
    rotate_90,
    rotate_270
}

export interface FoldedProductConfig {
    nFoldOptions: NFoldOptions;
    productSurfaces: {
        front: NFoldSurface,
        back: NFoldSurface
    };
    fullBleed: Size2D;
    trim?: ProductTrim;
    depth: number;
    rotation?: ProductRotation
}

export interface NFoldSurface {
    substrate: Substrate;
    finishes?: Finish[];
    page?: number;
}