import { AnimationAction, AnimationClip, AnimationMixer, LoopOnce } from 'three';
import { Animation, AnimationDirection } from '@rendering/vortex-core/animations';

export interface ModelAnimationOptions {
    direction: AnimationDirection;
    duration?: number;
}

export class ModelAnimation implements Animation {
    private completionStatus: boolean = false;
    private activeStatus: boolean = false;
    private canceledStatus: boolean = false;

    private myTags: string[];

    private mixer: AnimationMixer;
    private animation: AnimationAction;
    private options: ModelAnimationOptions;

    private animationFinished: Promise<void>;

    constructor(mixer: AnimationMixer, clip: AnimationClip, options: ModelAnimationOptions, tags?: string[]) {
        this.mixer = mixer;
        this.animation = this.buildAnimation(mixer, clip, options.duration);
        this.options = options;
        this.myTags = tags || [];

        this.animationFinished = new Promise<void>((resolve, _) => {
            this.mixer.addEventListener('finished', () => {
                this.completionStatus = true;
                this.activeStatus = false;
                resolve();
            });
        });
    }

    get isComplete(): boolean {
        return this.completionStatus
    }

    get isActive(): boolean {
        return this.activeStatus;
    }

    get tags(): string[] {
        return this.myTags;
    }

    get isCanceled(): boolean {
        return this.canceledStatus;
    }

    resetMeshState() {
        this.animation.reset();
    }

    update(delta: number) {
        this.mixer.update(delta);
    }

    start(): Promise<void> {
        this.activeStatus = true;

        if (this.options.direction === AnimationDirection.forward) {
            this.playAnimationForward();
        } else {
            this.playAnimationBackward();
        }

        return this.animationFinished;
    }

    stop() {
        this.animation.paused = true;
        this.activeStatus = false;
    }

    cancel() {
        this.canceledStatus = true;
        this.resetMeshState();
    }

    resume() {
        this.activeStatus = true;
        this.animation.paused = false;
    }

    private buildAnimation(mixer: AnimationMixer, clip: AnimationClip, duration?: number) {
        const animation = mixer.clipAction(clip);

        if (duration !== undefined) {
            animation.setDuration(duration);
        }

        animation.setLoop(LoopOnce, 1);
        animation.play();

        return animation;
    }

    private playAnimationForward() {
        this.resetMeshState();
        this.animation.setEffectiveTimeScale(Math.abs(this.animation.timeScale));
        this.animation.clampWhenFinished = true;
    }

    private playAnimationBackward() {
        if (this.animation.time === 0) {
            this.animation.time = this.animation.getClip().duration;
        }

        this.resume();
        this.animation.setEffectiveTimeScale(-Math.abs(this.animation.timeScale));
    }
}