import { parseSync, stringify } from 'svgson';

export interface Attributes {
  'stroke-width': string;
  stroke: string;
  fill: string;
}

const round = (num: number, places: number) => {
    let roundedValue;
    if (places > 0) {
        roundedValue = num.toFixed(places);
    } else if (places === 0) {
        roundedValue = Math.round(num);
    } else {
        roundedValue = parseFloat(`${roundedValue}${Math.round(parseFloat(`${num}e-${-places}`))}e+${-places}`);
    }

    return roundedValue || 0;
};

// for static svg we are calculating stroke width from view Box dimensions
export const getStrokeWidthByViewBox = (svgString: string) => {
    // Extracting viewBox width and height
    const parsedSVG = parseSync(svgString);
    const viewBox = parsedSVG.attributes['viewBox'];
    const viewBoxDimensions = viewBox.split(" ");
    const width = parseInt(viewBoxDimensions[viewBoxDimensions.length - 2]);
    const height = parseInt(viewBoxDimensions[viewBoxDimensions.length - 1]);
    // If the smaller dimension is much smaller than the larger, then the strokeWidth will still be too small
    let minSize = Math.min(width, height);
    const maxSize = Math.max(width, height);
    minSize = Math.max(minSize, maxSize * 0.33);
    // 0.5% of the smallest dimension yields a good line thickness
    return round(minSize * 0.005, 3).toString();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addAttributes = (children: any, attributes: Attributes) => {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
    function addAttrsToChildren(children: any) {
        if (children.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            children.forEach((child: any) => {
                const elementAttributes = child.attributes;
                if(elementAttributes) {
                    for (const [key, value] of Object.entries(attributes)) {
                        child.attributes[key] = value;
                    }
                }
                addAttrsToChildren(child);
            });
        }
    }

    addAttrsToChildren(children);
}

export function modifySVG(svgString: string, attrs: Attributes) {
    if (svgString) {
        const parsedSVG = parseSync(svgString);
        delete parsedSVG.attributes.width;
        delete parsedSVG.attributes.height;
        if(parsedSVG.children) {
            addAttributes(parsedSVG.children, attrs);
        }

        return stringify(parsedSVG);
    }
    return '';
}
