import { Light, PointLight } from 'three';

/**
 * Creates an array of generic point lights
 * @param intensities array of scalars for point lights, must be of length 6. Front, Back, Left, Right, Top, Bottom
 * @returns An array of THREE lights
 */
export function buildPointLightGrid(intensities: number[]): Light[] {
    if (intensities.length !== 6) {
        throw new Error('Must provide 6 point light intensities!');
    }

    const lights: Light[] = [];

    const frontLight = new PointLight(0xffffff, intensities[0], 500);
    frontLight.position.set(0, 0, 300);

    const backLight = new PointLight(0xffffff, intensities[1], 500);
    backLight.position.set(0, 0, -300);

    const leftLight = new PointLight(0xffffff, intensities[2], 500);
    leftLight.position.set(300, 0, 0);

    const rightLight = new PointLight(0xffffff, intensities[3], 500);
    rightLight.position.set(-300, 0, 0);

    const topLight = new PointLight(0xffffff, intensities[4], 500);
    topLight.position.set(0, -300, 0);

    const bottomLight = new PointLight(0xffffff, intensities[5], 500);
    bottomLight.position.set(0, 300, 0);

    lights.push(frontLight);
    lights.push(backLight);

    lights.push(leftLight);
    lights.push(rightLight);

    lights.push(topLight);
    lights.push(bottomLight);

    return lights;
}
