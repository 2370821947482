/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';
import { Tooltip } from '@cimpress/react-components';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getNumberOfAllowedDescriptionRows } from '../helper';

// eslint-disable-next-line react/prefer-stateless-function
class DetailsSection extends PureComponent {
  render() {
    const numberOfAllowedRows = getNumberOfAllowedDescriptionRows(
      this.props.size,
      this.props.orientation,
    );
    const [udf1, udf2, udf3] = this.props.productDetails;
    const { loading } = this.props;

    const renderProductDetailsKey = (udf) => {
      const key = get(udf, 'key', '');
      return <span className="attribute">{key} {key === '' ? '' : ':'}</span>;
    };

    return (
      <div className="contentArea">
        <div className={`truncateText twoLine ${loading && 'animated-background'}`}>
          <a
            href={
              !isEmpty(this.props.hyperlink) ? this.props.hyperlink : undefined
            }
            role="button"
            tabIndex="0"
            onClick={this.props.onNameClick}
          >
            {this.props.size === 's' ? (
              this.props.productName
            ) : (
              <Tooltip contents={this.props.productName}>
                <Dotdotdot clamp={2}>{this.props.productName}</Dotdotdot>
              </Tooltip>
            )}
          </a>
        </div>
        {this.props.price && this.props.size === 'xl'
          ? (
            <div className="priceContainer">
              {this.props.price}
            </div>
          ) : null}
        <div className="productDetailsList">
          {!isEmpty(udf1) || loading ? (
            <div className={`productDetail truncateText oneLine ${loading && 'animated-background animated-width-75'}`}>
              {
                this.props.size === 's' && this.props.orientation === 'vertical' ? null : renderProductDetailsKey(udf1)
              }
              <span className="value"> {get(udf1, 'value', '')}</span>
            </div>
          ) : null}

          {numberOfAllowedRows >= 2 && (!isEmpty(udf2) || loading) ? (
            <div className={`productDetail truncateText oneLine ${loading && 'animated-background animated-width-50'}`}>
              {renderProductDetailsKey(udf2)}
              <span className="value"> {get(udf2, 'value', '')}</span>
            </div>
          ) : null}
          {numberOfAllowedRows >= 3 && (!isEmpty(udf3) || loading) ? (
            <div className={`productDetail truncateText twoLine ${loading && 'animated-background animated-width-50'}`}>
              {renderProductDetailsKey(udf3)}
              <span className="value"> {get(udf3, 'value', '')}</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

DetailsSection.propTypes = {
  size: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  productDetails: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  productName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onNameClick: PropTypes.func.isRequired,
  hyperlink: PropTypes.string,
  loading: PropTypes.bool,
};

DetailsSection.defaultProps = {
  hyperlink: null,
  productName: null,
  loading: false,
};

export default DetailsSection;
