import { Group, Mesh, Light, PointLight, DirectionalLight, Vector3, Object3D } from 'three';
import { ProductState, ProductSurface, ProductTrim } from '@rendering/vortex-core/products';
import { Substrate } from '@rendering/vortex-core/substrates';
import { Size2D, RenderSize, disposeGeometryRecursively, Size3D } from '@rendering/vortex-core/common';
import { Finish } from '@rendering/vortex-core/finishes';
import { FlatGeometry, RectangularGeometry, RoundedGeometry, ScallopedGeometry, CustomPathGeometry, CustomPath } from '../flatGeometries';
import { PaperProduct } from '../common';
import { Animation, AnimationOptions } from '@rendering/vortex-core/animations';

export interface ScallopCounts {
    horizontal: number;
    vertical: number;
}

export enum FlatSheetOrientation {
    Horizontal,
    Vertical
}

export interface FlatSheetProductConfig {
    substrate?: Substrate;
    substrates?: Substrate[];

    finishes?: Finish[];

    fullBleed: Size2D;
    trim?: ProductTrim;
    depth: number;
    orientation?: FlatSheetOrientation;

    customPath?: CustomPath;
    cornerRadius?: number;
    scallopCounts?: ScallopCounts;
}

export class FlatSheetProduct implements PaperProduct {
    get name() {
        return 'flat sheet ' + this.flatGeometry.key;
    }

    requiresGeometryUpdate: boolean = false;

    private config: FlatSheetProductConfig;
    private flatGeometry: FlatGeometry;
    private initializedGeometry: Group | undefined;

    private surfaceMapping: { [key: string]: ProductSurface; };

    // Separate content and body layers by 100 micrometers
    private LayerOffset = 0.1;

    constructor(config: FlatSheetProductConfig) {
        this.config = config;

        const physicalSize = this.getPhysicalSize();

        if (config.cornerRadius && config.cornerRadius > 0) {
            this.flatGeometry = new RoundedGeometry(physicalSize, config.cornerRadius);
        } else if (config.scallopCounts) {
            this.flatGeometry = new ScallopedGeometry(physicalSize, config.scallopCounts.horizontal, config.scallopCounts.vertical);
        } else if (config.customPath) {
            this.flatGeometry = new CustomPathGeometry(config.customPath);
        } else {
            this.flatGeometry = new RectangularGeometry();
        }

        let substrateMap = {};

        // Initialize substrates
        if (this.config.substrate) {
            substrateMap = {
                'front': { ...this.config.substrate },
                'body': { ...this.config.substrate },
                'back': { ...this.config.substrate }
            };
        } else if (this.config.substrates) {
            if (this.config.substrates.length !== 3) {
                throw new Error('The FlatSheetProductConfig must contain three substrates for the front, back, and body!');
            }

            substrateMap = {
                'front': { ...this.config.substrates[0] },
                'body': { ...this.config.substrates[1] },
                'back': { ...this.config.substrates[2] }
            };
        } else {
            throw new Error('The FlatSheetProductConfig must contain a single substrate or multiple substrates!');
        }

        // Build the surface mapping
        this.surfaceMapping = {
            'front': {
                substrate: substrateMap['front'],
                finishes: this.config.finishes,
                fullBleed: this.config.fullBleed,
                trim: this.config.trim,
                page: 1,
                focalPoint: { cameraView: new Vector3(0, 0, -1) }
            },
            'body': {
                substrate: substrateMap['body'],
                fullBleed: this.config.fullBleed
            },
            'back': {
                substrate: substrateMap['back'],
                fullBleed: this.config.fullBleed,
                trim: this.config.trim,
                page: 2,
                focalPoint: { cameraView: new Vector3(0, 0, 1) }
            }
        };
    }

    setSubstrate(substrate: Substrate, surfaceId?: string): void {
        // Update a specific surface if its provided, otherwise update all of them
        if (surfaceId) {
            this.surfaceMapping[surfaceId].substrate = { ...substrate };
        } else {
            for (const mesh in this.surfaceMapping) {
                this.surfaceMapping[mesh].substrate = { ...substrate };
            }
        }

        this.resolveTransparency();
    }

    setFinishes(finishes: Finish[]): void {
        this.config.finishes = finishes;
        this.surfaceMapping['front'].finishes = finishes;
        this.requiresGeometryUpdate = true;
    }

    setCornerRadius(radius: number): void {
        this.config.cornerRadius = radius;

        if (this.config.cornerRadius && this.config.cornerRadius > 0) {
            this.flatGeometry = new RoundedGeometry(this.getPhysicalSize(), radius);
        } else {
            this.flatGeometry = new RectangularGeometry();
        }

        this.requiresGeometryUpdate = true;
    }

    setScallopCounts(horizontalCount: number, verticalCount: number): void {
        this.config.scallopCounts = { horizontal: horizontalCount, vertical: verticalCount };
        this.flatGeometry = new ScallopedGeometry(this.getPhysicalSize(), horizontalCount, verticalCount);

        this.requiresGeometryUpdate = true;
    }

    setOrientation(orientaton: FlatSheetOrientation): void {
        this.config.orientation = orientaton;
        this.requiresGeometryUpdate = true;
    }

    async getGeometry(): Promise<Group> {
        // If existing geometry already exists dispose of it
        if (this.initializedGeometry) {
            disposeGeometryRecursively(this.initializedGeometry);
        }

        const physicalSize: Size3D = this.getPhysicalSize();

        this.initializedGeometry = new Group();

        const front: Mesh = this.flatGeometry.buildFront(physicalSize);
        front.uuid = 'front';

        front.position.z = this.config.depth * 0.5 + this.LayerOffset;
        this.initializedGeometry.add(front);

        // Add premium finishes if they are configured
        if (this.config.finishes) {
            let finishDepthOffset = this.LayerOffset * 2;

            this.config.finishes.forEach(finish => {
                const frontFinish: Mesh = this.flatGeometry.buildFront(physicalSize);
                frontFinish.uuid = 'front-' + finish.name;

                frontFinish.position.z = this.config.depth * 0.5 + finishDepthOffset;
                finishDepthOffset += this.LayerOffset;

                this.initializedGeometry?.add(frontFinish);
            });
        }

        const body: Mesh = this.flatGeometry.buildBody(physicalSize);
        body.uuid = 'body';
        this.initializedGeometry.add(body);

        const back: Mesh = this.flatGeometry.buildBack(physicalSize);
        back.uuid = 'back';

        back.position.z = this.config.depth * -0.5 - this.LayerOffset;
        back.rotation.y = Math.PI;
        this.initializedGeometry.add(back);

        if (this.config.orientation === FlatSheetOrientation.Vertical) {
            this.initializedGeometry.rotateZ(Math.PI / 2);
        }

        this.resolveTransparency();
        this.requiresGeometryUpdate = false;

        return this.initializedGeometry;
    }

    getSurfaceMapping(): { [key: string]: ProductSurface; } {
        return this.surfaceMapping;
    }

    setProductSurface(surfaceId: string, productSurface: ProductSurface) {
        if (this.surfaceMapping[surfaceId]) {
            this.surfaceMapping[surfaceId] = productSurface;

            this.resolveTransparency();
        } else {
            throw new Error(`'${surfaceId}' is not a surface on this flat sheet product`);
        }
    };

    getStates(): ProductState[] {
        return [{ id: 'default' }];
    }

    setStates(productState: ProductState[]): void { }

    getCurrentState(): ProductState {
        return { id: 'default' };
    }

    setCurrentState(stateId: string) { }

    getAnimationIds(): string[] {
        return [];
    }

    buildAnimation(animationOptions: AnimationOptions): Animation {
        throw 'Method not implemented for Flat Sheet products.'
    }

    getAlphaMap(renderSize: RenderSize, physicalSize: Size2D, context: CanvasRenderingContext2D): void {
        // Clear the bg with black
        context.fillRect(0, 0, renderSize.productSize.width, renderSize.productSize.height);

        // Set the fill to white for any content drawn
        context.fillStyle = '#FFFFFF';

        // Close any paths
        context.closePath();
        context.fill();
    }

    getAreaLights(pointLightIntensity: number, directionalLightIntensity: number): Light[] {
        const lights: Light[] = [];

        // Add in directional lighting
        const frontDirection = new DirectionalLight(0xffffff, 0.12 * directionalLightIntensity);
        frontDirection.position.set(0, 0, 200);

        const backDirectional = new DirectionalLight(0xffffff, 0.12 * directionalLightIntensity);
        backDirectional.position.set(0, 0, -200);

        lights.push(frontDirection);
        lights.push(backDirectional);

        // Add in point lighting
        const frontLight = new PointLight(0xffffff, 0.25 * pointLightIntensity, 500);
        frontLight.position.set(0, 0, 300);

        const backLight = new PointLight(0xffffff, 0.25 * pointLightIntensity, 500);
        backLight.position.set(0, 0, -300);

        lights.push(frontLight);
        lights.push(backLight);

        return lights;
    }

    preLoad(): Promise<void> {
        return Promise.resolve();
    }

    dispose(): void {
        // Nothing to do
        if (!this.initializedGeometry) return;

        disposeGeometryRecursively(this.initializedGeometry);

        this.initializedGeometry = undefined;
    }

    // Creates the physical size from the provided trim if it exists, otherwise defaults to the fullbleed size
    private getPhysicalSize(): Size3D {
        if (this.config.trim) {
            return {
                width: this.config.trim.bottomRightX - this.config.trim.topLeftX,
                height: this.config.trim.bottomRightY - this.config.trim.topLeftY,
                depth: this.config.depth
            };
        }

        return { width: this.config.fullBleed.width, height: this.config.fullBleed.height, depth: this.config.depth };
    }

    // Ensures z-fighting will not cause artifacts for transparent materials
    private resolveTransparency() {
        // Ensure geometry is loaded
        if (!this.initializedGeometry || !this.surfaceMapping) return;

        // Find the front substrate
        if (this.surfaceMapping['front']) {
            const substrate: Substrate = this.surfaceMapping['front'].substrate;

            // Get the back mesh
            const backMesh: Object3D | undefined = this.initializedGeometry.children.find(element => element.uuid === 'back');

            // Toggle the back back mesh visibility based on transparency settings
            if (backMesh) {
                backMesh.visible = !substrate.supportsTransparency ?? true;
            }
        }
    }
}
