import { Finish } from './types';
import { CompositeMode, MapMode } from '../common';

export function getMetallicFinish(): Finish {
    return {
        name: 'metallic',
        channel: 'foil',
        bumpScale: 0.05,
        bumpMode: MapMode.inherit,
        shininess: 300,
        reflectivity: 0.05,
        baseCompositeMode: CompositeMode.overlay,
        baseTexture: {
            dpi: 400,
            sourceUrl: 'https://vortex.documents.cimpress.io/finishes/foil/v1/base',
        },
    };
}
