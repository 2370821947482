import { Finish } from './types';
import { CompositeMode, MapMode } from '../common';

export function getRaisedInkFinish(): Finish {
    return {
        name: 'raisedInk',
        channel: 'gloss',
        bumpScale: 0.05,
        bumpMode: MapMode.generate,
        shininess: 1000,
        reflectivity: 0.1,
        baseCompositeMode: CompositeMode.normal,
    };
}
