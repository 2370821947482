/** @jsxRuntime classic */
/** @jsx jsx */
import {memo } from 'react';
import { jsx, css } from '@emotion/react';
import { IconButton } from './component-library/buttons';
import { Theme, useTheme } from './component-library/theme';
import { SvgDefaults } from './constants';

import { Shape } from './shapes';
import { ShapeSvg } from './SvgShapes';
import { ReactComponent as EditIcon } from './icons/edit.svg';

interface Props {
    shape: Shape;
    isDraggable: boolean;
    onSelect: (shape: Shape) => void;
    onDrag?:(shape: Shape) => void;
}

const getEditButtonStyle = (theme: Theme) => { return css`
  position: absolute;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  margin: 2px;
  padding: 1px 3px;
  :hover {
    cursor: pointer;
  }
  svg {
      margin-right: 0px;
      width: 10px;
      height: 10px;
      fill: ${theme.primaryActiveColor};
  }
    top: 1px;
    right: 1px;
`;
}

const getOptionButtonStyle = (theme: Theme, isDraggable: boolean) => css`
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    margin: 4px;
    border: solid 1px ${theme.primaryBorderColor};
    border-radius: 4px;
    background-color: ${theme.primaryBackgroundColor};

    :hover {
      background-color: ${theme.primaryHoverDarkColor};
      cursor: ${isDraggable ? 'grab' : 'pointer'};
    }
    ${isDraggable ? `&:active { cursor: grabbing}` : ''}

    ${theme.mediaQueriesSmall}{
        padding-top: 0;
    }
    span {
        padding-top: 5px;
        font-size: 9px;
        color: ${theme.primaryColor};
    }

    img,
    span {
        color: ${theme.primaryTextColor};
    }

    position: relative;
    > img {
        width: 80%;
        height: 80%;
        left: calc(50% - 25%);
        top: calc(50% - 25%);
    }
    > span {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(70% - 6px);
    }
    > button {
      display: none;
    }
    &: hover {
      > button {
        display: block;
      }
    }
`;

const isEditable = (shape: Shape): boolean | undefined => {
    return shape.options && shape.options.filter((option) => option.input).length > 0;
};

function ShapeType({
    shape,
    isDraggable,
    onSelect,
    onDrag,
}: Props): JSX.Element {
    const theme = useTheme();
    return (
        <IconButton
            className='shape-type'
            css={getOptionButtonStyle(theme, isDraggable)}
        >
            {!!isEditable(shape) && (<button css={getEditButtonStyle(theme)} onClick={() => onSelect(shape)}>
                <EditIcon />
            </button>)}
            <ShapeSvg
                shape={shape}
                strokeWidth={6}
                stroke={theme.primaryTextColor}
                // updating view box to avoid border cutoff
                width={SvgDefaults.viewBoxWidth}
                height={SvgDefaults.viewBoxWidth}
                fillColor='none'
                isDraggable={isDraggable}
                onDrag={onDrag}
            />
        </IconButton>
    );
}
export default memo(ShapeType)
