import { FoldType, FourPanelZ, FourPanelRoll, DoubleGate, ParallelFold, ThreePanelZ, ThreePanelRoll, GateFold, TwoPageRoll, TwoPageRollH } from "./configurations";
import { NFoldOptions } from "./types";

export function getFoldConfiguration(foldType: FoldType): NFoldOptions {
    switch (foldType) {
        case FoldType.TwoPageRoll:
            return TwoPageRoll;
        case FoldType.ThreePanelRoll:
            return ThreePanelRoll;
        case FoldType.ThreePanelZ:
            return ThreePanelZ;
        case FoldType.Gate:
            return GateFold;
        case FoldType.FourPanelZ:
            return FourPanelZ;
        case FoldType.FourPanelRoll:
            return FourPanelRoll;
        case FoldType.DoubleGate:
            return DoubleGate;
        case FoldType.Parallel:
            return ParallelFold;
        case FoldType.TwoPageRollH:
            return TwoPageRollH;
        default:
            throw new Error('Unknown Fold Type type requested: ' + {foldType});
    }
}
