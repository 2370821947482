/* eslint-disable @typescript-eslint/explicit-function-return-type */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react';
import { CSSProperties } from 'react';
import cx from 'classnames';
import { SelectableValues } from './types';

export interface PaletteProps {
    values: SelectableValues[];
    onSelectValue(value: string): void;
    selectedValue?: string;
    className?: string;
    selectedStyling?: CSSProperties;
    valueChoiceButtonStyle?: CSSProperties;
    showNoColorOption?: boolean;
}

const baseStyle = css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const getChoiceButtonStyle = (
    value: string,
    isSelected: boolean
): SerializedStyles => css`
    height: 33px;
    width: 33px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #e9e9e9;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
    margin: 10px;
    box-sizing: border-box;

    ${value === '' &&
    `
        position: relative;
        :before {
            content: '';
            display: block;
            position: absolute;
            top: 14px;
            left: 0px;
            width: 30px;
            height: 3px;
            box-sizing: border-box;
            border: solid red;
            border-width: 2px 0 0 0;
            transform: rotate(135deg);
        }
    `}

    ${isSelected &&
    `
        position: relative;
        outline: none;

        :after {
            content: '';
            display: block;
            position: absolute;
            top: -6px;
            left: -6px;
            width: 42.5px;
            height: 42.5px;
            box-sizing: border-box;
            border: solid 1px #000;
            border-radius: 50%;
        }
    `}
`;

export function Palette({
    values,
    onSelectValue,
    selectedValue = '',
    className,
    selectedStyling,
    valueChoiceButtonStyle,
    showNoColorOption = false,
}: PaletteProps): JSX.Element {
    const classes = cx('dsc-palette', className);

    // eslint-disable-next-line no-param-reassign
    values = showNoColorOption
        ? [{ cssBackground: 'rgb(255, 255, 255)', value: '' }, ...values]
        : values;

    return (
        <div
            role='listbox'
            data-testid='dsc-palette'
            className={classes}
            css={baseStyle}
        >
            {values.map((value) => {
                const selectedStyle =
                    value.value === selectedValue ? selectedStyling : '';
                return (
                    <button
                        key={value.value}
                        role='option'
                        type='button'
                        className='dsc-choice-button'
                        onClick={() => onSelectValue(value.value)}
                        aria-selected={value.value === selectedValue}
                        css={getChoiceButtonStyle(
                            value.value,
                            value.value === selectedValue
                        )}
                        style={{
                            background: value.cssBackground,
                            ...valueChoiceButtonStyle,
                            ...selectedStyle,
                        }}
                    />
                );
            })}
        </div>
    );
}
