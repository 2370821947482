import { Object3D } from 'three';
import { Animation } from './animation';

const DEFAULT_RANGE = 45;
const DEFAULT_DAMPING = -0.05;
const SWIVEL_MINIMUM_DELTA = -4;

function linearNormalization(outMin: number, outMax: number, value: number) {
    const inMin = -1;
    const inMax = 1;
    const a = (outMax - outMin) / (inMax - inMin);
    const b = outMax - a * inMax;
    const normalizedValue = a * value + b;
    return normalizedValue;
}

export interface SwivelAnimationOptions {
    range: number;
    damping: number | boolean;
    tags: string[];
}

function getRange(rangeOption: number) {
    const range = rangeOption || DEFAULT_RANGE;
    if (range > (2 * Math.PI)) {
        return (range / 180) * Math.PI;
    }
    return range;
}
export class SwivelAnimation implements Animation {
    private minRange: number;
    private maxRange: number;
    private applyDamping: Function;
    private elapsedTime: number;
    private swivelGroup: Object3D;
    private shouldDamp: boolean;
    private dampValue = 0;
    private resolveAnimation?: Function;
    private activeState = false;
    private completed = false;
    private canceledState = false;
    private initialRotation: number;

    constructor(geometry: Object3D, options: SwivelAnimationOptions) {
        const range = getRange(options.range);
        this.minRange = -range;
        this.maxRange = range;

        this.elapsedTime = 0;

        this.swivelGroup = geometry;
        this.initialRotation = this.swivelGroup.rotation.y;

        this.shouldDamp = options.damping !== undefined;

        if (this.shouldDamp) {
            this.dampValue = options.damping === true ? DEFAULT_DAMPING : -options.damping;
        }

        this.applyDamping = this.shouldDamp
            // eslint-disable-next-line no-restricted-properties
            ? (value: number, elapsedTime: number) => value * Math.pow(Math.E, this.dampValue * elapsedTime)
            : (value: number) => value;
    }

    get isComplete() {
        return this.completed;
    }

    public tags: string[] = [];

    get isCanceled() {
        return this.canceledState;
    }

    resetMeshState() {
        this.swivelGroup.rotation.y = this.initialRotation;
    }

    cancel() {
        this.resetMeshState();
        this.resolveAnimation && this.resolveAnimation();
        this.canceledState = true;
    }

    get isActive() {
        return this.activeState;
    }

    update(delta: number) {
        if (this.isActive) {
            let value = Math.sin(this.elapsedTime);
            value = this.applyDamping(value, this.elapsedTime);

            if (this.shouldDamp && (this.dampValue * this.elapsedTime) < SWIVEL_MINIMUM_DELTA) {
                this.activeState = false;
                this.completed = true;
                this.resolveAnimation && this.resolveAnimation();
            }

            const normalizedValue = linearNormalization(this.minRange, this.maxRange, value);
            this.swivelGroup.rotation.y = normalizedValue;
            this.elapsedTime += delta;
        }
    }

    // If the swivel is indefinite then the promise will instantly resolve.
    start() {
        this.activeState = true;
        if (this.shouldDamp) {
            return new Promise<void>((resolve) => {
                this.resolveAnimation = resolve;
            });
        }
        return Promise.resolve();
    }

    resume() {
        if (this.resolveAnimation !== undefined) {
            this.activeState = true;
        }
    }

    stop() {
        this.activeState = false;
    }
}
