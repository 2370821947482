/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Palette } from './component-library/palette';
import { Slider } from './component-library/slider';
import { Theme, useTheme } from './component-library/theme';
import { InputTypes, OptionValueType, ShapeOption } from './shapes';
import { ReactComponent as VflipIcon } from './icons/vflip.svg';
import { ReactComponent as HflipIcon } from './icons/hflip.svg';
import { ReactComponent as ActiveHflipIcon } from './icons/activeHflip.svg';
import { ReactComponent as ActiveVflipIcon } from './icons/ActiveVflip.svg';
import i18n from './translation/i18n';
import { useState } from 'react';
import { IconButton } from 'component-library/buttons';
import { FlipOptions } from 'types';

const getBaseStyle = (theme: Theme) => css`
    width: 100%;
    padding: 10px 11px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 20px;

    ${theme.mediaQueriesSmall} {
      display: flex;
      flex: 1;
      overflow-x: hidden;
      flex-wrap: wrap;
    }
`;

const optionContainer = css`
    width: 100%;
    margin-bottom: 14px;
`;

const getFlipButtonStyle = (isActive: boolean, theme: Theme) => css`
    box-sizing: border-box;
    height: 32px;
    width: 34px;
    border: 1px solid ${isActive? theme.primaryActiveColor : theme.primaryBorderColor};
    &:hover {
      border: 1px solid ${theme.primaryHoverColor};
    }
    border-radius: 2px;
    background-color: ${theme.primaryBackgroundColor};
    margin-right: 10px;
`;

const optionHeaderLabelStyle = css`
    height: 25px;
    width: 100%;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    text-align: left;
    user-select: none;
`;

interface ShapeOptionsProps {
    shapeOptions: ShapeOption[];
    onChange: (optionId: string, newValue: OptionValueType) => void;
    className?: string;
    flip: (flipArg: FlipOptions) => void;
}

export default function ShapeOptions({
    shapeOptions,
    onChange,
    flip
}: ShapeOptionsProps): JSX.Element {
    const theme = useTheme();
    const [horizontalFlip, setHorizontalFlip] = useState(false);
    const [verticalFlip, setVerticalFlip] = useState(false);
    const horizontalFlipHandle = () => {
        flip({ horizontalFlip: !horizontalFlip, verticalFlip });
        setHorizontalFlip(!horizontalFlip);
    }

    const verticalFlipHandle = () => {
        flip({ horizontalFlip, verticalFlip: !verticalFlip });
        setVerticalFlip(!verticalFlip);
    }
    return (
        <div
            className='shape-options'
            css={getBaseStyle(theme)}
        >
            <div css={optionContainer} >
                <div css={optionHeaderLabelStyle}>{i18n.t('flip')}</div>
                <IconButton css={getFlipButtonStyle(horizontalFlip, theme)} onClick={horizontalFlipHandle}>
                    { horizontalFlip ? <ActiveHflipIcon /> : <HflipIcon /> }
                </IconButton>
                <IconButton css={getFlipButtonStyle(verticalFlip, theme)} onClick={verticalFlipHandle}>
                    {verticalFlip ? <ActiveVflipIcon /> : <VflipIcon />}
                </IconButton>
            </div>
            {shapeOptions
                .filter((option) => option.input)
                .map((option) => {
                    if (option.input?.type === InputTypes.Number) {
                        const { min, max, steps } = option.input;
                        return (
                            <div css={optionContainer} key={option.id}>
                                <Slider
                                    label={option.displayLabel || ''}
                                    min={min || 0}
                                    max={max || 0}
                                    increment={steps || 1}
                                    value={option.value as number || min || 0}
                                    onSelect={(value): void => {
                                        onChange(option.id, value);
                                    }}
                                    selectOnDrag={true}
                                />
                            </div>
                        );
                    }
                    if (option.input?.type === InputTypes.Color) {
                        return (
                            <div css={optionContainer}>
                                <div>{option.displayLabel}</div>
                                <Palette
                                    values={[
                                        {
                                            value: 'pantone(Violet C)',
                                            cssBackground: 'rgb(68,0,153)',
                                        },
                                        {
                                            value: 'pantone(Reflex Blue C)',
                                            cssBackground: 'rgb(0,20,137)',
                                        },
                                        {
                                            value: 'pantone(186 C)',
                                            cssBackground: 'rgb(200,16,46)',
                                        },
                                        {
                                            value: 'pantone(240 C)',
                                            cssBackground: 'rgb(197,41,155)',
                                        },
                                        {
                                            value: 'pantone(354 C)',
                                            cssBackground: 'rgb(0,177,64)',
                                        },
                                        {
                                            value: 'pantone(210 C)',
                                            cssBackground: 'rgb(249,159,201)',
                                        },
                                        {
                                            value: 'color 101',
                                            cssBackground: 'rgb(10,159,201)',
                                        },
                                        {
                                            value: 'color 102',
                                            cssBackground: 'rgb(200,150,150)',
                                        },
                                        {
                                            value: 'color 103',
                                            cssBackground: 'rgb(80,222,150)',
                                        },
                                        {
                                            value: 'color 104',
                                            cssBackground: 'rgb(122,159,222)',
                                        },
                                        {
                                            value: 'color 105',
                                            cssBackground: 'rgb(200,50,89)',
                                        },
                                    ]}
                                    onSelectValue={(value) => {
                                        console.log(value);
                                    }}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
        </div>
    );
}
