/* eslint-disable @typescript-eslint/no-unused-vars */
import md5 from 'md5';
import { MeshPhongMaterial, Color, DoubleSide, CubeTextureLoader, RGBFormat, FrontSide, BackSide } from 'three';
import { RenderSide } from '../substrates/types';
import { memoize } from '../common/memoize';
import { ProductSurface, VortexProduct } from '../products';
import { Size2D, RenderSize } from '../common/sizes';
import { tileImage, loadImage, compositeImages, TiledTexture, loadCanvas, colorCanvas, buildCanvas } from './canvasUtility';
import { RenderingClient } from '../clients';
import { Environment } from '../config';
import { Finish } from '../finishes';
import { CompositeMode, MapMode } from './renderingModes';
import { loadTextureFromUri, createTextureFromCanvas } from './webGLHelper';

function sizeFromSurface(surface: ProductSurface): Size2D {
    if (surface.trim) {
        return {
            width: surface.trim.bottomRightX - surface.trim.topLeftX,
            height: surface.trim.bottomRightY - surface.trim.topLeftY,
        };
    }

    return surface.fullBleed;
}

// Builds a unique key for a substrate from surface/rendering/product options
function buildSubstrateKey(
    documentImageUrl: string | undefined,
    surface: ProductSurface,
    product: VortexProduct,
    renderingClient: RenderingClient,
    _environment: Environment,
): string {
    // eslint-disable-next-line prefer-destructuring
    const substrate = surface.substrate;
    const geometryAspect = surface.fullBleed.width / surface.fullBleed.height;

    const renderSize: RenderSize = renderingClient.computeRenderSize(geometryAspect);
    const physicalSize: Size2D = sizeFromSurface(surface);

    // @ts-ignore
    const key = [
        renderSize.textureSize.width,
        renderSize.textureSize.height,
        substrate.color,
        substrate.shininess,
        substrate.bumpScale,
        substrate.baseCompositeMode,
        substrate.reflectivity,
        substrate.supportsTransparency,
        substrate.fullyTransparent,
        substrate.side,
        product.name,
        physicalSize.width,
        physicalSize.height,
        surface.page,
        documentImageUrl,
    ];

    if (substrate.baseTexture) {
        key.push(substrate.baseTexture.dpi, substrate.baseTexture.sourceUrl);

        if (substrate.baseTexture.overlayColor) {
            key.push(substrate.baseTexture.overlayColor);
        }
    }

    if (substrate.bumpTexture) {
        key.push(substrate.bumpTexture.dpi, substrate.bumpTexture.sourceUrl);

        if (substrate.bumpTexture.overlayColor) {
            key.push(substrate.bumpTexture.overlayColor);
        }
    }

    if (substrate.specularTexture) {
        key.push(substrate.specularTexture.dpi, substrate.specularTexture.sourceUrl);

        if (substrate.specularTexture.overlayColor) {
            key.push(substrate.specularTexture.overlayColor);
        }
    }

    if (substrate.reflectionMapOverrideUrls && substrate.reflectionMapOverrideUrls.length > 0) {
        key.push(substrate.reflectionMapOverrideUrls[0]);
    }

    return md5(key.join('-')) as string;
}

// Build a unique key for a finish from surface/rendering/product options
function buildFinishKey(
    documentImageUrl: string,
    finishMaskUrl: string,
    finish: Finish,
    surface: ProductSurface,
    product: VortexProduct,
    renderingClient: RenderingClient,
    _environment: Environment,
): string {
    const geometryAspect = surface.fullBleed.width / surface.fullBleed.height;

    const renderSize: RenderSize = renderingClient.computeRenderSize(geometryAspect);
    const physicalSize: Size2D = sizeFromSurface(surface);

    const key = [
        renderSize.textureSize.width,
        renderSize.textureSize.height,
        finish.shininess,
        finish.reflectivity,
        product.name,
        finish.bumpMode,
        physicalSize.width,
        physicalSize.height,
        documentImageUrl,
    ];

    if (finish.baseTexture) {
        key.push(finish.baseTexture.dpi, finish.baseTexture.sourceUrl, finish.baseCompositeMode);
    }

    return md5(key.join('-')) as string;
}

function colorBaseImage(color: Color, mode: CompositeMode, baseMap: HTMLCanvasElement): HTMLCanvasElement {
    const colorHex = `#${color.getHexString()}`;

    // Fill the background of the base map with the substrate color
    if (mode === CompositeMode.normal) {
        const colorFill: HTMLCanvasElement = colorCanvas({ width: baseMap.width, height: baseMap.height }, colorHex);

        return compositeImages(colorFill, baseMap, CompositeMode.normal);
    }
    if (mode === CompositeMode.multiply) {
        // Skip base color compositing when the target color is already white
        if (color.r === 1 && color.g === 1 && color.b === 1) return baseMap;

        // Multiply non-white the substrate color onto the tiled texture
        // ---------------------------------------------------
        // TODO in the future this should become a shader to correctly support
        // the multiply blending mode. HTML canvas multiply does not preserve opacity
        // so 'source-in' is currently used which doesn't preserve content and is
        // only used for transparent materials like plastics
        const colorFill: HTMLCanvasElement = colorCanvas({ width: baseMap.width, height: baseMap.height }, colorHex);

        return compositeImages(baseMap, colorFill, CompositeMode.sourceIn);
    }

    return baseMap;
}

// Adds a reflection environment map and reflectivity to a material
function addReflectionMap(phongMaterial: MeshPhongMaterial, environment: Environment, reflectivity: number, reflectionMapOverrideUrls?: string[]): void {
    let reflectionUrls = reflectionMapOverrideUrls;

    // Use the overridden reflection map if provided, otherwise default to environment
    if (reflectionUrls === undefined) {
        // If only one map is provided, use it 6 times. Otherwise use the array of 6 maps
        if (environment.reflectionUrl) {
            reflectionUrls = new Array(6).fill(environment.reflectionUrl);
        } else {
            reflectionUrls = environment.reflectionUrls;
        }
    }

    const reflectionCube = new CubeTextureLoader().load(reflectionUrls as string[]);
    reflectionCube.format = RGBFormat;

    // TODO: We should create a new MeshPhongMaterial and return it, instead of changing params
    // eslint-disable-next-line no-param-reassign
    phongMaterial.envMap = reflectionCube;
    // eslint-disable-next-line no-param-reassign
    phongMaterial.reflectivity = reflectivity;
}

// Builds an alpha map for a phong material for corners
async function addAlphaMap(phongMaterial: MeshPhongMaterial, renderSize: RenderSize, physicalSize: Size2D, product: VortexProduct): Promise<void> {
    // Generate a canvas at the product width and height
    const canvas: HTMLCanvasElement = buildCanvas(renderSize.productSize.width, renderSize.productSize.height);
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    // Let the given product define the alpha map
    product.getAlphaMap(renderSize, physicalSize, context);

    // Stretch the canvas to fit on the output texture size
    const stretchedCanvas: HTMLCanvasElement = buildCanvas(renderSize.textureSize.width, renderSize.textureSize.height);
    const stretchedContext = stretchedCanvas.getContext('2d') as CanvasRenderingContext2D;

    stretchedContext.drawImage(canvas, 0, 0, renderSize.textureSize.width, renderSize.textureSize.height);

    // TODO: We should create a new MeshPhongMaterial and return it, instead of changing params
    // eslint-disable-next-line no-param-reassign
    phongMaterial.alphaMap = await createTextureFromCanvas(stretchedCanvas);
}

// Adds a bump map for a phong material given the substrate properties
async function addBumpMap(
    phongMaterial: MeshPhongMaterial,
    tiledTexture: TiledTexture,
    bumpScale: number,
    textureSize: Size2D,
    physicalSize: Size2D,
): Promise<void> {
    const tiledBumpCanvas = await tileImage(tiledTexture, textureSize, physicalSize);

    // TODO: We should create a new MeshPhongMaterial and return it, instead of changing params
    // eslint-disable-next-line no-param-reassign
    phongMaterial.bumpMap = createTextureFromCanvas(tiledBumpCanvas);
    // eslint-disable-next-line no-param-reassign
    phongMaterial.bumpScale = bumpScale;
}

// Builds a specular map for a phong material given the substrate properties
async function addSpecularMap(phongMaterial: MeshPhongMaterial, tiledTexture: TiledTexture, textureSize: Size2D, physicalSize: Size2D): Promise<void> {
    const tiledSpecularCanvas = await tileImage(tiledTexture, textureSize, physicalSize);

    // TODO: We should create a new MeshPhongMaterial and return it, instead of changing params
    // eslint-disable-next-line no-param-reassign
    phongMaterial.specularMap = createTextureFromCanvas(tiledSpecularCanvas);
}

// Builds a substrate from the provided surface, product, rendering, and environment options
async function buildSubstrateInternal(
    documentImageUrl: string | undefined,
    surface: ProductSurface,
    product: VortexProduct,
    renderingClient: RenderingClient,
    environment: Environment,
): Promise<MeshPhongMaterial> {
    const physicalSize: Size2D = sizeFromSurface(surface);

    const geometryAspect = surface.fullBleed.width / surface.fullBleed.height;
    const renderSize: RenderSize = renderingClient.computeRenderSize(geometryAspect);

    const { substrate } = surface;

    const phongMaterial = new MeshPhongMaterial({
        shininess: substrate.shininess,
        emissive: substrate.emissive ?? 0x0,
        transparent: true,
    });

    if (substrate.fullyTransparent) {
        return phongMaterial;
    }

    // For semi-transparent materials disable depth writing to show occluded geometry
    // Ensure transparent materials are double sided so they are see-through
    if (substrate.supportsTransparency) {
        phongMaterial.depthWrite = false;
        phongMaterial.side = DoubleSide;
    }

    // Allow optional side overrides (used in transparent materials that are not completely see-through)
    if (substrate.side) {
        if (substrate.side === RenderSide.front) {
            phongMaterial.side = FrontSide;
        } else if (substrate.side === RenderSide.back) {
            phongMaterial.side = BackSide;
        } else {
            phongMaterial.side = DoubleSide;
        }
    }

    const promises: Promise<any>[] = [];

    // The base color material properties of the substrate
    const baseColor = new Color(substrate.color);
    const baseColorMode: CompositeMode = substrate.baseColorMode ?? CompositeMode.normal;

    // The substrate has a base texture (i.e. kraft or semi-transparent plastic)
    if (substrate.baseTexture) {
        // Generate the base image by tiling the base texture at it's correct DPI (based on physical size)
        const baseTexturePromise = tileImage(substrate.baseTexture, renderSize.textureSize, physicalSize);

        // If a document exists, composite the document onto the base texture using it's desired blending mode
        if (documentImageUrl) {
            promises.push(
                Promise.all([baseTexturePromise, loadImage(documentImageUrl)]).then(([baseTexture, documentImage]) => {
                    // Color the base image with the desired substrate color
                    const baseImage: HTMLCanvasElement = colorBaseImage(baseColor, baseColorMode, baseTexture);

                    // Composite the document onto the colored base image
                    const documentComposite: HTMLCanvasElement = compositeImages(baseImage, documentImage, substrate.baseCompositeMode ?? CompositeMode.normal);

                    phongMaterial.map = createTextureFromCanvas(documentComposite);
                }),
            );
        } else {
            // Otherwise just color the base image and create a texture
            const texturePromise = baseTexturePromise.then((baseTexture) => {
                const baseImage: HTMLCanvasElement = colorBaseImage(baseColor, baseColorMode, baseTexture);

                phongMaterial.map = createTextureFromCanvas(baseImage);
            });
            promises.push(texturePromise);
        }
    } else if (documentImageUrl) {
        // There is no base texture, but a document exists and must be drawn onto the substrate
        const texturePromise = loadImage(documentImageUrl).then((documentImage) => {
            const hexBaseColor = `#${new Color(substrate.color).getHexString()}`;

            const baseImage: HTMLCanvasElement = colorCanvas(renderSize.textureSize, hexBaseColor);
            const documentComposite: HTMLCanvasElement = compositeImages(baseImage, documentImage, substrate.baseCompositeMode ?? CompositeMode.normal);

            phongMaterial.map = createTextureFromCanvas(documentComposite);
        });
        promises.push(texturePromise);
    } else {
        // No document or base texture, just apply the substrate color directly to the material
        phongMaterial.color = baseColor;
        phongMaterial.side = DoubleSide;
    }

    // Apply reflectivity if the substrate requests it
    if (substrate.reflectivity) {
        addReflectionMap(phongMaterial, environment, substrate.reflectivity as number, substrate.reflectionMapOverrideUrls);
    }

    // Apply a specular map if the substrate requests it
    if (substrate.specularTexture) {
        promises.push(addSpecularMap(phongMaterial, substrate.specularTexture, renderSize.textureSize, physicalSize));
    }

    // Apply a bump map if the substrate requests it
    if (substrate.bumpTexture) {
        promises.push(addBumpMap(phongMaterial, substrate.bumpTexture, substrate.bumpScale as number, renderSize.textureSize, physicalSize));
    }

    // Apply an alpha map if required
    if (surface.hasAlphaMap) {
        promises.push(addAlphaMap(phongMaterial, renderSize, physicalSize, product));
    }

    await Promise.all(promises);

    return phongMaterial;
}

// Builds a finish from the provided surface, product, rendering, and environment options
async function buildFinishInternal(
    documentImageUrl: string,
    finishMaskUrl: string,
    finish: Finish,
    surface: ProductSurface,
    product: VortexProduct,
    renderingClient: RenderingClient,
    environment: Environment,
): Promise<MeshPhongMaterial> {
    const physicalSize: Size2D = sizeFromSurface(surface);

    const geometryAspect = surface.fullBleed.width / surface.fullBleed.height;
    const renderSize: RenderSize = renderingClient.computeRenderSize(geometryAspect);

    // Material that will be further customized per finish
    const finishMaterial = new MeshPhongMaterial({
        transparent: true,
        shininess: finish.shininess,
    });

    // The finish requires an environment map reflection cube
    if (finish.reflectivity) {
        addReflectionMap(finishMaterial, environment, finish.reflectivity as number, finish.reflectionMapOverrideUrls);
    }

    const promises = [];

    let finishTexturePromise: Promise<HTMLCanvasElement>;

    // If the finish has a base texture, load that and composite it (ex. metallic)
    if (finish.baseTexture) {
        const baseTexturePromise = tileImage(finish.baseTexture, renderSize.textureSize, physicalSize);

        // If the compositing mode is not normal, the real document texture must be pulled for blending
        if (finish.baseCompositeMode !== CompositeMode.normal) {
            // eslint-disable-next-line arrow-body-style
            finishTexturePromise = Promise.all([baseTexturePromise, loadImage(documentImageUrl)]).then(([baseTextureCanvas, documentTexture]) => {
                return compositeImages(baseTextureCanvas, documentTexture, finish.baseCompositeMode);
            });
            // Otherwise just return the tiled base texture (ex. gold foil)
        } else {
            finishTexturePromise = baseTexturePromise;
        }
        // No base texture provided so just use the document texture directly (ex. raised ink)
    } else {
        finishTexturePromise = loadCanvas(documentImageUrl);
    }

    // Now mask the base texture so it only portions on the finish come through
    promises.push(
        Promise.all([finishTexturePromise, loadImage(finishMaskUrl)]).then(([finishTexture, maskTexture]) => {
            const compositedMaskTexture: HTMLCanvasElement = compositeImages(finishTexture, maskTexture, CompositeMode.mask);

            // If a bump map needs to be generated from the mask create a texture for it (ex. raised ink, gold foil)
            if (finish.bumpMode === MapMode.generate) {
                return Promise.all([createTextureFromCanvas(compositedMaskTexture), loadTextureFromUri(finishMaskUrl)]).then(([mapTexture, bumpTexture]) => {
                    finishMaterial.map = mapTexture;
                    finishMaterial.bumpMap = bumpTexture;
                    finishMaterial.bumpScale = finish.bumpScale;
                });
                // Otherwise just re-use the same texture for both the map and bump (ex. metallic)
            }
            const finishMap = createTextureFromCanvas(compositedMaskTexture);

            finishMaterial.map = finishMap;
            finishMaterial.bumpMap = finishMap;
            finishMaterial.bumpScale = finish.bumpScale;

            return Promise.resolve();
        }),
    );

    // Apply and alpha map if required
    if (surface.hasAlphaMap) {
        promises.push(addAlphaMap(finishMaterial, renderSize, physicalSize, product));
    }

    // Apply a specular map if the substrate requests it
    if (finish.specularTexture) {
        promises.push(addSpecularMap(finishMaterial, finish.specularTexture, renderSize.textureSize, physicalSize));
    }

    return Promise.all(promises).then(() => finishMaterial);
}

// Builds and memorizes a substrate
export const buildSubstrate = memoize(buildSubstrateInternal, buildSubstrateKey);

// Builds and memorizes a finish
export const buildFinish = memoize(buildFinishInternal, buildFinishKey);
