import { Size2D } from "@rendering/vortex-core/common";
import { ProductTrim } from "@rendering/vortex-core/products";
import { CustomPath, PathOperation, PathOperationType } from "@rendering/vortex-paper-products/flatGeometries";

const CM_TO_MM = 10;

export function getTrimBounds(docAdditionalData: DocAdditionalData): ProductTrim | undefined {
    if (docAdditionalData && docAdditionalData.masks) {
        const trimMask = docAdditionalData.masks.find(itr => itr.pathType === 'TRIM');
        if (trimMask && trimMask.pathType === 'TRIM' && trimMask.boundingArea) {
            return {
                topLeftX: trimMask.boundingArea.position.x * CM_TO_MM,
                topLeftY: trimMask.boundingArea.position.y * CM_TO_MM,
                bottomRightX: (trimMask.boundingArea.position.width + trimMask.boundingArea.position.x) * CM_TO_MM,
                bottomRightY: (trimMask.boundingArea.position.height + trimMask.boundingArea.position.y) * CM_TO_MM
            }
        }
    }
    return undefined;
}

function generatePath(specPath: SurfaceSpecPath, offset: Size2D): PathOperation[] {
    const specPathPoints: SurfaceSpecPathPoint[] = specPath.pathPoints;

    const path: PathOperation[] = [];

    // Start at the anchor
    path.push({
        type: PathOperationType.moveTo, 
        x: specPath.anchorX * CM_TO_MM - offset.width, 
        y: specPath.anchorY * CM_TO_MM - offset.height
    });

    // Add all lineTo or bezierCurveTo points from the full path
    for (let i = 0; i < specPathPoints.length; i++) {
        const point: SurfaceSpecPathPoint = specPathPoints[i];

        // Bezier curve
        if (point.firstControlPointX !== undefined && point.firstControlPointY !== undefined &&
            point.secondControlPointX !== undefined && point.secondControlPointY !== undefined) {
            path.push({
                type: PathOperationType.bezierCurveTo,
                x: point.endPointX * CM_TO_MM - offset.width,
                y: point.endPointY * CM_TO_MM - offset.height,
                aCP1x: point.firstControlPointX * CM_TO_MM - offset.width,
                aCP1y: point.firstControlPointY * CM_TO_MM - offset.height,
                aCP2x: point.secondControlPointX * CM_TO_MM - offset.width,
                aCP2y: point.secondControlPointY * CM_TO_MM - offset.height
            });
        } else {
            // Otherwise simple line
            path.push({
                type: PathOperationType.lineTo,
                x: point.endPointX * CM_TO_MM - offset.width,
                y: point.endPointY * CM_TO_MM - offset.height
            });

        }
    }

    return path;
}

// Gets the custom trim path (can include cutouts)
export function getTrimPath(docAdditionalData: DocAdditionalData): CustomPath | undefined {
    if (docAdditionalData && docAdditionalData.masks) {
        const trimMasks: SurfaceSpecPathGroup[] = docAdditionalData.masks.filter(mask => mask.pathType === 'TRIM');
        const trimBounds = getTrimBounds(docAdditionalData);

        // Assume the first trim mask is the only path
        if (trimBounds && trimMasks.length > 0 && trimMasks[0].paths.length > 0) {
            const trimOffset: Size2D = { width: trimBounds.topLeftX, height: trimBounds.topLeftY };

            const primaryPath: PathOperation[] = generatePath(trimMasks[0].paths[0], trimOffset);

            const cutouts: Array<PathOperation[]> = [];

            // If any additional paths exists treat them as cutouts
            for (let i = 1; i < trimMasks[0].paths.length; i++) {
                cutouts.push(generatePath(trimMasks[0].paths[i], trimOffset));
            }

            return { primary: primaryPath, cutouts };
        }
    }

    return undefined;
}

export interface SurfaceSpecification {
    mcpSku: string;
    surfaceGroups: SurfaceGroup[];
}

export interface SurfaceGroup {
    id: string;
    surfaces: ProductSurface[];
}

export interface ProductSurface {
    id: string;
    name: string;
    processType: string;
    colorType: string;
    widthCm: number;
    heightCm: number;
    docAdditionalData: DocAdditionalData;
}

export interface DocAdditionalData {
    masks?: SurfaceSpecPathGroup[];
    guides?: SurfaceSpecPathGroup[];
}

export interface SurfaceSpecPathGroup {
    pathType: string;
    paths: SurfaceSpecPath[];
    boundingArea?: SurfaceSpecBoundingArea;
}

export interface SurfaceSpecPath {
    anchorX: number;
    anchorY: number;
    pathPoints: SurfaceSpecPathPoint[];
}

export interface SurfaceSpecPathPoint {
    ordinal: number;
    endPointX: number;
    endPointY: number;
    firstControlPointX?: number;
    firstControlPointY?: number;
    secondControlPointX?: number;
    secondControlPointY?: number;
}

export interface SurfaceSpecBoundingArea {
    type: string;
    position: { x: number, y: number, width: number, height: number };
}