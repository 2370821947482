import i18n from '../translation/i18n';
import { InputTypes, Shape, ShapeTypes } from './types.shapes';

const defaultShapes: Shape[] = [
    {
        id: 'default-shape-001',
        type: ShapeTypes.Rectangle,
    },
    {
        id: 'default-shape-002',
        type: ShapeTypes.Ellipse,
    },
    {
        id: 'default-shape-003',
        type: ShapeTypes.Line,
    },
    {
        id: 'default-shape-004',
        type: ShapeTypes.Polygon,
        options: [
            {
                value: 3,
                id: 'numberOfSides',
            },
        ],
    },
];

const recentShapes: Shape[] = [
    {
        id: 'recent-shape-001',
        type: ShapeTypes.Polygon,
        options: [
            {
                id: 'numberOfSides',
                value: 4,
            },
        ],
    },
    {
        id: 'recent-shape-002',
        type: ShapeTypes.StarBurst,
        options: [
            {
                id: 'numberOfSides',
                value: 3,
            },
        ],
    },
];

const shapes: Shape[] = [
    {
        id: 'generic-shape-001',
        type: ShapeTypes.Polygon,
        options: [
            {
                id: 'numberOfSides',
                input: {
                    type: InputTypes.Number,
                    min: 3,
                    max: 20,
                    steps: 1,
                },
                displayLabelKey: 'numberOfSides',
                value: 5,
            },
            {
                id: 'radius',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'radius',
                value: 0,
            },
        ],
    },
    {
        id: 'generic-shape-002',
        type: ShapeTypes.StarBurst,
        options: [
            {
                input: {
                    type: InputTypes.Number,
                    min: 2,
                    max: 80,
                    steps: 1,
                },
                displayLabelKey: 'numberOfPoints',
                id: 'numberOfPoints',
                value: 5,
            },
            {
                id: 'height',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'height',
                value: 50,
            },
            // {
            //     input: {
            //         type: InputTypes.Color,
            //     },
            //     id: 'fillColor',
            //     displayLabel: 'Fill Color',
            //     value: '#CC120E',
            // },
        ],
    },
    {
        id: 'generic-shape-003',
        type: ShapeTypes.Arrow,
        options: [
            {
                id: 'tailWidth',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'tailWidth',
                value: 50,
            },
            {
                id: 'headWidth',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'headWidth',
                value: 50,
            },
        ],
    },
    {
        id: 'generic-shape-004',
        type: ShapeTypes.DoubleSidedArrow,
        options: [
            {
                id: 'tailWidth',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'tailWidth',
                value: 50,
            },
            {
                id: 'headWidth',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'headWidth',
                value: 50,
            },
        ],
    },
    {
        id: 'generic-shape-005',
        type: ShapeTypes.CircularSpeechBubble,
        options: [
            {
                id: 'pointerWidth',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'pointerWidth',
                value: 50,
            },
            {
                id: 'pointerHeight',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'pointerHeight',
                value: 50,
            },
        ],
    },
    {
        id: 'generic-shape-006',
        type: ShapeTypes.SpeechBubble,
        options: [
            {
                id: 'radius',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'radius',
                value: 45,
            },
            {
                id: 'pointerPosition',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'pointerPosition',
                value: 16,
            },
            {
                id: 'pointerHeight',
                input: {
                    type: InputTypes.Number,
                    min: 0,
                    max: 100,
                    steps: 1,
                },
                displayLabelKey: 'pointerHeight',
                value: 100,
            },
        ],
    },
];

function addDisplayLabel(shape: Shape): Shape {
    const options = shape.options?.map((option) => {
        const displayLabel = option.displayLabelKey
            ? i18n.t(option.displayLabelKey)
            : '';
        return { ...option, displayLabel };
    });
    return { ...shape, options };
}

export function getShapes(): Shape[] {
    return shapes.map(addDisplayLabel);
}

export function getRecentShapes(): Shape[] {
    return recentShapes.map(addDisplayLabel);
}

export function getDefaultShapes(): Shape[] {
    return defaultShapes.map(addDisplayLabel);
}

export const availableShapes = [...defaultShapes, ...shapes];
