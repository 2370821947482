import { URLs } from '../constants';
import axios from 'axios';
import { logError } from '../loggingManager';
import { Shape } from '../shapes';
import { AuthConfig } from '../types';

export function addNewShape(newShape: {
    shape: Shape;
    hostApp?: string;
    tenantId: string;
    authConfig: AuthConfig;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> {
    const { shape, hostApp, tenantId, authConfig } = newShape;
    return axios
        .post(`${URLs.SHAPES_BASE_URL}`, {
            shape,
            hostApp,
            tenantId,
            authToken: authConfig.value,
        })
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not add folder from the API ${error}`);
            throw error;
        });
}
