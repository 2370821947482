import { Finish } from './types';
import { CompositeMode, MapMode } from '../common';

export function getRaisedFoilGoldFinish(): Finish {
    return {
        name: 'raisedFoilGold',
        channel: 'RaisedFoilGold',
        bumpScale: 0.03,
        bumpMode: MapMode.generate,
        shininess: 100,
        reflectivity: 0.4,
        baseCompositeMode: CompositeMode.normal,
        baseTexture: {
            dpi: 400,
            sourceUrl: 'https://vortex.documents.cimpress.io/finishes/foil/v1/base',
            overlayColor: '#937011',
        },
    };
}
