/**
 * The purpose of a theme is that the colors and styles that are defined here can be reused around the application.
 * This can mean that a primary color, a secondary color, etc can be used here. While colors are the most useful
 * application of this, general spacing values, such as "compact" or "large" could describe the general sizes of all
 * components that are used.
 *
 * All components can always be directly altered, as all should have classNames for every DOM element. So try to
 * minimize one off things here.
 *
 * Do not nest values.
 */

/**
 * POTENTIAL VALUES (Don't add until we actually use them in several components and the theme is confirmed)
 * spacing: 'normal' | 'compact' | 'spacious; // Nice for padding and things
 * secondaryColor: string; // What would THIS really be used for?
 * white: string; // Don't use for intentionally white things, only for things that can deal with being offwhite
 * black: string; // The darkest color that shows? idk
 * iconColor: string; // Pretty self explanatory. But would this be ALL icons regardless of location? hmmm
 */
export interface Theme {
    /**
     * Color used for buttons, indicators, etc
     */
    primaryColor: string;
    /**
     * Alternate, darker color used for interactive elements
     */
    // primaryColorDark: string;
    /**
     * Primary opaque overlay color
     */
    // primaryOverlayColor: string;
    /**
     * Color for hover state on primary color
     */
    primaryHoverColor: string;
    /**
     * Light color for hover state on primary color
     */
    // primaryHoverLightColor: string;
    /**
     * Light color for hover state on primary color
     */
    primaryHoverDarkColor: string;
    /**
     * Color for active state on primary color
     */
    primaryActiveColor: string;
    /**
     * Color for label text and header (secondary)
     */
    // secondaryColor: string;
    /**
     * Color used for the handles on the canvas (resize, rotate, etc)
     */
    // designAreaInteractionColor: string;
    /**
     * Color used to contrast against the primary color (text on buttons, etc)
     */
    // contrastColor: string;
    /**
     * Color used for elements that are interactable, but not currently active
     */
    inactiveColor: string;
    /**
     * Border color used for elements that are interactable, but not currently active
     */
    // inactiveBorderColor: string;
    /**
     * Color used as the primary text color
     */
    primaryTextColor: string;
    /**
     * Color used as the primary background color (for modals and large areas containing other components)
     */
    primaryBackgroundColor: string;
    /**
     * Color used to contrast next to the primary background color (headings, footers, menus, etc)
     */
    secondaryBackgroundColor: string;
    /**
     * Color used as borders around our components
     */
    primaryBorderColor: string;
    /**
     * Color used to indicate disabled or non interactive colors (alternative dark color)
     */
    // disabledDarkColor: string;
    /**
     * Color used to indicate disabled or non interactive icons
     */
    // inactiveIconColor: string;
    /**
     * Color used to indicate active or interactive icons
     */
    // activeIconColor: string;
    /**
     * Small devices (portrait tablets and large phones, 600px and up)
     */
    mediaQueriesSmall: string;
    /**
     * Medium devices (landscape tablets, 768px and up)
     */
    // mediaQueriesMedium: string;
    /**
     * Large devices (laptops/desktops, 992px and up)
     */
    // mediaQueriesLarge: string;
    /**
     * Extra large devices (large laptops and desktops, 1200px and up)
     */
    // mediaQueriesExtraLarge: string;
}

export enum Breakpoint {
    S = 600,
    M = 768,
    L = 992,
    XL = 1200,
}

export const defaultTheme: Theme = {
    inactiveColor: '#DFE0E2',
    primaryActiveColor: '#0088a9',
    primaryBackgroundColor: '#FFFFFF',
    primaryBorderColor: '#C4CDD6',
    primaryColor: '#0088a9',
    primaryHoverColor: '#00738e',
    primaryHoverDarkColor: '#c2c6ca',
    primaryTextColor: '#3A414C',
    secondaryBackgroundColor: '#EFF3F6',
    mediaQueriesSmall: `@media (max-width: ${Breakpoint.S}px)`,
};

export const darkTheme: Theme = {
    ...defaultTheme,
    primaryBackgroundColor: '#000000',
    secondaryBackgroundColor: '#0a0908',
};
