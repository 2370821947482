import Axios from 'axios';

const ASSET_URL = 'https://assets.documents.cimpress.io/v3/assets';

export async function getAssetById(bearerToken: string, assetId: string): Promise<AssetInfo> {
    let url = `${ASSET_URL}/${assetId}`;

    const axiosConfig = {
        headers: {
            'Authorization': `Bearer ${bearerToken}`
        },
    };

    const asset = await Axios.get(url, axiosConfig);

    if (asset.status >= 400) {
        throw `Failed to fetch assets: ${JSON.stringify(asset.data)}}`;
    }

    return {
        contentLink: asset.data._links.content.href,
        id: asset.data.id,
        referenceId: asset.data.referenceId,
        attributes: asset.data.attributes,
        tenant: asset.data.tenant
    }
}

export function getAssetContentUrl(assetId: string): string {
    return `${ASSET_URL}/${assetId}/content`;
}

export async function queryLinksBySkuAndVariables(bearerToken: string, referenceId: string, attributes: any, tenant: {id: string, type: string}): Promise<LinkInfo[]> {
    let url = `${ASSET_URL}/-/links:query?pageSize=250&strict=true`;

    const axiosConfig = {
        headers: {
            'Authorization': `Bearer ${bearerToken}`
        }
    };

    const data = { referenceId, attributes, assetTypes: ['vortexModelConfig'], tenant };
    const links = await Axios.post(url, data, axiosConfig);

    if (links.status >= 400) {
        throw `Failed to fetch assets: ${JSON.stringify(links.data)}}`;
    }

    return links.data._embedded.item.map((item: any) => {
        return {
            id: item.id,
            assetId: item.assetId,
            assetType: item.assetType,
            referenceId: item.referenceId,
            attributes: item.attributes,
            tenant: item.tenant
        };
    });
}

export interface AssetInfo {
    contentLink: string;

    id: string;

    referenceId: string;
    attributes: { [key: string]: string | number };

    tenant: { id: string, type: string };
}

export interface LinkInfo {
    id: string;
    assetId: string;
    assetType: string;

    referenceId: string;
    attributes: { [key: string]: string | number }

    tenant: { id: string, type: string }
}
