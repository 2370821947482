import { Substrate } from './types';
import { CompositeMode } from '../common';
import defaults from './defaults';

export function getDarkWoodSubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 80,
        color: 0X231a1b,
        baseCompositeMode: CompositeMode.multiply,
        baseTexture: {
            dpi: 200,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/darkWood/v1/base',
            overlayColor: '#34260d',
        },
        bumpScale: 0.4,
        bumpTexture: {
            dpi: 200,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/darkWood/v1/base',
        },
    };
}
