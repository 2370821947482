/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/react';

import { cvar } from '../utility/cvar';

export interface SpinnerProps {
    label?: string;
    size: string;
    thickness?: number;
}

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

function getSpinnerBaseStyle(size: string, thickness: number) {
    return css`
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        &:before {
            content: '';
            border: ${thickness}px solid ${cvar('primaryColor')};
            border-radius: 50%;
            width: ${size};
            height: ${size};
            border-top: ${thickness}px solid ${cvar('secondaryBackgroundColor')};
            animation: ${spin} 2s linear infinite;
        }
    `;
}

function getLabelStyle(size: string) {
    return css`
        width: ${size};
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50%);
        transform: translateX(-50%);
        text-align: center;
    `;
}

export function Spinner({ label, size, thickness = 16 }: SpinnerProps) {
    return (
        <div className='ss-spinner' css={getSpinnerBaseStyle(size, thickness)} data-testid='ss-spinner'>
            {label && (
                <span className='ss-spinner__label' css={getLabelStyle(size)}>
                    {label}
                </span>
            )}
        </div>
    );
}
