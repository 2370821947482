import { Substrate } from './types';
import defaults from './defaults';
import { CompositeMode } from '../common';

export function getClearPlasticSubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 200,
        reflectivity: 0.2,
        reflectionMapOverrideUrls: [
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
        ],
        supportsTransparency: true,
        baseTexture: {
            dpi: 20,
            sourceUrl: 'https://uploads.documents.cimpress.io/v1/uploads/f4edf94d-eaae-4a81-a30e-c3e7f6bb0766~110/original?tenant=doc-platform',
        },
        baseColorMode: CompositeMode.multiply
    };
}
