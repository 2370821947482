import { NFoldOptions, PanelEdge } from './types';
import { Vector3, Euler, Object3D, Quaternion } from 'three';
import { Section } from './types2';

export function getSection(id: string, sections: Section[]): Section {
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].id == id) {
            return sections[i];
        }
    }

    throw new Error(`Section id '${id}' is not defined in the NFold configuration!`);
}

export function getZScaleRatios(foldOptions: NFoldOptions): number[] {
    // Way to cheat panel stacking by thinning panels that will be stacked.
    const scaleRatiosZ = new Array(foldOptions.horizontalRatios.length * foldOptions.verticalRatios.length).fill(1);
    let panelThinner = 0.3;

    for (let i = 0; i < foldOptions.foldDirections.length; i++) {
        for (let j = 0; j < foldOptions.foldDirections[i].length; j++) {
            if (Math.abs(foldOptions.foldDirections[i][j].angleClosed) > Math.PI * 0.97) {
                const ratioIndex: number = foldOptions.foldDirections[i][j].ratioIndex;
                if (foldOptions.foldDirections[i][j].edge === PanelEdge.left || foldOptions.foldDirections[i][j].edge === PanelEdge.right) {
                    // Apply slimming to vertical group
                    for (let k = 0; k < foldOptions.verticalRatios.length; k++) {
                        scaleRatiosZ[k * foldOptions.horizontalRatios.length + ratioIndex] = panelThinner;
                        panelThinner += 0.02;
                    }
                } else { // along top/bottom
                    for (let u = 0; u < foldOptions.horizontalRatios.length; u++) {
                        scaleRatiosZ[ratioIndex * foldOptions.horizontalRatios.length + u] = panelThinner;
                        panelThinner += 0.02;
                    }
                }
            }
        }
    }

    return scaleRatiosZ;
}

export function rotateVector(edge: Vector3, pivot: Vector3, rotation: Euler): Vector3 {
    let result = edge.sub(pivot);
    result = result.applyEuler(rotation);
    result = result.add(pivot);
    return result;
}

export function getRotationAxis(edge: PanelEdge): Vector3 {
    if (edge === PanelEdge.left || edge === PanelEdge.right) {
        return new Vector3(0, 1, 0);
    }
    return new Vector3(1, 0, 0);
}

export function applyRotation(panel: Object3D, rotationPoint: Vector3, rotationAngle: number, rotationAxis: Vector3): Object3D {
    const quaternion = new Quaternion();
    quaternion.setFromAxisAngle(rotationAxis, rotationAngle);
    panel.quaternion.multiplyQuaternions(quaternion, panel.quaternion);
    panel.position.sub(rotationPoint);
    panel.position.applyQuaternion(quaternion);
    panel.position.add(rotationPoint);
    return panel;
}
