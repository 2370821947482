import { Object3D, Vector3 } from 'three';
import { Animation } from './animation';

const DEFAULT_DURATION = 1;

export interface RotationAnimationOptions {
    axis: { x: number, y: number, z: number };
    angle: number; // angle in degrees
    duration: number; // in seconds
}

export class RotationAnimation implements Animation {
    private rotationGroup: Object3D;
    private resolveAnimation?: Function;
    private activeState = false;
    private completed = false;
    private canceledState = false;
    private initialRotation: number;
    private elapsedTime = 0;
    private rotation = 0;

    private axis: Vector3;
    private angle: number;
    private duration: number;

    constructor(geometry: Object3D, options: RotationAnimationOptions) {
        // throw error if angle and axis is not defined
        if (!options.angle) throw new Error('Angle is not defined for RotationAnimation! (Angle in degrees)');
        if (!options.axis) throw new Error('Axis is not defined for RotationAnimation! Please specify the x, y, z value of the axis vector');

        this.duration = options.duration ?? DEFAULT_DURATION;
        // convert angle from degrees to radians
        this.angle = (options.angle * Math.PI) / 180;
        this.axis = (new Vector3(options.axis.x, options.axis.y, options.axis.z)).normalize();

        this.rotationGroup = geometry;
        this.initialRotation = this.rotationGroup.rotation.x;
    }

    public tags: string[] = [];

    get isComplete() {
        return this.completed;
    }

    get isCanceled() {
        return this.canceledState;
    }

    resetMeshState() {
        this.rotationGroup.rotation.x = this.initialRotation;
    }

    cancel() {
        this.resetMeshState();
        this.resolveAnimation && this.resolveAnimation();
        this.canceledState = true;
        this.activeState = false;
    }

    get isActive() {
        return this.activeState;
    }

    update(delta: number) {
        if (this.isActive) {
            // calculate rotation angle for delta time based on rate given
            const radians = (this.angle / this.duration) * delta;

            if (this.rotation + radians >= this.angle) {
                // finish remaining angle rotation
                this.rotationGroup.rotateOnAxis(this.axis, this.angle - this.rotation);
                // animation is completed
                this.activeState = false;
                this.completed = true;
                this.resolveAnimation && this.resolveAnimation();
                // reset time and angle tracker
                this.elapsedTime = 0;
                this.rotation = 0;
            } else {
                this.rotationGroup.rotateOnAxis(this.axis, radians);
                this.elapsedTime += delta;
                this.rotation += radians;
            }
        }
    }

    start() {
        this.activeState = true;
        return new Promise<void>((resolve) => {
            this.resolveAnimation = resolve;
        });
    }

    resume() {
        if (this.resolveAnimation !== undefined) {
            this.activeState = true;
        }
    }

    stop() {
        this.activeState = false;
    }
}
