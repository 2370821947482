// Check if the environment is a Browser
export function isBrowser(): boolean {
    return (typeof window === 'object');
}

// Check if the client is using safari
export const isSafari: boolean | null = isBrowser()
    ? navigator.vendor.match(/apple/i)
    && !navigator.userAgent.match(/crios/i)
    && !navigator.userAgent.match(/fxios/i)
    && !navigator.userAgent.match(/Opera|OPT\//) : false;
