import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@cimpress/react-components';
import isEmpty from 'lodash/isEmpty';

import ThreeDotMenu from '../Utility/ThreeDotMenu';
import IconWithTooltip from '../Utility/IconWithTooltip';
import { getNumberOfAllowedActionIcons } from '../helper';

class ActionSection extends PureComponent {
  renderActionOverflow = () => {
    const {
      actions,
      size,
      showMenu,
      onMenuIconClick,
      onClickOutside,
      orientation,
      callbackData,
    } = this.props;
    if (isEmpty(actions)) {
      return null;
    }
    const numberOfActionIcon = getNumberOfAllowedActionIcons(size, orientation);

    const actionsAsIcons = actions.slice(0, numberOfActionIcon);
    const actionsInDrawer = actions.slice(numberOfActionIcon);
    return (
      <React.Fragment>
        {actionsAsIcons.map(x => (
          <IconWithTooltip
            key={x.title}
            className="actionIcon"
            tooltipContent={x.title}
            icon={x.icon}
            onClick={x.onClick}
            clickable
            callbackData={callbackData}
          />
        ))}
        {isEmpty(actionsInDrawer) ? null : (
          <ThreeDotMenu
            className="actionIcon menuIcon"
            menuItems={actionsInDrawer}
            showMenu={showMenu}
            onMenuIconClick={onMenuIconClick}
            onClickOutside={onClickOutside}
            callbackData={callbackData}
          />
        )}
      </React.Fragment>
    );
  };

  renderTopLeftActionArea = () => {
    const {
      checked, showCheckbox, topLeftAction, callbackData,
    } = this.props;
    if (isEmpty(topLeftAction)) {
      if (showCheckbox) {
        return (<Checkbox checked={checked} />);
      }
      return null;
    }
    return (
      <IconWithTooltip
        key={topLeftAction.title}
        tooltipContent={topLeftAction.title}
        icon={topLeftAction.icon}
        onClick={topLeftAction.onClick}
        clickable
        callbackData={callbackData}
      />
    );
  }

  renderActionArea = () => {
    const { onSelectionChanged, showCheckbox, topLeftAction } = this.props;
    return (
      <React.Fragment>
        <div
          className="checkBox actionIcon"
          onClick={(isEmpty(topLeftAction) && showCheckbox) ? onSelectionChanged : null}
          role="button"
          tabIndex="0"
        >
          {this.renderTopLeftActionArea()}
        </div>
        <div className="actionOverlow flexRowSpacing">{this.renderActionOverflow()}</div>
      </React.Fragment>
    );
  }

  render() {
    const { loading } = this.props;
    const ActionArea = this.renderActionArea;
    return (
      <div className="actionArea flexRowSpacing">
        {loading ? null : <ActionArea /> }
      </div>
    );
  }
}

ActionSection.propTypes = {
  checked: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  showMenu: PropTypes.bool.isRequired,
  onMenuIconClick: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  callbackData: PropTypes.string,
  loading: PropTypes.bool,
  topLeftAction: PropTypes.node,
};

ActionSection.defaultProps = {
  callbackData: null,
  loading: false,
  topLeftAction: null,
};

export default ActionSection;
