// @ts-nocheck
// Ignore all typing checks, too much wrk to type when we should never be touching this file.
// TODO remove the usage of var in this file
import { LoadingManager, DefaultLoadingManager, FileLoader, Vector3, Matrix4, MeshBasicMaterial, BufferGeometry, Float32BufferAttribute, Bone, Group, LineSegments, Line, Mesh, Scene, Euler } from "three";

/**
 * @author mrdoob / http://mrdoob.com/
 * @author Mugen87 / https://github.com/Mugen87
 */

export default class ColladaLoader {
    private _manager: LoadingManager;
    private _path: string;

    constructor(manager: LoadingManager) {
        this._manager = (manager !== undefined) ? manager : DefaultLoadingManager;
    }

    load(url: string, onLoad: (arg0: { scene: Scene }) => void, onProgress: (request: ProgressEvent) => void = undefined, onError: (event: ErrorEvent) => void = undefined) {


        const loader = new FileLoader(this._manager);
        loader.setPath(this._path);
        loader.load(url, (text) => {

            onLoad(this.parse(text as string));

        }, onProgress, onError);
    }

    setPath(value: string) {
        this._path = value;
    }

    parse(text: string) {
        function getElementsByTagName(xml: Document, name: string) {

            // Non recursive xml.getElementsByTagName() ...

            const array = [];
            const childNodes = xml.childNodes;

            for (let i = 0, l = childNodes.length; i < l; i++) {

                const child = childNodes[i];

                if (child.nodeName === name) {
                    array.push(child);
                }
            }

            return array;
        }

        function parseStrings(text: string) {

            if (text.length === 0) return [];

            const parts = text.trim().split(/\s+/);
            const array = new Array(parts.length);

            for (let i = 0, l = parts.length; i < l; i++) {

                array[i] = parts[i];

            }

            return array;

        }

        function parseFloats(text: string) {

            if (text.length === 0) return [];

            const parts = text.trim().split(/\s+/);
            const array = new Array(parts.length);

            for (let i = 0, l = parts.length; i < l; i++) {

                array[i] = parseFloat(parts[i]);

            }

            return array;

        }

        function parseInts(text: string) {

            if (text.length === 0) return [];

            const parts = text.trim().split(/\s+/);
            const array = new Array(parts.length);

            for (let i = 0, l = parts.length; i < l; i++) {

                array[i] = parseInt(parts[i]);

            }

            return array;

        }

        function parseId(text: string) {

            return text.substring(1);

        }

        function generateId() {

            return 'three_default_' + (count++);

        }

        // asset

        function parseAsset(xml: Document) {

            return {
                unit: parseAssetUnit(getElementsByTagName(xml, 'unit')[0]),
                upAxis: parseAssetUpAxis(getElementsByTagName(xml, 'up_axis')[0])
            };

        }

        function parseAssetUnit(xml) {

            if ((xml !== undefined) && (xml.hasAttribute('meter') === true)) {

                return parseFloat(xml.getAttribute('meter'));

            } else {

                return 1; // default 1 meter

            }

        }

        function parseAssetUpAxis(xml) {

            return xml !== undefined ? xml.textContent : 'Y_UP';

        }

        // library

        function parseLibrary(xml, libraryName: string, nodeName: string, parser) {

            const library = getElementsByTagName(xml, libraryName)[0];

            if (library !== undefined) {

                const elements = getElementsByTagName(library, nodeName);

                for (let i = 0; i < elements.length; i++) {

                    parser(elements[i]);

                }

            }

        }

        // get

        function getBuild(data, builder) {

            if (data.build !== undefined) return data.build;

            data.build = builder(data);

            return data.build;

        }

        function buildController(data) {

            var build: any = {
                id: data.id
            };

            var geometry = library.geometries[build.id];

            if (data.skin !== undefined) {

                build.skin = buildSkin(data.skin);

                // we enhance the 'sources' property of the corresponding geometry with our skin data

                geometry.sources.skinIndices = build.skin.indices;
                geometry.sources.skinWeights = build.skin.weights;

            }

            return build;

        }

        function buildSkin(data) {

            const BONE_LIMIT = 4;

            var build: any = {
                joints: [], // this must be an array to preserve the joint order
                indices: {
                    array: [],
                    stride: BONE_LIMIT
                },
                weights: {
                    array: [],
                    stride: BONE_LIMIT
                }
            };

            var sources = data.sources;
            var vertexWeights = data.vertexWeights;

            var vcount = vertexWeights.vcount;
            var v = vertexWeights.v;
            var jointOffset = vertexWeights.inputs.JOINT.offset;
            var weightOffset = vertexWeights.inputs.WEIGHT.offset;

            var jointSource = data.sources[data.joints.inputs.JOINT];
            var inverseSource = data.sources[data.joints.inputs.INV_BIND_MATRIX];

            var weights = sources[vertexWeights.inputs.WEIGHT.id].array;
            var stride = 0;

            var i, j, l;

            // procces skin data for each vertex

            for (i = 0, l = vcount.length; i < l; i++) {

                var jointCount = vcount[i]; // this is the amount of joints that affect a single vertex
                var vertexSkinData = [];

                for (j = 0; j < jointCount; j++) {

                    var skinIndex = v[stride + jointOffset];
                    var weightId = v[stride + weightOffset];
                    var skinWeight = weights[weightId];

                    vertexSkinData.push({ index: skinIndex, weight: skinWeight });

                    stride += 2;

                }

                // we sort the joints in descending order based on the weights.
                // this ensures, we only procced the most important joints of the vertex

                vertexSkinData.sort(descending);

                // now we provide for each vertex a set of four index and weight values.
                // the order of the skin data matches the order of vertices

                for (j = 0; j < BONE_LIMIT; j++) {

                    var d = vertexSkinData[j];

                    if (d !== undefined) {

                        build.indices.array.push(d.index);
                        build.weights.array.push(d.weight);

                    } else {

                        build.indices.array.push(0);
                        build.weights.array.push(0);

                    }

                }

            }

            // setup bind matrix

            if (data.bindShapeMatrix) {

                build.bindMatrix = new Matrix4().fromArray(data.bindShapeMatrix).transpose();

            } else {

                build.bindMatrix = new Matrix4().identity();

            }

            // process bones and inverse bind matrix data

            for (i = 0, l = jointSource.array.length; i < l; i++) {

                var name = jointSource.array[i];
                var boneInverse = new Matrix4().fromArray(inverseSource.array, i * inverseSource.stride).transpose();

                build.joints.push({ name: name, boneInverse: boneInverse });

            }

            return build;

            // array sort function

            function descending(a, b) {

                return b.weight - a.weight;

            }

        }

        function getController(id) {

            return getBuild(library.controllers[id], buildController);

        }

        // effect



        // geometry

        function parseGeometry(xml) {

            var data = {
                name: xml.getAttribute('name'),
                sources: {},
                vertices: {},
                primitives: []
            };

            var mesh = getElementsByTagName(xml, 'mesh')[0];

            // the following tags inside geometry are not supported yet (see https://github.com/mrdoob/three.js/pull/12606): convex_mesh, spline, brep
            if (mesh === undefined) return;

            for (var i = 0; i < mesh.childNodes.length; i++) {

                var child = mesh.childNodes[i];

                if (child.nodeType !== 1) continue;

                var id = child.getAttribute('id');

                switch (child.nodeName) {

                    case 'source':
                        data.sources[id] = parseSource(child);
                        break;

                    case 'vertices':
                        // data.sources[ id ] = data.sources[ parseId( getElementsByTagName( child, 'input' )[ 0 ].getAttribute( 'source' ) ) ];
                        data.vertices = parseGeometryVertices(child);
                        break;

                    case 'polygons':
                        console.warn('THREE.ColladaLoader: Unsupported primitive type: ', child.nodeName);
                        break;

                    case 'lines':
                    case 'linestrips':
                    case 'polylist':
                    case 'triangles':
                        data.primitives.push(parseGeometryPrimitive(child));
                        break;

                    default:
                        console.log(child);

                }

            }

            library.geometries[xml.getAttribute('id')] = data;

        }

        function parseSource(xml) {

            var data = {
                array: [],
                stride: 3
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'float_array':
                        data.array = parseFloats(child.textContent);
                        break;

                    case 'Name_array':
                        data.array = parseStrings(child.textContent);
                        break;

                    case 'technique_common':
                        var accessor = getElementsByTagName(child, 'accessor')[0];

                        if (accessor !== undefined) {

                            data.stride = parseInt(accessor.getAttribute('stride'));

                        }
                        break;

                }

            }

            return data;

        }

        function parseGeometryVertices(xml) {

            var data = {};

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                data[child.getAttribute('semantic')] = parseId(child.getAttribute('source'));

            }

            return data;

        }

        function parseGeometryPrimitive(xml) {

            var primitive: any = {
                type: xml.nodeName,
                material: xml.getAttribute('material'),
                count: parseInt(xml.getAttribute('count')),
                inputs: {},
                stride: 0,
                hasUV: false
            };

            for (var i = 0, l = xml.childNodes.length; i < l; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'input':
                        var id = parseId(child.getAttribute('source'));
                        var semantic = child.getAttribute('semantic');
                        var offset = parseInt(child.getAttribute('offset'));
                        var set = parseInt(child.getAttribute('set'));
                        var inputname = (set > 0 ? semantic + set : semantic);
                        primitive.inputs[inputname] = { id: id, offset: offset };
                        primitive.stride = Math.max(primitive.stride, offset + 1);
                        if (semantic === 'TEXCOORD') primitive.hasUV = true;
                        break;

                    case 'vcount':
                        primitive.vcount = parseInts(child.textContent);
                        break;

                    case 'p':
                        primitive.p = parseInts(child.textContent);
                        break;

                }

            }

            return primitive;

        }

        function groupPrimitives(primitives) {

            var build = {};

            for (var i = 0; i < primitives.length; i++) {

                var primitive = primitives[i];

                if (build[primitive.type] === undefined) build[primitive.type] = [];

                build[primitive.type].push(primitive);

            }

            return build;

        }

        function checkUVCoordinates(primitives) {

            var count = 0;

            for (var i = 0, l = primitives.length; i < l; i++) {

                var primitive = primitives[i];

                if (primitive.hasUV === true) {

                    count++;

                }

            }

            if (count > 0 && count < primitives.length) {

                primitives.uvsNeedsFix = true;

            }

        }

        function buildGeometry(data) {

            var build: any = {};

            var sources = data.sources;
            var vertices = data.vertices;
            var primitives = data.primitives;

            if (primitives.length === 0) return {};

            // our goal is to create one buffer geometry for a single type of primitives
            // first, we group all primitives by their type

            var groupedPrimitives = groupPrimitives(primitives);

            for (var type in groupedPrimitives) {

                var primitiveType = groupedPrimitives[type];

                // second, ensure consistent uv coordinates for each type of primitives (polylist,triangles or lines)

                checkUVCoordinates(primitiveType);

                // third, create a buffer geometry for each type of primitives

                build[type] = buildGeometryType(primitiveType, sources, vertices);
                build[type].data.name = data.name;
            }

            return build;

        }

        function buildGeometryType(primitives, sources, vertices) {

            var build: any = {};

            var position = { array: [], stride: 0 };
            var normal = { array: [], stride: 0 };
            var uv = { array: [], stride: 0 };
            var uv2 = { array: [], stride: 0 };
            var color = { array: [], stride: 0 };

            var skinIndex = { array: [], stride: 4 };
            var skinWeight = { array: [], stride: 4 };

            var geometry = new BufferGeometry();

            var materialKeys = [];

            var start = 0;

            for (var p = 0; p < primitives.length; p++) {

                var primitive = primitives[p];
                var inputs = primitive.inputs;

                // groups

                var count = 0;

                switch (primitive.type) {

                    case 'lines':
                    case 'linestrips':
                        count = primitive.count * 2;
                        break;

                    case 'triangles':
                        count = primitive.count * 3;
                        break;

                    case 'polylist':

                        for (var g = 0; g < primitive.count; g++) {

                            var vc = primitive.vcount[g];

                            switch (vc) {

                                case 3:
                                    count += 3; // single triangle
                                    break;

                                case 4:
                                    count += 6; // quad, subdivided into two triangles
                                    break;

                                default:
                                    count += (vc - 2) * 3; // polylist with more than four vertices
                                    break;

                            }

                        }

                        break;

                    default:
                        console.warn('THREE.ColladaLoader: Unknow primitive type:', primitive.type);

                }

                geometry.addGroup(start, count, p);
                start += count;

                // material

                if (primitive.material) {

                    materialKeys.push(primitive.material);

                }

                // geometry data

                for (var name in inputs) {

                    var input = inputs[name];

                    switch (name) {

                        case 'VERTEX':
                            for (var key in vertices) {

                                var id = vertices[key];

                                switch (key) {

                                    case 'POSITION':
                                        var prevLength = position.array.length;
                                        buildGeometryData(primitive, sources[id], input.offset, position.array);
                                        position.stride = sources[id].stride;

                                        if (sources.skinWeights && sources.skinIndices) {

                                            buildGeometryData(primitive, sources.skinIndices, input.offset, skinIndex.array);
                                            buildGeometryData(primitive, sources.skinWeights, input.offset, skinWeight.array);

                                        }

                                        // see #3803

                                        if (primitive.hasUV === false && primitives.uvsNeedsFix === true) {

                                            var count = (position.array.length - prevLength) / position.stride;

                                            for (var i = 0; i < count; i++) {

                                                // fill missing uv coordinates

                                                uv.array.push(0, 0);

                                            }

                                        }
                                        break;

                                    case 'NORMAL':
                                        buildGeometryData(primitive, sources[id], input.offset, normal.array);
                                        normal.stride = sources[id].stride;
                                        break;

                                    case 'COLOR':
                                        buildGeometryData(primitive, sources[id], input.offset, color.array);
                                        color.stride = sources[id].stride;
                                        break;

                                    case 'TEXCOORD':
                                        buildGeometryData(primitive, sources[id], input.offset, uv.array);
                                        uv.stride = sources[id].stride;
                                        break;

                                    case 'TEXCOORD1':
                                        buildGeometryData(primitive, sources[id], input.offset, uv2.array);
                                        uv.stride = sources[id].stride;
                                        break;

                                    default:
                                        console.warn('THREE.ColladaLoader: Semantic "%s" not handled in geometry build process.', key);

                                }

                            }
                            break;

                        case 'NORMAL':
                            buildGeometryData(primitive, sources[input.id], input.offset, normal.array);
                            normal.stride = sources[input.id].stride;
                            break;

                        case 'COLOR':
                            buildGeometryData(primitive, sources[input.id], input.offset, color.array);
                            color.stride = sources[input.id].stride;
                            break;

                        case 'TEXCOORD':
                            buildGeometryData(primitive, sources[input.id], input.offset, uv.array);
                            uv.stride = sources[input.id].stride;
                            break;

                        case 'TEXCOORD1':
                            buildGeometryData(primitive, sources[input.id], input.offset, uv2.array);
                            uv2.stride = sources[input.id].stride;
                            break;

                    }

                }

            }

            // build geometry

            if (position.array.length > 0) geometry.setAttribute('position', new Float32BufferAttribute(position.array, position.stride));
            if (normal.array.length > 0) geometry.setAttribute('normal', new Float32BufferAttribute(normal.array, normal.stride));
            if (color.array.length > 0) geometry.setAttribute('color', new Float32BufferAttribute(color.array, color.stride));
            if (uv.array.length > 0) geometry.setAttribute('uv', new Float32BufferAttribute(uv.array, uv.stride));
            if (uv2.array.length > 0) geometry.setAttribute('uv2', new Float32BufferAttribute(uv2.array, uv2.stride));

            if (skinIndex.array.length > 0) geometry.setAttribute('skinIndex', new Float32BufferAttribute(skinIndex.array, skinIndex.stride));
            if (skinWeight.array.length > 0) geometry.setAttribute('skinWeight', new Float32BufferAttribute(skinWeight.array, skinWeight.stride));

            build.data = geometry;
            build.type = primitives[0].type;
            build.materialKeys = materialKeys;

            return build;

        }

        function buildGeometryData(primitive, source, offset, array) {

            var indices = primitive.p;
            var stride = primitive.stride;
            var vcount = primitive.vcount;

            function pushVector(i) {

                var index = indices[i + offset] * sourceStride;
                var length = index + sourceStride;

                for (; index < length; index++) {

                    array.push(sourceArray[index]);

                }

            }

            var sourceArray = source.array;
            var sourceStride = source.stride;

            if (primitive.vcount !== undefined) {

                var index = 0;

                for (var i = 0, l = vcount.length; i < l; i++) {

                    var count = vcount[i];

                    if (count === 4) {

                        var a = index + stride * 0;
                        var b = index + stride * 1;
                        var c = index + stride * 2;
                        var d = index + stride * 3;

                        pushVector(a); pushVector(b); pushVector(d);
                        pushVector(b); pushVector(c); pushVector(d);

                    } else if (count === 3) {

                        var a = index + stride * 0;
                        var b = index + stride * 1;
                        var c = index + stride * 2;

                        pushVector(a); pushVector(b); pushVector(c);

                    } else if (count > 4) {

                        for (var k = 1, kl = (count - 2); k <= kl; k++) {

                            var a = index + stride * 0;
                            var b = index + stride * k;
                            var c = index + stride * (k + 1);

                            pushVector(a); pushVector(b); pushVector(c);

                        }

                    }

                    index += stride * count;

                }

            } else {

                for (var i = 0, l = indices.length; i < l; i += stride) {

                    pushVector(i);

                }

            }

        }

        function getGeometry(id) {

            return getBuild(library.geometries[id], buildGeometry);

        }

        // kinematics

        // physics

        // scene

        // nodes

        function prepareNodes(xml) {

            var elements = xml.getElementsByTagName('node');

            // ensure all node elements have id attributes

            for (let i = 0; i < elements.length; i++) {

                var element = elements[i];

                if (element.hasAttribute('id') === false) {

                    element.setAttribute('id', generateId());

                }

            }

        }

        var matrix = new Matrix4();
        var vector = new Vector3();

        function parseNode(xml) {

            var data = {
                name: xml.getAttribute('name') || '',
                type: xml.getAttribute('type'),
                id: xml.getAttribute('id'),
                sid: xml.getAttribute('sid'),
                matrix: new Matrix4(),
                nodes: [],
                instanceControllers: [],
                instanceLights: [],
                instanceGeometries: [],
                instanceNodes: [],
                transforms: {}
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                if (child.nodeType !== 1) continue;

                switch (child.nodeName) {

                    case 'node':
                        data.nodes.push(child.getAttribute('id'));
                        parseNode(child);
                        break;

                    case 'instance_controller':
                        data.instanceControllers.push(parseNodeInstance(child));
                        break;

                    case 'instance_geometry':
                        data.instanceGeometries.push(parseNodeInstance(child));
                        break;

                    case 'instance_node':
                        data.instanceNodes.push(parseId(child.getAttribute('url')));
                        break;

                    case 'matrix':
                        var array = parseFloats(child.textContent);
                        data.matrix.multiply(matrix.fromArray(array).transpose());
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'translate':
                        var array = parseFloats(child.textContent);
                        vector.fromArray(array);
                        data.matrix.multiply(matrix.makeTranslation(vector.x, vector.y, vector.z));
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'rotate':
                        var array = parseFloats(child.textContent);
                        var angle = array[3] * Math.PI / 180;
                        data.matrix.multiply(matrix.makeRotationAxis(vector.fromArray(array), angle));
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'scale':
                        var array = parseFloats(child.textContent);
                        data.matrix.scale(vector.fromArray(array));
                        data.transforms[child.getAttribute('sid')] = child.nodeName;
                        break;

                    case 'extra':
                        break;

                    default:
                        console.log(child);

                }

            }

            if (hasNode(data.id)) {

                console.warn('THREE.ColladaLoader: There is already a node with ID %s. Exclude current node from further processing.', data.id);

            } else {

                library.nodes[data.id] = data;

            }

            return data;

        }

        function parseNodeInstance(xml) {

            var data = {
                id: parseId(xml.getAttribute('url')),
                materials: {},
            };

            for (var i = 0; i < xml.childNodes.length; i++) {

                var child = xml.childNodes[i];

                switch (child.nodeName) {

                    case 'bind_material':
                        var instances = child.getElementsByTagName('instance_material');

                        for (var j = 0; j < instances.length; j++) {

                            var instance = instances[j];
                            var symbol = instance.getAttribute('symbol');
                            var target = instance.getAttribute('target');

                            data.materials[symbol] = parseId(target);

                        }
                        break;


                    default:
                        break;

                }

            }

            return data;

        }

        function buildNode(data) {

            var objects = [];

            var matrix = data.matrix;
            var nodes = data.nodes;
            var type = data.type;
            var instanceControllers = data.instanceControllers;
            var instanceGeometries = data.instanceGeometries;
            var instanceNodes = data.instanceNodes;

            // nodes

            for (var i = 0, l = nodes.length; i < l; i++) {

                objects.push(getNode(nodes[i]));

            }

            // instance controllers

            for (var i = 0, l = instanceControllers.length; i < l; i++) {

                var instance = instanceControllers[i];
                var controller = getController(instance.id);
                var geometries = getGeometry(controller.id);
                var newObjects = buildObjects(geometries);


                for (var j = 0, jl = newObjects.length; j < jl; j++) {

                    var object = newObjects[j];


                    objects.push(object);

                }

            }

            // instance geometries

            for (var i = 0, l = instanceGeometries.length; i < l; i++) {

                var instance = instanceGeometries[i];

                // a single geometry instance in collada can lead to multiple object3Ds.
                // this is the case when primitives are combined like triangles and lines

                var geometries = getGeometry(instance.id);
                var newObjects = buildObjects(geometries);

                for (var j = 0, jl = newObjects.length; j < jl; j++) {

                    objects.push(newObjects[j]);

                }

            }

            // instance nodes

            for (var i = 0, l = instanceNodes.length; i < l; i++) {

                objects.push(getNode(instanceNodes[i]).clone());

            }

            var object;

            if (nodes.length === 0 && objects.length === 1) {

                object = objects[0];

            } else {

                object = (type === 'JOINT') ? new Bone() : new Group();

                for (var i = 0; i < objects.length; i++) {

                    object.add(objects[i]);

                }

            }

            if (object.name === '') {

                object.name = (type === 'JOINT') ? data.sid : data.name;
                object.uuid = data.id;

            }

            object.matrix.copy(matrix);
            object.matrix.decompose(object.position, object.quaternion, object.scale);

            return object;

        }

        var fallbackMaterial = new MeshBasicMaterial({ color: 0xff00ff });

        function buildObjects(geometries) {

            var objects = [];

            for (var type in geometries) {

                var geometry = geometries[type];

                var material = fallbackMaterial;

                // now create a specific 3D object

                var object;

                switch (type) {

                    case 'lines':
                        object = new LineSegments(geometry.data, material);
                        break;

                    case 'linestrips':
                        object = new Line(geometry.data, material);
                        break;

                    case 'triangles':
                    case 'polylist':
                        object = new Mesh(geometry.data, material);

                        break;

                }
                // Fix issue with loading DAE files that doesn't have names for Mesh elements
                if (object.name === '' && geometry.data.name) {
                    object.uuid = geometry.data.name.replace('Mesh', '');
                }
                
                objects.push(object);

            }

            return objects;

        }

        function hasNode(id) {

            return library.nodes[id] !== undefined;

        }

        function getNode(id) {

            return getBuild(library.nodes[id], buildNode);

        }

        // visual scenes

        function parseVisualScene(xml) {

            var data = {
                name: xml.getAttribute('name'),
                children: []
            };

            prepareNodes(xml);

            var elements = getElementsByTagName(xml, 'node');

            for (var i = 0; i < elements.length; i++) {

                data.children.push(parseNode(elements[i]));

            }

            library.visualScenes[xml.getAttribute('id')] = data;

        }

        function buildVisualScene(data) {

            var group = new Group();
            group.name = data.name;

            var children = data.children;

            for (var i = 0; i < children.length; i++) {

                var child = children[i];

                group.add(getNode(child.id));

            }

            return group;

        }

        function getVisualScene(id) {

            return getBuild(library.visualScenes[id], buildVisualScene);

        }

        // scenes

        function parseScene(xml) {

            var instance = getElementsByTagName(xml, 'instance_visual_scene')[0];
            return getVisualScene(parseId(instance.getAttribute('url')));

        }

        // convert the parser error element into text with each child elements text
        // separated by new lines.

        function parserErrorToText(parserError) {

            var result = '';
            var stack = [parserError];

            while (stack.length) {

                var node = stack.shift();

                if (node.nodeType === Node.TEXT_NODE) {

                    result += node.textContent;

                } else {

                    result += '\n';
                    stack.push.apply(stack, node.childNodes);

                }

            }

            return result.trim();

        }

        if (text.length === 0) {

            return { scene: new Scene };

        }

        var xml = new DOMParser().parseFromString(text, 'application/xml');

        var collada = getElementsByTagName(xml, 'COLLADA')[0];

        var parserError = xml.getElementsByTagName('parsererror')[0];
        if (parserError !== undefined) {

            // Chrome will return parser error with a div in it

            var errorElement = getElementsByTagName(parserError, 'div')[0];
            var errorText;

            if (errorElement) {

                errorText = errorElement.textContent;

            } else {

                errorText = parserErrorToText(parserError);

            }

            console.error('THREE.ColladaLoader: Failed to parse collada file.\n', errorText);

            return null;

        }

        var asset = parseAsset(getElementsByTagName(collada, 'asset')[0]);

        var count = 0;

        var library = {
            animations: {},
            clips: {},
            controllers: {},
            images: {},
            effects: {},
            materials: {},
            cameras: {},
            lights: {},
            geometries: {},
            nodes: {},
            visualScenes: {},
            kinematicsModels: {},
            physicsModels: {},
            kinematicsScenes: {}
        };

        parseLibrary(collada, 'library_geometries', 'geometry', parseGeometry);
        parseLibrary(collada, 'library_visual_scenes', 'visual_scene', parseVisualScene);

        const scene: Scene = parseScene(getElementsByTagName(collada, 'scene')[0]);

        if (asset.upAxis === 'Z_UP') {

            scene.quaternion.setFromEuler(new Euler(- Math.PI / 2, 0, 0));

        }

        scene.scale.multiplyScalar(asset.unit);

        return {
            library: library,
            scene: scene
        };

    }

}
