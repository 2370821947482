import { FocalPoint, ProductState } from "@rendering/vortex-core/products";

export enum ProductType {
    model = 'model',
    flatSheet ='flatSheet',
    nFold ='nFold',
    canvasPrint ='canvasPrint'
}

 export interface VortexProductConfig {
    version: number;

    type: ProductType;
    productDataUrl: string;

    surfaceMapping: { [key: string]: ProductSurface };
    substrateUrlMap: { [key: string]: string };
    finishUrlMap?: { [key: string]: string };

    modelStates?: ProductState[];
}

export interface ProductSurface {
    page?: number;

    substrateId: string;
    finishIds?: string[];

    focalPoint?: FocalPoint;
}