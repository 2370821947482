import { SupportedLanguages } from '../constants';

interface TranslationConfig {
    lng?: string;
    fallbackLng: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resources: any;
}

let currentLocale: string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let resources: any;

function init(config: TranslationConfig): void {
    currentLocale = (SupportedLanguages.find((l) => l.code===config.lng) && config.lng) || config.fallbackLng;
    resources = config.resources;
}

function getResource(key: string): string {
    const localeResources = resources[currentLocale].translation;
    return localeResources[key] || key;
}

export function useTranslation() {
    return { t: getResource };
}

export default {
    init: init,
    t: getResource,
};
