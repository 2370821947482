import Axios from 'axios';
import { Finish } from "@rendering/vortex-core/finishes";
import { Substrate } from "@rendering/vortex-core/substrates";
import { VortexProductConfig } from 'configs';

export async function loadSubstrateMap(config: VortexProductConfig): Promise<{ [key: string]: Substrate }> {
    const substrates: { [key: string]: Substrate } = {};

    for (const substrateKey in config.substrateUrlMap) {
        substrates[substrateKey] = await getSubstrateFromUrl(substrateKey, config.substrateUrlMap[substrateKey]);
    }

    return substrates;
}

export async function loadFinishMap(config: VortexProductConfig): Promise<{ [key: string]: Finish }> {
    const finishes: { [key: string]: Finish } = {};

    if (!config.finishUrlMap) {
        return finishes;
    }

    for (const finishKey in config.finishUrlMap) {
        finishes[finishKey] = await getFinishFromUrl(finishKey, config.finishUrlMap[finishKey]);
    }

    return finishes;
}

async function getSubstrateFromUrl(substrateId: string, substrateUrl: string): Promise<Substrate> {
    const substrateResponse = await Axios.get<Substrate>(substrateUrl);

    if (substrateResponse.status >= 400) {
        throw `Failed to fetch substrate '${substrateId}' from '${substrateUrl}'!`;
    }

    return substrateResponse.data;
}

async function getFinishFromUrl(finishId: string, finishUrl: string): Promise<Finish> {
    const finishResponse = await Axios.get<Finish>(finishUrl);

    if (finishResponse.status >= 400) {
        throw `Failed to fetch finish '${finishId}' from '${finishUrl}'!`;
    }

    return finishResponse.data;
}