import { Box3, Geometry, Mesh, Scene, Spherical, Vector3 } from 'three';
import { EPSILON, HALF_PI, MAX_GEOMETRY_DIMENSION } from '../config';
import { Preview } from '../preview';
import { ProductState, ProductSurface, VortexProduct } from '../products';
import { instantlyChangeState } from './stateHelper';
import { TraceIntersectionFromPosition } from '.';

// Changes the product to a specific state and updates the world matrix
// to get the correct geometry normals
async function changeWorldState(stateId: string, preview: Preview): Promise<void> {
    await instantlyChangeState(stateId, preview);

    // @ts-ignore
    preview.scene.updateMatrixWorld();
}

function detectSurfaceHit(surfaceId: string, meshCenter: Vector3, meshNormal: Vector3, scene: Scene): boolean {
    // Cast the normal out to the world at 1.5 the size of maximum geometry
    const projectedNormal = new Vector3(meshNormal.x * MAX_GEOMETRY_DIMENSION * 1.5,
        meshNormal.y * MAX_GEOMETRY_DIMENSION * 1.5,
        meshNormal.z * MAX_GEOMETRY_DIMENSION * 1.5);

    // Find the 'mock' camera position in world space
    const cameraPosition: Vector3 = meshCenter.clone().add(projectedNormal);
    const cameraDirection = new Vector3(-meshNormal.x, -meshNormal.y, -meshNormal.z);

    const surfaceHit: string | undefined = TraceIntersectionFromPosition(cameraPosition, cameraDirection, scene);

    return (surfaceHit === surfaceId);
}

// Finds the normal of a mesh in world space
function getNormal(surfaceId: string, mesh: Mesh, scene: Scene): Vector3 | undefined {
    if (mesh.geometry instanceof Geometry) {
        const geometry = mesh.geometry as Geometry;

        // Take the first face and convert the normal to world space
        if (geometry.faces.length > 0 && geometry.faces[0].vertexNormals.length > 0) {
            const faceNormal: Vector3 = geometry.faces[0].vertexNormals[0].clone();
            const meshNormal: Vector3 = faceNormal.transformDirection(mesh.matrixWorld);

            // Find the center of the geometry for it's focal point
            const meshBounds = new Box3().setFromObject(mesh);
            const meshCenter: Vector3 = meshBounds.getCenter(new Vector3());

            // The surface was directly visible from the camera
            if (detectSurfaceHit(surfaceId, meshCenter, meshNormal, scene)) {
                return meshNormal;
            }
            // The following code is searches for a visible surface hit between -90 and 90 degrees
            // from the target normal. Instead of using two for loops that go between
            // -90 to 90 for each axis, this code uses 4 that go between 0 to 90 and 0 to -90. It is done
            // this way to ensure the first valid surface hit looks visually pleasing. For example,
            // a surface could be visible at any rotation above 30 degrees. It would be preferable
            // to use 30 instead of higher angles so the camera isn't staring straight down at the model. This
            // is why each axis is analyzed starting from 0, instead of starting from the 90 or -90 extremes.

            // Check angles in 45 degree increments
            const angleIncrement = Math.PI / 6;

            const sphericalNormal = new Spherical().setFromVector3(meshNormal);
            const initialPhi = sphericalNormal.phi;
            const initialTheta = sphericalNormal.theta;

            // Try checking up the positive phi axis
            for (let phiOffset = 0; phiOffset < HALF_PI; phiOffset += angleIncrement) {
                sphericalNormal.phi = initialPhi + phiOffset;
                const rotatedNormal = new Vector3().setFromSpherical(sphericalNormal);

                if (detectSurfaceHit(surfaceId, meshCenter, rotatedNormal, scene)) {
                    return rotatedNormal;
                }
            }

            // Try checking down the negative phi axis
            for (let phiOffset = 0; phiOffset > -HALF_PI; phiOffset -= angleIncrement) {
                sphericalNormal.phi = initialPhi + phiOffset;
                const rotatedNormal = new Vector3().setFromSpherical(sphericalNormal);

                if (detectSurfaceHit(surfaceId, meshCenter, rotatedNormal, scene)) {
                    return rotatedNormal;
                }
            }

            // Reset the phi axis before checking theta
            sphericalNormal.phi = initialPhi;

            // Try checking up the positive theta axis
            for (let thetaOffset = 0; thetaOffset < HALF_PI; thetaOffset += angleIncrement) {
                sphericalNormal.theta = initialTheta + thetaOffset;
                const rotatedNormal = new Vector3().setFromSpherical(sphericalNormal);

                if (detectSurfaceHit(surfaceId, meshCenter, rotatedNormal, scene)) {
                    return rotatedNormal;
                }
            }

            // Try checking down the negative theta axis
            for (let thetaOffset = 0; thetaOffset > -HALF_PI; thetaOffset -= angleIncrement) {
                sphericalNormal.theta = initialTheta + thetaOffset;
                const rotatedNormal = new Vector3().setFromSpherical(sphericalNormal);

                if (detectSurfaceHit(surfaceId, meshCenter, rotatedNormal, scene)) {
                    return rotatedNormal;
                }
            }

            return undefined;
        }
    }

    return undefined;
}

export async function ComputeFocalPoints(product: VortexProduct, meshMap: { [key: string]: Mesh }, preview: Preview): Promise<void> {
    const surfaceMapping: { [key: string]: ProductSurface } = product.getSurfaceMapping();

    // Get the initial state of the product and all existing states
    const initialStateId: string = product.getCurrentState().id;
    const productStates: ProductState[] = product.getStates();

    // Go through all product states to determine the best focal point for each mesh
    for (const productState of productStates) {
        // Skip states that don't require dynamic focal points
        if (!productState.allowGeneratedFocalPoints) continue;

        // Switch the product to the current state
        await changeWorldState(productState.id, preview);

        // Attempt to find the focal state of each surface
        for (const surfaceId in surfaceMapping) {
            const productSurface: ProductSurface = surfaceMapping[surfaceId];

            // Don't generate focal points for non customizable pages
            if (!productSurface.page) continue;

            // A focal point already exists for this surface
            if (productSurface.focalPoint) {
                const { focalPoint } = productSurface;

                // If the focal point is already set for this state continue
                if (focalPoint.allowedStates?.includes(productState.id)) {
                    continue;
                } else { // Otherwise determine if its the same for the current state
                    // @ts-ignore
                    const normal: Vector3 | undefined = getNormal(surfaceId, meshMap[surfaceId], preview.scene);

                    // The normal was found and the surface is visible from the current state
                    if (normal) {
                        const existingCameraView: Vector3 = focalPoint.cameraView;
                        const currentCameraView = new Vector3(-normal.x, -normal.y, -normal.z);

                        // Add the current state to the allowed states if it has the same camera view
                        if (Math.abs(existingCameraView.x - currentCameraView.x) < EPSILON
                            && Math.abs(existingCameraView.y - currentCameraView.y) < EPSILON
                            && Math.abs(existingCameraView.z - currentCameraView.z) < EPSILON) {
                            focalPoint.allowedStates?.push(productState.id);
                        }
                    }
                }
            } else if (meshMap[surfaceId]) { // Otherwise try to compute it for valid surfaces
                // @ts-ignore
                const normal: Vector3 | undefined = getNormal(surfaceId, meshMap[surfaceId], preview.scene);

                // The normal was found and the surface is visible from the current state
                if (normal) {
                    productSurface.focalPoint = {
                        cameraView: new Vector3(-normal.x, -normal.y, -normal.z),
                        allowedStates: [productState.id],
                    };
                }
            }
        }
    }

    // Reset back to the initial state after computing focal points
    await changeWorldState(initialStateId, preview);
}
