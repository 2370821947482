import { RenderSize, Size2D, disposeGeometryRecursively, buildPointLightGrid, Rotation3D, Position3D } from '@rendering/vortex-core/common';
import { ProductState, ProductSurface, VortexProduct } from '@rendering/vortex-core/products';
import { buildSurfaceMapRecursively, centerModel, getSurfaceMapping, ModelConfig } from '../common';
import { Group, DefaultLoadingManager, Scene, Light } from 'three';
import ColladaLoader from './colladaLoader';
import { AnimationOptions, Animation } from '@rendering/vortex-core/animations';

export class ColladaProduct implements VortexProduct {
    name: string = 'collada';

    private modelUri: string;
    private scene?: Scene;
    private meshGroup?: Group;
    private rotationOffset?: Rotation3D;
    private positionOffset?: Position3D;
    private surfaceMapping: { [key: string]: ProductSurface };

    constructor(config: ModelConfig) {
        this.modelUri = config.modelUri;

        this.rotationOffset = config.rotationOffset;
        this.positionOffset = config.positionOffset;

        this.surfaceMapping = getSurfaceMapping(config.productMapping, config.fullBleed);
    }

    get requiresGeometryUpdate() {
        return false;
    }

    async getGeometry(): Promise<Group> {
        if (this.meshGroup) {
            return this.meshGroup;
        }

        await this.preLoad();

        if (this.scene === undefined) {
            throw new Error("Failed to load model from uri");
        }

        this.meshGroup = new Group;
        this.meshGroup.add(this.scene);

        return centerModel(this.positionOffset, this.meshGroup);
    }

    getSurfaceMapping() {
        return this.surfaceMapping;
    }

    setProductSurface(surfaceId: string, productSurface: ProductSurface) {
        if (this.surfaceMapping[surfaceId]) {
            this.surfaceMapping[surfaceId] = productSurface;
        } else {
            throw new Error(`'${surfaceId}' is not a surface on this modeled product`);
        }
    };

    getAlphaMap(renderSize: RenderSize, physicalSize: Size2D, context: CanvasRenderingContext2D): void { }

    getAreaLights(pointLightIntensity: number, directionalLightIntensity: number): Light[] {
        const pointLightIntensities: number[] = [
            0.4 * pointLightIntensity, 0.4 * pointLightIntensity,
            0.37 * pointLightIntensity, 0.37 * pointLightIntensity,
            0.43 * pointLightIntensity, 0.40 * pointLightIntensity
        ];

        return buildPointLightGrid(pointLightIntensities);
    }

    getStates(): ProductState[] {
        return [{ id: 'default' }];
    }

    setStates(productState: ProductState[]): void { }

    getCurrentState(): ProductState {
        return { id: 'default' };
    }

    setCurrentState(stateId: string) { }

    getAnimationIds(): string[] {
        return [];
    }

    buildAnimation(animationOptions: AnimationOptions): Animation {
        throw 'Method not implemented for Collda products.'
    }

    preLoad() {
        if (this.scene !== undefined) {
            return Promise.resolve();
        }
        const loader = new ColladaLoader(DefaultLoadingManager);

        return new Promise<void>((resolve) => {
            loader.load(this.modelUri, (collada) => {
                // If the collada model fails to load or has no scene
                if (!collada || !collada.scene) {
                    resolve();
                }
                this.scene = collada.scene;

                // Apply rotation offsets before the model is re-sized
                if (this.rotationOffset) {
                    collada.scene.rotation.set(this.rotationOffset.yaw,
                        this.rotationOffset.pitch,
                        this.rotationOffset.roll);
                }

                // model surface is not mapped. Map each surface to specified substrate in surface 'primary'
                if (Object.keys(this.surfaceMapping).length === 1 && this.surfaceMapping.primary) {
                    let meshSurfaces: { [key: string]: ProductSurface } = {};
                    buildSurfaceMapRecursively(meshSurfaces, this.scene, this.surfaceMapping.primary);
                    this.surfaceMapping = meshSurfaces;
                }

                resolve();
            });
        });
    }

    dispose() {
        // TODO handle disposal correctly
        this.scene && disposeGeometryRecursively(this.scene);
        this.scene = undefined;
        this.meshGroup = undefined;
    }
}
