import defaults from './defaults';
import { Substrate } from './types';

export function getFabricSubstrate(): Substrate {
    return {
        ...defaults,
        emissive: 0x0a0a0a,
        bumpScale: 0.4,
        shininess: 40,
        bumpTexture: {
            dpi: 800,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/fabric/v1/bump',
        },
    };
}
