import defaults from './defaults';
import { Substrate } from './types';

export function getNaturalSubstrate(): Substrate {
    return {
        ...defaults,
        bumpScale: 1.5,
        bumpTexture: {
            dpi: 200,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/natural/v1/bump',
        },
    };
}
