import { css } from '@emotion/react'

export const customScrollStyle = css`
    *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    *::-webkit-scrollbar-track {
        background: #FFF;
    }

    *::-webkit-scrollbar-thumb {
        background: #C4CDD6;
        border-radius: 8px;
    }

    *::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    *::-moz-scrollbar {
        width: 8px;
        height: 8px;
    }

    *::-moz-scrollbar-track {
        background: #FFF;
    }

    *::-moz-scrollbar-thumb {
        background: #C4CDD6;
        border-radius: 8px;
    }

    *::-moz-scrollbar-thumb:hover {
        background: #555;
    }

    *::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0);
    }
`;
