import { IsDevMode } from './constants';

enum LOG_LEVEL {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
}

function productionLogging(log: { message: string; level: LOG_LEVEL }): void {
    // log to production
    console.log(log);
}

export function logInfo(message: string): void {
    if (IsDevMode) {
        console.log(message); // eslint-disable-line no-console
    }
    productionLogging({ message: message, level: LOG_LEVEL.INFO });
}

export function logError(message: string): void {
    if (IsDevMode) {
        console.error(message); // eslint-disable-line no-console
    }
    productionLogging({ message, level: LOG_LEVEL.WARNING });
}
