import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const DEFAULT_TAG = 'default';

const ICON_COLOR = {
  [DEFAULT_TAG]: '#697582',
  error: '#d24a35',
  warning: '#ffa23f',
};

const NUMBER_OF_ICONS_VERTICAL = {
  xl: 3,
  l: 1,
  m: 1,
  s: 0,
  [DEFAULT_TAG]: 0,
};

const NUMBER_OF_ICONS_HORIZONTAL = {
  xl: 3,
  l: 2,
  m: 2,
  s: 0,
  [DEFAULT_TAG]: 0,
};

const NUMBER_OF_DESCRIPTION_ROWS_VERTICAL = {
  xl: 3,
  l: 1,
  m: 1,
  s: 1,
  [DEFAULT_TAG]: 0,
};

const NUMBER_OF_DESCRIPTION_ROWS_HORIZONTAL = {
  xl: 3,
  l: 3,
  m: 2,
  s: 1,
  [DEFAULT_TAG]: 0,
};

const LOADER_SIZE = {
  xl: 'large',
  l: 'large',
  m: 'medium',
  s: 'medium',
  [DEFAULT_TAG]: 'medium',
};

export const getLoaderSize = (size) => {
  if (isEmpty(size)) {
    return LOADER_SIZE[DEFAULT_TAG];
  }
  return get(LOADER_SIZE, size, LOADER_SIZE[DEFAULT_TAG]);
};

export const getIconColor = (type) => {
  if (isEmpty(type)) {
    return ICON_COLOR[DEFAULT_TAG];
  }
  return get(ICON_COLOR, type, ICON_COLOR[DEFAULT_TAG]);
};

export const getNumberOfAllowedActionIcons = (size, orientation) => {
  const iconCountData = orientation === 'horizontal' ? NUMBER_OF_ICONS_HORIZONTAL : NUMBER_OF_ICONS_VERTICAL;
  if (isEmpty(size)) {
    return iconCountData[DEFAULT_TAG];
  }
  return get(iconCountData, size, iconCountData[DEFAULT_TAG]);
};

export const getNumberOfAllowedDescriptionRows = (size, orientation) => {
  const countData = orientation === 'horizontal' ? NUMBER_OF_DESCRIPTION_ROWS_HORIZONTAL : NUMBER_OF_DESCRIPTION_ROWS_VERTICAL;
  if (isEmpty(size)) {
    return countData[DEFAULT_TAG];
  }
  return get(countData, size, countData[DEFAULT_TAG]);
};
