import Axios from 'axios';
import { Substrate } from '@rendering/vortex-core/substrates';
import { CanvasPrintData, ProductSurface, VortexProductConfig } from '../configs';
import { loadSubstrateMap } from './materialLoader';
import { CanvasPrintFrameOptions, CanvasPrintProduct, CanvasPrintProductConfig } from '@rendering/vortex-canvas-prints';

export async function getCanvasPrintProduct(productConfig: VortexProductConfig): Promise<CanvasPrintProduct> {
    const dataResponse = await Axios.get<CanvasPrintData>(productConfig.productDataUrl);

    if (dataResponse.status >= 400) {
        throw new Error(`Failed to fetch CanvasPrintData from '${productConfig.productDataUrl}'!`)
    }

    const canvasPrintData: CanvasPrintData = dataResponse.data;

    // Load all the substrates
    const substrateMap: { [key: string]: Substrate } = await loadSubstrateMap(productConfig);
    const surfaceMapping: { [key: string]: ProductSurface } = productConfig.surfaceMapping;

    // Validate surfaces exist for the canvas and interior frame
    if (!surfaceMapping['canvas'] || !surfaceMapping['interiorFrame']) {
        throw new Error('The surface mapping must contain surfaces for canvas and interiorFrame!');
    }

    // Fill out the required options for canvas prints
    let canvasPrintConfig: CanvasPrintProductConfig = {
        fullBleed: canvasPrintData.fullBleed,
        trim: canvasPrintData.trim,
        depth: canvasPrintData.depth,
        fabricSubstrate: substrateMap[surfaceMapping['canvas'].substrateId],
        interiorOptions: {
            frameOptions: {
                thickness: canvasPrintData.interiorOptions.frameOptions.thickness,
                substrate: substrateMap[surfaceMapping['interiorFrame'].substrateId]
            }
        }
    };

    // Add covers if they exist
    if (canvasPrintData.exteriorOptions?.cover) {
        if (!surfaceMapping['cover']) {
            throw new Error('The surface mapping is missing a cover!');
        }

        canvasPrintConfig.exteriorOptions = {
            cover: {
                substrate: substrateMap[surfaceMapping['cover'].substrateId],
                depth: canvasPrintData.exteriorOptions.cover.depth
            }
        };
    }

    // Add exterior frames if they exist
    if (canvasPrintData.exteriorOptions?.frameOptions) {
        if (!surfaceMapping['exteriorFrame']) {
            throw new Error('The surface mapping is missing an exteriorFrame!');
        }

        const exteriorFrameOptions: CanvasPrintFrameOptions = {
            substrate: substrateMap[surfaceMapping['exteriorFrame'].substrateId],
            inner: {
                depth: canvasPrintData.exteriorOptions?.frameOptions.inner.depth,
                thickness: canvasPrintData.exteriorOptions?.frameOptions.inner.thickness
            },
            outer: {
                depth: canvasPrintData.exteriorOptions?.frameOptions.outer.depth,
                thickness: canvasPrintData.exteriorOptions?.frameOptions.outer.thickness
            }
        };

        // If a external frame options where already defined with a cover add them
        if (canvasPrintConfig.exteriorOptions) {
            canvasPrintConfig.exteriorOptions.frameOptions = exteriorFrameOptions;
        } else {
            canvasPrintConfig.exteriorOptions = {
                frameOptions: exteriorFrameOptions
            }
        }
    }

    return new CanvasPrintProduct(canvasPrintConfig);
}