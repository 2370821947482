import Axios from 'axios';
import { SurfaceSpecification } from '../externalModels';

const SURFACE_SPEC_URL = 'https://cdn-surface.products.cimpress.io/v3/calculatedSurfaceSets/';

export async function getSurfaceSpecification(bearerToken: string, mcpSku: string, selectedOptions?: { [key: string]: string | number }): Promise<SurfaceSpecification> {
    let specUrl = `${SURFACE_SPEC_URL}${mcpSku}`;
    if (selectedOptions) {
        const queryData = JSON.stringify(selectedOptions);
        specUrl += `?variables=${encodeURIComponent(queryData)}`;
    }

    const axiosConfig = {
        headers: {
            'Authorization': `Bearer ${bearerToken}`
        },
        validateStatus: () => true
    };

    const surfaceSpec = await Axios.get<SurfaceSpecification>(specUrl, axiosConfig);

    if (surfaceSpec.status >= 400) {
        throw `Failed to fetch product specification: ${JSON.stringify(surfaceSpec.data)}}`;
    }

    return surfaceSpec.data;
}