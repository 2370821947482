import md5 from 'md5';
import { Color, Vector2 } from 'three';
import { memoize } from '../common/memoize';
import { Size2D } from '../common/sizes';
import { CompositeMode } from './renderingModes';

const MM_TO_IN = 0.0393701;

export interface TiledTexture {
    dpi: number,
    sourceUrl: string,
    overlayColor?: string
}

// Converts a global position into normalized coordinates relative to the canvas center
export function normalizePosition(x: number, y: number, canvas: HTMLCanvasElement): Vector2 {
    const canvasBounds = canvas.getBoundingClientRect();

    const canvasX = x - canvasBounds.left;
    const canvasY = y - canvasBounds.top;

    return new Vector2((canvasX / canvasBounds.width) * 2 - 1, -(canvasY / canvasBounds.height) * 2 + 1);
}

/**
 * Uses an html image element to load an image
 * @param imageUrl A string to the uri to load the image from
 * @returns HTMLImageElement
 */
export function loadImage(imageUrl: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const sourceImg = new Image();
        sourceImg.crossOrigin = 'Anonymous';

        sourceImg.onload = () => {
            resolve(sourceImg);
        };

        sourceImg.onerror = () => {
            reject(new Error(`Could not fetch url: ${imageUrl}`));
        };

        sourceImg.src = imageUrl;
    });
}

export function buildCanvas(width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;

    return canvas;
}

export async function loadCanvas(imageUrl: string): Promise<HTMLCanvasElement> {
    const imageTexture = await loadImage(imageUrl);

    const canvas: HTMLCanvasElement = buildCanvas(imageTexture.width, imageTexture.height);

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.drawImage(imageTexture, 0, 0);

    return canvas;
}

/**
 * Generates an HTMLCanvasElement of a solid color
 * @param size Width + Height of the canvas to return
 * @param color The hex value of a color
 * @returns HTMLCanvasElement of a solid color
 */
export function colorCanvas(size: Size2D, color: string): HTMLCanvasElement {
    const canvas: HTMLCanvasElement = buildCanvas(size.width, size.height);

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.fillStyle = color;
    context.fillRect(0, 0, size.width, size.height);

    return canvas;
}

export function compositeImages(
    imageOne: HTMLImageElement | HTMLCanvasElement,
    imageTwo: HTMLImageElement | HTMLCanvasElement,
    mode: CompositeMode,
): HTMLCanvasElement {
    const canvas: HTMLCanvasElement = buildCanvas(imageOne.width, imageOne.height);

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    context.drawImage(imageOne, 0, 0);
    context.globalCompositeOperation = mode;
    context.drawImage(imageTwo, 0, 0);

    return canvas;
}

function buildTileKey(tiledTexture: TiledTexture, textureSize: Size2D, physicalSize: Size2D): string {
    return md5([tiledTexture.dpi, tiledTexture.sourceUrl, tiledTexture.overlayColor, textureSize.width,
        textureSize.height, physicalSize.width, physicalSize.height].join('-'));
}

async function tileImageInternal(tiledTexture: TiledTexture, textureSize: Size2D, physicalSize: Size2D): Promise<HTMLCanvasElement> {
    const textureDpiX = textureSize.width / (physicalSize.width * MM_TO_IN);
    const textureDpiY = textureSize.height / (physicalSize.height * MM_TO_IN);

    // Otherwise build the tile promise
    const tileImage = await loadImage(tiledTexture.sourceUrl);
    const scaleCanvas = document.createElement('canvas');

    // First scale the tile texture based on it's DPI and the target DPI
    scaleCanvas.width = Math.max(tileImage.naturalWidth * (textureDpiX / tiledTexture.dpi), 1);
    scaleCanvas.height = Math.max(tileImage.naturalHeight * (textureDpiY / tiledTexture.dpi), 1);

    const scaleContext = scaleCanvas.getContext('2d') as CanvasRenderingContext2D;
    scaleContext.drawImage(tileImage, 0, 0, scaleCanvas.width, scaleCanvas.height);

    // If the tile texture has an overlay color apply that first
    // Otherwise see if the texture has a color and overlay that
    if (tiledTexture.overlayColor) {
        scaleContext.globalCompositeOperation = CompositeMode.overlay;
        scaleContext.fillStyle = `#${new Color(tiledTexture.overlayColor).getHexString()}`;
        scaleContext.fillRect(0, 0, scaleCanvas.width, scaleCanvas.height);
    }

    const tileCanvas: HTMLCanvasElement = buildCanvas(textureSize.width, textureSize.height);
    const tileContext = tileCanvas.getContext('2d') as CanvasRenderingContext2D;

    // Tile the scaled texture so it fills the desired texture size
    tileContext.rect(0, 0, textureSize.width, textureSize.height);
    tileContext.fillStyle = tileContext.createPattern(scaleCanvas, 'repeat') as CanvasPattern;
    tileContext.fill();

    return tileCanvas;
}

export const tileImage = memoize(tileImageInternal, buildTileKey);
