import { Object3D, Vector3, Quaternion, Euler, Group } from 'three';
import { Animation, AnimationDirection } from '@rendering/vortex-core/animations';
import { Fold, FoldOperation, FoldSequence, NFoldConfig, SectionSet } from './types2';
import { NFoldProduct2 } from './nfoldProduct2';
import { easeInOutQuart, Size3D } from '@rendering/vortex-core/common';
import { mirror, preserve } from '../common/pathHelper';

const DEGREES_TO_RADIANS: number = 0.0174533;

export interface FoldAnimationOptions2 {
    duration: number;
    direction: AnimationDirection;

    sequenceId: string;
}

interface FoldState {
    fold: Fold;

    startRatio: number;
    endRatio: number;

    startAngle: number;
    endAngle: number;
}

export class FoldingAnimation implements Animation {
    private completionStatus: boolean = false;
    private activeStatus: boolean = false;
    private canceledStatus: boolean = false;

    private elapsedTime: number = 0;
    private duration: number;
    private direction: AnimationDirection;

    private product: NFoldProduct2;
    private config: NFoldConfig;
    private primarySectionSet: SectionSet;

    private foldStates: { [key: string]: FoldState[] };
    private physicalSize: Size3D;

    private resolveAnimation?: (value: void | PromiseLike<void>) => void;

    constructor(product: NFoldProduct2, config: NFoldConfig, options: FoldAnimationOptions2) {
        this.product = product;
        this.config = config;
        this.primarySectionSet = config.sectionSets[0];

        this.physicalSize = product.getPhysicalSize();

        this.foldStates = this.generateFoldStates(options.sequenceId, config);
        this.duration = options.duration;
        this.direction = options.direction;
    }

    get isComplete(): boolean {
        return this.completionStatus;
    }

    get isActive(): boolean {
        return this.activeStatus;
    }

    get tags(): string[] {
        return [];
    }

    get isCanceled(): boolean {
        return this.canceledStatus;
    }

    resetMeshState() {
        this.activeStatus = false;
        this.interpolateValues(this.duration);
        this.resolveAnimation && this.resolveAnimation();
    }

    update(delta: number) {
        if (this.isActive && this.elapsedTime < this.duration) {
            this.interpolateValues(this.elapsedTime);
            this.elapsedTime += delta;
        }

        if (this.elapsedTime >= this.duration && this.isActive) {
            this.interpolateValues(this.duration);
            this.resolveAnimation && this.resolveAnimation();
            this.activeStatus = false;
        }
    }

    start() {
        this.activeStatus = true;
        return new Promise<void>((resolve, _reject) => {
            this.resolveAnimation = resolve;
        });
    }

    resume() {
        if (this.resolveAnimation !== undefined) {
            this.activeStatus = true;
        }
    }

    stop(): void {
        this.activeStatus = false;
    }

    cancel() {
        this.activeStatus = false;
        this.canceledStatus = true;
        this.resetMeshState();
        if (this.resolveAnimation) {
            this.resolveAnimation();
        }
    }

    private getFoldSequence(id: string): FoldSequence {
        for (let i = 0; i < this.primarySectionSet.foldSequences.length; i++) {
            if (this.primarySectionSet.foldSequences[i].id === id) {
                return this.primarySectionSet.foldSequences[i];
            }
        }

        throw new Error(`Fold sequence '${id}' is not an available in the NFold Config!`);
    }

    private getFold(id: string): Fold {
        for (let i = 0; i < this.primarySectionSet.folds.length; i++) {
            if (this.primarySectionSet.folds[i].id === id) {
                return this.primarySectionSet.folds[i];
            }
        }

        throw new Error(`Fold '${id}' is not an available in the NFold Config!`);
    }

    // Finds the last operation to occur for a specific fold id (max ending percentage)
    private getLastOperationById(foldId: string, sequence: FoldSequence): FoldOperation {
        let maxEndingRatio: number = 0;
        let lastOperation: FoldOperation | undefined = undefined;

        for (let foldOperation of sequence.operations) {
            if (foldOperation.foldId === foldId && foldOperation.endRatio > maxEndingRatio) {
                lastOperation = foldOperation;
                maxEndingRatio = foldOperation.endRatio;
            }
        }

        if (lastOperation === undefined) {
            throw new Error(`Fold operation '${foldId}' is not an available in the fold sequence '${sequence.id}'!`);
        }

        return lastOperation;
    }

    private generateFoldStates(state: string, config: NFoldConfig): { [key: string]: FoldState[] } {
        const foldStates: { [key: string]: FoldState[] } = {};

        const sequence: FoldSequence = this.getFoldSequence(state);

        // The sequence has a dependency on an existing state for fold values
        if (sequence.dependencyId) {
            const parentSequence: FoldSequence = this.getFoldSequence(sequence.dependencyId);

            const groupedFoldOperations: { [key: string]: FoldOperation[] } = this.groupOperationsByFold(sequence.operations);

            for (let foldId in groupedFoldOperations) {
                // Get the fold for this operation group
                const fold: Fold = this.getFold(foldId);

                foldStates[fold.childSectionId] = [];
                const foldOperations: FoldOperation[] = groupedFoldOperations[foldId];

                for (let i = 0; i < foldOperations.length; i++) {
                    let parentOperation: FoldOperation;

                    // If this is the first operation in the sequence, its parent is from the parent sequence
                    if (i === 0) {
                        parentOperation = this.getLastOperationById(foldId, parentSequence);
                    } else {
                        // Otherwise the parent is the previous fold in the current sequence
                        parentOperation = foldOperations[i - 1];
                    }

                    // Set the fold state of the child section (effected section) for this fold
                    foldStates[fold.childSectionId].push({
                        fold,
                        startRatio: foldOperations[i].startRatio,
                        endRatio: foldOperations[i].endRatio,
                        startAngle: parentOperation.angle,
                        endAngle: foldOperations[i].angle,
                    });
                }
            }
        } else { // Otherwise assume the 'open' state (all base operations at 0)
            const groupedFoldOperations: { [key: string]: FoldOperation[] } = this.groupOperationsByFold(sequence.operations);

            for (let foldId in groupedFoldOperations) {
                // Get the fold for this operation group
                const fold: Fold = this.getFold(foldId);

                foldStates[fold.childSectionId] = [];
                const foldOperations: FoldOperation[] = groupedFoldOperations[foldId];

                for (let i = 0; i < foldOperations.length; i++) {
                    // The parent angle is 0 to start for the first operation
                    let parentAngle: number = 0;

                    // If multiple fold operations exist in the current sequence, the parentAngle should the previous fold angle
                    if (i > 0) {
                        parentAngle = foldOperations[i - 1].angle;
                    }

                    // Set the fold state of the child section (effected section) for this fold
                    foldStates[fold.childSectionId].push({
                        fold,
                        startRatio: foldOperations[i].startRatio,
                        endRatio: foldOperations[i].endRatio,
                        startAngle: parentAngle,
                        endAngle: foldOperations[i].angle,
                    });
                }
            }
        }

        return foldStates;
    }

    // Group all fold operations by their fold id
    private groupOperationsByFold(foldOperations: FoldOperation[]): { [key: string]: FoldOperation[] } {
        const groupedOperations: { [key: string]: FoldOperation[] } = {};

        for (let foldOperation of foldOperations) {
            if (!groupedOperations[foldOperation.foldId]) {
                groupedOperations[foldOperation.foldId] = [foldOperation];
            } else {
                groupedOperations[foldOperation.foldId].push(foldOperation);
            }
        }

        return groupedOperations;
    }

    private interpolateValues(time: number) {
        // Compute the animation ratio based on direction
        const animationRatio = this.direction === AnimationDirection.forward ? time / this.duration : 1.0 - time / this.duration;

        const sectionGroupMap: { [key: string]: Group; } = this.product.getSectionGroupMapping();

        // Animate each section
        for (let sectionId in sectionGroupMap) {
            // The section may not have any operations
            if (!this.foldStates[sectionId]) continue;

            const foldStates: FoldState[] = this.foldStates[sectionId];

            // Run all fold states for the section
            for (let i = 0; i < foldStates.length; i++) {
                const foldState: FoldState = foldStates[i];

                // Clamp the animation to its starting state
                if (animationRatio <= foldState.startRatio) {
                    if (i === 0) {
                        this.foldSection(sectionId, 0, foldState, sectionGroupMap);
                    }
                    // Clamp the animation to it's ending state
                } else if (animationRatio >= foldState.endRatio) {
                    if (i === foldStates.length - 1) {
                        this.foldSection(sectionId, 1, foldState, sectionGroupMap);
                    }
                    // Interpolate between the starting and ending ratios
                } else {
                    const relativeTime = animationRatio - foldState.startRatio;
                    const relativeDuration = foldState.endRatio - foldState.startRatio;

                    // Interpolate between 0-1 based on relative ratios
                    const interpolatedPercent = easeInOutQuart(relativeTime, 0, 1, relativeDuration);

                    this.foldSection(sectionId, interpolatedPercent, foldState, sectionGroupMap);
                }
            }
        };
    }

    private foldSection(sectionId: string, animationRatio: number, foldState: FoldState, sectionGroupMap: { [key: string]: Group; }) {
        const foldAngle = -DEGREES_TO_RADIANS * ((foldState.endAngle - foldState.startAngle) * animationRatio + foldState.startAngle)

        // Translate the fold line into coordinates with 0,0 being the bottom left
        const foldLine = {
            x0: preserve(foldState.fold.path.primary[0].x, this.physicalSize.width),
            y0: mirror(foldState.fold.path.primary[0].y, this.physicalSize.height),
            x1: preserve(foldState.fold.path.primary[1].x, this.physicalSize.width),
            y1: mirror(foldState.fold.path.primary[1].y, this.physicalSize.height)
        }

        // Reset group to initial state
        sectionGroupMap[sectionId].rotation.set(0, 0, 0);
        sectionGroupMap[sectionId].position.set(0, 0, 0);

        const foldVector = new Vector3(foldLine.x1, foldLine.y1, this.primarySectionSet.thickness);

        const foldAxis = new Vector3(foldLine.x1 - foldLine.x0, foldLine.y1 - foldLine.y0).normalize();
        const rotationPoint = this.rotateVector(foldVector, new Vector3(), new Euler());

        // Apply new position / rotation state
        this.applyRotation(sectionGroupMap[sectionId], rotationPoint, foldAngle, foldAxis);
    }

    private rotateVector(edge: Vector3, pivot: Vector3, rotation: Euler): Vector3 {
        let result = edge.sub(pivot);
        result = result.applyEuler(rotation);
        result = result.add(pivot);
        return result;
    }

    private applyRotation(section: Object3D, rotationPoint: Vector3, rotationAngle: number, rotationAxis: Vector3): Object3D {
        const quaternion = new Quaternion();
        quaternion.setFromAxisAngle(rotationAxis, rotationAngle);
        section.quaternion.multiplyQuaternions(quaternion, section.quaternion);
        section.position.sub(rotationPoint);
        section.position.applyQuaternion(quaternion);
        section.position.add(rotationPoint);
        return section;
    }
}