/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { colors } from '@cimpress/react-components';
import IconNavigationMenu from '@cimpress-technology/react-streamline-icons/lib/IconNavigationMenu';
import './ThreeDotMenu.css';

class ThreeDotMenu extends PureComponent {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode.contains(event.target)) {
      this.props.onClickOutside();
    }
  };

  renderListElement = (element, callbackData) => {
    const onClick = (event) => {
      element.onClick(event, callbackData);
    };
    return (
      <li key={element.title} className="menuItem">
        <a role="button" tabIndex="0" onClick={onClick}>
          {element.icon}
          <span>{element.title}</span>
        </a>
      </li>
    );
  };

  renderContextMenu = (props) => {
    const { callbackData, menuItems } = props;
    return (
      <div className="contextMenu">
        <ul>{menuItems.map(x => this.renderListElement(x, callbackData))}</ul>
      </div>
    );
  };

  render() {
    const ContextMenu = this.renderContextMenu;
    const {
      className, callbackData, onMenuIconClick, showMenu,
    } = this.props;
    return (
      <div
        className={`threeDotMenuContainer ${className}`}
        onClick={onMenuIconClick}
        role="button"
        tabIndex="0"
      >
        <a role="button" tabIndex="0">
          <IconNavigationMenu color={colors.info.base} />
        </a>
        {showMenu ? (
          <ContextMenu callbackData={callbackData} {...this.props} />
        ) : null}
      </div>
    );
  }
}

ThreeDotMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  showMenu: PropTypes.bool,
  onMenuIconClick: PropTypes.func,
  onClickOutside: PropTypes.func,
  callbackData: PropTypes.string,
};

ThreeDotMenu.defaultProps = {
  className: '',
  showMenu: false,
  onMenuIconClick: () => null,
  onClickOutside: () => null,
  callbackData: null,
};

export default ThreeDotMenu;
