export interface Animation {
    isComplete: boolean;
    isActive: boolean;
    tags: string[];
    isCanceled: boolean;

    resetMeshState: () => void;

    update: (delta: number) => void;

    start: () => Promise<void>;
    stop(): void;

    cancel: () => void;
    resume: () => void;
}

export enum AnimationDirection {
    forward,
    backward
}

export interface AnimationOptions {
    animationId: string;
    direction: AnimationDirection;
    duration: number;
}
