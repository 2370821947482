import { PerspectiveCamera } from 'three';
import { OrbitControls, OrbitOptions } from './orbitControls';

export interface HoverEventOptions {
    hoverStart?: () => void;
    hoverEnd?: () => void;
    hoverAnimate?: (azimuthAngle: number, polarAngle: number, duration?: number) => void;
    invertXAxis?: boolean;
    invertYAxis?: boolean;
}

export interface FovSettings {
    vertical?: number;
    horizontal?: number;
}

const defaultOptions: Required<HoverEventOptions> = {
    hoverStart: () => { },
    hoverEnd: () => { },
    hoverAnimate: () => { },
    invertXAxis: false,
    invertYAxis: false,
};

const defaultFov: FovSettings = {
    vertical: 40,
    horizontal: 45,
};

export class OrbitControlsWithHover extends OrbitControls {
    private _enableHover = false;
    private hasInitialized: boolean;
    private fov: { vertical?: number, horizontal?: number };
    private captureMouse: boolean;
    private isClickDragging = false;
    private hoverStartAzimuth = 0;
    private hoverStartPolar: number;
    private hoverSettings: Required<HoverEventOptions>;

    private static readonly RESET_CAMERA_DURATION = 0.01;
    private static readonly HOVER_CAMERA_DURATION = 0.08;

    public constructor(object: PerspectiveCamera, domElement: HTMLElement, fov?: FovSettings, hoverOptions?: HoverEventOptions, orbitOptions?: OrbitOptions) {
        super(object, domElement, undefined, orbitOptions);

        this.fov = { ...defaultFov, ...fov };
        this.hoverSettings = { ...defaultOptions, ...hoverOptions };
        this.captureMouse = true;
        this.hasInitialized = false;

        // Perform initialization of positional information on first update
        const _update = this.update.bind(this);
        this.update = () => {
            const res = _update();

            if (!this.hasInitialized) {
                this.hoverStartAzimuth = this.getAzimuthalAngle();
                this.hoverStartPolar = this.getPolarAngle();
                this.hasInitialized = true;
            }
            return res;
        };

        this.hoverStartAzimuth = this.getAzimuthalAngle();
        this.hoverStartPolar = this.getPolarAngle();
    }

    private get hasStartingPosition() {
        return this.hoverStartAzimuth !== undefined
            && this.hoverStartPolar !== undefined
            && this.hasInitialized;
    }

    public get enableHover() { return this._enableHover; }

    public set enableHover(enabled: boolean) {
        this._enableHover = enabled;

        if (enabled) {
            // @ts-ignore
            this.domElement.addEventListener('mouseenter', this.onMouseEnterLocal);
            // @ts-ignore
            this.domElement.addEventListener('mouseleave', this.onMouseLeaveLocal);
            // @ts-ignore
            this.domElement.addEventListener('mousemove', this.onMouseMoveLocal);
        } else {
            // @ts-ignore
            this.domElement.removeEventListener('mouseenter', this.onMouseEnterLocal);
            // @ts-ignore
            this.domElement.removeEventListener('mouseleave', this.onMouseLeaveLocal);
            // @ts-ignore
            this.domElement.removeEventListener('mousemove', this.onMouseMoveLocal);
        }
    }

    /**
     * @param event: MouseEvent
     */
    public onMouseEnterLocal = () => {
        if (this.isClickDragging || !this.hasInitialized) return;

        this.hoverSettings.hoverStart();
        this.hoverStartAzimuth = this.getAzimuthalAngle();
        this.hoverStartPolar = this.getPolarAngle();
        this.captureMouse = true;
    }

    /**
     * @param event: MouseEvent
     */
    public onMouseLeaveLocal = () => {
        if (this.isClickDragging || !this.hasStartingPosition) return;

        this.captureMouse = false;
        this.hoverSettings.hoverAnimate(this.hoverStartAzimuth || 1, this.hoverStartPolar || 1, OrbitControlsWithHover.RESET_CAMERA_DURATION);
        this.hoverSettings.hoverEnd();
    }

    public onMouseMoveLocal = (e: MouseEvent) => {
        // eslint-disable-next-line no-bitwise
        if ((e.buttons & 1) || ((e.buttons === undefined) && e.which === 1)) {
            this.isClickDragging = true;
        } else {
            this.isClickDragging = false;
        }

        if (!this.captureMouse || this.isClickDragging || !this.hasStartingPosition) {
            return;
        }

        const target = e.target as HTMLElement;

        const clientWidth = target.offsetWidth;
        const clientHeight = target.offsetHeight;

        const deltaX = e.offsetX - (clientWidth / 2);
        const deltaY = e.offsetY - (clientHeight / 2);

        const azimuthFov = 45;
        const polarFov = this.fov.vertical;

        let azimuthRelativeDegree = (deltaX / clientWidth) * azimuthFov;
        let polarRelativeDegree = (deltaY / clientHeight) * (polarFov || 1);

        if (this.hoverSettings.invertXAxis) {
            azimuthRelativeDegree *= -1;
        }
        if (this.hoverSettings.invertYAxis) {
            polarRelativeDegree *= -1;
        }

        const azimuth = this.hoverStartAzimuth + azimuthRelativeDegree * (Math.PI / 180);
        const polar = this.hoverStartPolar + polarRelativeDegree * (Math.PI / 180);

        this.hoverSettings.hoverAnimate(azimuth, polar, OrbitControlsWithHover.HOVER_CAMERA_DURATION);
    }

    public dispose() {
        super.dispose();

        // @ts-ignore
        this.domElement.removeEventListener('mouseenter', this.onMouseEnterLocal);
        // @ts-ignore
        this.domElement.removeEventListener('mouseleave', this.onMouseLeaveLocal);
        // @ts-ignore
        this.domElement.removeEventListener('mousemove', this.onMouseMoveLocal);
    }
}
