import axios from 'axios';
import { logError } from 'loggingManager';

import { URLs } from '../constants';
import { AuthConfig,AssetQueryBody, AssetQueryParams } from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getAssetList(
    authConfig: AuthConfig,
    assetBody: AssetQueryBody,
    queryParams?: AssetQueryParams) {
    const queryString = queryParams && Object.keys(queryParams)
        .filter((param) => !!queryParams[param])
        .map((param) => `${param}=${queryParams[param]}`)
        .join('&');
    try {
        const { data } = await axios.post(`${URLs.SHERBERT_QUERY_URL}?${queryString}` , assetBody, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
            },
        });
        if (data && data._embedded && Array.isArray(data._embedded.collections)) {
            return data._embedded.collections
        }
        return [];
    } catch (err) {
        logError(`could not fetch data ${err}`);
        return [];
    }
}
