import { CustomPathGeometry, CustomPath, PathOperation, PathOperationType } from "./customPathGeometry";
import { Size3D } from '@rendering/vortex-core/common';

function generateScallopedPath(physicalSize: Size3D, horizontalScallops: number, verticalScallops: number): CustomPath {
    // The scallop size is the products width divided by scallop count supplied
    const horizontalDiameter = physicalSize.width / horizontalScallops;
    const horizontalRadius = horizontalDiameter / 2;

    // The top and bottom scallops can be removed from the product's height
    const verticalScallopHeight = physicalSize.height - horizontalDiameter * 2;

    // Find the amount of vertical scallops that could fit within the remaining height
    const verticalScallopDiameter = verticalScallopHeight / verticalScallops;

    const path: PathOperation[] = [];

    // Initial move and top left 3/4 arc
    path.push({ type: PathOperationType.moveTo, x: horizontalRadius, y: horizontalDiameter });
    path.push({ type: PathOperationType.arcTo, x: horizontalRadius, y: horizontalRadius, radius: horizontalRadius, startAngle: Math.PI * 0.5, endAngle: 0 });

    // Top
    for (let i = 1; i < horizontalScallops - 1; i++) {
        path.push({ type: PathOperationType.arcTo, x: horizontalRadius + i * horizontalDiameter, y: horizontalRadius, radius: horizontalRadius, startAngle: Math.PI, endAngle: 0 });
    }

    const rightX = horizontalRadius + ((horizontalScallops - 1) * horizontalDiameter);

    // Top right 3/4 arc
    path.push({ type: PathOperationType.arcTo, x: rightX, y: horizontalRadius, radius: horizontalRadius, startAngle: Math.PI, endAngle: Math.PI * 0.5 });

    // Now start from the correct Y coordinate of the first vertical scallop
    let currentY = horizontalDiameter + verticalScallopDiameter / 2;

    // Right
    for (let i = 0; i < verticalScallops; i++) {
        path.push({ type: PathOperationType.arcTo, x: rightX, y: currentY, radius: horizontalRadius, startAngle: -Math.PI * 0.5, endAngle: Math.PI * 0.5 });
        currentY += verticalScallopDiameter;
    }

    // Bottom should be exactly the scallop radius subtracted from the product height
    const bottomY = physicalSize.height - horizontalRadius;

    // Bottom right 3/4 arc
    path.push({ type: PathOperationType.arcTo, x: rightX, y: bottomY, radius: horizontalRadius, startAngle: -Math.PI * 0.5, endAngle: Math.PI });

    // Bottom
    for (let i = horizontalScallops - 1; i > 0; i--) {
        path.push({ type: PathOperationType.arcTo, x: horizontalRadius + i * horizontalDiameter, y: bottomY, radius: horizontalRadius, startAngle: 0, endAngle: Math.PI });
    }

    // Bottom left 3/4 arc
    path.push({ type: PathOperationType.arcTo, x: horizontalRadius, y: bottomY, radius: horizontalRadius, startAngle: 0, endAngle: Math.PI * 1.5 });

    // Left
    for (let i = 0; i < verticalScallops + 1; i++) {
        path.push({ type: PathOperationType.arcTo, x: horizontalRadius, y: currentY, radius: horizontalRadius, startAngle: Math.PI * 0.5, endAngle: -Math.PI * 0.5 });
        currentY -= verticalScallopDiameter;
    }

    return { primary: path };
}

export class ScallopedGeometry extends CustomPathGeometry {
    get key(): string {
        return 'scalloped ' + this.horizontalScallops + ' ' + this.verticalScallops;
    }

    private horizontalScallops: number;
    private verticalScallops: number;

    constructor(physicalSize: Size3D, horizontalScallops: number, verticalScallops: number) {
        // Lower the curve segment count to dramatically reduce the geometry faces
        super(generateScallopedPath(physicalSize, horizontalScallops, verticalScallops - 2));

        this.horizontalScallops = horizontalScallops;
        this.verticalScallops = verticalScallops;
    }
}