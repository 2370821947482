import { getGlitterFinish } from './glitter';
import { getMetallicFinish } from './metallic';
import { getRaisedFoilGoldFinish } from './raisedFoilGold';
import { getRaisedFoilSilverFinish } from './raisedFoilSilver';
import { getRaisedInkFinish } from './raisedInk';

import { Finish, FinishType } from './types';

export function getFinish(type: FinishType): Finish {
    switch (type) {
    case FinishType.metallic:
        return getMetallicFinish();
    case FinishType.raisedInk:
        return getRaisedInkFinish();
    case FinishType.raisedFoilGold:
        return getRaisedFoilGoldFinish();
    case FinishType.raisedFoilSilver:
        return getRaisedFoilSilverFinish();
    case FinishType.glitter:
        return getGlitterFinish();
    default:
        throw new Error(`Unknown finish type requested: ${{ type }}`);
    }
}
