import defaults from './defaults';
import { Substrate } from './types';

export function getPremiumGlossySubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 60,
        bumpScale: 0.2,
        reflectivity: 0.06,
        bumpTexture: {
            dpi: 150,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/glossy/v1/bump',
        },
        reflectionMapOverrideUrls: [
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/77329236-4130-40c3-bdb0-91f1721bcd98~110/original?tenant=doc-platform',
        ],
    };
}
