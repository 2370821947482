/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useContext, createContext, ReactNode } from 'react';
import * as React from 'react';

import { Theme, defaultTheme } from './theme';

const themeContext = createContext<Theme>(defaultTheme);

export function useTheme() {
    return useContext(themeContext);
}

export interface ThemeProviderProps {
    /** The theme */
    theme?: Theme;
    /** All components should be nested underneath this provider */
    children: ReactNode | ReactNode[];
}

/**
 * The Provider of the component theme. If this Provider is not used, the default theme would be used instead.
 */
export function ThemeProvider({ theme = defaultTheme, children }: ThemeProviderProps) {
    const { Provider } = themeContext;

    return <Provider value={theme}>{children}</Provider>;
}
