import { Shape } from 'three';
import { Size2D } from "@rendering/vortex-core/common";
import { CustomPath, PathOperation, PathOperationType } from '../flatGeometries';

export interface PointTransform {
    x: (i: number, max: number) => number;
    y: (i: number, max: number) => number;
}

// Preserves the provided value
export function preserve(i: number, max: number): number {
    return i;
}

// Mirrors the values
export function mirror(i: number, max: number): number {
    return -i + max;
}

// Converts a set of provided paths into a shape
export function convertPathToShape(path: PathOperation[], maxSize: Size2D, transformX: Function, transformY: Function): Shape {
    const shape = new Shape();

    for (let i = 0; i < path.length; i++) {
        let operation: PathOperation = path[i];

        switch (operation.type) {
            case PathOperationType.moveTo:
                shape.moveTo(transformX(operation.x, maxSize.width), transformY(operation.y, maxSize.height));
                break;
            case PathOperationType.lineTo:
                shape.lineTo(transformX(operation.x, maxSize.width), transformY(operation.y, maxSize.height));
                break;
            case PathOperationType.quadraticCurveTo:
                shape.quadraticCurveTo(transformX(operation.aCP1x as number, maxSize.width), transformY(operation.aCP1y as number, maxSize.height),
                    transformX(operation.x, maxSize.width), transformY(operation.y, maxSize.height));
                break;
            case PathOperationType.bezierCurveTo:
                shape.bezierCurveTo(transformX(operation.aCP1x as number, maxSize.width), transformY(operation.aCP1y as number, maxSize.height),
                    transformX(operation.aCP2x as number, maxSize.width), transformY(operation.aCP2y as number, maxSize.height),
                    transformX(operation.x, maxSize.width), transformY(operation.y, maxSize.height));
                break;
            case PathOperationType.arcTo:
                // TODO fully debug transformation of arc points (requires moving x and y by angles)
                shape.absarc(operation.x, operation.y, operation.radius as number,
                    operation.startAngle as number, operation.endAngle as number, false);
                break;
        }
    }

    return shape;
}

// Converts a custom path into a shape including any holes for cutouts
export function convertToShape(customPath: CustomPath, maxSize: Size2D, primaryTransform: PointTransform, cutoutTransform: PointTransform): Shape {
    // Convert the primary path to a shape
    const shape: Shape = convertPathToShape(customPath.primary, maxSize, primaryTransform.x, primaryTransform.y);

    // Add any cutouts as holes to the shape
    if (customPath.cutouts) {
        customPath.cutouts.forEach(path => shape.holes.push(convertPathToShape(path, maxSize, cutoutTransform.x, cutoutTransform.y)));
    }

    return shape;
}
