import { VortexProduct } from "@rendering/vortex-core/products";
import { GltfProduct } from "../gltfModel";
import { ColladaProduct } from "../colladaModel";
import { ModelConfig, ModelType } from "./config";

// Load a vortex product if model can be loaded from given configuration
export async function loadModelFromConfig(modelConfig: ModelConfig): Promise<VortexProduct> {
    const modelResponse = await fetch(modelConfig.modelUri);

    if (modelResponse.status >= 400) {
        throw `Failed to fetch the model asset at '${modelConfig.modelUri}'`;
    }

    // Try to determine the model format by its type
    if (modelConfig.type) {
        if (modelConfig.type === ModelType.dae) {
            return new ColladaProduct(modelConfig);
        } else if (modelConfig.type === ModelType.glb) {
           return new GltfProduct(modelConfig);
        } else {
            throw new Error(`The model type: ${modelConfig.type} is not supported!`);
        }
    }

    // Otherwise look at the headers to determine glb vs dae
    const isGlt = modelResponse.headers.get('content-type')?.includes('binary');

    return isGlt ? new GltfProduct(modelConfig) : new ColladaProduct(modelConfig);
}