import { Box3, BoxGeometry, Mesh, MeshBasicMaterial, Object3D, Vector3 } from 'three';
import { Preview } from '../preview';
import { ProductState, VortexProduct, BoundingBox } from '../products';
import { Animation, AnimationDirection } from '../animations';
import { EPSILON } from '../config';

export interface StateOptions {
    duration?: number;
    centerGeometry?: boolean;
    scaleToFitGeometry?: boolean;
}

export async function instantlyChangeState(stateId: string, preview: Preview): Promise<void> {
    // Create a state with a near zero duration
    const statePromise = preview.transitionState(stateId, { duration: EPSILON });

    // Update animation by one second to assure it finishes
    preview.updateAnimations(1);

    // Wait for the state to finish (instantaneously)
    await statePromise;
}

export async function instantlyPlayAnimation(animationId: string, product: VortexProduct): Promise<void> {
    // Build an animation with near zero duration
    const animation: Animation = product.buildAnimation({
        animationId,
        direction: AnimationDirection.forward,
        duration: EPSILON,
    });

    const animationPromise = animation.start();

    // Update animation by one second to assure it finishes
    animation.update(1);

    // Wait for the animation to finish (instantaneously)
    await animationPromise;
}

export async function getStateBounds(preview: Preview, product: VortexProduct, geometry: Object3D, normalizationFactor?: number): Promise<{ [key: string]: Box3 }> {
    const stateBounds: { [key: string]: Box3 } = {};

    const initialStateId: string = product.getCurrentState().id;
    const productStates: ProductState[] = product.getStates();

    // Find the bounds for each state
    // NOTE: needs to run async to prevent conflict as it modifies the same object
    for (const state of productStates) {
        if (state.boundingBox) {
            const box = buildBoundingBoxGeometry(state.boundingBox, normalizationFactor)
            stateBounds[state.id] = new Box3().setFromObject(box);
        } else {
            await instantlyChangeState(state.id, preview);
            stateBounds[state.id] = new Box3().setFromObject(geometry);
        }
    }

    // Reset the model to its initial state before finishing
    await instantlyChangeState(initialStateId, preview);

    return stateBounds;
}

export function buildBoundingBoxGeometry(boundingBox: BoundingBox, scaleFactor?: number): Object3D {
    // calculate dimensions of the bounding box
    const width = boundingBox.max.x - boundingBox.min.x;
    const height = boundingBox.max.y - boundingBox.min.y;
    const depth = boundingBox.max.z - boundingBox.min.z;

    // calculate center point position of bounding box
    const x = (boundingBox.max.x + boundingBox.min.x) / 2.0;
    const y = (boundingBox.max.y + boundingBox.min.y) / 2.0;
    const z = (boundingBox.max.z + boundingBox.min.z) / 2.0;

    // create box geometry and translate to correct position 
    // as BoxGeometry is created with its center at the origin
    const boundingBoxGeometry = new BoxGeometry(width, height, depth);
    boundingBoxGeometry.translate(x, y, z);

    const material = new MeshBasicMaterial({ color: 0x00ff00 });
    const box = new Mesh(boundingBoxGeometry, material);

    if (scaleFactor) {
        // Scale and position the geometry based on the scaleFactor
        box.scale.multiplyScalar(scaleFactor);
        box.position.multiplyScalar(scaleFactor);
    }

    return box;
}
