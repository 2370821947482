import { onDragEnd, onDragStart } from './dragAndDrop';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Attributes, modifySVG } from './svgs/svgModifier';
import { SvgDefaults } from './constants';
import { Shape, getSvgFromShape } from './shapes';

interface Props {
    shape: Shape;
    strokeWidth?: number;
    fillColor?: string;
    stroke?: string;
    width: number;
    height: number;
    isDraggable: boolean;
    onDrag?: (shape: Shape) => void;
}

export function ShapeSvg({ shape, strokeWidth, fillColor, stroke, width, height, isDraggable, onDrag }: Props): JSX.Element {
    const [svg, setSvg] = useState<string>();
    const referenceImg = useRef<HTMLImageElement>(null);

    useEffect(() => {
        getSvgFromShape(
            shape,
            width,
            height,
            stroke || SvgDefaults.stroke,
            strokeWidth || SvgDefaults.strokeWidth,
            fillColor || SvgDefaults.fillColor
        ).then((data) => setSvg(data.replace(/#/g, '%23')));
    }, [fillColor, height, shape, stroke, strokeWidth, width]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSVGDragStart = (event: any, svg: string) => {
        onDrag && onDrag(shape);

        const attrs: Attributes = {
            'stroke-width': '2',
            fill: fillColor || SvgDefaults.fillColor,
            stroke: stroke || SvgDefaults.stroke,
        };
        const modifiedSvgString = modifySVG(svg, attrs);
        const image = document.createElement('img');
        image.setAttribute('src', `data:image/svg+xml;utf8,${modifiedSvgString.replace(/#/g, '%23')}`);
        onDragStart(event, image);
    };

    return (
        <img
            ref={referenceImg}
            src={`data:image/svg+xml;utf8,${svg}`}
            draggable={isDraggable}
            {...(isDraggable && svg && { onDragStart: (event) => onSVGDragStart(event, svg), onDragEnd: onDragEnd })}
        />
    );
}
