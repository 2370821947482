import { Size3D } from '@rendering/vortex-core/common';
import { CustomPathGeometry, CustomPath, PathOperation, PathOperationType } from "./customPathGeometry";

function generateRoundedPath(physicalSize: Size3D, radius: number): CustomPath {
    const path: PathOperation[] = [];

    path.push({ type: PathOperationType.moveTo, x: 0, y: radius });
    path.push({ type: PathOperationType.lineTo, x: 0, y: physicalSize.height - radius });
    path.push({ type: PathOperationType.quadraticCurveTo, x: radius, y: physicalSize.height, aCP1x: 0, aCP1y: physicalSize.height });
    path.push({ type: PathOperationType.lineTo, x: physicalSize.width - radius, y:  physicalSize.height });
    path.push({ type: PathOperationType.quadraticCurveTo, x: physicalSize.width, y: physicalSize.height - radius, aCP1x: physicalSize.width, aCP1y: physicalSize.height });
    path.push({ type: PathOperationType.lineTo, x: physicalSize.width, y: radius });
    path.push({ type: PathOperationType.quadraticCurveTo, x: physicalSize.width - radius, y: 0, aCP1x: physicalSize.width, aCP1y: 0 });
    path.push({ type: PathOperationType.lineTo, x: radius, y: 0 });
    path.push({ type: PathOperationType.quadraticCurveTo, x: 0, y: radius, aCP1x: 0, aCP1y: 0 });

    return { primary: path };
}

export class RoundedGeometry extends CustomPathGeometry {
    get key(): string {
        return 'rounded ' + this.radius;
    }

    private radius: number;

    constructor(physicalSize: Size3D, radius: number) {
        super(generateRoundedPath(physicalSize, radius));
        
        this.radius = radius;
    }
}