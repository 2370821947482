import { Action } from '@rendering/vortex-core/actions';
import { disposeGeometryRecursively, RenderSize, Size2D, Size3D, UVMapPlane } from '@rendering/vortex-core/common';
import { Preview } from '@rendering/vortex-core/preview';
import { ProductState, ProductSurface } from '@rendering/vortex-core/products';
import { Group, Vector2, Vector3, PlaneGeometry, Mesh, Light, PointLight, DirectionalLight, Object3D, Box3 } from 'three';
import { FoldAnimationDirection, FoldAnimationOptions, NFoldAnimation } from './animations';
import { applyRotation, getRotationAxis, getZScaleRatios, rotateVector } from './helpers';
import { FoldDirection, FoldedProductConfig, NFoldOptions, PanelEdge, ProductRotation } from './types';
import { Finish } from '@rendering/vortex-core/finishes';
import { Substrate } from '@rendering/vortex-core/substrates';
import { PaperProduct } from '../common';
import { AnimationDirection, Animation, AnimationOptions } from '@rendering/vortex-core/animations';

export class NFoldProduct implements PaperProduct {
    name: string = 'n-fold';

    private surfaceMapping: { [key: string]: ProductSurface; };
    private geometry?: Group;

    private currentState: ProductState;
    private productStates: ProductState[];

    private foldCommands: FoldDirection[];
    private nfoldOptions: NFoldOptions;
    private panelRatiosH: number[];
    private panelRatiosV: number[];
    private numberOfPanels: number;

    private depth: number;
    private size: Size2D;
    private closedSize?: Size3D;
    private openSize?: Size3D;

    private rotation: ProductRotation;

    requiresGeometryUpdate: boolean = false;

    constructor(config: FoldedProductConfig) {
        this.surfaceMapping = {};
        this.numberOfPanels = config.nFoldOptions.horizontalRatios.length * config.nFoldOptions.verticalRatios.length;
        this.panelRatiosH = config.nFoldOptions.horizontalRatios;
        this.panelRatiosV = config.nFoldOptions.verticalRatios;
        this.nfoldOptions = config.nFoldOptions;
        this.foldCommands = config.nFoldOptions.foldDirections.reduce((a, b) => { return a.concat(b); }, []).sort((a, b) => (a.ratioIndex > b.ratioIndex) ? 1 : -1);
        this.depth = config.depth;
        this.size = config.fullBleed;
        this.rotation = config.rotation ?? ProductRotation.none;

        // if a trim exists use that as the size
        if (config.trim) {
            this.size = {
                width: config.trim.bottomRightX - config.trim.topLeftX,
                height: config.trim.bottomRightY - config.trim.topLeftY,
            }
        }

        this.openSize = { width: this.size.width, height: this.size.height, depth: this.depth }

        const surfaceFront: ProductSurface = {
            substrate: config.productSurfaces.front.substrate,
            fullBleed: config.fullBleed,
            trim: config.trim,
            page: config.productSurfaces.front.page,
            finishes: config.productSurfaces.front.finishes
        };

        const surfaceBack: ProductSurface = {
            substrate: config.productSurfaces.back.substrate,
            fullBleed: config.fullBleed,
            trim: config.trim,
            page: config.productSurfaces.back.page,
            finishes: config.productSurfaces.back.finishes
        };

        const surfaceBody: ProductSurface = {
            substrate: config.productSurfaces.back.substrate,
            fullBleed: config.fullBleed,
            trim: config.trim
        };

        for (let i = 0; i < config.nFoldOptions.verticalRatios.length; i++) {
            for (let j = 0; j < config.nFoldOptions.horizontalRatios.length; j++) {
                this.surfaceMapping[`front-${i}-${j}`] = surfaceFront;
                this.surfaceMapping[`back-${i}-${j}`] = surfaceBack;

                this.surfaceMapping[`left-${i}-${j}`] = this.getJoinMaterials(j, PanelEdge.left, surfaceFront, surfaceBack, surfaceBody);
                this.surfaceMapping[`right-${i}-${j}`] = this.getJoinMaterials(j, PanelEdge.right, surfaceFront, surfaceBack, surfaceBody);

                this.surfaceMapping[`top-${i}-${j}`] = surfaceBody;
                this.surfaceMapping[`bottom-${i}-${j}`] = surfaceBody;
            }
        }

        this.productStates = [
            { id: 'Folded' },
            {
                id: 'Unfolded',
                dependency: {
                    stateId: 'Folded',
                    animationId: 'Fold',
                    animationDirection: AnimationDirection.backward
                }
            }
        ];

        this.currentState = this.productStates[0];
    }

    setSubstrate(substrate: Substrate, surfaceId?: string) {
        // Update a specific surface if its provided, otherwise update all of them
        if (surfaceId) {
            this.surfaceMapping[surfaceId].substrate = { ...substrate };
        } else {
            for (const surfaceKey in this.surfaceMapping) {
                this.surfaceMapping[surfaceKey].substrate = { ...substrate };
            }
        }
    }

    setFinishes(finishes: Finish[]) {
        for (let key in this.surfaceMapping) {
            if (key.startsWith('front')) {
                this.surfaceMapping[key].finishes = finishes;
            }
        }
        this.requiresGeometryUpdate = true;
    }

    private getJoinAngles(): number[] {
        const joins: number[] = new Array(this.numberOfPanels - 1).fill(0);
        for (let i = 0; i < this.numberOfPanels; i++) {
            let foldCommand = this.foldCommands[i];
            if (foldCommand.edge === PanelEdge.right && i < this.numberOfPanels - 1) {
                joins[i] -= foldCommand.angleClosed;
            }
            else if (foldCommand.edge === PanelEdge.left && i > 0) {
                joins[i - 1] += foldCommand.angleClosed;
            }
        }
        return joins;
    }

    private getJoinMaterials(index: number, edge: PanelEdge, front: ProductSurface, back: ProductSurface, body: ProductSurface): ProductSurface {
        const joins: number[] = this.getJoinAngles();
        const edgeOffset = edge === PanelEdge.left ? 0 : 1;
        const i = index + edgeOffset;

        if (i === 0 || i === this.numberOfPanels) {
            return front;
        } // the length of joins is this.numberOfPanels-1
        else if (joins[i - 1] >= 0) {
            return back;
        }
        else {
            return front;
        }
    }

    private buildFrontUVRanges(ratios: number[]): Vector2[] {
        const uvRanges: Vector2[] = [];
        let currentLeft = 0;
        for (let i = 0; i < ratios.length; i++) {
            uvRanges.push(new Vector2(currentLeft, currentLeft + ratios[i]));
            currentLeft += ratios[i]
        }

        return uvRanges;
    }

    private buildBackUVRanges(ratios: number[]): Vector2[] {
        const uvRanges: Vector2[] = [];
        let currentLeft = 0;
        for (let i = ratios.length - 1; i >= 0; i--) {
            uvRanges.push(new Vector2(currentLeft, currentLeft + ratios[i]));
            currentLeft += ratios[i];
        }

        return uvRanges.reverse();
    }

    async getGeometry(): Promise<Group> {

        this.dispose();

        this.geometry = new Group();

        const frontUvRanges = this.buildFrontUVRanges(this.panelRatiosH);
        const backUvRanges = this.buildBackUVRanges(this.panelRatiosH);

        const verticalUvRanges = this.buildFrontUVRanges(this.panelRatiosV);

        for (let i = 0; i < this.panelRatiosV.length; i++) {
            const panelHeight = this.size.height * this.panelRatiosV[i];
            const vertRange = verticalUvRanges[i];
            for (let j = 0; j < this.panelRatiosH.length; j++) {
                const panelGroup = new Group();
                const panelWidth = this.size.width * this.panelRatiosH[j];

                // front panel
                const frontFace = new PlaneGeometry(panelWidth, panelHeight);
                UVMapPlane(frontFace, frontUvRanges[j].x, frontUvRanges[j].y, vertRange.x, vertRange.y);
                const frontMesh = new Mesh(frontFace);
                frontMesh.uuid = `front-${i}-${j}`;
                frontMesh.position.z = this.depth / 2;
                panelGroup.add(frontMesh);

                const finishOffset = 0.001
                const surfaceFront = this.surfaceMapping[`front-${i}-${j}`];
                if (surfaceFront.finishes) {
                    let finishDepthOffset = finishOffset * 2;

                    surfaceFront.finishes.forEach(element => {
                        const finishPlane = new PlaneGeometry(panelWidth, panelHeight);
                        UVMapPlane(finishPlane, frontUvRanges[j].x, frontUvRanges[j].y, vertRange.x, vertRange.y);
                        const frontFinish = new Mesh(finishPlane);
                        frontFinish.uuid = `front-${i}-${j}-` + element.name;

                        frontFinish.position.z = frontMesh.position.z;
                        finishDepthOffset += finishOffset;

                        panelGroup.add(frontFinish);
                    });
                }

                // back panel
                const backFace = new PlaneGeometry(panelWidth, panelHeight);
                UVMapPlane(backFace, backUvRanges[j].x, backUvRanges[j].y, vertRange.x, vertRange.y);
                const backMesh = new Mesh(backFace);
                backMesh.uuid = `back-${i}-${j}`;
                backMesh.rotation.y = Math.PI;
                backMesh.position.z = -this.depth / 2;
                panelGroup.add(backMesh);

                const depthRatio = this.depth / this.size.width;

                // left panel
                const leftFace = new PlaneGeometry(this.depth, panelHeight);
                UVMapPlane(leftFace, backUvRanges[j].x - depthRatio, backUvRanges[j].x + depthRatio, vertRange.x, vertRange.y);
                const leftMesh = new Mesh(leftFace);
                leftMesh.uuid = `left-${i}-${j}`;
                leftMesh.rotation.y = Math.PI / 2;
                leftMesh.position.x = panelWidth / 2;
                panelGroup.add(leftMesh);

                // right panel
                const rightFace = new PlaneGeometry(this.depth, panelHeight);
                UVMapPlane(rightFace, backUvRanges[j].x - depthRatio, backUvRanges[j].x + depthRatio, vertRange.x, vertRange.y);
                const rightMesh = new Mesh(rightFace);
                rightMesh.uuid = `right-${i}-${j}`;
                rightMesh.rotateY(-Math.PI / 2);
                rightMesh.position.x = -panelWidth / 2;
                panelGroup.add(rightMesh);

                // top panel
                const topFace = new PlaneGeometry(panelWidth, this.depth);
                const topMesh = new Mesh(topFace);
                topMesh.uuid = `top-${i}-${j}`;
                topMesh.rotateX(-Math.PI / 2);
                topMesh.position.y = -1 * panelHeight / 2;
                panelGroup.add(topMesh);

                // bottom panel
                const bottomFace = new PlaneGeometry(panelWidth, this.depth);
                const bottomMesh = new Mesh(bottomFace);
                bottomMesh.uuid = `bottom-${i}-${j}`;
                bottomMesh.rotateX(Math.PI / 2);
                bottomMesh.position.y = panelHeight / 2;
                panelGroup.add(bottomMesh);

                this.geometry.add(panelGroup);
            }
        }

        const scaleRatiosZ = getZScaleRatios(this.nfoldOptions);
        for (let i = 0; i < this.panelRatiosV.length; i++) {
            let meshHeight = this.panelRatiosV.slice(0, i + 1).reduce(function (total, num) { return total + num });
            for (let j = 0; j < this.panelRatiosH.length; j++) {
                const panel = this.geometry.children[i * this.panelRatiosH.length + j];
                const meshPosition = this.panelRatiosH.slice(0, j + 1).reduce(function (total, num) { return total + num });
                panel.position.y = this.size.height * meshHeight - this.size.height * this.panelRatiosV[i] * 0.5 - this.size.height / 2
                panel.position.x = this.size.width * meshPosition - this.size.width * this.panelRatiosH[j] * 0.5 - this.size.width / 2;
                // Slim panels that will be stacked
                panel.scale.setZ(scaleRatiosZ[i * this.panelRatiosH.length + j]);
            }
        }
        this.geometry.userData['openPosition'] = this.geometry.position.clone();

        // set closed state
        for (let i = 0; i < this.foldCommands.length; i++) {
            const foldDirection = this.foldCommands[i];

            const rotationPanel = this.getRotationPanel(foldDirection);

            //  Flag to signify which side of the rotating panel we're rotating about
            const modifier = foldDirection.edge === PanelEdge.right || foldDirection.edge === PanelEdge.top ? -1 : 1;
            const angleModifier = foldDirection.angleClosed > 0 ? 1 : -1;

            const rotationMesh = rotationPanel.children[0] as Mesh;
            const rotationMesh2 = rotationPanel.children[1] as Mesh;

            // Ensure bounding box is generated for the geometry
            if (rotationMesh.geometry.boundingBox === null || rotationMesh.geometry.boundingBox === undefined) {
                rotationMesh.geometry.computeBoundingBox();
                rotationMesh2.geometry.computeBoundingBox();
            }

            if (rotationMesh.geometry.boundingBox === null || rotationMesh2.geometry.boundingBox === null) {
                throw "unable to compute bounding box";
            }

            const panelWidth = Math.max(rotationMesh.geometry.boundingBox.max.x, rotationMesh2.geometry.boundingBox.max.x) - Math.min(rotationMesh.geometry.boundingBox.min.x, rotationMesh2.geometry.boundingBox.min.x);
            const panelHeight = Math.max(rotationMesh.geometry.boundingBox.max.y, rotationMesh2.geometry.boundingBox.max.y) - Math.min(rotationMesh.geometry.boundingBox.min.y, rotationMesh2.geometry.boundingBox.min.y);

            // The edge of a panel we'll be rotating about
            let rotationPoint = new Vector3(rotationPanel.position.x + modifier * panelWidth / 2, rotationPanel.position.y - modifier * panelHeight / 2, rotationPanel.position.z - modifier * this.depth * rotationPanel.scale.z * 0.5 * angleModifier);
            // Rotate the edge about the center of the panel with the panels rotation
            rotationPoint = rotateVector(rotationPoint, rotationPanel.position, rotationPanel.rotation);

            // The idea here is to rotate the panel about it's edge at the center, and then shift it into place.
            const rotationAxis: Vector3 = getRotationAxis(foldDirection.edge);

            const targetPanels = this.findRotationPanels(foldDirection);
            targetPanels.forEach(panel => applyRotation(panel, rotationPoint, foldDirection.angleClosed, rotationAxis));
        }

        this.geometry.rotation.y = Math.PI;

        if (this.nfoldOptions.rotationOffset) {
            applyRotation(this.geometry, new Vector3(0, 0, 0), this.nfoldOptions.rotationOffset.yaw, new Vector3(1, 0, 0));
            applyRotation(this.geometry, new Vector3(0, 0, 0), this.nfoldOptions.rotationOffset.pitch, new Vector3(0, 1, 0));
            applyRotation(this.geometry, new Vector3(0, 0, 0), this.nfoldOptions.rotationOffset.roll, new Vector3(0, 0, 1));
        }

        const geometryContainer = new Box3().setFromObject(this.geometry);
        const geometryCenter = geometryContainer.getCenter(new Vector3());
        let geometrySize = geometryContainer.getSize(new Vector3());

        this.geometry.translateX(geometryCenter.x);
        this.geometry.translateY(geometryCenter.y);
        this.geometry.translateZ(geometryCenter.z);

        if (this.rotation === ProductRotation.rotate_90) {
            applyRotation(this.geometry, new Vector3(0, 0, 0), Math.PI / 2, new Vector3(0, 0, 1));
            geometrySize = new Vector3(geometrySize.y, geometrySize.x, geometrySize.z);
        } else if (this.rotation === ProductRotation.rotate_270) {
            applyRotation(this.geometry, new Vector3(0, 0, 0), Math.PI * 3 / 2, new Vector3(0, 0, 1));
            geometrySize = new Vector3(geometrySize.y, geometrySize.x, geometrySize.z);
        }
        this.geometry.userData['state'] = 'closed';
        this.geometry.userData['closedPosition'] = this.geometry.position.clone();

        this.closedSize = { width: geometrySize.x, height: geometrySize.y, depth: geometrySize.z };

        this.requiresGeometryUpdate = false;

        return Promise.resolve(this.geometry);
    }

    getSurfaceMapping(): { [key: string]: ProductSurface; } {
        return this.surfaceMapping;
    }

    setProductSurface(surfaceId: string, productSurface: ProductSurface) {
        if (this.surfaceMapping[surfaceId]) {
            this.surfaceMapping[surfaceId] = productSurface;
        } else {
            throw new Error(`'${surfaceId}' is not a surface on this N-Fold product`);
        }
    };

    getCurrentState(): ProductState {
        return this.currentState;;
    }

    setCurrentState(stateId: string) {
        const newState = this.productStates.find(state => state.id === stateId);
        if (newState) {
            this.currentState = newState;
        } else {
            throw new Error(`'${stateId} is not a state defined in this product!`);
        }
    }

    getStates(): ProductState[] {
        return this.productStates;
    }

    setStates(productState: ProductState[]): void { }

    getAnimationIds(): string[] {
        return [];
    }

    buildAnimation(animationOptions: AnimationOptions): Animation {
        const options: FoldAnimationOptions = {
            direction: animationOptions.direction === AnimationDirection.forward ? FoldAnimationDirection.close : FoldAnimationDirection.open,
            duration: animationOptions.duration,
            nFoldOptions: this.nfoldOptions,
            depth: this.depth
        };

        return new NFoldAnimation(this.geometry as Object3D, options);
    }

    getAlphaMap(_renderSize: RenderSize, _physicalSize: Size2D, _context: CanvasRenderingContext2D): void { }

    getAreaLights(pointLightIntensity: number, directionalLightIntensity: number): Light[] {
        const lights: Light[] = [];

        // Add in directional lighting
        const frontDirection = new DirectionalLight(0xffffff, 0.12 * directionalLightIntensity);
        frontDirection.position.set(0, 0, 200);

        const backDirectional = new DirectionalLight(0xffffff, 0.12 * directionalLightIntensity);
        backDirectional.position.set(0, 0, -200);

        lights.push(frontDirection);
        lights.push(backDirectional);

        // Add in point lighting
        const frontLight = new PointLight(0xffffff, 0.25 * pointLightIntensity, 500);
        frontLight.position.set(0, 0, 300);

        const backLight = new PointLight(0xffffff, 0.25 * pointLightIntensity, 500);
        backLight.position.set(0, 0, -300);

        lights.push(frontLight);
        lights.push(backLight);

        return lights;
    }

    open(preview: Preview, duration?: number): Promise<void> {
        const animationOptions: AnimationOptions = {
            animationId: 'fold',
            direction: AnimationDirection.backward,
            duration: duration || 2
        }

        const action: Action = {
            id: 'n-fold-open',
            animation: this.buildAnimation(animationOptions),
            cameraOptions: {
                duration,
                geometrySize: { width: this.size.width, height: this.size.height, depth: this.depth }
            }
        }
        this.setCurrentState('Unfolded');
        return preview.performAction(action);
    }

    close(preview: Preview, duration?: number): Promise<void> {
        const animationOptions: AnimationOptions = {
            animationId: 'fold',
            direction: AnimationDirection.forward,
            duration: duration || 2
        }

        const action: Action = {
            id: 'n-fold-close',
            animation: this.buildAnimation(animationOptions),
            cameraOptions: {
                duration,
                geometrySize: this.closedSize
            }
        }

        this.setCurrentState('Folded');
        return preview.performAction(action).then(_ => {
            // Try to get an accurate open size
            if (this.geometry) {
                const geometryContainer = new Box3().setFromObject(this.geometry);
                const geometrySize = geometryContainer.getSize(new Vector3());
                this.openSize = { width: geometrySize.x, height: geometrySize.y, depth: geometrySize.z };
            }
        });
    }

    preLoad() {
        return Promise.resolve()
    }

    dispose() {
        if (this.geometry !== undefined) {
            disposeGeometryRecursively(this.geometry);
            this.geometry = undefined;
        }
    }

    private getRotationPanel(foldDirection: FoldDirection) {
        const edge = foldDirection.edge;
        const geometry = this.geometry as Group;
        if (edge === PanelEdge.left || edge === PanelEdge.right) {
            const rotationPanelIndex: number = foldDirection.edge === PanelEdge.left ? foldDirection.ratioIndex - 1 : foldDirection.ratioIndex + 1;
            return geometry.children[rotationPanelIndex];
        }
        const rotationPanelIndex: number = foldDirection.edge === PanelEdge.top ? foldDirection.ratioIndex - 1 : foldDirection.ratioIndex + 1;
        return geometry.children[rotationPanelIndex * this.panelRatiosH.length];
    }

    private findRotationPanels(foldDirection: FoldDirection): Object3D[] {
        const edge = foldDirection.edge;
        const geometry = this.geometry as Group;

        const targetPanels: Object3D[] = [];

        let minHor = 0;
        let maxHor = this.panelRatiosH.length;

        let minVert = 0;
        let maxVert = this.panelRatiosV.length;

        // Move all panels to the right
        if (foldDirection.edge === PanelEdge.left) {
            minHor = foldDirection.ratioIndex;
        } else if (edge === PanelEdge.right) {
            maxHor = foldDirection.ratioIndex + 1;
        }
        else if (edge === PanelEdge.bottom) {
            maxVert = foldDirection.ratioIndex + 1;
        }
        else {
            minVert = foldDirection.ratioIndex;
        }

        for (let i = minVert; i < maxVert; i++) {
            for (let j = minHor; j < maxHor; j++) {
                targetPanels.push(geometry.children[i * this.panelRatiosH.length + j]);
            }
        }

        return targetPanels;
    }
}
