import { Position3D, Size2D } from "@rendering/vortex-core/common";
import { ProductSurface } from "@rendering/vortex-core/products";
import { ModelSurface } from "./config";
import { Group, Box3, Vector3, Object3D } from 'three';

export function getSurfaceMapping(productMapping: { [key: string]: ModelSurface; }, fullBleed: Size2D): { [key: string]: ProductSurface } {
    const keys = Object.keys(productMapping);

    const surfaceMapping: { [key: string]: ProductSurface } = {};
    keys.forEach(key => {
        const prodSurface: ProductSurface = {
            substrate: productMapping[key].substrate,
            page: productMapping[key].page,
            fullBleed: productMapping[key].fullBleed || fullBleed,
            trim: productMapping[key].trim,
            focalPoint: productMapping[key].focalPoint
        }
        surfaceMapping[key] = prodSurface;
    });
    return surfaceMapping;
}

export function centerModel(positionOffset: Position3D | undefined, meshGroup: Group){
    // Find the center of the geometry
    const bounds = new Box3().setFromObject(meshGroup);
    const center: Vector3 = bounds.getCenter(new Vector3());

    // Apply position offsets if they exist
    if (positionOffset) {
        center.x -= positionOffset.x;
        center.y -= positionOffset.y;
        center.z -= positionOffset.z;
    }

    // Translate the center of the model to the origin
    meshGroup.position.set(-center.x, -center.y, -center.z);
    meshGroup.updateMatrix();

    return meshGroup;
}

export function buildSurfaceMapRecursively(surfaceMap: { [key: string]: ProductSurface }, group: Object3D, primary: ProductSurface) {
    if (!group.children || group.children.length === 0) {
        return;
    }

    group.children.forEach(child => {
        if (child.type === 'Mesh') {
            surfaceMap[child.uuid] = primary
        }

        buildSurfaceMapRecursively(surfaceMap, child, primary);
    });
}