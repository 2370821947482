import defaults from './defaults';
import { Substrate } from './types';

export function getGlossySubstrate(): Substrate {
    return {
        ...defaults,
        shininess: 40,
        bumpScale: 0.2,
        reflectivity: 0.04,
        bumpTexture: {
            dpi: 150,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/glossy/v1/bump',
        },
        reflectionMapOverrideUrls: [
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
            'https://uploads.documents.cimpress.io/v1/uploads/53b31c7f-6c22-47fc-9deb-46a4ffdb3f02~110/original?tenant=doc-platform',
        ],
    };
}
