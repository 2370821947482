import { Mesh, BoxGeometry, PlaneGeometry } from 'three';
import { FlatGeometry } from "./flatGeometry";
import { Size3D } from '@rendering/vortex-core/common';

export class RectangularGeometry implements FlatGeometry {
    key = 'rectangular';
    
    buildFront(physicalSize: Size3D): Mesh {
        return new Mesh(new PlaneGeometry(physicalSize.width, physicalSize.height));
    }

    buildBack(physicalSize: Size3D): Mesh {
        return new Mesh(new PlaneGeometry(physicalSize.width, physicalSize.height));
    }

    buildBody(physicalSize: Size3D): Mesh {
        return new Mesh(new BoxGeometry(physicalSize.width, physicalSize.height, physicalSize.depth));
    }
}