import { useState, useEffect } from 'react';
import { Breakpoint } from '../theme';

function getBreakpointInfo() {
    const { innerWidth: width } = window;

    let breakpoint;
    let isSmallDevice = false;
    let isMediumDevice = false;
    let isLargeDevice = false;
    let isXLargeDevice = false;

    if (width <= Breakpoint.S) {
        breakpoint = Breakpoint.S;
        isSmallDevice = true;
    } else if (width <= Breakpoint.M) {
        breakpoint = Breakpoint.M;
        isMediumDevice = true;
    } else if (width <= Breakpoint.L) {
        breakpoint = Breakpoint.L;
        isLargeDevice = true;
    } else {
        breakpoint = Breakpoint.XL;
        isXLargeDevice = true;
    }

    return {
        breakpoint,
        isSmallDevice,
        isMediumDevice,
        isLargeDevice,
        isXLargeDevice,
    };
}
export function useBreakpointInfo() {
    const [viewportInfo, setViewportInfo] = useState(() => getBreakpointInfo());

    useEffect(() => {
        function handleResize() {
            setViewportInfo(getBreakpointInfo());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return viewportInfo;
}
