import { Substrate } from './types';

export function getCanvasSubstrate(): Substrate {
    return {
        color: 0xFFFFFF,
        shininess: 20,
        bumpScale: 6,
        bumpTexture: {
            dpi: 150,
            sourceUrl: 'https://vortex.documents.cimpress.io/substrates/canvas/v1/bump',
        },
    };
}
