import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cimpress/react-components';
import isFunction from 'lodash/isFunction';

import './ProductCard.css';
import ActionSection from './Section/ActionSection';
import PreviewSection from './Section/PreviewSection';
import DetailsSection from './Section/DetailsSection';
import FooterSection from './Section/FooterSection';

class ProductCard extends PureComponent {
  state = {
    isMenuVisible: false,
    preloadedImage: '',
  };

  onClick = (event) => {
    this.props.onClick(event, this.props.uniqueId);
  };

  onSelectionChanged = (event) => {
    this.props.onSelectionChanged(event, this.props.uniqueId);
  };

  toggleMenu = () => {
    if (!this.state.isMenuVisible) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  };

  openMenu = () => {
    this.setState({ isMenuVisible: true });
  };

  closeMenu = () => {
    this.setState({ isMenuVisible: false });
  };

  onImageLoaded = (image) => {
    const { onImageLoaded, uniqueId } = this.props;

    this.setState({ preloadedImage: image });

    if (isFunction(onImageLoaded)) {
      onImageLoaded(uniqueId);
    }
  }

  isLoading = () => {
    const { loading, waitForImageLoad, imageUrl } = this.props;

    if (!waitForImageLoad) {
      return loading;
    }

    const { preloadedImage } = this.state;

    return preloadedImage !== imageUrl ? true : loading;
  }

  renderOverlayArea = (props) => {
    if (props.loading) {
      return null;
    }

    if (props.size !== 's') {
      return null;
    }

    return (
      <div className="overlay">
        <div className="overlayButton">
          <Button type="default" size="sm" onClick={this.onClick}>
            View
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const sizeClass = `size-${this.props.size}`;
    const orientationClass = `orientation-${this.props.orientation}`;
    const selectedClass = this.props.isSelected ? 'selected' : '';
    const OverlayArea = this.renderOverlayArea;
    const loading = this.isLoading();
    return (
      <div
        className={`cardContainer ${sizeClass} ${orientationClass} ${selectedClass} ${this.props.className}`}
        onMouseLeave={this.closeMenu}
        role="presentation"
      >
        <OverlayArea
          loading={loading}
          size={this.props.size}
        />
        <ActionSection
          actions={this.props.actions}
          onSelectionChanged={this.onSelectionChanged}
          size={this.props.size}
          showMenu={this.state.isMenuVisible}
          onMenuIconClick={this.toggleMenu}
          onClickOutside={this.closeMenu}
          checked={this.props.isSelected}
          orientation={this.props.orientation}
          showCheckbox={!this.props.notSelectable}
          topLeftAction={this.props.topLeftAction}
          callbackData={this.props.uniqueId}
          loading={loading}
        />
        <PreviewSection
          imageUrl={this.props.imageUrl}
          imageClassName={this.props.imageClassName}
          allowImageClick={this.props.allowImageClick}
          onClick={this.onClick}
          hyperlink={this.props.hyperlink}
          loading={loading}
          size={this.props.size}
          onImageLoaded={this.onImageLoaded}
          preloadImage={this.props.waitForImageLoad || isFunction(this.props.onImageLoaded)}
        />
        <DetailsSection
          productName={this.props.productName}
          onNameClick={this.onClick}
          price={this.props.price}
          productDetails={this.props.productDetails}
          size={this.props.size}
          orientation={this.props.orientation}
          hyperlink={this.props.hyperlink}
          loading={loading}
        />
        <FooterSection
          productFulfiller={this.props.productFulfiller}
          tag={this.props.tag}
          tags={this.props.tags}
          size={this.props.size}
          loading={loading}
        />
      </div>
    );
  }
}

ProductCard.propTypes = {
  orientation: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  })),
  onSelectionChanged: PropTypes.func,
  imageUrl: PropTypes.string,
  productName: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  onClick: PropTypes.func,
  productFulfiller: PropTypes.string,
  tag: PropTypes.shape({ title: PropTypes.string, color: PropTypes.string }),
  tags: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  })),
  price: PropTypes.node,
  productDetails: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  notSelectable: PropTypes.bool,
  uniqueId: PropTypes.string,
  imageClassName: PropTypes.string,
  hyperlink: PropTypes.string,
  allowImageClick: PropTypes.bool,
  loading: PropTypes.bool,
  onImageLoaded: PropTypes.func,
  waitForImageLoad: PropTypes.bool,
  topLeftAction: PropTypes.node,
};

ProductCard.defaultProps = {
  orientation: 'vertical',
  size: 'l',
  isSelected: false,
  className: '',
  actions: [],
  onSelectionChanged: () => null,
  onClick: () => null,
  tag: null,
  tags: [],
  price: null,
  productDetails: [],
  imageUrl: '',
  notSelectable: false,
  uniqueId: null,
  imageClassName: '',
  hyperlink: null,
  allowImageClick: false,
  productFulfiller: null,
  loading: false,
  onImageLoaded: null,
  waitForImageLoad: false,
  topLeftAction: null,
};

export default ProductCard;
