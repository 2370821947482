/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { setAppUser, setCurrentShape } from './commands/command.currentSvg';
import { useTheme } from './component-library/theme';
import { getDefaultShapes, getShapes, OptionValueType, Shape } from './shapes';
import i18n from './translation/i18n';

import ShapeTypeList from './ShapeTypeList';
import { resources } from './translation/resources';
import { customScrollStyle } from './customScrollStyle';
import { SupportedLanguages, Layout, iconFetchAppId, devIconFetchAppId, IsDevDeployment } from './constants';
import { useBreakpointInfo } from './component-library/hooks';
import { AuthConfig, FlipOptions } from './types';
import { DragAndDrop, enbaleDragAndDrop } from './dragAndDrop';
import { EditorView } from 'EditorView';
import { getAssetList } from './services/assert.service';

export interface SmartShapesProps {
    locale?: string;
    hostApp: string;
    tenantId: string;
    authConfig: AuthConfig;
    layout?: Layout;
    dragAndDrop?: DragAndDrop;
}

const getLayoutContainerStyle = () => {
    return css`
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;

        .smart-shapes__list-view {
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            width: 100%;
        }

        .smart-shapes__editor-view {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

        .shape-type-list {
            overflow-y: auto;
            height: 100%;
        }
    `;
};

export const SmartShapes = ({
    locale,
    tenantId,
    hostApp,
    authConfig,
    layout = Layout.PreviewOnTop,
    dragAndDrop,
}: SmartShapesProps): JSX.Element => {
    i18n.init({
        lng: locale,
        fallbackLng: SupportedLanguages.find((l) => l.default)?.code || 'en-US',
        resources,
    });
    const theme = useTheme();
    const { isSmallDevice } = useBreakpointInfo();
    const [selectedShape, setSelectedShape] = useState<Shape>(getShapes()[0]);
    const [isEditingShape, setIsEditingShape] = useState(false);
    const [isFetchingShapes, setIsFetchingShapes] = useState(false);
    const [shapeList, setShapeList] = useState<{ collectionName: string; items: Shape[] }[]>([
        {
            collectionName: 'Basic Shape',
            items: [...getDefaultShapes(), ...getShapes()],
        },
    ]);

    useEffect(() => {
        if (dragAndDrop) {
            enbaleDragAndDrop(dragAndDrop);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAppUser({ tenantId, hostApp, authConfig });
    }, [authConfig, hostApp, tenantId]);

    const handleShapeSelect = (shape: Shape): void => {
        setSelectedShape(shape);
        setIsEditingShape(true);
    };

    const onEditCancel = () => {
        setIsEditingShape(false);
    };

    const handleShapeDrag = (shape: Shape): void => {
        setSelectedShape(shape);
    };

    const setOptionValue = (optionId: string, value: OptionValueType): void => {
        if (selectedShape) {
            const options = selectedShape.options
                ? selectedShape.options.map((option) => (optionId === option.id ? { ...option, value } : option))
                : [];

            setSelectedShape({ ...selectedShape, options });
        }
    };

    const getAssets = async () => {
        setIsFetchingShapes(true);
        const assetBodyObj = {
            metadata: {
                'designassets': 'tags',
            },
            app: IsDevDeployment ? devIconFetchAppId : iconFetchAppId ,
        };
        const collections = await getAssetList(authConfig, assetBodyObj);
        if (collections.length) {
            setShapeList([...shapeList, ...collections]);
        }
        setIsFetchingShapes(false);
    };

    useEffect(() => {
        setCurrentShape(selectedShape);
    }, [selectedShape]);

    useEffect(() => {
        if (authConfig.value) getAssets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authConfig.value]);

    const flipHandler = (flip: FlipOptions) => {
        setSelectedShape({...selectedShape, horizontalFlip: flip.horizontalFlip, verticalFlip: flip.verticalFlip });
    }

    return (
        <div
            className='smart-shapes'
            css={css`
                ${getLayoutContainerStyle()}
                ${!isSmallDevice && customScrollStyle}
            `}
        >
            <Fragment>
                {!isEditingShape ? (
                    <div className='smart-shapes__list-view'>
                        <ShapeTypeList
                            shapeList={shapeList}
                            isFetchingShapes={isFetchingShapes}
                            onSelect={handleShapeSelect}
                            isDraggable={!!dragAndDrop}
                            {...(!!dragAndDrop && { onDrag: handleShapeDrag })}
                        />
                    </div>
                ) : (
                    <div className='smart-shapes__editor-view'>
                        <EditorView
                            shape={selectedShape}
                            isDraggable={!!dragAndDrop}
                            theme={theme}
                            layout={layout}
                            onEditCancel={onEditCancel}
                            setOptionValue={setOptionValue}
                            flip={flipHandler}
                        />
                    </div>
                )}
            </Fragment>
        </div>
    );
};
