import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { colors } from '@cimpress/react-components';
import IconWithTooltip from '../Utility/IconWithTooltip';
import { getIconColor } from '../helper';

class FooterSection extends PureComponent {
  renderProductTags = () => {
    const tagColor = get(this.props, 'tag.color', colors.ocean.base);
    if (!isEmpty(this.props.tags) && Array.isArray(this.props.tags)) {
      return (
        <div className="multipleTag">
          {this.props.tags.slice(0, 5).map(x => (
            <IconWithTooltip
              key={x.title}
              icon={x.icon}
              tooltipContent={x.title}
              iconColor={getIconColor(x.type)}
            />
          ))}
        </div>
      );
    }
    if (!isEmpty(this.props.tag)) {
      return (
        <div className="singleTag" style={{ backgroundColor: tagColor }}>
          {this.props.tag.title}
        </div>
      );
    }
    return null;
  };

  render() {
    if (this.props.size === 's') {
      return null;
    }
    const { loading } = this.props;
    return (
      <div className="footerArea flexRowSpacing">
        <div className={`productFulfiller ${loading && 'animated-background animated-width-20'}`}>{this.props.productFulfiller}</div>
        {loading ? null : <div className="productTags">{this.renderProductTags()}</div>}
      </div>
    );
  }
}

FooterSection.propTypes = {
  productFulfiller: PropTypes.string,
  tag: PropTypes.shape({ title: PropTypes.string, color: PropTypes.string }),
  tags: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  })),
  size: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

FooterSection.defaultProps = {
  tag: null,
  tags: null,
  productFulfiller: null,
  loading: false,
};

export default FooterSection;
