import { PerspectiveCamera, Vector3 } from 'three';
import { OrbitControlsWithHover } from './orbitControlsWithHover';
import { Size3D, easeInOutQuart } from '../common';
import { MAX_GEOMETRY_DIMENSION, TWO_PI } from '../config';
import { OrbitOptions } from './orbitControls';

const VERTICAL_FOV = 40;
const NEAR = 5;
const FAR = 3000;

export interface CameraOptions {
    panning: boolean;
    hover: boolean;
    rotationSpeed?: number;

    invertXAxis?: boolean;
    invertYAxis?: boolean;

    minZoom: number;
    maxZoom: number;

    containerPadding: number;
    initialOrientation?: CameraOrientation;
    orbitOptions?: OrbitOptions;
}

export interface DomOptions {
    aspectRatio: number;
    domElement: HTMLElement;
}

export interface FocusAnimation {
    animating: boolean;
    elapsedTime: number;
    duration: number;
    initialOrbitRadius: number;
    targetOrbitRadius: number;
}

export interface OrientationAnimation {
    animating: boolean;
    elapsedTime: number;
    duration: number;
    target: CameraOrientation;
    initial: CameraOrientation;
    easingFunction: (time: number, startVal: number, deltaVal: number, duration: number) => number;
}

export interface ZoomAnimation {
    animating: boolean;
    elapsedTime: number;
    duration: number;
    initialZoom: number;
    targetZoom: number;
    easingFunction: (time: number, startVal: number, deltaVal: number, duration: number) => number;
}

export interface CameraOrientation {
    azimuthAngle: number;
    polarAngle: number;
}

const defaultOptions: CameraOptions = {
    panning: false,
    hover: false,
    invertXAxis: false,
    invertYAxis: false,
    minZoom: 1,
    maxZoom: 1,
    containerPadding: 30,
};

export class OrbitalCamera {
    private camera: PerspectiveCamera;
    private controls: OrbitControlsWithHover;

    private minZoom: number;
    private maxZoom: number;
    private containerPadding: number;

    private lastPosition: Vector3;
    private initialOrbitRadius: number;
    private _requiresUpdate: boolean;
    private focusAnimation: FocusAnimation;
    private rotationAnimation: OrientationAnimation;
    private zoomAnimation: ZoomAnimation;

    private focusPromiseResolver?: (value: void | PromiseLike<void>) => void;
    private lookAtPromiseResolver?: (value: void | PromiseLike<void>) => void;
    private zoomPromiseResolver?: (value: void | PromiseLike<void>) => void;

    constructor(cameraOptions: CameraOptions, { aspectRatio, domElement }: DomOptions) {
        const { panning, hover, invertXAxis, invertYAxis, minZoom, maxZoom, containerPadding } = { ...defaultOptions, ...cameraOptions };

        // Initialize and orient the camera
        this.camera = new PerspectiveCamera(VERTICAL_FOV, aspectRatio, NEAR, FAR);

        const initialOrientation: CameraOrientation = cameraOptions.initialOrientation ?? { azimuthAngle: 0, polarAngle: Math.PI / 2 };

        this.setPosition(initialOrientation.azimuthAngle, initialOrientation.polarAngle, 1);

        this.controls = new OrbitControlsWithHover(
            this.camera,
            domElement,
            { vertical: VERTICAL_FOV },
            {
                invertXAxis,
                invertYAxis,
            },
            cameraOptions.orbitOptions,
        );

        this.controls.rotateSpeed = cameraOptions.rotationSpeed ?? 0.07;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.1;

        // Hovering should effect both the control hover (attaches mouse bindings) and
        // rotation which prevents the camera from moving if bindings are set
        this.controls.enableHover = hover;
        this.controls.enableRotate = hover;

        this.controls.enablePan = panning;
        this.minZoom = minZoom;
        this.maxZoom = maxZoom;
        this.containerPadding = containerPadding;

        // Disable zooming when min and max zoom are the same
        this.controls.enableZoom = this.minZoom !== this.maxZoom;
        this.lastPosition = new Vector3();
        this._requiresUpdate = true;

        // Rotation animation defaults
        this.rotationAnimation = {
            animating: false,
            duration: 2,
            elapsedTime: 0,
            target: {
                azimuthAngle: 0,
                polarAngle: 0,
            },
            initial: {
                azimuthAngle: 0,
                polarAngle: 0,
            },
            easingFunction: easeInOutQuart,
        };

        // Focus animation defaults
        this.focusAnimation = {
            animating: false,
            elapsedTime: 0,
            duration: 2,
            targetOrbitRadius: 0,
            initialOrbitRadius: 0,
        };

        // Focus animation defaults
        this.zoomAnimation = {
            animating: false,
            elapsedTime: 0,
            duration: 0.5,
            targetZoom: 0,
            initialZoom: 0,
            easingFunction: easeInOutQuart,
        };

        this.initialOrbitRadius = this.orbitRadius;
    }

    get requiresUpdate(): boolean {
        return this._requiresUpdate;
    }

    get orbitRadius(): number {
        return this.camera.position.length();
    }

    set orbitRadius(length) {
        this.controls.minDistance = this.minZoom * length;
        this.controls.maxDistance = this.maxZoom * length;

        this.setPosition(this.controls.getAzimuthalAngle(), this.controls.getPolarAngle(), length);
    }

    get zoomFactor(): number {
        return this.initialOrbitRadius / this.orbitRadius;
    }

    // zoom value is restricted by defined max and min zooms in update function
    adjustZoomFactor(
        zoom: number,
        duration = 0.5,
        easingFunction: (time: number, startVal: number, deltaVal: number, duration: number) => number = easeInOutQuart,
    ): Promise<void> {
        // zoom factor can only be greater than zero and be between the specified min and max values
        if (zoom > 0 && zoom >= this.minZoom && zoom <= this.maxZoom) {
            // If there was an existing task, resolve it and move on
            if (this.zoomPromiseResolver) {
                this.zoomPromiseResolver();
            }

            this.zoomAnimation.elapsedTime = 0;
            this.zoomAnimation.duration = duration;

            this.zoomAnimation.initialZoom = this.orbitRadius / this.initialOrbitRadius;
            this.zoomAnimation.targetZoom = zoom;

            this.zoomAnimation.animating = true;
            this.zoomAnimation.easingFunction = easingFunction;

            return new Promise<void>((resolve) => {
                this.zoomPromiseResolver = resolve;
            });
        }
        return Promise.resolve();
    }

    // Get the zoom factor relative to the initial geometry scale and internal camera zoom
    get normalizedZoomFactor(): number {
        return (this.orbitRadius * this.controls.getZoomScale()) / MAX_GEOMETRY_DIMENSION;
    }

    computeOrbitRadius(geometrySize: Size3D) {
        this.orbitRadius = this.calculateOrbitRadius(geometrySize);
        this.initialOrbitRadius = this.orbitRadius;

        this.lastPosition = new Vector3(0, 0, 0);
    }

    set aspectRatio(newAspectRatio: number) {
        // Store the initial azimuth and polar angles
        const azimuth = this.controls.getAzimuthalAngle();
        const polar = this.controls.getPolarAngle();

        // Update the camera projection, this will reset azimuth and polar angles
        this.camera.aspect = newAspectRatio;
        this.camera.updateProjectionMatrix();

        this.setPosition(azimuth, polar, this.orbitRadius);
    }

    update(deltaTime: number) {
        // Set all the current camera properties
        let targetOrbitRadius = this.orbitRadius;

        if (this.focusAnimation.animating) {
            const animation = this.focusAnimation;

            targetOrbitRadius = easeInOutQuart(
                animation.elapsedTime,
                animation.initialOrbitRadius,
                animation.targetOrbitRadius - animation.initialOrbitRadius,
                animation.duration,
            );
            animation.elapsedTime += deltaTime;

            // If the animation is finished ensure the camera is set to the right position
            if (animation.elapsedTime > animation.duration) {
                animation.animating = false;
                targetOrbitRadius = animation.targetOrbitRadius;

                this.focusPromiseResolver && this.focusPromiseResolver();
                this.focusPromiseResolver = undefined;
            }

            this.orbitRadius = targetOrbitRadius;
        }

        if (this.rotationAnimation.animating) {
            let targetAzimuthAngle = this.controls.getAzimuthalAngle();
            let targetPolarAngle = this.controls.getPolarAngle();
            const animation = this.rotationAnimation;

            // Interpolate the azimuth and polar angles
            targetAzimuthAngle = this.rotationAnimation.easingFunction(
                animation.elapsedTime,
                animation.initial.azimuthAngle,
                animation.target.azimuthAngle - animation.initial.azimuthAngle,
                animation.duration,
            );
            targetPolarAngle = this.rotationAnimation.easingFunction(
                animation.elapsedTime,
                animation.initial.polarAngle,
                animation.target.polarAngle - animation.initial.polarAngle,
                animation.duration,
            );

            animation.elapsedTime += deltaTime;

            // If the animation is finished ensure the camera is set to the right position
            if (animation.elapsedTime > animation.duration) {
                targetAzimuthAngle = animation.target.azimuthAngle;
                targetPolarAngle = animation.target.polarAngle;

                animation.animating = false;
                this.lookAtPromiseResolver && this.lookAtPromiseResolver();
                this.lookAtPromiseResolver = undefined;
            }

            this.setPosition(targetAzimuthAngle, targetPolarAngle, targetOrbitRadius);
        }

        if (this.zoomAnimation.animating) {
            const animation = this.zoomAnimation;
            let targetZoom = animation.easingFunction(
                animation.elapsedTime,
                animation.initialZoom,
                animation.targetZoom - animation.initialZoom,
                animation.duration,
            );

            animation.elapsedTime += deltaTime;

            if (animation.elapsedTime > animation.duration) {
                targetZoom = animation.targetZoom;
                animation.animating = false;
                this.zoomPromiseResolver && this.zoomPromiseResolver();
                this.zoomPromiseResolver = undefined;
            }

            this.setPosition(this.controls.getAzimuthalAngle(), this.controls.getPolarAngle(), targetZoom * this.initialOrbitRadius);
        }

        this.controls.update();

        // Require a render update if the camera position changed since last frame
        this._requiresUpdate = this.lastPosition.distanceTo(this.camera.position) > 0.0001;
        this.lastPosition = this.camera.position.clone();
    }

    lookAt(
        target: CameraOrientation,
        duration = 2,
        easingFunction: (time: number, startVal: number, deltaVal: number, duration: number) => number = easeInOutQuart,
    ): Promise<void> {
        // If there was an existing task, resolve it and move on
        if (this.lookAtPromiseResolver) {
            this.lookAtPromiseResolver();
        }

        // Normalize both the initial and target orientations between 0 and 2pi
        const initialOrientation: CameraOrientation = this.normalizeOrientation({
            azimuthAngle: this.controls.getAzimuthalAngle(),
            polarAngle: this.controls.getPolarAngle(),
        });

        const targetOrientation: CameraOrientation = this.normalizeOrientation(target);

        // Ensure that azimuth rotations are less than PI (camera doesn't spin awkwardly)
        if (Math.abs(targetOrientation.azimuthAngle - initialOrientation.azimuthAngle) > Math.PI) {
            if (targetOrientation.azimuthAngle > initialOrientation.azimuthAngle) {
                initialOrientation.azimuthAngle += TWO_PI;
            } else {
                targetOrientation.azimuthAngle += TWO_PI;
            }
        }

        // Ensure that polar rotations are less than PI (camera doesn't spin awkwardly)
        if (Math.abs(targetOrientation.polarAngle - initialOrientation.polarAngle) > Math.PI) {
            if (targetOrientation.polarAngle > initialOrientation.polarAngle) {
                initialOrientation.polarAngle += TWO_PI;
            } else {
                targetOrientation.polarAngle += TWO_PI;
            }
        }

        this.rotationAnimation.elapsedTime = 0;
        this.rotationAnimation.duration = duration;

        this.rotationAnimation.initial = initialOrientation;
        this.rotationAnimation.target = targetOrientation;

        this.rotationAnimation.animating = true;
        this.rotationAnimation.easingFunction = easingFunction;

        return new Promise((resolve) => {
            this.lookAtPromiseResolver = resolve;
        });
    }

    adjustFocus(geometrySize: Size3D, duration = 2): Promise<void> {
        // If there was an existing task, resolve it and move on
        if (this.focusPromiseResolver) {
            this.focusPromiseResolver();
        }

        const targetOrbitRadius = this.calculateOrbitRadius(geometrySize);

        this.focusAnimation.duration = duration;
        this.focusAnimation.initialOrbitRadius = this.orbitRadius;
        this.focusAnimation.targetOrbitRadius = targetOrbitRadius;

        this.focusAnimation.animating = true;
        this.focusAnimation.elapsedTime = 0;

        return new Promise((resolve) => {
            this.focusPromiseResolver = resolve;
        });
    }

    dispose() {
        if (this.lookAtPromiseResolver) {
            this.lookAtPromiseResolver();
        }

        if (this.focusPromiseResolver) {
            this.focusPromiseResolver();
        }

        if (this.zoomPromiseResolver) {
            this.zoomPromiseResolver();
        }

        this.controls.dispose();
    }

    get threeJsCamera() {
        return this.camera;
    }

    private calculateOrbitRadius(geometrySize: Size3D): number {
        const verticalFov = VERTICAL_FOV * (Math.PI / 180);
        const horizontalFov = 2 * Math.atan(this.camera.aspect * Math.tan(verticalFov / 2));

        const verticalDistance = (geometrySize.height / 2 + this.containerPadding) / Math.tan(verticalFov / 2);
        const horizontalDistance = (geometrySize.width / 2 + this.containerPadding) / Math.tan(horizontalFov / 2);
        const depthDistance = (geometrySize.depth / 2 + this.containerPadding) / Math.tan(verticalFov / 2);

        return Math.max(verticalDistance, horizontalDistance, depthDistance);
    }

    private setPosition(azimuthAngle: number, polarAngle: number, orbitRadius: number) {
        const unitRotation = new Vector3(Math.sin(azimuthAngle) * Math.sin(polarAngle), Math.cos(polarAngle), Math.cos(azimuthAngle) * Math.sin(polarAngle));

        // Set the camera position to the unit vector scaled by the desired distance
        this.camera.position.copy(unitRotation.multiplyScalar(orbitRadius));
    }

    /**
     * @param  {CameraOrientation} input
     * @returns CameraOrientation
     * TODO: This should use the this.camera or be converted in a util
     */
    // eslint-disable-next-line class-methods-use-this
    private normalizeOrientation(input: CameraOrientation): CameraOrientation {
        let outputAzimuthAngle = input.azimuthAngle % TWO_PI;
        let outputPolarAngle = input.polarAngle % TWO_PI;

        if (outputAzimuthAngle < 0) {
            outputAzimuthAngle += TWO_PI;
        }

        if (outputPolarAngle < 0) {
            outputPolarAngle += TWO_PI;
        }

        return {
            azimuthAngle: outputAzimuthAngle,
            polarAngle: outputPolarAngle,
        };
    }
}
